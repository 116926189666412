import React from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Input, Spacing, Select, FormFieldProps } from '@netfront/ui-library';
import { Control, Controller } from 'react-hook-form';

import { ScheduleTabYearlyProps } from './ScheduleTabYearly.interfaces';


const ScheduleTabYearly = ({ control }: ScheduleTabYearlyProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <>
      <Spacing>
        <Controller
          control={control as Control<FormFieldProps>}
          name="yearlyOccurEvery"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              iconAfter={{
                isTextOnly: true,
                helperText: 'Year(s)'
              }}
              id="id_scenario_yearly_occurrence"
              labelText="Recur every"
              minNumber={1}
              tooltipText="How regularly will this scenario run in years"
              type="number"
              isLabelSideBySide
              isRequired
              {...field}
              
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control as Control<FormFieldProps>}
          name="yearlyDay"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              
              id="id_scenario_yearly_day"
              labelText="Day"
              maxNumber={30}
              minNumber={1}
              type="number"
              isLabelSideBySide
              isRequired
              {...field}
              
            />
          )}
        />
      </Spacing>
      <Spacing size="x-large">
        <Controller
          control={control as Control<FormFieldProps>}
          name="yearlyMonth"
          render={({ field, fieldState }) => (
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_yearly_occurrence_month"
              labelText="Month"
              options={[
                { id: 1, name: 'January', value: 1 },
                { id: 2, name: 'February', value: 2 },
                { id: 3, name: 'March', value: 3 },
                { id: 4, name: 'April', value: 4 },
                { id: 5, name: 'May', value: 5 },
                { id: 6, name: 'June', value: 6 },
                { id: 7, name: 'July', value: 7 },
                { id: 8, name: 'August', value: 8 },
                { id: 9, name: 'September', value: 9 },
                { id: 10, name: 'October', value: 10 },
                { id: 11, name: 'November', value: 11 },
                { id: 12, name: 'December', value: 12 },
              ]}
              {...field}
              isLabelSideBySide
              isRequired
            />
          )}
        />
      </Spacing>
      
    </>
  );
};

export { ScheduleTabYearly };
