/* eslint-disable @typescript-eslint/indent */
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_AVAILABLE_PROJECT_INSIGHTS_QUERY } from './useGetProjectInsights.graphql';
import {
  IGetProjectInsightsQueryGraphQLResponse,
  IGetProjectInsightsQueryVariables,
  IUseGetProjectInsights,
  IUseGetProjectInsightsOptions,
} from './useGetProjectInsights.interfaces';

const useGetProjectInsights = (options?: IUseGetProjectInsightsOptions): IUseGetProjectInsights => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetProjectInsightsOptions);

  const [executeGetProjectInsights, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetProjectInsightsQueryGraphQLResponse,
    IGetProjectInsightsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ insights: { configuration } }) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const { availableInsights = [] } = configuration || {};

        if (!onCompleted) {
          return;
        }

        onCompleted({
          availableInsights,
        });
      },
      onError,
    },
    query: GET_AVAILABLE_PROJECT_INSIGHTS_QUERY,
    token,
  });

  const handleGetProjectInsights = async ({ projectId }: IGetProjectInsightsQueryVariables) => {
    await executeGetProjectInsights({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetProjectInsights,
    isLoading,
  };
};

export { useGetProjectInsights };
