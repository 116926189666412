import { gql } from '@apollo/client';

const CREATE_EVENT_MUTATION = gql`
  mutation CreateEvent($inputType: CreateEventCommunityInputType!) {
    community {
      createEvent(inputType: $inputType) {
        bannerImage {
          assetId
          fileName
          fileSizeInBytes
          s3Key
          presignedUrl
          contentType
        }
        categoryId
        description
        id
        privacy
        profileImage {
          assetId
          contentType
          fileName
          fileSizeInBytes
          presignedUrl
          s3Key
        }
        start
        end
        status
        title
      }
    }
  }
`;

export { CREATE_EVENT_MUTATION };
