import React, { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import {
  ControlledForm,
  Dialog,
  FormFieldProps,
  Input,
  ISearchList,
  SelectWithSearch,
  SidebarButtons,
  Spacing,
  Spinner,
  useControlledForm,
} from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { useAddTestUser, useDeleteTestUser, useGetPaginatedProjectUsers, useGetUserById, useToast } from 'hooks';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';

import { TestModeGeneralTabProps } from './TestModeGeneralTab.interfaces';



const TestModeGeneralTab = ({
  selectedTestUser,
  onClose,
  onUpdate,
  projectId,
  isSidebarOpen = false,
}: TestModeGeneralTabProps) => {
  const { handleToastError } = useToast();
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [responseTitle, setResponseTitle] = useState<string>();
  const [searchSuggestions, setSearchSuggestions] = useState<ISearchList[]>([]);

  const handleError = (error: ApolloError) => {
    handleToastError({
      error,
    });
  };


  const { control, handleSubmit, reset, setValue } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        userId: yup.number().required('User'),
      }),
    ),
  });

  const { handleAddTestUser, isLoading: isAddTestUserLoading = false } = useAddTestUser({
    onCompleted: () => {
      reset();
      onUpdate();
    },
    onError: handleError,
  });

  const { handleDeleteTestUser, isLoading: isDeleteTestUserLoading = false } = useDeleteTestUser({
    onCompleted: () => {
      reset();
      onUpdate();
    },
    onError: handleError,
  });

  const { handleGetUserById, isLoading: isGetUserByIdLoading = false } = useGetUserById({
    onCompleted: ({ phoneNumber }) => {
      const {
        email = '', 
        id: userId, 
        firstname: firstName = '', 
        lastname: lastName = '' 
      } = selectedTestUser ?? {};

      setDefaultValues({
        email,
        userId,
        firstName,
        lastName,
        phoneNumber,
      });
    },
    onError: handleError,
  });

  const { handleGetPaginatedProjectUsers, isLoading: isGetPaginatedProjectUsersLoading = false } = useGetPaginatedProjectUsers({
    onCompleted: ({ users: { edges } }) => {
      const projectUsers = edges.map(({ node }) => node);

      const suggestions = projectUsers.map(({ id, firstName, lastName, communityName }) => ({
        id,
        label: firstName ? `${firstName} ${lastName}`: communityName ,
      }));
      setSearchSuggestions(suggestions);
    },
    onError: handleError,
  });


  const handleDelete = () => {
    if (!selectedTestUser) return;
    handleDeleteTestUser({
      userId: selectedTestUser.id,
      projectId,
    })
  };

  const handleSave = (item: FormFieldProps) => {
    const { userId } = item;

    handleAddTestUser({
      projectId,
      userId,
    });
  };

  const handleSearchItemClick = (selectedId: string | number) => {
    setValue('userId', Number(selectedId));
    setResponseTitle(searchSuggestions.find(({ id }) => id === selectedId)?.label ?? '')
  };


  useEffect(() => {
    if (!isSidebarOpen) return;


    if (selectedTestUser) {
      handleGetUserById({
        userId: selectedTestUser.id,
        projectId,
      });
    } else {
      void handleGetPaginatedProjectUsers({
        projectId,
        status: 'ACTIVE',
      });

      setDefaultValues({
        email: '',
        userId: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
      });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTestUser]);

  const isLoading = isAddTestUserLoading || isDeleteTestUserLoading || isGetUserByIdLoading || isGetPaginatedProjectUsersLoading;


  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        isStopPropagation
      >
        

        { selectedTestUser ? (
          <>
            <Spacing>
              <Controller
                control={control as Control<FormFieldProps>}
                name="firstName"
                render={({ field }) => (
                  <Input
                    id="id_test_user_first_name"
                    labelText="First name"
                    type="text"
                    isDisabled
                    isLabelSideBySide
                    {...field}
                    
                  />
                )}
              />
            </Spacing>
            <Spacing>
              <Controller
                control={control as Control<FormFieldProps>}
                name="lastName"
                render={({ field }) => (
                  <Input
                    id="id_test_user_last_name"
                    labelText="Last name"
                    type="text"
                    isDisabled
                    isLabelSideBySide
                    {...field}
                    
                  />
                )}
              />
            </Spacing>
            <Spacing>
              <Controller
                control={control as Control<FormFieldProps>}
                name="phoneNumber"
                render={({ field }) => (
                  <Input
                    id="id_test_user_phone_number"
                    labelText="Phone number"
                    type="text"
                    isDisabled
                    isLabelSideBySide
                    {...field}
                    
                  />
                )}
              />
            </Spacing>
            <Spacing>
              <Controller
                control={control as Control<FormFieldProps>}
                name="email"
                render={({ field }) => (
                  <Input
                    id="id_test_user_email"
                    labelText="Email"
                    type="text"
                    isDisabled
                    isLabelSideBySide
                    {...field}
                  />
                )}
              />
            </Spacing>
          </>
        ): (
          <Spacing>
            <Controller
              control={control as Control<FormFieldProps>}
              name="userId"
              render={({ fieldState }) => (
                <SelectWithSearch
                  buttonText="Add team member"
                  countText="users"
                  defaultValue={responseTitle}
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_content_group"
                  isDisplaySearchContent={isSearchContentVisible}
                  isLabelHidden={false}
                  labelText="Search"
                  searchList={searchSuggestions}
                  tooltipText="Add team member to test group to test scenarios before sending to the project users"
                  isAvatarVisible
                  isLabelSideBySide
                  isRequired
                  onDisplaySearchContent={() => setSearchIsContentVisible(!isSearchContentVisible)}
                  onSearchItemClick={handleSearchItemClick}
                />
              )}
            />
          </Spacing>
        )}

        <SidebarButtons
          buttonSize="xs"
          isSaveButtonDisabled={Boolean(selectedTestUser)}
          onCancel={onClose}
          onDelete={selectedTestUser ? () => setIsDeleteDialogOpen(true) : undefined}
          onSaveButtonType="submit"
        />
      </ControlledForm>
      { selectedTestUser && (
        <Dialog
          isOpen={isDeleteDialogOpen}
          title={`Delete test user: ${String(defaultValues?.firstName ?? '')} ${String(defaultValues?.lastName ?? '')}?`}
          isNarrow
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={handleDelete}
        />
      )}
      
    </SidebarContainer>
  );
};

export { TestModeGeneralTab };
