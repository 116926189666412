const DESCRIPTION_CHARACTER_MAX_LENGTH = 500;

const TITLE_CHARACTER_MAX_LENGTH = 50;

const CONDITION_DELAY_MIN_NUMBER = 0;

const CONDITION_PERCENTAGE_MAX_NUMBER = 100;
const CONDITION_PERCENTAGE_MIN_NUMBER = 0;

const CONDITION_DAY_OPTIONS = [
  { labelText: 'Monday', id: 'monday', value: 'MONDAY' },
  { labelText: 'Tuesday', id: 'tuesday', value: 'TUESDAY' },
  { labelText: 'Wednesday', id: 'wednesday', value: 'WEDNESDAY' },
  { labelText: 'Thursday', id: 'thursday', value: 'THURSDAY' },
  { labelText: 'Friday', id: 'friday', value: 'FRIDAY' },
  { labelText: 'Saturday', id: 'saturday', value: 'SATURDAY' },
  { labelText: 'Sunday', id: 'sunday', value: 'SUNDAY' },
];

const CONDITION_MET_OPTIONS = [
  { labelText: 'True', id: 'exists', value: 'EXISTS' },
  { labelText: 'False', id: 'not-exists', value: 'NOT_EXISTS' },
];

const CONDITION_STEP_PROGRESSION_VALUE_COMPARATOR_OPTIONS = [
  { labelText: 'Greater', id: 'greater', value: 'GREATER' },
  { labelText: 'Lower', id: 'lower', value: 'LOWER' },
  { labelText: 'Equals', id: 'equal', value: 'EQUALS' },
];

const CONDITION_SELECT_TYPES = [
  {
    id: 1,
    label: 'User registered',
    value: 'userRegistered',
  },
  {
    id: 2,
    label: 'User activated',
    value: 'userActivated',
  },
  {
    id: 3,
    label: 'Login',
    value: 'userLogin',
  },
  {
    id: 4,
    label: 'Last login',
    value: 'userLastLogin',
  },
  {
    id: 5,
    label: 'Session',
    value: 'userSession',
  },
  {
    id: 6,
    label: 'Step started',
    value: 'stepStarted',
  },
  {
    id: 7,
    label: 'Step completed',
    value: 'stepCompleted',
  },
  {
    id: 8,
    label: 'Step progression',
    value: 'stepProgression',
  },
  {
    id: 9,
    label: 'Hour',
    value: 'hour',
  },
  {
    id: 10,
    label: 'Day',
    value: 'day',
  },
  {
    id: 11,
    label: 'User groups',
    value: 'manyUserGroup',
  },
];

const CONDITION_GROUP_OPERATOR_TYPES_OPTIONS = [
  {
    id: 1,
    labelText: 'AND',
    value: 'AND',
  },
  {
    id: 2,
    labelText: 'OR',
    value: 'OR',
  },
];

const CONDITION_TYPE_MAP: { [key: string]: string} = Object.freeze({
  ConditionStepCompletedGraphType: 'stepCompleted',
  ConditionStepStartedGraphType: 'stepStarted',
  ConditionStepProgressionGraphType: 'stepProgression',
  ConditionUserActivatedGraphType: 'userActivated',
  ConditionUserLoginGraphType: 'userLogin',
  ConditionUserRegisteredGraphType: 'userRegistered',
  ConditionLastLoginGraphType: 'userLastLogin',
  ConditionDayGraphType: 'day',
  ConditionHourGraphType: 'hour',
  ConditionUserSessionGraphType: 'userSession',
  ConditionManyUserGroupGraphType: 'manyUserGroup',
});

const CONDITION_TYPE_MESSAGE_MAP = Object.freeze({
  stepCompleted: "Based on any tracking completed for the selected content",
  stepStarted: "Based on any tracking started for the selected content",
  stepProgression: "Based on any tracking percentage completed for the selected content",
  userActivated: 'Based on the date the user activated their account',
  userLogin: 'Based on any user login',
  userRegistered: 'Based on the date the account was created',
  userLastLogin: 'Based on the last user login',
  day: 'Defines what day the condition will be met',
  hour: 'Defines what hour the condition will be met',
  userSession: 'Based on any user session',
  manyUserGroup: 'The condition will only be met if the user is in one of the selected user groups',
});

const scenarioConditionTabConstants = Object.freeze({
  conditionDayOptions: CONDITION_DAY_OPTIONS,
  conditionDelayMinNumber: CONDITION_DELAY_MIN_NUMBER,
  conditionPercentageMaxNumber: CONDITION_PERCENTAGE_MAX_NUMBER,
  conditionPercentageMinNumber: CONDITION_PERCENTAGE_MIN_NUMBER,
  conditionGroupOperatorTypesOptions: CONDITION_GROUP_OPERATOR_TYPES_OPTIONS,
  conditionMetOptions: CONDITION_MET_OPTIONS,
  conditionSelectTypes: CONDITION_SELECT_TYPES,
  conditionStepProgressionValueComparatorOptions: CONDITION_STEP_PROGRESSION_VALUE_COMPARATOR_OPTIONS,
  conditionTypeMap: CONDITION_TYPE_MAP,
  conditionTypeMessageMap: CONDITION_TYPE_MESSAGE_MAP,
  titleCharacterMaxLength: TITLE_CHARACTER_MAX_LENGTH,
  descriptionCharacterMaxLength: DESCRIPTION_CHARACTER_MAX_LENGTH,
});

export { scenarioConditionTabConstants };
