import { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledForm, Dialog, FormFieldProps, GeneralTabIcon, QuestionnaireIcon, SideBarTabSet, Spinner, TabSetImperativeHandleRef, useControlledForm } from '@netfront/ui-library';
import { FormsCorrectAnswersTab, FormsGeneralTab } from 'components';
import { useDeleteForm, useGetFormDetails, useToast, useUpsertForm } from 'hooks';
import { Control, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getFormDefaultValues, getUpdatedMarkableQuestions } from './LibraryFormsSidebarView.helpers';
import { LibraryFormsSidebarViewProps } from './LibraryFormsSidebarView.interfaces';


const LibraryFormsSidebarView = ({
  handleSideBarClose,
  isSideBarOpen = false,
  onUpdate,
  projectId,
  selectedFormId,
}: LibraryFormsSidebarViewProps) => {

  const { handleToastError, handleToastSuccess } = useToast();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);


  const { control, handleSubmit, reset, getValues, setValue } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().required().label('Title'),
      }),
    ),
  });

  const { handleGetFormDetails, isLoading: isGetFormDetailsLoading = false } = useGetFormDetails({
    onCompleted: ({ contentGroup }) => {
      setDefaultValues(getFormDefaultValues(contentGroup));
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });



  const { handleDeleteForm, isLoading: isDeleteFormLoading = false } = useDeleteForm({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);
      reset();

      handleToastSuccess({ message: 'Form successfully deleted'});

      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpsertForm, isLoading: isUpsertFormLoading = false } = useUpsertForm();

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };


  const handleSaveForm = (item: FormFieldProps) => {

    const { markableQuestions} = item;

    const updatedMarkableQuestions = getUpdatedMarkableQuestions({ 
      formId: selectedFormId, 
      initialAnswers: defaultValues?.markableQuestions,
      updatedAnswers: markableQuestions,
    });

    handleUpsertForm({
      isCreate: !selectedFormId,
      callBack: () => {
        reset();
        onUpdate();
      },
      item,
      projectId,
      updatedMarkableQuestions,
    });
  };

  useEffect(() => {
    if (selectedFormId) {
      handleGetFormDetails({
        contentGroupId: selectedFormId,
      })
    } else {
      setDefaultValues(getFormDefaultValues());
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormId, isSideBarOpen]);

  const isLoading = isGetFormDetailsLoading || isDeleteFormLoading || isUpsertFormLoading;

  return (
    <>
      <Spinner isLoading={isLoading} spinnerIconSize={'small'} />
      <Dialog
        isOpen={isDeleteDialogOpen}
        title="Delete form?"
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!selectedFormId) {
            return;
          }

          handleDeleteForm({
            contentGroupId: selectedFormId,
            status: 'DELETED',
          });
        }}
      />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSaveForm(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >

        <SideBarTabSet
          defaultActiveTabId="id_general_tab"
          handleOpenCloseSideBar={handleSideBarClose}
          hasViewPadding={false}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_general_tab',
              label: 'General',
              view: () => (
                <FormsGeneralTab
                  control={control as Control<FormFieldProps>}
                  isLoading={isLoading}
                />
              )
            },
            {
              icon: QuestionnaireIcon,
              id: 'id_correct_answers_tab',
              label: 'Correct answers',
              isHidden: !selectedFormId || defaultValues?.markableQuestions.length === 0,
              view: () => (
                <FormsCorrectAnswersTab
                  getValues={getValues}
                  markableQuestions={defaultValues?.markableQuestions}
                  setValue={setValue}
                />
              )
            },
          ]}
          onDelete={selectedFormId ? () => setIsDeleteDialogOpen(true) : undefined}
          onSaveButtonType="submit"

        />
      </ControlledForm>
    </>
  );
};

export { LibraryFormsSidebarView };
