import { gql } from '@apollo/client';

const EXPORT_INTERACTIONS = gql`
  query ExportInteractions($ianaTimeZone: String!, $projectId: String!, $dateFrom: DateTime, $dateTo: DateTime) {
    report {
      exportInteractions(ianaTimeZone: $ianaTimeZone, projectId: $projectId, dateFrom: $dateFrom, dateTo: $dateTo) {
        contentType
        s3Key
        presignedUrl
      }
    }
  }
`;

export { EXPORT_INTERACTIONS };
