import { useContext, useState, useEffect } from 'react';

import { Button, FlexContainer, Pill } from '@netfront/ui-library';
import { useToast } from 'hooks';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { CachingEntitiesContext, ProjectSettingsContext } from '../../../../context';
import { DashboardListTemplatePage } from '../../../Shared';

const ContentDashboardPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { projectSettings } = useContext(ProjectSettingsContext);
  const { handleToastError } = useToast();
  const { query: { isBetaVersion: queryIsBetaVersion }, push, asPath } = useRouter();

  const [projectName, setProjectName] = useState<string>(project?.name ?? '');
  const [projectId, setProjectId] = useState<string>(project?.id ?? '');
  const [organisationKey, setOrganisationKey] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isBetaVersion, setIsBetaVersion] = useState<boolean>(false);

  const handleSwitch = () => {
    push(`${asPath.split('?')[0]}/${!isBetaVersion ? '?isBetaVersion=true': ''}`).catch((error) =>
      handleToastError({
        error,
      }),
    );
  };

  useEffect(() => {
    if (!project) {
      return;
    }

    const { name, organisationId, id } = project;

    setProjectId(id);
    setProjectName(name);
    setOrganisationKey(organisationId);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name, project?.organisationId, project?.id]);

  useEffect(() => {
    setIsBetaVersion( queryIsBetaVersion === 'true' && projectSettings?.['HAS_NEW_BUILDER'] as boolean);
  }, [queryIsBetaVersion, projectSettings]);

  return (
    <DashboardListTemplatePage
      activePage="content"
      additionalClassNames="c-content-dashboard__page-layout"
      breadcrumbItems={[
        {
          key: '0',
          content: <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}`}><span>Dashboard</span></Link>,
        },
        {
          key: '1',
          content: 'Content',
        },
      ]}
      description={`Content dashboard for ${projectName || 'the project'}`}
      informationBoxMessage={(
        <FlexContainer alignItems="center" justifyContent="space-between">
          <FlexContainer alignItems="center" gap="small">
              {isBetaVersion && (
                <Pill value="BETA" variant="green"/>
              )}
            <span>Manage and add to your content</span>
          </FlexContainer>
          {projectSettings?.['HAS_NEW_BUILDER'] as boolean && (
            <Button
              size="xs"
              text={`Switch to ${isBetaVersion ? 'Legacy': 'Beta'}`}
              variant="secondary"
              onClick={handleSwitch}
            />
          )}
        </FlexContainer>
      )}
      isLoading={isLoading}
      logoUrl={project?.logo?.presignedUrl}
      pageId="content"
      pageTitle={`${projectName ? projectName + ' content' : 'Content'} dashboard`}
      projectName={projectName}
    />

  );
};

export { ContentDashboardPage };
