import { IDBUserFlowStep } from '@netfront/ekardo-content-library';
import { FormFieldProps, IOption } from '@netfront/ui-library';
import { scenarioConditionTabConstants } from 'components';
import capitalize from 'lodash/capitalize';

import { IConditionTypeOptions, ISelectedCondition } from './ConditionFormView.interfaces';

const getScenarioConditionDefaultValues = ({ condition }: { condition?: ISelectedCondition}): FormFieldProps => {
  const { conditionTypeMap } = scenarioConditionTabConstants;

  const {
    delay,
    description,
    title, 
    day,
    value,
    comparator,
    stepId,
    percentage,
    valueComparator,
    groupIds = [],
    __typename: conditionType,
  } = condition ?? {};

  return {
    // general
    conditionType: conditionTypeMap[String(conditionType)],
    delay,
    description,
    title,

    // session, step started, step completed, step progression, user activated
    comparator,

    // day
    day,

    // hour
    value,

    // step started, step completed, step progression
    stepId,

    // step progression
    percentage,
    valueComparator,

    // many user group
    groupIds: groupIds.map((id) => String(id)),
  }
}

const getConditionTypeSelectOptions = (data: readonly IConditionTypeOptions[]): IOption[] => {
  return data.map((item) => {
    const { id, label, value } = item;

    const option: IOption = {
      id,
      name: capitalize(label),
      value,
    };

    return option;
  }).sort((a, b) => a.name.localeCompare(b.name))
};

const getConditionStepSelectOptions = (data: readonly IDBUserFlowStep[]): IOption[] => {
  return data.map((item) => {
    const { id, stepName } = item;

    const option: IOption = {
      id,
      name: capitalize(stepName),
      value: id,
    };

    return option;
  }).sort((a, b) => a.name.localeCompare(b.name));
};

const getScenarioConditionVariables = ({conditionGroupId, item }: {conditionGroupId: number; item: FormFieldProps}) => {
  const {
    delay,
    description,
    title,
    conditionType,
    day,
    hour,
    stepId,
    comparator,
    percentage,
    valueComparator,
    groupIds,
  } = item;

  const baseVariables = {
    conditionGroupId,
    delay: Number(delay),
    description,
    title,
  };

  let scenarioConditionSpecificDetails = {};

  if (conditionType === 'day') {
    scenarioConditionSpecificDetails = {
      day: {
        ...baseVariables,
        delay: 0,
        day,
      }
    }
  }

  if (conditionType === 'hour') {
    scenarioConditionSpecificDetails = {
      hour: {
        ...baseVariables,
        delay: 0,
        hour: Number(hour),
      }
    }
  }

  if (['stepStarted', 'stepCompleted'].includes(String(conditionType))) {
    scenarioConditionSpecificDetails = {
      [conditionType]: {
        ...baseVariables,
        comparator,
        stepId: Number(stepId),
      }
    }
  }

  if (conditionType === 'stepProgression') {
    scenarioConditionSpecificDetails = {
      stepProgression: {
        ...baseVariables,
        comparator,
        stepId: Number(stepId),
        percentage: Number(percentage),
        valueComparator,
      }
      
    }
  }

  if (['userActivated', 'userLogin', 'userSession'].includes(String(conditionType))) {
    scenarioConditionSpecificDetails = {
      [conditionType]: {
        ...baseVariables,
        comparator,
      }
    }
  }

  if (conditionType === 'manyUserGroup') {
    scenarioConditionSpecificDetails = {
      manyUserGroup: {
        ...baseVariables,
        comparator,
        delay: 0,
        groupIds: (groupIds as string[]).map((id: string) => Number(id)),
      }
    }
  }

  if (['userRegistered', 'userLastLogin'].includes(String(conditionType))) {
    scenarioConditionSpecificDetails = {
      [conditionType]: {
        ...baseVariables,
      }
    }
  }

  return {
    ...scenarioConditionSpecificDetails,
  }
}

export { getConditionTypeSelectOptions, getConditionStepSelectOptions, getScenarioConditionDefaultValues, getScenarioConditionVariables };
