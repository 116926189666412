import { PermissionType } from "./useGetUserPermissions.interfaces";

const PERMISSIONS: {[key: number]: PermissionType} = {
  0: 'NONE',
  2: 'MANAGE_USERS',
  4: 'EDIT',
  8: 'COLLABORATE',
  16: 'READ',
  32: 'LIMITED',
};

const PERMISSIONS_MAPPING = {
  LIMITED: ['READ'],
  NONE: [],
  MANAGE_USERS: ['EDIT', 'READ'],
  EDIT: ['READ'],
  COLLABORATE: ['EDIT', 'READ'],
  READ: [],
};

export { PERMISSIONS, PERMISSIONS_MAPPING};