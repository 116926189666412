import React, { useContext, useEffect, useState } from 'react';

import { CMSContentPage, ContentPageProvider, IContentPage, useContentPageContext } from '@netfront/ekardo-content-library';
import { BinIcon, ConditionIcon, EnterIcon, SettingsIcon } from '@netfront/ui-library';
import { ContentBuilderContext } from 'context';
import { IEntityType } from 'interfaces';


import { scopeStylesToPreview } from './ContentBuilderPreview.helpers';

const ContentBuilderPreviewInner = ({ contentPage, customStyleSheet, isEditMode = true }: { contentPage: IContentPage; customStyleSheet?: string; isEditMode?: boolean } ) => {
  const { openUpdateEntitySidebar, openDeleteEntityDialog, addItemToEntity, openActionsSidebar } = useContext(ContentBuilderContext);
  const { dispatch } = useContentPageContext();
  const [styleSheet, setStyleSheet] = useState<string>('');


  useEffect(() => {
    dispatch({
      payload: {
        contentPage,
      },
      type: 'loadContentPage',
    });


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPage]);

  useEffect(() => {
    if (!customStyleSheet) return;
    const cleanedStyles = scopeStylesToPreview(customStyleSheet);
    setStyleSheet(cleanedStyles);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customStyleSheet]);

  useEffect(() => {
    if (!styleSheet) return;

    const styleTag = document.createElement('style');
    styleTag.id = 'id_custom_css_file';
    styleTag.type = 'text/css';

    const existingStyleSheet = document.head.querySelector('#id_custom_css_file');

    if (existingStyleSheet) document.head.removeChild(existingStyleSheet);

    // Add the CSS content to the <style> tag
    styleTag.appendChild(document.createTextNode(styleSheet));

    document.head.appendChild(styleTag);

  }, [styleSheet])

  return (
    <>
      <CMSContentPage
        editModeCallbacks={{
          addItemToEntityCallback: ({ id: selectedEntityId, type: selectedEntityType }) => addItemToEntity(Number(selectedEntityId), selectedEntityType as IEntityType),
          dropdownOptions: [
            {
              icon: SettingsIcon,
              label: 'Edit',
              isHidden: false,
              onClick: ({ id: entityId, type: entityType }) => openUpdateEntitySidebar(Number(entityId), entityType as IEntityType)
            },
            {
              id: 'id_add_new_item',
              icon: EnterIcon,
              label: 'Add new content',
              isVisibleByTypeArray: ['section', 'container', 'sectionGroup'],
              onClick: ({ id: selectedEntityId, type: selectedEntityType }) => addItemToEntity(Number(selectedEntityId), selectedEntityType as IEntityType),
            },
            {
              id: 'id_entity_actions',
              icon: ConditionIcon,
              label: 'Actions',
              isHidden: false,
              onClick: ({ id: selectedEntityId, type: selectedEntityType }) => openActionsSidebar(Number(selectedEntityId), selectedEntityType as IEntityType),
            },
            {
              icon: BinIcon,
              label: 'Delete',
              isHidden: false,
              onClick: ({ id: entityId, type }) => openDeleteEntityDialog({ id: Number(entityId), type: type as IEntityType })
            },

          ]
        }}
        isEditMode={isEditMode}
      />
    </>
  );
};

const ContentBuilderPreview = ({ contentPage, isEditMode = true, customStyleSheet }: { contentPage: IContentPage; customStyleSheet?: string; isEditMode?: boolean }) => {
  return (
    <ContentPageProvider>
      <div className="scope-content-preview">
        <ContentBuilderPreviewInner contentPage={contentPage} customStyleSheet={customStyleSheet} isEditMode={isEditMode} />
      </div>
    </ContentPageProvider>
  );
};

export { ContentBuilderPreview };
