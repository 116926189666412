import React, { useEffect, useState } from 'react';

import { RadioButtonGroup, Spacing } from '@netfront/ui-library';
import { ConditionDelayInput } from 'components';
import { Controller } from 'react-hook-form';

import { ConditionFormUserSessionTypeViewProps } from './ConditionFormUserSessionTypeView.interfaces';

import { scenarioConditionTabConstants } from '../../../ScenarioConditionTab.constants';

const ConditionFormUserSessionTypeView = ({ 
  control,
  initialComparatorValue = '',
  setValue,
}: ConditionFormUserSessionTypeViewProps) => {
  const [comparator, setComparator] = useState<string>('');

  const { conditionDelayMinNumber, conditionMetOptions } = scenarioConditionTabConstants;

  useEffect(() => {
    setComparator(initialComparatorValue);
  }, [initialComparatorValue]);

  return (
    <div className="c-condition-form-user-activated-type-view">
      <Spacing size="x-large">
        <Controller
          control={control}
          name="comparator"
          render={({ field }) => (
            <RadioButtonGroup
              items={conditionMetOptions}
              selectedValue={String(field.value)}
              title="Condition met*"
              tooltipText="Did the user trigger the condition during the specified delay?"
              isLabelSideBySide
              {...field}
              onChange={(event) => {
                const { target: { value }} = event;
                field.onChange(event);
                setComparator(value);
                if (value === 'NOT_EXISTS') setValue('delay', 0);
              }}
            />
          )}
        />
      </Spacing>
      {comparator && comparator === 'EXISTS' && (
        <Spacing size="large">
          <Controller
            control={control}
            name="delay"
            render={({ field }) => (
              <ConditionDelayInput
                id="user_session_type"
                initialValue={field.value}
                labelText="Condition delay"
                minNumber={conditionDelayMinNumber}
                name={field.name}
                tooltipText="The schedule will execute using the configuration below"
                onChange={({ value }) => field.onChange(value)}
              />
            )}
          />
        </Spacing>
      )}

    </div>
  );
};

export { ConditionFormUserSessionTypeView };
