import { useHowlerLazyQuery } from 'hooks';

import { GET_TEMPLATE_QUERY } from './useGetTemplate.graphql';
import {
  IGetTemplateQueryGraphQLResponse,
  IGetTemplateQueryVariables,
  IHandleGetTemplateParams,
  IUseGetTemplate,
  IUseGetTemplateOptions,
} from './useGetTemplate.interfaces';

const useGetTemplate = (options?: IUseGetTemplateOptions): IUseGetTemplate => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetTemplateOptions);

  const [executeGetTemplate, { loading: isLoading }] = useHowlerLazyQuery<IGetTemplateQueryGraphQLResponse, IGetTemplateQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          project: { getTemplate: templateConnection },
        } = data;

        onCompleted({
          templateConnection,
        });
      },
      onError,
    },
    query: query ?? GET_TEMPLATE_QUERY,
    token,
  });

  const handleGetTemplate = ({ templateId }: IHandleGetTemplateParams) => {
    void executeGetTemplate({
      variables: {
        templateId,
      },
    });
  };

  return {
    handleGetTemplate,
    isLoading,
  };
};

export { useGetTemplate };
