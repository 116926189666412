
import { useCapuchinMutation } from 'hooks';

import { SORT_CONDITION_GROUP_MUTATION } from './useSortConditionGroup.graphql';
import {
  ISortConditionGroupMutationGraphQLResponse,
  ISortConditionGroupMutationVariables,
  IHandleSortConditionGroupParams,
  IUseSortConditionGroupOptions,
  IUseSortConditionGroup,
} from './useSortConditionGroup.interfaces';

const useSortConditionGroup = (options?: IUseSortConditionGroupOptions): IUseSortConditionGroup => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseSortConditionGroupOptions);

  const [executeSortConditionGroup, { loading: isLoading }] = useCapuchinMutation<
    ISortConditionGroupMutationGraphQLResponse,
    ISortConditionGroupMutationVariables
  >({
    mutation: mutation ?? SORT_CONDITION_GROUP_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          conditionGroups: { sort: conditionGroupConnection },
        } = data;

        onCompleted(conditionGroupConnection);
      },
      onError,
    },
    token,
  });

  const handleSortConditionGroup = ({ conditionGroupId, sort }: IHandleSortConditionGroupParams) => {
    void executeSortConditionGroup({
      variables: {
        conditionGroupId,
        sort,
      },
    });
  };

  return {
    handleSortConditionGroup,
    isLoading,
  };
};

export { useSortConditionGroup };
