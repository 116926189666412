import React, { useEffect, useState } from 'react';

import { InformationBox, Input, RadioButtonGroup, Select, Spacing, WarningIcon } from '@netfront/ui-library';
import { ConditionDelayInput } from 'components';
import { Controller } from 'react-hook-form';

import { ConditionFormStepProgressionTypeViewProps } from './ConditionStepProgressionTypeView.interfaces';

import { scenarioConditionTabConstants } from '../../../ScenarioConditionTab.constants';
import { getConditionStepSelectOptions } from '../../ConditionFormView.helpers';

const ConditionFormStepProgressionTypeView = ({
  control,
  initialComparatorValue = '',
  setValue,
  userFlowSteps,
}: ConditionFormStepProgressionTypeViewProps) => {

  const {
    conditionDelayMinNumber,
    conditionPercentageMaxNumber,
    conditionPercentageMinNumber,
    conditionMetOptions,
    conditionStepProgressionValueComparatorOptions,
  } = scenarioConditionTabConstants;

  const [comparator, setComparator] = useState<string>('');

  useEffect(() => {
    setComparator(initialComparatorValue);
  }, [initialComparatorValue]);

  return (
    <>
      <Spacing size="x-large">
        <Controller
          control={control}
          name="stepId"
          render={({ field }) => (
            <Select
              id="condition-step"
              labelText="Time point"
              options={getConditionStepSelectOptions(userFlowSteps)}
              tooltipText="Time point when the condition will be triggered"
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>
      {userFlowSteps.length === 0 && (
        <Spacing size="x-large">
          <InformationBox icon={WarningIcon} message="Please create a userflow for the project" />
        </Spacing>
      )}

      <Spacing size="x-large">
        <Controller
          control={control}
          name="comparator"
          render={({ field }) => (
            <RadioButtonGroup
              items={conditionMetOptions}
              selectedValue={String(field.value)}
              title="Condition met*"
              tooltipText="Did the user trigger the condition during the specified delay?"
              isLabelSideBySide
              {...field}
              onChange={(event) => {
                const { target: { value }} = event;
                field.onChange(event);
                setComparator(value);
                if (value === 'NOT_EXISTS') {
                  setValue('delay', 0);
                  setValue('valueComparator', '');
                  setValue('percentage', '0');
                } 
              }}
            />
          )}
        />
      </Spacing>

      {comparator && comparator === 'EXISTS' && (
        <>
          <Spacing size="large">
            <Controller
              control={control}
              name="delay"
              render={({ field }) => (
                <ConditionDelayInput
                  id="step_progression_type"
                  initialValue={field.value}
                  labelText="Condition delay"
                  minNumber={conditionDelayMinNumber}
                  name={field.name}
                  tooltipText="The schedule will execute using the configuration below"
                  onChange={({ value }) => field.onChange(value)}
                />
              )}
            />
          </Spacing>
          <Spacing size="x-large">
            <Controller
              control={control}
              name="valueComparator"
              render={({ field }) => (
                <RadioButtonGroup
                  items={conditionStepProgressionValueComparatorOptions}
                  selectedValue={String(field.value)}
                  title="Condition day"
                  tooltipText="Choose the value comparator"
                  isLabelSideBySide
                  {...field}
                />
              )}
            />
          </Spacing>

          <Spacing size="large">
            <Controller
              control={control}
              name="percentage"
              render={({ field }) => (
                <Input
                  iconAfter={{
                    isTextOnly: true,
                    helperText: 'Percent'
                  }}
                  id="percentage"
                  labelText="Condition percentage"
                  maxNumber={conditionPercentageMaxNumber}
                  minNumber={conditionPercentageMinNumber}
                  type="number"
                  isLabelSideBySide
                  isRequired
                  {...field}
                  onChange={({ target: { value }}) => {
                    const formattedValue = Number(value);
                    field.onChange(formattedValue > conditionPercentageMaxNumber ? conditionPercentageMaxNumber : formattedValue)
                  }}
                />
              )}
            />
          </Spacing>
        </>
      )}
    </>
  );
};

export { ConditionFormStepProgressionTypeView };
