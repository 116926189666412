import { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledForm, Dialog, FormFieldProps, GeneralTabIcon, NotesTabIcon, ScheduleIcon, SideBarTabSet, Spinner, TabSetImperativeHandleRef, UnitsTabIcon, useControlledForm } from '@netfront/ui-library';
import { IScenarioTemplate, ScenarioConditionTab, ScenarioGeneralTab, ScenarioScheduleTab, ScenarioTemplatesTab } from 'components';
import { useDeleteScenario, useGetCurrentTemplates, useGetScenario, useToast, useUpsertScenario } from 'hooks';
import { IDBCurrentTemplate, IDBScenario } from 'interfaces';
import { Control, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getScenarioDefaultValues, getScenarioVariables } from './ScenarioSidebarView.helpers';
import { ScenarioSidebarViewProps } from './ScenarioSidebarView.interfaces';


const ScenarioSidebarView = ({
  handleSideBarClose,
  isSideBarOpen = false,
  onUpdate,
  projectId,
  selectedScenarioId,
}: ScenarioSidebarViewProps) => {

  const { handleToastError, handleToastSuccess, handleToastCustomError } = useToast();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);
  const [scenario, setScenario] = useState<IDBScenario>();
  const [templates, setTemplates] = useState<IDBCurrentTemplate[]>([]);


  const { control, handleSubmit, reset, setValue, getValues, watch } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().required().label('Title'),
        description: yup.string().required().label('Description'),
      }),
    ),
  });

  const { handleGetScenario, isLoading: isGetScenarioLoading = false } = useGetScenario({
    onCompleted: ({ scenario: returnedScenario }) => {
      setScenario(returnedScenario);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetCurrentTemplates, isLoading: isGetCurrentTemplatesLoading = false} = useGetCurrentTemplates({
    onCompleted: ({ templateConnection: templatesData }) => {
      setTemplates(templatesData);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { handleDeleteScenario, isLoading: isDeleteScenarioLoading = false } = useDeleteScenario({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);
      reset();

      handleToastSuccess({ message: 'Scenario successfully deleted'});

      onUpdate({});
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpsertScenario, isLoading: isUpsertScenarioLoading = false } = useUpsertScenario({
    onCompleted: ({ scenario: returnedScenario }) => {
      reset();
      onUpdate({ isCreate: !selectedScenarioId ,scenario: returnedScenario});
    },
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };


  const handleSave = (item: FormFieldProps) => {

    const { templates: savedTemplates = []} = item;

    if (savedTemplates.length > 0 && Boolean(selectedScenarioId)) {
      const totalPercentage = (savedTemplates as IScenarioTemplate[]).reduce((accumulator, { percentage }) => {
        return accumulator + percentage;
      }, 0);

      if (totalPercentage > 100) {
        handleToastCustomError({
          message: 'whoops! Total percentage cannot exceed 100%',
        });
        return;
      }
    }

    handleUpsertScenario({
      scenarioId: selectedScenarioId,
      projectId,
      ...getScenarioVariables(item),
    });
  };

  useEffect(() => {
    if (selectedScenarioId) {
      handleGetScenario({
        scenarioId: selectedScenarioId,
      });

      handleGetCurrentTemplates({
        scenarioId: selectedScenarioId,
      });
    } else {
      setDefaultValues(getScenarioDefaultValues({}));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScenarioId]);

  useEffect(() => {

    if (!selectedScenarioId) return;
    setDefaultValues(getScenarioDefaultValues({ scenario, templates }));
  }, [scenario, selectedScenarioId, templates]);

  const isLoading = isGetScenarioLoading || isDeleteScenarioLoading || isUpsertScenarioLoading || isGetCurrentTemplatesLoading;

  return (
    <>
      <Spinner isLoading={isLoading} spinnerIconSize={'small'} />
      <Dialog
        isOpen={isDeleteDialogOpen}
        title="Delete scenario?"
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!selectedScenarioId) {
            return;
          }

          handleDeleteScenario({
            scenarioId: selectedScenarioId,
          });
        }}
      />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >

        <SideBarTabSet
          defaultActiveTabId="id_general_tab"
          handleOpenCloseSideBar={handleSideBarClose}
          hasViewPadding={false}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_general_tab',
              label: 'General',
              view: () => (
                <ScenarioGeneralTab
                  control={control as Control<FormFieldProps>}
                  initialIsOverrideReceiver={defaultValues?.isOverrideReceiver ?? false}
                  isUpdate={Boolean(selectedScenarioId)}
                />
              )
            },
            {
              icon: UnitsTabIcon,
              id: 'id_condition_tab',
              label: 'Condition',
              isHidden: !selectedScenarioId,
              view: () => selectedScenarioId ? (
                <ScenarioConditionTab projectId={projectId} scenarioId={selectedScenarioId} />
              ): <></>
            },
            {
              icon: ScheduleIcon,
              id: 'id_schedule_tab',
              label: 'Schedule',
              isHidden: !selectedScenarioId,
              view: () => selectedScenarioId ? (
                <ScenarioScheduleTab
                  control={control as Control<FormFieldProps>}
                  initialIsMultiOccurrence={defaultValues?.isMultiOccurrence ?? false}
                  initialOccurrenceType={defaultValues?.multiOccurrenceType ?? ''}
                  setValue={setValue}
                />
              ): <></>
            },
            {
              icon: NotesTabIcon,
              id: 'id_templates_tab',
              label: 'Templates',
              isHidden: !selectedScenarioId,
              view: () => selectedScenarioId ? (
                <ScenarioTemplatesTab
                  control={control as Control<FormFieldProps>}
                  getValues={getValues}
                  initialTemplates={defaultValues?.templates ?? []}
                  projectId={projectId}
                  setValue={setValue}
                  watch={watch}
                />
              ): <></>
            },
          ]}
          onDelete={selectedScenarioId ? () => setIsDeleteDialogOpen(true) : undefined}
          onSaveButtonType="submit"

        />
      </ControlledForm>
    </>
  );
};

export { ScenarioSidebarView };
