import React, { useEffect, useState } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import {
  FormFieldProps,
  Input,
  Spacing,
  Textarea,
  ToggleSwitch,
} from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { Control, Controller } from 'react-hook-form';

import { ScenarioGeneralTabProps } from './ScenarioGeneralTab.interfaces';



const ScenarioGeneralTab = ({ control, initialIsOverrideReceiver = false, isUpdate = false }: ScenarioGeneralTabProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  
  const [isOverrideReceiver, setIsOverrideReceiver] = useState<boolean>(false);


  useEffect(() => {
    setIsOverrideReceiver(initialIsOverrideReceiver);

  }, [initialIsOverrideReceiver]);


  return (
    <SidebarContainer>
      
      {
        isUpdate && (
          <Spacing size="2x-large">
            <Controller
              control={control as Control<FormFieldProps>}
              name="status"
              render={({ field }) => (
                <ToggleSwitch
                  additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
                  id="id_scenario_status"
                  isChecked={field.value === 'ACTIVE'}
                  labelText="Activated"
                  name={field.name}
                  isInline
                  isLabelSideBySide
                  onChange={({ target: { checked: isChecked }}) => {
                    field.onChange(isChecked ? 'ACTIVE': 'INACTIVE');
                  }}
                />
              )}
            />
          </Spacing>
        )
      }
        
      <Spacing>
        <Controller
          control={control as Control<FormFieldProps>}
          name="title"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_scenario_title"
              labelText="Title"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
              
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control as Control<FormFieldProps>}
          name="description"
          render={({ field, fieldState }) => (
            <Textarea 
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_scenario_description"
              labelText="Description"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control as Control<FormFieldProps>}
          name="isTestPhase"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
              id="id_is_test_phase"
              isChecked={field.value}
              labelText="Test mode"
              name={field.name}
              tooltipPosition="start"
              tooltipText="If the test group is activated, all scheduled notifications will be sent to the active test group instead of the associated user"
              isInline
              isLabelSideBySide
              onChange={field.onChange}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control as Control<FormFieldProps>}
          name="isOverrideReceiver"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
              id="id_is_override_receiver"
              isChecked={field.value}
              labelText="Override receiver"
              name={field.name}
              tooltipPosition="start"
              tooltipText="Add an email or phone number or distribution list key here to override who receives the message when this scenario is triggered, if using a mobile number, please include the country code"
              isInline
              isLabelSideBySide
              onChange={(event) => {
                const { target: { checked: isChecked}} = event;
                setIsOverrideReceiver(isChecked);
                field.onChange(event);
              }}
            />
          )}
        />
      </Spacing>
      {
        isOverrideReceiver && (
          <Spacing>
            <Controller
              control={control as Control<FormFieldProps>}
              name="overrideReceiver"
              render={({ field }) => (
                <Textarea
                  id="id_scenario_override_receiver"
                  labelText="Email or distribution key to notify"
                  isLabelSideBySide
                  {...field}
                />
              )}
            />
          </Spacing>
        )
      }
    </SidebarContainer>
  );
};

export { ScenarioGeneralTab };
