import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, ExcelIcon } from '@netfront/ui-library';
import axios, { AxiosResponse } from 'axios';
import { ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { addMonths } from 'date-fns';
import { useGetInteractionsReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { INTERACTIONS_REPORT_COLUMNS } from './InteractionsReportPage.constants';
import { getInteractionsReportTableData } from './InteractionsReportPage.helpers';
import { InteractionsReportCsvRowData, IInteractionsReportItem, IInteractionsReportTableData } from './InteractionsReportPage.interfaces';



const InteractionsReportPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [interactionsReportItems, setInteractionsReportItems] = useState<IInteractionsReportItem[]>();
  const [allInteractionsReportItems, setAllInteractionsReportItems] = useState<IInteractionsReportItem[]>([]);
  const [interactionsReportTableData, setInteractionsReportTableData] = useState<IInteractionsReportTableData[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>([addMonths(new Date(), -1), new Date()]);
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isGenerate, setIsGenerate] = useState<boolean>(false);


  const {
    handleGetInteractionsReport,
    isLoading: isGetInteractionsLoading = false,
  } = useGetInteractionsReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;

      if (isGenerate) {
        setIsGenerate(false);
        setCsvUrl(presignedUrl);
        axios
          .get(presignedUrl)
          .then((res: AxiosResponse) => {

            const { data } = Papa.parse<InteractionsReportCsvRowData>(String(res.data), {
              header: true,
              skipEmptyLines: true,
            });

            setTotalCount(data.length);
            const formattedItems = data.map((row: InteractionsReportCsvRowData) => ({
              id: Number(row['User id']),
              communityCount: Number(row['Community count']),
              firstName: String(row['First name']),
              lastName: String(row['Last name']),
              totalPosts: Number(row['Total post count']),
              postCount: Number(row['Post count']),
              totalComments: Number(row['Total comment count']),
              commentCount: Number(row['Comment count']),
              totalConnections: Number(row['Total connection count']),
              connectionCount: Number(row['Connection count']),
              totalRelates: Number(row['Total relates count']),
              relatesCount: Number(row['Relates count']),
              totalMessages: Number(row['Total message count']),
              messageCount: Number(row['Message count']),
            }));

            setAllInteractionsReportItems(formattedItems);
            const paginatedItems = formattedItems.slice(0, pageSize);
            setInteractionsReportItems(paginatedItems);


            setLastItemIndex(pageSize);
          })
          .catch((error) => {
            handleToastCustomError({
              message: 'whoops! something went wrong',
            });
            // eslint-disable-next-line no-console
            console.log({ error });
          });
      } else {
        window.open(presignedUrl, '_blank');
      }
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId: String(projectId),
  });



  const generateReport = () => {

    setIsGenerate(true);
    void handleGetInteractionsReport({
      dateFrom: dateRange[0] ? dateRange[0] : undefined,
      dateTo: dateRange[1] ? dateRange[1] : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId,
      fileType: 'CSV'
    });
  };

  const handleDownload = (type: "CSV" | "EXCEL") => {

    setIsGenerate(false);
    void handleGetInteractionsReport({
      dateFrom: dateRange[0] ? dateRange[0] : undefined,
      dateTo: dateRange[1] ? dateRange[1] : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId,
      fileType: type
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setInteractionsReportItems(allInteractionsReportItems.slice(0, selectedPageSize));
  };


  const handlePaginate = () => {
    const updateIndex = lastItemIndex + pageSize;
    setLastItemIndex(updateIndex);
    setIsLoadMoreDisabled(totalCount <= updateIndex);
    setInteractionsReportItems(allInteractionsReportItems.slice(0, updateIndex));
  };


  useEffect(() => {
    if (!interactionsReportItems) {
      return;
    }

    setInteractionsReportTableData(
      getInteractionsReportTableData({
        items: interactionsReportItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactionsReportItems]);


  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetInteractionsLoading;

  return (
    <div className="c-interaction-report-table">
      <ReportTable<IInteractionsReportTableData>
        activePath={['reporting', 'reporting-social', 'export-interactions']}
        additionalBreadcrumbItems={[{
          key: '2',
          content: 'Interactions',
        }]}
        additionalDownloadOptions={
          csvUrl ? (
          <Button
            additionalClassNames="c-report-download-button"
            icon={ExcelIcon}
            size="xs"
            text="Download as excel"
            isIconOnly
            onClick={() => handleDownload('EXCEL')}
          />
        ): undefined}
        columns={INTERACTIONS_REPORT_COLUMNS}
        csvUrl={csvUrl}
        data={interactionsReportTableData}
        dateRange={dateRange}
        description={`Interactions reports for ${String(projectName)}`}
        handleOnPageSizeChange={handleChangePageSize}
        handleOnPaginate={void handlePaginate}
        informationBoxMessage={'This page exports interactions based on the selected filters'}
        isLoading={isLoading}
        isLoadMoreDisabled={isLoadMoreDisabled}
        logoUrl={project?.logo?.presignedUrl}
        pageSize={pageSize}
        pageTitle={String(projectName)}
        title={`${String(projectName)} reporting`}
        totalCount={totalCount}
        onDateSelect={(dates: Date[]) => setDateRange(dates)}
        onGenerate={generateReport}
      />
    </div>
  );
};

export { InteractionsReportPage };
