import { createContext } from 'react';

import { IProjectSetting } from '@netfront/gelada-identity-library';



export interface IProjectSettingsContext {
  isLoading?: boolean;
  projectSettings: IProjectSetting | undefined;
  refreshProjectSettings: () => void;
}

export const ProjectSettingsContext = createContext<IProjectSettingsContext>({
  isLoading: true,
  projectSettings: undefined,
  refreshProjectSettings: () => undefined,
});

// eslint-disable-next-line import/no-default-export
export default ProjectSettingsContext;
