
import { useCapuchinMutation } from 'hooks';

import { ADD_CONDITION_MUTATION } from './useAddScenarioCondition.graphql';
import {
  IAddScenarioConditionMutationGraphQLResponse,
  IAddScenarioConditionMutationVariables,
  IHandleAddScenarioConditionParams,
  IUseAddScenarioConditionOptions,
  IUseAddScenarioCondition,
} from './useAddScenarioCondition.interfaces';

const useAddScenarioCondition = (options?: IUseAddScenarioConditionOptions): IUseAddScenarioCondition => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseAddScenarioConditionOptions);

  const [executeAddScenarioCondition, { loading: isLoading }] = useCapuchinMutation<
  IAddScenarioConditionMutationGraphQLResponse,
  IAddScenarioConditionMutationVariables
  >({
    mutation: mutation ?? ADD_CONDITION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          conditions: { add: conditionConnection },
        } = data;

        const { condition } = conditionConnection;

        onCompleted(condition);
      },
      onError,
    },
    token,
  });

  const handleAddScenarioCondition = ({
    day,
    hour,
    stepStarted,
    stepCompleted,
    stepProgression,
    userActivated,
    userLogin,
    userRegistered,
    userLastLogin,
    userSession,
    manyUserGroup,
  }: IHandleAddScenarioConditionParams) => {
    void executeAddScenarioCondition({
      variables: {
        day,
        hour,
        stepStarted,
        stepCompleted,
        stepProgression,
        userActivated,
        userLogin,
        userRegistered,
        userLastLogin,
        userSession,
        manyUserGroup,
      },
    });
  };

  return {
    handleAddScenarioCondition,
    isLoading,
  };
};

export { useAddScenarioCondition };
