import { ActionsIcon, AudioIcon, ButtonIcon, CheckboxIcon, CodeIcon, DropdownIcon, FormsIcon, HollowImageIcon, HollowVideoIcon, InvertedCalendarIcon, ListViewIcon, MapIcon, MultiLineIcon, QuoteIcon, RadioIcon, ReplaceIcon, SliderIcon, TextBoxIcon } from '@netfront/ui-library';

import { ISnippetOption } from './SnippetTypeSelectorSidebarView.interfaces';

import { SnippetTypeConst, underScoredToResponseTypeConst } from '../../../../../constants';

const snippetTypeDefaultOptions: ISnippetOption[] = [
  {
    icon: TextBoxIcon,
    label: 'Text',
    value: SnippetTypeConst.TEXT,
    type: 'general',
  },
  {
    icon: HollowImageIcon,
    label: 'Image',
    value: SnippetTypeConst.IMAGE,
    type: 'general',
  },
  {
    icon: HollowVideoIcon,
    label: 'Video',
    value: SnippetTypeConst.VIDEO,
    type: 'general',
  },
  {
    icon: AudioIcon,
    label: 'Audio',
    value: SnippetTypeConst.AUDIO,
    type: 'general',
  },
  {
    icon: ActionsIcon,
    label: 'Document',
    value: SnippetTypeConst.DOCUMENT,
    type: 'general',
  },
  {
    icon: ButtonIcon,
    label: 'Button',
    value: SnippetTypeConst.BUTTON,
    type: 'general',
  },
  {
    icon: FormsIcon,
    label: 'Form block',
    value: SnippetTypeConst.FORM,
    type: 'forms',
  },
  {
    icon: TextBoxIcon,
    label: 'Single-line',
    value: underScoredToResponseTypeConst.SINGLE_TEXT,
    type: 'forms',
  },
  {
    icon: MultiLineIcon,
    label: 'Multi-line',
    value: underScoredToResponseTypeConst.MULTI_TEXT,
    type: 'forms',
  },
  {
    icon: RadioIcon,
    label: 'Radio',
    value: underScoredToResponseTypeConst.RADIO,
    type: 'forms',
  },
  {
    icon: CheckboxIcon,
    label: 'Checkbox',
    value: underScoredToResponseTypeConst.CHECKBOX,
    type: 'forms',
  },
  {
    icon: ReplaceIcon,
    label: 'Multi-answer',
    value: underScoredToResponseTypeConst.MULTI_RESPONSE,
    type: 'forms',
  },
  // {
  //   icon: LayoutIcon,
  //   label: 'Match',
  //   value: underScoredToResponseTypeConst.MATCH,
  //   type: 'forms',
  // },
  {
    icon: FormsIcon,
    label: 'Number',
    value: underScoredToResponseTypeConst.NUMBER,
    type: 'forms',
  },
  {
    icon: InvertedCalendarIcon,
    label: 'Calendar',
    value: underScoredToResponseTypeConst.CALENDAR,
    type: 'forms',
  },
  {
    icon: DropdownIcon,
    label: 'Dropdown',
    value: underScoredToResponseTypeConst.DROP_DOWN_LIST,
    type: 'forms',
  },


  // {
  //   icon: ImageSliderIcon,
  //   label: 'Slider',
  //   value: SnippetTypeConst.SLIDER,
  //   type: 'other',
  // },
  // {
  //   icon: TabsIcon,
  //   label: 'Tab',
  //   value: SnippetTypeConst.TABS,
  //   type: 'other',
  // },
  // {
  //   icon: SocialIcon,
  //   label: 'Social links',
  //   value: SnippetTypeConst.SOCIAL_LINKS, does not exist
  //   type: 'other',
  // },
  // {
  //   icon: SocialIcon,
  //   label: 'Social feed',
  //   value: SnippetTypeConst.SOCIAL_FEED, does not exist
  //   type: 'other',
  // },
  {
    icon: QuoteIcon,
    label: 'Quote',
    value: SnippetTypeConst.QUOTE,
    type: 'other',
  },
  {
    icon: ListViewIcon,
    label: 'Summary',
    value: SnippetTypeConst.SUMMARY,
    type: 'other',
  },
  {
    icon: CodeIcon,
    label: 'Code',
    value: SnippetTypeConst.CODE,
    type: 'other',
  },
  {
    icon: MapIcon,
    label: 'Map',
    value: SnippetTypeConst.MAP,
    type: 'other',
  },
  {
    icon: SliderIcon,
    label: 'Slider',
    value: SnippetTypeConst.SLIDER,
    type: 'other',
  },

  // {
  //   icon: AppIcon,
  //   label: 'App',
  //   value: SnippetTypeConst.APP,
  //   type: 'other',
  // },
  // {
  //   icon: PinIcon,
  //   label: 'Image region',
  //   value: SnippetTypeConst.IMAGE_MAP,
  //   type: 'other',
  // },
  // {
  //   icon: ClusterIcon,
  //   label: 'Clusters',
  //   value: SnippetTypeConst.CLUSTER,
  //   type: 'other',
  // },
  // {
  //   icon: RecordIcon,
  //   label: 'Record',
  //   value: SnippetTypeConst.RECORD, does not exist
  //   type: 'other',
  // },
  // {
  //   icon: DirectoryIcon,
  //   label: 'Directory',
  //   value: SnippetTypeConst.RECORD, does not exist
  //   type: 'other',
  // },
  // {
  //   icon: AvatarIcon,
  //   label: 'Avatar',
  //   value: SnippetTypeConst.AVATAR,
  //   type: 'other',
  // },
  // {
  //   icon: '', icon needed
  //   label: 'Placeholder',
  //   value: SnippetTypeConst.PLACEHOLDER,  does not exist
  //   type: 'other',
  // },
  // {
  //   icon: EventsIcon,
  //   label: 'Events',
  //   value: SnippetTypeConst.EVENTS,  does not exist
  //   type: 'other',
  // },
  // {
  //   icon: HollowImageIcon,
  //   label: 'Cartoon',
  //   value: SnippetTypeConst.CARTOON,  does not exist
  //   type: 'other',
  // },
  // {
  //   icon: HelpIcon,
  //   label: 'Help',
  //   value: SnippetTypeConst.HELP,  does not exist
  //   type: 'other',
  // },
  // {
  //   icon: ActivityIcon,
  //   label: 'Activity',
  //   value: SnippetTypeConst.ACTIVITY,  does not exist
  //   type: 'other',
  // },
];


export { snippetTypeDefaultOptions };
