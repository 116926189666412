/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FormFieldProps } from "@netfront/ui-library"
import { IScenarioTemplate } from "components";
import { IDBCurrentTemplate, IDBScenario } from "interfaces";
import capitalize from "lodash.capitalize";


import { scheduleTabsetSelectionTypeMap } from "./ScenarioSidebarView.constants";

const getScenarioDefaultValues = ({scenario, templates = []}: { scenario?: IDBScenario; templates?: IDBCurrentTemplate[]}): FormFieldProps => {
  const {
    description,
    isTestPhase,
    overrideReceiver,
    status,
    title,
    schedule,
    startDate,
    endDate,
    createdDate,
    maxRecurrence,
  } = scenario ?? {};

  const { 
    __typename: type,
    occurEvery,
    recurrenceDays,
    day,
    month,
  } = schedule ?? {};

  const allScenarioTemplates = templates.map((template) => {
      const {
        percentage,
        template: { id, title: templateTitle, type: scenarioTemplateType },
      } = template;

      const templateType = scenarioTemplateType.split('_').length > 1 ? capitalize(scenarioTemplateType.split('_').join(' ').toLocaleLowerCase()) : type;

      return {
        id,
        percentage,
        title: `${templateTitle} (${String(templateType)})`,
      };
    });


  return {
    // general
    description: description || '',
    isTestPhase: isTestPhase || false,
    overrideReceiver: overrideReceiver || '',
    isOverrideReceiver: overrideReceiver ? true : false,
    status: status || 'INACTIVE',
    title: title || '',

    

    // schedule
    isDisplayingCreatedDate: Boolean(scenario) && !startDate, 
    multiOccurrenceType: scheduleTabsetSelectionTypeMap[type ?? scheduleTabsetSelectionTypeMap.ScheduleDailyGraphType],
    startDate: startDate ?? createdDate,
    endDate,
    maxRecurrence,
    isMultiOccurrence: maxRecurrence !== 1,
    dailyOccurEvery: type === 'ScheduleDailyGraphType' ? occurEvery: undefined,
    weeklyOccurEvery: type === 'ScheduleWeeklyGraphType' ? occurEvery: undefined,
    weeklyRecurrenceDays: recurrenceDays,
    monthlyOccurEvery: type === 'ScheduleMonthlyGraphType' ? occurEvery: undefined,
    monthlyDay: type === 'ScheduleMonthlyGraphType' ? day: undefined,
    yearlyOccurEvery: type === 'ScheduleYearlyGraphType' ? occurEvery: undefined,
    yearlyDay: type === 'ScheduleYearlyGraphType' ? day: undefined,
    yearlyMonth: month,

    // templates
    templates: allScenarioTemplates,
    isAbTesting: allScenarioTemplates.length > 1,
  };
};


const getScenarioVariables = (item: FormFieldProps) => {
  const { 
    description,
    isTestPhase,
    overrideReceiver,
    isOverrideReceiver,
    status,
    title,

    multiOccurrenceType,
    isMultiOccurrence,
    maxRecurrence,
    dailyOccurEvery,
    weeklyOccurEvery,
    weeklyRecurrenceDays,
    monthlyOccurEvery,
    monthlyDay,
    yearlyOccurEvery,
    yearlyDay,
    yearlyMonth,
    startDate,
    endDate,
    isDisplayingCreatedDate,

    templates = [],
  } = item;


  let scheduleDetails = {};

  if (isMultiOccurrence && multiOccurrenceType === 'daily') {
    scheduleDetails = {
      daily: {
        occurEvery: Number(dailyOccurEvery),
      }
    }
  }

  if (isMultiOccurrence && multiOccurrenceType === 'weekly') {
    scheduleDetails = {
      weekly: {
        occurEvery: Number(weeklyOccurEvery),
        recurrenceDays: weeklyRecurrenceDays
      }
    }
  }

  if (isMultiOccurrence && multiOccurrenceType === 'monthly') {
    scheduleDetails = {
      monthly: {
        occurEvery: Number(monthlyOccurEvery),
        day: Number(monthlyDay)
      }
    }
  }

  if (isMultiOccurrence && multiOccurrenceType === 'yearly') {
    scheduleDetails = {
      yearly: {
        occurEvery: Number(yearlyOccurEvery),
        day: Number(yearlyDay),
        month: Number(yearlyMonth),
      },
    }
  }


  return {
    // general
    description,
    isTestPhase,
    overrideReceiver,
    isOverrideReceiver,
    status,
    title,

    // schedule
    startDate: !isDisplayingCreatedDate ? startDate: undefined,
    endDate,
    isMultiOccurrence,
    maxRecurrence: isMultiOccurrence ? maxRecurrence : 1,
    ...scheduleDetails,
    templatesAllocation: (templates as IScenarioTemplate[]).map(({ id: templateId, percentage }) => {
      return {
        percentage,
        templateId,
      };
    })
  }
}; 

export { getScenarioDefaultValues, getScenarioVariables };
