import { gql } from '@apollo/client';

const GET_PROJECT_COMMUNITIES_QUERY = gql`
  query GetProjectCommunities(
    $after: String
    $first: Int
    $filter: String
    $projectId: String!
    $shouldIncludeUserConnection: Boolean = false
  ) {
    community {
      byProject(after: $after, first: $first, filter: $filter, projectId: $projectId, type: GROUP) {
        edges {
          cursor
          node {
            ... on GroupCommunityGraphType {
              bannerImage {
                presignedUrl
              }
              id
              description
              title
              privacy
              key
              profileImage {
                presignedUrl
              }
              status
              userConnection @include(if: $shouldIncludeUserConnection) {
                role
                userId
                id
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;

export { GET_PROJECT_COMMUNITIES_QUERY };
