import { FormFieldProps } from "@netfront/ui-library";
import { IDBCommunity } from "interfaces";

const getEventDefaultValues = ({ community } : { community?: IDBCommunity; }): FormFieldProps => {
  const {
    title,
    description,
    privacy,
    status,
    bannerImage,
    profileImage,
    key,
    start,
    end,
  } = community ?? {};

  const { assetId: bannerId, presignedUrl: bannerPresignedUrl = '' } = bannerImage ?? {};
  const { assetId: imageId, presignedUrl: imagePresignedUrl = '' } = profileImage ?? {};

  const startDate = start ? new Date(String(start)): '';
  const startTime = startDate ? startDate.toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }): '';

  const endDate = end ? new Date(String(end)): '';
  const endTime = endDate ? endDate.toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }): '';

  return {
    key: key ?? '',
    title: title ?? '',
    description: description ?? '',
    privacy: privacy ?? '',
    status: status ?? '',
    bannerPresignedUrl: bannerPresignedUrl,
    imagePresignedUrl: imagePresignedUrl,
    bannerId,
    imageId,
    startDate: start,
    endDate: endDate,
    startTime,
    endTime,
  }
};


const getDateTime = (date: Date, time: string) => {
  
  // eslint-disable-next-line prefer-destructuring
  const datePart = date.toISOString().split("T")[0];

  const combinedDateTime = new Date(`${datePart}T${time}:00`); 

  return combinedDateTime;
}



export { getEventDefaultValues, getDateTime };
