import { useEkardoMutation } from '@netfront/ekardo-content-library';

import {
  SET_CHECKBOX_CORRECT_RESPONSE_MUTATION,
  SET_RADIO_CORRECT_RESPONSE_MUTATION,
  SET_DROPDOWN_LIST_CORRECT_RESPONSE_MUTATION,
} from './useSetCorrectResponse.graphql';
import { ISetCorrectResponseMutationGraphQLResponse, ISetCorrectResponseMutationVariables, IHandleSetCorrectResponseParams, IUseSetCorrectResponse, IUseSetCorrectResponseOptions } from './useSetCorrectResponse.interfaces';

const useSetCorrectResponse = (options?: IUseSetCorrectResponseOptions): IUseSetCorrectResponse => {
  const { mutation, onCompleted, onError, token, questionType } = options ?? ({} as IUseSetCorrectResponseOptions);

  const setCorrectResponseMutationQueryMap = {
    Checkbox: SET_CHECKBOX_CORRECT_RESPONSE_MUTATION,
    Radio: SET_RADIO_CORRECT_RESPONSE_MUTATION,
    DropDownList: SET_DROPDOWN_LIST_CORRECT_RESPONSE_MUTATION,
  };

  const [executeSetCorrectResponse, { loading: isLoading }] = useEkardoMutation<ISetCorrectResponseMutationGraphQLResponse, ISetCorrectResponseMutationVariables>({
    mutation: mutation ?? setCorrectResponseMutationQueryMap[questionType],
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentGroup: { setCorrectResponse: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleSetCorrectResponse = ({ 
    contentGroupId,
    contentSnippetId,
    questionResponseId,
  }: IHandleSetCorrectResponseParams) => {
    void executeSetCorrectResponse({
      variables: {
        contentGroupId,
        contentSnippetId,
        questionResponseId,
      },
    });
  };

  return {
    handleSetCorrectResponse,
    isLoading,
  };
};

export { useSetCorrectResponse };
