import { Column } from 'react-table';

import { IInteractionsReportTableData } from './InteractionsReportPage.interfaces';



const INTERACTIONS_REPORT_COLUMNS: ReadonlyArray<Column<IInteractionsReportTableData>> = [
  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full h-word-break">{value}</div>,
    Header: () => <div className="text-left w-full">User id</div>,
    width: '7%'
  },
  {
    accessor: 'communityCount',
    Cell: ({ value }) => <div className="text-left w-full h-word-break">{value}</div>,
    Header: () => <div className="text-left h-word-break">Memberships</div>,
    width: '7%'
  },
  {
    accessor: 'firstName',
    Cell: ({ value }) => <div className="text-left w-full h-word-break">{value}</div>,
    Header: () => <div className="text-left w-full">First name</div>,
    width: '7%'
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div className="text-left w-full h-word-break">{value}</div>,
    Header: () => <div className="text-left w-full">Last name</div>,
    width: '7%'
  },
  {
    accessor: 'totalPosts',
    Cell: ({ value }) => <div className="text-left w-full h-word-break">{value}</div>,
    Header: () => <div className="text-left w-full">Total posts</div>,
    width: '7%'
  },
  {
    accessor: 'postCount',
    Cell: ({ value }) => <div className="text-left w-full h-word-break">{value}</div>,
    Header: () => <span className="text-left w-full">Posts (period)</span>,
    width: '7%'
  },
  {
    accessor: 'totalRelates',
    Cell: ({ value }) => <div className="text-left w-full h-word-break">{value}</div>,
    Header: () => <span className="text-left w-full">Total related</span>,
    width: '7%'
  },
  {
    accessor: 'relatesCount',
    Cell: ({ value }) => <div className="text-left w-full h-word-break">{value}</div>,
    Header: () => <span className="text-left w-full">Related (period)</span>,
    width: '7%'
  },

  {
    accessor: 'totalComments',
    Cell: ({ value }) => <div className="text-left w-full h-word-break">{value}</div>,
    Header: () => <span className="text-left w-full">Total comments</span>,
    width: '7%'
  },
  {
    accessor: 'commentCount',
    Cell: ({ value }) => <div className="text-left w-full h-word-break">{value}</div>,
    Header: () => <span className="text-left w-full">Comments (period)</span>,
    width: '7%'
  },
  {
    accessor: 'totalConnections',
    Cell: ({ value }) => <div className="text-left w-full h-word-break">{value}</div>,
    Header: () => <span className="text-left w-full">Total connections</span>,
    width: '7%'
  },
  {
    accessor: 'connectionCount',
    Cell: ({ value }) => <div className="text-left w-full h-word-break">{value}</div>,
    Header: () => <span className="text-left w-full">Connections (period)</span>,
    width: '7%'
  },
  {
    accessor: 'totalMessages',
    Cell: ({ value }) => <div className="text-left w-full h-word-break">{value}</div>,
    Header: () => <span className="text-left w-full">Total messages</span>,
    width: '7%'
  },
  {
    accessor: 'messageCount',
    Cell: ({ value }) => <div className="text-left w-full h-word-break">{value}</div>,
    Header: () => <span className="text-left w-full">Messages (period)</span>,
    width: '7%'
  },

];

export { INTERACTIONS_REPORT_COLUMNS };
