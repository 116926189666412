import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { GeneralTabIcon, SingleDatePicker } from '@netfront/ui-library';
import { SimulatorGeneralTab, TablePageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetPaginatedSimulators, useToast } from 'hooks';
import { IDBSimulator } from 'interfaces';
import last from 'lodash.last';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';

import { SIMULATORS_TABLE_COLUMNS } from './SimulatorPage.constants';
import { getSimulatorsTableData } from './SimulatorPage.helpers';
import { ISimulatorsTableData } from './SimulatorPage.interfaces';


const SimulatorPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError } = useToast();
  const defaultPageSize = 10;
  const maxDate = String(Number(new Date().getFullYear()) + 5);

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [allSimulatorItems, setAllSimulatorItems] = useState<IDBSimulator[]>();
  const [filter, setFilter] = useState<string>();
  const [templateId, setTemplateId] = useState<number>();
  const [simulatorTableData, setSimulatorTableData] = useState<ISimulatorsTableData[]>([]);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [totalSimulators, setTotalSimulators] = useState(0);
  const [simulatorDate, setSimulatorDate] = useState<Date>(new Date());

  const {
    handleFetchMorePaginatedSimulators,
    handleGetPaginatedSimulators,
    isLoading: isGetSimulatorsLoading,
  } = useGetPaginatedSimulators({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ simulatorsConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const simulatorItems = edges.map(({ node }) => node);
      setAllSimulatorItems(simulatorItems);
      setIsLoadMoreDisabled(simulatorItems.length >= totalCount || totalCount <= pageSize);
      setTotalSimulators(totalCount);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleFilterSearch = (value: string) => {
    void handleGetPaginatedSimulators({
      first: pageSize,
      projectId: String(projectId),
      date: simulatorDate,
      filter: value,
    });

    setFilter(value);
  };

  const handleCloseSidebar = () => {
    setIsSideBarOpen(false);
    setTemplateId(undefined);
  };

  const handleOnPageSizeChange = (changedPageSize: number) => {
    setPageSize(Number(changedPageSize));
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    void handleGetPaginatedSimulators({
      first: pageSize,
      projectId: String(projectId),
      date: simulatorDate,
      filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId]);

  useEffect(() => {
    if (!allSimulatorItems) {
      return;
    }

    setSimulatorTableData(
      getSimulatorsTableData({
        simulators: allSimulatorItems,
        onSettingsButtonClick: (template) => {
          setIsSideBarOpen(true);
          setTemplateId(template.id);
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSimulatorItems]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  return (
    <TablePageTemplate<ISimulatorsTableData>
      activePath={['notifications', 'scheduler', 'simulator']}
      additionalBreadcrumbItems={[{
        key: '1',
        content: 'Notifications',
      }, {
        key: '2',
        content: 'Scheduler',
      }, {
        key: '3',
        content: 'Simulator'
      }]}
      childrenMiddle={
        <SingleDatePicker 
          config={{
            maxDate: maxDate ? new Date(maxDate) : undefined
          
          }}
          dateInputProps={{
            labelText: 'Start date',
            id: 'date',
            name: 'date',
            type: 'text',
            isLabelHidden: true,
          }}
          selectedDates={[simulatorDate]} 
          onSelected={(dates: Date[]) => setSimulatorDate(dates[0])} 
        />
      }
      columns={SIMULATORS_TABLE_COLUMNS}
      data={simulatorTableData}
      defaultActiveTabId="id_general_tab"
      description={`Scenario simulators for ${String(projectName)}`}
      handleOnPageSizeChange={handleOnPageSizeChange}
      handleOnPaginate={async () => {
        await handleFetchMorePaginatedSimulators({
          after: lastCursor,
          first: pageSize,
          projectId: String(projectId),
          date: simulatorDate,
          filter: filter,
        });
      }}
      handleSearch={handleFilterSearch}
      informationBoxMessage={`Manage ${String(projectName)} schedule notifications information: ${totalSimulators} ${pluralize('simulator', totalSimulators)}`}
      isLoading={isGetSimulatorsLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      isSideBarOpen={isSideBarOpen}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      searchPlaceholder="Search"
      size={project?.logo?.presignedUrl ? 'small': 'medium'}
      tableType="simulator"
      tabs={[
        {
          icon: GeneralTabIcon,
          id: 'id_general_tab',
          label: 'General',
          view: () => <SimulatorGeneralTab selectedTemplateId={templateId} onClose={handleCloseSidebar} />,
        },
      ]}
      title={`${String(projectName)} Scenario simulator`}
      toggleIsSideBarOpen={handleCloseSidebar}
      totalItems={totalSimulators}
      hideSideBarButtons
    />
  );
};

export { SimulatorPage };
