import { IGetTestUsersTableDataParams, ITestUsersTableData } from './TestModePage.interfaces';

const getTestUsersTableData = ({ testUsers, onSettingsButtonClick }: IGetTestUsersTableDataParams): ITestUsersTableData[] => {
  return testUsers.map(({ email, firstname: firstName, id, lastname: lastName }): ITestUsersTableData => {
    return {
      email,
      firstName,
      lastName,
      id,
      settingsButtonData: {
        id,
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getTestUsersTableData };


