
import { ISimulatorsTableData, ISimulatorsTableDataParams } from "./SimulatorPage.interfaces";


const getSimulatorsTableData = ({ simulators, onSettingsButtonClick }: ISimulatorsTableDataParams): ISimulatorsTableData[] => {
  return simulators.map(({ template, user }): ISimulatorsTableData => {
    return {
      id: user.id,
      email: user.email,
      firstName: user.firstname,
      lastName: user.lastname,
      medium: template.type,
      settingsButtonData: {
        template,
        onClick: onSettingsButtonClick,
      },
      template: template.title,
    };
  });
};

export { getSimulatorsTableData };

