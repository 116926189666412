import { useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { IContentGroup } from '@netfront/ekardo-content-library';
import { FormFieldProps } from '@netfront/ui-library';
import { IHandleSetCorrectResponseParams, IUnsetCorrectResponseMutationVariables, useCreateForm, useSetCorrectResponse, useToast, useUnsetCorrectResponse, useUpdateForm, useUpdateFormStatus } from 'hooks';
import { IUpdatedCorrectResponse } from 'interfaces';



const useUpsertForm = () => {
  const variablesRef = useRef<{value: FormFieldProps | undefined}>({value: undefined });
  const contentGroupRef = useRef<{value: IContentGroup | undefined}>({value: undefined });
  const dynamicCallBackRef = useRef<(({contentGroup }: {contentGroup?: IContentGroup}) => void) | null>(null);
  const initialIsActivatedStatusRef = useRef<{value: boolean}>({value: false});

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const cleanUpAndReturn = (contentGroup?: IContentGroup ) => {
    if (dynamicCallBackRef.current) {
      dynamicCallBackRef.current({ contentGroup });
    }

    contentGroupRef.current.value = undefined;
    variablesRef.current.value = undefined;
    dynamicCallBackRef.current = null;
  };

  const { handleCreateForm, isLoading: isCreateFormLoading = false } = useCreateForm({
    onCompleted: ({ contentGroup }) => {
      contentGroupRef.current.value = contentGroup;
      const { id } = contentGroup;
      handleToastSuccess({ message: 'Form successfully created'});

      const { isActivated = false } = variablesRef.current.value ?? {};

      handleUpdateFormStatus({
        contentGroupId: id,
        status: isActivated ? 'PUBLISHED': 'UNPUBLISHED',
      })
    },
    onError: handleGetError,
  });

  const { handleUpdateForm, isLoading: isUpdateFormLoading = false } = useUpdateForm({
    onCompleted: ({ contentGroup}) => {
      contentGroupRef.current.value = contentGroup;
      const { id } = contentGroup;
      handleToastSuccess({ message: 'Form successfully updated'});
      const { isActivated = false } = variablesRef.current.value ?? {};


      if (isActivated !== initialIsActivatedStatusRef) {
        handleUpdateFormStatus({
          contentGroupId: id,
          status: isActivated ? 'PUBLISHED': 'UNPUBLISHED',
        });
      } else {
        cleanUpAndReturn();
      }

    },
    onError: handleGetError,
  });

  const { handleUpdateFormStatus, isLoading: isUpdateFormLoadingStatus = false } = useUpdateFormStatus({
    onCompleted: () => {
      cleanUpAndReturn(contentGroupRef.current.value);
    },
    onError: handleGetError,
  });

  const { handleSetCorrectResponse: handleSetCheckboxCorrectResponse, isLoading: isSetCheckboxCorrectResponseLoading = false } = useSetCorrectResponse({
    questionType: 'Checkbox',
    onError: handleGetError,
  });

  const { handleUnsetCorrectResponse: handleUnsetCheckboxCorrectResponse, isLoading: isUnsetCheckboxCorrectResponseLoading = false } = useUnsetCorrectResponse({
    questionType: 'Checkbox',
    onError: handleGetError,
  });

  const { handleSetCorrectResponse: handleSetRadioCorrectResponse, isLoading: isSetRadioCorrectResponseLoading = false } = useSetCorrectResponse({
    questionType: 'Radio',
    onError: handleGetError,
  });

  const { handleUnsetCorrectResponse: handleUnsetRadioCorrectResponse, isLoading: isUnsetRadioCorrectResponseLoading = false } = useUnsetCorrectResponse({
    questionType: 'Radio',
    onError: handleGetError,
  });

  const { handleSetCorrectResponse: handleSetDropDownListCorrectResponse, isLoading: isSetDropDownListCorrectResponseLoading = false } = useSetCorrectResponse({
    questionType: 'DropDownList',
    onError: handleGetError,
  });

  const { handleUnsetCorrectResponse: handleUnsetDropDownListCorrectResponse, isLoading: isUnsetDropDownListCorrectResponseLoading = false } = useUnsetCorrectResponse({
    questionType: 'DropDownList',
    onError: handleGetError,
  });

  const questionTypeFunctionMap: { [key: string]: { set: (params: IHandleSetCorrectResponseParams) => void; unset: (params: IUnsetCorrectResponseMutationVariables) => void }; } = {
    Checkbox: {
      set: handleSetCheckboxCorrectResponse,
      unset: handleUnsetCheckboxCorrectResponse,
    },
    Radio: {
      set: handleSetRadioCorrectResponse,
      unset: handleUnsetRadioCorrectResponse,
    },
    DropDownList: {
      set: handleSetDropDownListCorrectResponse,
      unset: handleUnsetDropDownListCorrectResponse,
    },
  };

  const handleUpsertForm = ({
    callBack,
    hasUpsert = true,
    initialIsActivatedStatus = false,
    isCreate = false,
    item,
    projectId,
    updatedMarkableQuestions = [],
  }: {
    callBack: ({ contentGroup } : { contentGroup?: IContentGroup }) => void;
    hasUpsert?: boolean;
    initialIsActivatedStatus?: boolean;
    isCreate?: boolean;
    item: FormFieldProps;
    projectId: string;
    updatedMarkableQuestions?: IUpdatedCorrectResponse[] 
  }) => {
    if (!hasUpsert) {
      callBack({});
      return;
    };
    const {
      description,
      title,
      formType,
      id: contentGroupId,
      sort,
      url,
    } = item;

    initialIsActivatedStatusRef.current.value = initialIsActivatedStatus;
    dynamicCallBackRef.current = callBack;
    variablesRef.current.value = item;

    if (isCreate) {
      handleCreateForm({
        projectId,
        title,
        description: description ?? title,
        formType,
      });

    } else {

      if (updatedMarkableQuestions.length > 0) {
        updatedMarkableQuestions.forEach(({
          contentGroupId,
          contentSnippetId,
          currentResponseId,
          type,
          updatedResponseId,
        }) => {

          const { set, unset } = questionTypeFunctionMap[type];

          if (currentResponseId) {
            unset({
              contentGroupId,
              contentSnippetId,
              questionResponseId: currentResponseId,
            });
          }

          if (updatedResponseId) {
            set({
              contentGroupId,
              contentSnippetId,
              questionResponseId: updatedResponseId,
            });
          }
          

        });
      }

      handleUpdateForm({
        contentGroupId,
        sort,
        title,
        description: description ?? title,
        url,
      });
    }
  };

  return {
    isLoading: isUpdateFormLoadingStatus  || isUpdateFormLoading || isCreateFormLoading,
    handleUpsertForm,
  };
};
export { useUpsertForm };
