import React, { useState, useEffect, useContext } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { IQuestionAction, IQuestionCondition } from '@netfront/ekardo-content-library';
import { Spacing, Input, Select, InformationBox, Dialog, Spinner, FormFieldProps, useControlledForm, ControlledForm, SidebarButtons, Breadcrumbs, HomeIcon } from '@netfront/ui-library';
import { SkipToPageSelector } from 'components';
import { ContentBuilderContext } from 'context';
import {
  useGetActionDetails,
  useCreateAction,
  useDeleteCondition,
  useUpdateAction,
  useGetConditionTemplates,
  useToast,
  useUpsertConditions,
} from 'hooks';
import { useRouter } from 'next/router';
import { Control, Controller } from 'react-hook-form';
import { generateRandomId, sortObjectArrayAlphabetically } from 'utils';
import * as yup from 'yup';

import { AdditionalActionParametersSelector } from '../AdditionalActionParametersSelector';
import { ConditionsOverview } from '../ConditionsOverview';
import { ConditionTemplateSelector } from '../ConditionTemplateSelector';
import { UpsertCondition } from '../UpsertCondition';

import { getActionDefaultValues } from './UpsertAction.helpers';
import { IActionCondition, UpsertActionProps } from './UpsertAction.interfaces';

import { QUESTION_CONDITION_TYPE_MAP, ACTION_DEFAULT_TYPE } from '../../UpsertActionsTab.constants';
import { getActionOptions, getActionTypeName } from '../../UpsertActionsTab.helpers';


const UpsertAction = ({ onUpdate, onCancel, onDelete, activePageId, selectedActionId }: UpsertActionProps) => {
  const { query: { projectId: queryProjectId } } = useRouter();
  const { actionsSidebarDetails, contentPages } = useContext(ContentBuilderContext);
  const { handleToastError, handleToastSuccess } = useToast();
  const { type = 'targetSnippet', id, contentEventId: actionContentEventId, hasContentEvent: hasActionContentEvent = false } = actionsSidebarDetails ?? {};

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();

  const [projectId, setProjectId] = useState<string>('');
  const [isUpsertMode, setIsUpsertMode] = useState<boolean>(false);
  const [selectedCondition, setSelectedCondition] = useState<IActionCondition>();
  const [additionalParameters, setAdditionalParameters] = useState({});
  const [actionDetails, setActionDetails] = useState<IQuestionAction>();
  const [hasContentEvent, setHasContentEvent] = useState<boolean>(false);
  const [contentEventId, setContentEventId] = useState<number>();
  const [conditionTemplates, setConditionTemplates] = useState<IQuestionCondition[]>([]);
  const [deleteConditionId, setDeleteConditionId] = useState<string>();
  const [isDeleteConditionDialogOpen, setIsDeleteConditionDialogOpen] = useState<boolean>(false);
  const [selectedParamsId, setSelectedParamsId] = useState<string>();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { control, handleSubmit, reset, setValue, watch } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        actionType: yup.string().label('Action type').required(),
        conditions: yup.array().required().min(1, 'Please provide at least one condition'),
      }),
    ),
  });

  const { actionType, conditions, deletedConditionIds } = watch();

  const { handleGetActionDetails, isLoading: isGetActionDetailsLoading = false } = useGetActionDetails({
    onCompleted: ({ actionDetails: returnedActionDetails }) => {
      const {
        contentEventId: returnedContentEventId,
      } = returnedActionDetails;

      setDefaultValues(getActionDefaultValues(returnedActionDetails));
      setActionDetails(returnedActionDetails);

      if (returnedContentEventId) {
        setHasContentEvent(true);
        setContentEventId(returnedContentEventId);
      }
    },
    onError: handleGetError,
  });


  // get condition templates to be able to quick add a condition to an action
  const { handleGetConditionTemplates, isLoading: isGetConditionTemplatesLoading = false } = useGetConditionTemplates({
    onCompleted: ({ conditions: returnedTemplates }) => {
      setConditionTemplates(sortObjectArrayAlphabetically<IQuestionCondition>(returnedTemplates, 'title'));
    },
    onError: handleGetError,
  });

  // TODO: @ash remove this when conditions logic is handled by createAction / updateAction
  const { handleUpsertConditions, isLoading: isUpsertConditionsLoading = false } = useUpsertConditions({
    onUpdate: () => {
      reset();
      onUpdate();
    },
  });


  const { handleCreateAction, isLoading: isCreateActionLoading = false } = useCreateAction({
    type: actionType ?? ACTION_DEFAULT_TYPE[type],
    onCompleted: ({ action }) => {
      const { id: actionId } = action;
      // TODO: @ash this should be handled on create / update of actions
      // TODO: @ash remove this when conditions logic is handled by createAction / updateAction
      const formattedConditions = (conditions as IActionCondition[]).map((condition) => {
        return {
        ...condition,
        userFlowStepId: condition.isApplyToAllSteps ? undefined: condition.userFlowStepId,
      }}) as IActionCondition[]

      handleUpsertConditions({
        conditions: formattedConditions,
        actionId,
      });

      handleToastSuccess({ message: 'Action successfully created.' });
    },
    onError: handleGetError,
  });

  const { handleUpdateAction, isLoading: isUpdateActionLoading = false } = useUpdateAction({
    onCompleted: ({ action }) => {
      const { id: actionId } = action;
      // TODO: @ash this should be handled on create / update of actions
      // TODO: @ash remove this when conditions logic is handled by createAction / updateAction

      const formattedConditions = (conditions as IActionCondition[]).map((condition) => {
        return {
        ...condition,
        userFlowStepId: condition.isApplyToAllSteps ? undefined: condition.userFlowStepId,
      }}) as IActionCondition[]

      handleUpsertConditions({
        conditions: formattedConditions,
        actionId,
      });

      handleToastSuccess({ message: 'Action successfully created.' });
    },
    onError: handleGetError,
  });

  // TODO: remove this once delete conditions is handled by updateAction
  const { handleDeleteCondition: executeDeleteCondition, isLoading: isDeleteConditionLoading = false } = useDeleteCondition({
    onError: handleGetError,
  });

  const handleSaveAction = (item: FormFieldProps) => {
    const { title: itemTitle, conditions: itemConditions, actionType: saveActionType, deletedConditionIds: saveDeletedConditionIds, targetContentPageId } = item;

    if (!selectedActionId) {
      void handleCreateAction({
        contentPageId: Number(activePageId),
        // send the contentEventId if we want to use the open / close actions
        wrapperId: ['QuestionActionTriggerContentEventType'].includes(String(saveActionType)) ? Number(contentEventId) : Number(id),
        title: itemTitle,
        conditions:  (itemConditions as IActionCondition[]).map((condition) => ({
          ...condition,
          userFlowStepId: condition.isApplyToAllSteps ? undefined: condition.userFlowStepId,
        })) as IQuestionCondition[],
        targetContentPageId: targetContentPageId ? Number(targetContentPageId) : 0,
        ...additionalParameters,
      });
    } else {
      void handleUpdateAction({
        contentPageId: Number(activePageId),
        actionId: selectedActionId,
        title: itemTitle,
        conditions: (itemConditions as IActionCondition[]).map((condition) => ({
          ...condition,
          userFlowStepId: condition.isApplyToAllSteps ? undefined: condition.userFlowStepId,
        })) as IQuestionCondition[],
        deletedConditionIds: saveDeletedConditionIds,
        ...additionalParameters,
      });
    }
  };

  const handleUpsertCondition = (conditionId?: string) => {

    setSelectedCondition((conditions as IActionCondition[]).find(({ tempId }) => tempId === conditionId));
    setIsUpsertMode(true);
  };

  const handleCloseUpsertCondition = () => {
    setSelectedCondition(undefined);
    setIsUpsertMode(false);
  };

  const handleAddTemplate = (value: string) => {
    const template = conditionTemplates.find(({ id: templateId }) => templateId === Number(value));

    if (!template) return;

    // check if the condition has already been added to the list
    const isAlreadyAdded = (conditions as IActionCondition[]).some(({ conditionTemplateId: existingId }) => template.id === existingId);
    if (!isAlreadyAdded) {
      const newCondition: IActionCondition = {
        ...template,
        tempId: `${generateRandomId()}`,
        type: QUESTION_CONDITION_TYPE_MAP[String(template.__typename)],
        isTemplate: false,
        conditionTemplateId: template.id,
      };

      delete newCondition.id;

      setValue('conditions', [...conditions, newCondition]);
    }
  };

  const handleCancelDeleteCondition = () => {
    setIsDeleteConditionDialogOpen(false);
    setDeleteConditionId(undefined);
  };

  const onUpsertCondition = (returnedCondition: IActionCondition) => {
    const isExistingCondition = (conditions as IActionCondition[]).some((condition) => condition.tempId === returnedCondition.tempId);
    let updatedConditions: IActionCondition[] = [...conditions];
    if (isExistingCondition) {
      // if the condition is already part of the list, then update
      updatedConditions = updatedConditions.map((condition) => {
        if (condition.tempId === returnedCondition.tempId) return returnedCondition;
        return condition;
      });
    } else {
      // add condition to list
      updatedConditions.push(returnedCondition);
    }
    setValue('conditions', updatedConditions);
    handleCloseUpsertCondition();
  };

  const handleDeleteCondition = (conditionTempId: string) => {
    const condition = (conditions as IActionCondition[]).find(({ tempId }) => tempId === conditionTempId);

    if (condition) {
      const { id: questionConditionId } = condition;
      // The condition exists in the database, so we need to remove it
      if (questionConditionId) {
        setValue('deletedConditionIds', [...deletedConditionIds, questionConditionId]);
      }

      // TODO: @ash temporarily delete, this will be handled in the updateAction request,
      // remove once this functionality is available
      if (questionConditionId) {
        executeDeleteCondition({
          questionConditionId,
        });
      }
      setValue('conditions', (conditions as IActionCondition[]).filter(({ tempId }) => tempId !== conditionTempId));
      handleCloseUpsertCondition();
      setIsDeleteConditionDialogOpen(false);
      setDeleteConditionId(undefined);
    }
  };

  const handleAdditionalParameters = ({ params, value }: {params: {[key: string]: unknown}; value: string}) => {
    setAdditionalParameters(params);
    setSelectedParamsId(value);
  };

  const handleCancel = () => {
    reset();
    onCancel();
  }
  // fetch details if it is an existing action
  useEffect(() => {
    if (!selectedActionId) {
      setDefaultValues(getActionDefaultValues());
    } else {

      handleGetActionDetails({
        actionId: selectedActionId,
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActionId]);

  // Determine whether the wrapper has an event attached to it.
  useEffect(() => {
    if (selectedActionId) return;
    if (!(hasActionContentEvent && actionContentEventId)) return;

    setHasContentEvent(hasActionContentEvent);
    setContentEventId(actionContentEventId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, hasActionContentEvent, actionContentEventId, selectedActionId]);

  useEffect(() => {
    if (!projectId) return;

    handleGetConditionTemplates({
      projectId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (!queryProjectId) return;
    setProjectId(String(queryProjectId));
  }, [queryProjectId]);

  const isLoading =
    isGetActionDetailsLoading ||
    isCreateActionLoading ||
    isDeleteConditionLoading ||
    isUpsertConditionsLoading ||
    isUpdateActionLoading ||
    isGetConditionTemplatesLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      {isUpsertMode ? (
        <UpsertCondition
          selectedCondition={selectedCondition}
          onCancel={handleCloseUpsertCondition}
          onDelete={(tempId: string) => {
            setIsDeleteConditionDialogOpen(true);
            setDeleteConditionId(tempId);
          }}
          onUpdate={onUpsertCondition}
        />
      ) : (
        <>
          <Breadcrumbs
            itemDivider="chevron"
            items={[
              {
                key: 'actions',
                onClick: handleCancel,
                content: 'Actions',
                icon: HomeIcon,
              },
              {
                key: 'upsert-action',
                content: <span className="c-breadcrumb-item__link">{selectedActionId ? 'Edit' : 'Add'} Action</span> ,
              }
            ]}
            isContained
          />
          <Spacing>
            <InformationBox message="Name this action for future reference and configure the required conditions to execute the action" />
          </Spacing>

          <ControlledForm
            callBack={(item: FormFieldProps) => {
              handleSaveAction(item);
            }}
            handleSubmit={handleSubmit}
          >

            <Spacing>
              <Controller
                control={control as Control<FormFieldProps>}
                name="title"
                render={({ field, fieldState }) => (
                  <Input
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id="id_action_title"
                    labelText="Title"
                    type="text"
                    isLabelSideBySide
                    isRequired
                    {...field}
                  />
                )}
              />
            </Spacing>
            {selectedActionId ? (
              <Spacing size="2x-large">
                <Input
                  id="id_action_type"
                  labelText="Action type"
                  name="action_type"
                  type="text"
                  value={getActionTypeName(type, String(actionType))}
                  isDisabled
                  isLabelSideBySide
                  onChange={() => { return }}
                />
              </Spacing>
            ) : (
              <Spacing size="2x-large">
                <Controller
                  control={control as Control<FormFieldProps>}
                  name="actionType"
                  render={({ field, fieldState }) => (
                    <Select
                      errorMessage={getFormFieldErrorMessage(fieldState)}
                      id="id_action_type_select"
                      labelText="Action type"
                      options={getActionOptions(type, hasContentEvent)
                        .filter(({ isHidden = false }) => !isHidden)
                        .map(({ id: optionId, value, name }) => ({
                          id: optionId,
                          value,
                          name,
                        }))}
                      isLabelSideBySide
                      isRequired
                      {...field}
                    />
                  )}
                />
              </Spacing>
            )}
            {['QuestionActionTriggerContentEventType'].includes(String(actionType)) && (
              // Additional parameters are required for certain actions, here we can define the required parameters
              <AdditionalActionParametersSelector
                actionDetails={actionDetails}
                selectedActionType={actionType}
                selectedParamsId={selectedParamsId}
                onSelectParameters={handleAdditionalParameters}
              />
            )}

            {['QuestionActionSkipToPageType'].includes(String(actionType)) && (
              <SkipToPageSelector
                contentPages={contentPages}
                control={control as Control<FormFieldProps>}
                currentPageId={Number(activePageId)}
                isDisabled={Boolean(selectedActionId)}
              />
            )}

            {conditionTemplates.length > 0 && (
              <ConditionTemplateSelector templateOptions={conditionTemplates} onSelectTemplate={handleAddTemplate} />
            )}

            <Spacing size="6x-large">
              <Controller
                control={control as Control<FormFieldProps>}
                name="conditions"
                render={({ field }) => (
                  <ConditionsOverview
                    conditionItems={field.value}
                    onDelete={selectedActionId ? () => onDelete(Number(selectedActionId)) : undefined}
                    onDeleteClick={(tempId: string) => {
                      setIsDeleteConditionDialogOpen(true);
                      setDeleteConditionId(tempId);
                    }}
                    onEditClick={handleUpsertCondition}
                  />
                )}
              />
            </Spacing>

            <SidebarButtons
              onCancel={handleCancel}
              onDelete={selectedActionId ? () => onDelete(selectedActionId) : undefined}
              onSaveButtonType="submit"
            />
          </ControlledForm>

          <Dialog
            isOpen={isDeleteConditionDialogOpen}
            title="Delete condition?"
            isNarrow
            onCancel={handleCancelDeleteCondition}
            onClose={handleCancelDeleteCondition}
            onConfirm={() => handleDeleteCondition(String(deleteConditionId))}
          />
        </>
      )}
    </>
  );
};

export { UpsertAction };
