import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { removeItemFromLocalStorage, saveItemWithExpiryToLocalStorage } from '@netfront/common-library';
import {
  IGeladaProject,
  useGetGeladaProject,
  useGetGeladaOrganisationByKey,
  IGeladaOrganisation,
  useProtectedRoute,
} from '@netfront/gelada-identity-library';
import { Spinner } from '@netfront/ui-library';
import { useToast } from 'hooks';
import { useRouter } from 'next/router';

import CachingEntitiesContext from './CachingEntitiesContext';
import { CachingEntitiesContextProps } from './CachingEntitiesContext.interfaces';

export function CachingEntitiesProvider({ children }: Readonly<CachingEntitiesContextProps>) {

  const { isAuthenticated } = useProtectedRoute({
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const {
    query: { projectId: queryProjectId, organisationId: queryOrganisationId },
  } = useRouter();
  const { handleToastError } = useToast();

  const [organisationId, setOrganisationId] = useState<string>('');
  const [organisation, setOrganisation] = useState<IGeladaOrganisation>();
  const [organisationExpiry, setOrganisationExpiry] = useState<number>();
  const [project, setProject] = useState<IGeladaProject>();
  const [projectId, setProjectId] = useState<string>('');
  const [projectExpiry, setProjectExpiry] = useState<number>();

  const { handleGetGeladaProject, isLoading: isGetGeladaProjectLoading } = useGetGeladaProject({
    fetchPolicy: 'cache-first',
    onCompleted: ({ geladaProject }) => {
      saveItemWithExpiryToLocalStorage('project', JSON.stringify(geladaProject), {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 1,
        },
      });

      setProject(geladaProject);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { handleGetGeladaOrganisationByKey, isLoading: isGetGeladaOrganisationLoading } = useGetGeladaOrganisationByKey({
    fetchPolicy: 'cache-first',
    onCompleted: ({ geladaOrganisation }) => {
      saveItemWithExpiryToLocalStorage('organisation', JSON.stringify(geladaOrganisation), {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 1,
        },
      });

      setOrganisation(geladaOrganisation);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!projectId) {
      return;
    }

    if (projectId !== project?.id) {
      removeItemFromLocalStorage('project');

      void handleGetGeladaProject({
        projectId: String(projectId),
        shouldIncludeProjectLogo: true,
        shouldIncludeProjectSettings: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, project]);

  useEffect(() => {
    if (!organisationId) {
      return;
    }

    if (Number(organisationId) !== organisation?.id) {
      removeItemFromLocalStorage('organisation');

      void handleGetGeladaOrganisationByKey({
        organisationKey: String(organisationId),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);


  useEffect(() => {
    if (!projectId) return;
    const projectJSON = JSON.parse(String(window.localStorage.getItem('project')));

    if (!projectJSON) {
      return;
    }

    setProject(JSON.parse(String(projectJSON.value)) as IGeladaProject);
    setProjectExpiry(Number(projectJSON.expiry));
  }, [projectId]);


  useEffect(() => {
    if (!projectExpiry) {
      return;
    }

    if (new Date().getTime() < projectExpiry) {
      return;
    }

    removeItemFromLocalStorage('project');
  }, [projectExpiry]);

  useEffect(() => {
    if (!organisationId) return;
    const organisationJSON = JSON.parse(String(window.localStorage.getItem('organisation')));

    if (!organisationJSON) {
      return;
    }

    setOrganisation(JSON.parse(String(organisationJSON.value)) as IGeladaOrganisation);
    setOrganisationExpiry(Number(organisationJSON.expiry));
  }, [organisationId]);

  useEffect(() => {
    if (!organisationExpiry) {
      return;
    }

    if (new Date().getTime() < organisationExpiry) {
      return;
    }

    removeItemFromLocalStorage('organisation');
  }, [organisationExpiry]);

  useEffect(() => {
    if (!isAuthenticated) return;
    setProjectId(queryProjectId as string);
  }, [queryProjectId, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) return;
    setOrganisationId(queryOrganisationId as string);
  }, [queryOrganisationId, isAuthenticated]);

  const isLoading = isGetGeladaProjectLoading ?? isGetGeladaOrganisationLoading;

  return (
    <CachingEntitiesContext.Provider
      value={{
        isLoading,
        organisation,
        project,
      }}
    >
      {isLoading ? <Spinner isLoading={true} /> : children}
    </CachingEntitiesContext.Provider>
  );
}
