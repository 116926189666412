

import { useCapuchinMutation } from 'hooks';

import { ADD_TEST_USER_MUTATION } from './useAddTestUser.graphql';
import {
  IAddTestUserMutationGraphQLResponse,
  IAddTestUserMutationVariables,
  IHandleAddTestUserParams,
  IUseAddTestUserOptions,
  IUseAddTestUser,
} from './useAddTestUser.interfaces';

const useAddTestUser = (options?: IUseAddTestUserOptions): IUseAddTestUser => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseAddTestUserOptions);

  const [executeAddTestUser, { loading: isLoading }] = useCapuchinMutation<
    IAddTestUserMutationGraphQLResponse,
    IAddTestUserMutationVariables
  >({
    mutation: mutation ?? ADD_TEST_USER_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          projectTestUserMutations: { addUser: testUser },
        } = data;

        onCompleted(testUser);
      },
      onError,
    },
    token,
  });

  const handleAddTestUser = ({ projectId, userId }: IHandleAddTestUserParams) => {
    void executeAddTestUser({
      variables: {
        projectId,
        userId,
      },
    });
  };

  return {
    handleAddTestUser,
    isLoading,
  };
};

export { useAddTestUser };
