import { gql } from '@apollo/client';


export const UNSET_CHECKBOX_CORRECT_RESPONSE_MUTATION = gql`
  mutation unsetCheckboxCorrectResponseMutation(
    $questionResponseId: Int!
    $contentGroupId: Int!
    $contentSnippetId: Int!
  ) {
    contentGroup {
      unsetCorrectResponse(
        checkbox: {
          questionResponseCheckboxId: $questionResponseId
          contentGroupId: $contentGroupId
          contentSnippetId: $contentSnippetId
        }
      )
    }
  }
`;

export const UNSET_RADIO_CORRECT_RESPONSE_MUTATION = gql`
  mutation unsetRadioCorrectResponseMutation(
    $questionResponseId: Int!
    $contentGroupId: Int!
    $contentSnippetId: Int!
  ) {
    contentGroup {
      unsetCorrectResponse(
        radio: {
          questionResponseRadioId: $questionResponseId
          contentGroupId: $contentGroupId
          contentSnippetId: $contentSnippetId
        }
      )
    }
  }
`;

export const UNSET_DROPDOWN_LIST_CORRECT_RESPONSE_MUTATION = gql`
  mutation unsetDropDownListCorrectResponseMutation(
    $questionResponseId: Int!
    $contentGroupId: Int!
    $contentSnippetId: Int!
  ) {
    contentGroup {
      unsetCorrectResponse(
        dropdownlist: {
          questionResponseDropDownListId: $questionResponseId
          contentGroupId: $contentGroupId
          contentSnippetId: $contentSnippetId
        }
      )
    }
  }
`;

