import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IDBAsset } from '@netfront/common-library';
import { GeneralTabIcon } from '@netfront/ui-library';
import { CustomCssGeneralTab, TablePageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import { useCompileCssFiles, useSearchPaginatedAssets, useToast } from 'hooks';
import last from 'lodash.last';
import { useRouter } from 'next/router';

import { CSS_FILE_TABLE_COLUMNS } from './LibraryCustomCssPage.constants';
import { getCssFilesTableData } from './LibraryCustomCssPage.helpers';
import { ICssFilesTableData } from './LibraryCustomCssPage.interfaces';


const LibraryCustomCssPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError } = useToast();
  const defaultPageSize = 10;

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [allCssFiles, setAllCssFiles] = useState<IDBAsset[]>();
  const [filter, setFilter] = useState<string>();
  const [selectedCssFileId, setSelectedCssFileId] = useState<string>();
  const [cssFilesTableData, setCssFilesTableData] = useState<ICssFilesTableData[]>([]);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [totalCssFiles, setTotalCssFiles] = useState(0);

  const handleError =  (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const {
    handleFetchMorePaginatedAssets,
    handleSearchPaginatedAssets,
    isLoading: isGetCssFilesLoadingLoading = false,
  } = useSearchPaginatedAssets({
    fetchPolicy: 'no-cache',
    onCompleted: ({ assetConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const cssFiles = edges.map(({ node }) => node);
      setAllCssFiles(cssFiles);
      setIsLoadMoreDisabled(cssFiles.length >= totalCount || totalCount <= pageSize);
      setTotalCssFiles(totalCount);
    },
    onError: handleError
  });

  const { handleCompileCssFiles, isLoading: isCompileCssFilesLoading = false } = useCompileCssFiles({
    onError: handleError,
  });

  const handleFilterSearch = (value: string) => {
    setFilter(value);
  };

  const handleCloseSidebar = () => {
    setIsSideBarOpen(false);
    setSelectedCssFileId(undefined);
  };

  const handlePaginate = async () => {
    await handleFetchMorePaginatedAssets({
      after: lastCursor,
      first: pageSize,
      projectId: String(projectId),
      filter,
      types: ['CSS'],
    });
  };

  const handleUpdate = () => {
    handleCompileCssFiles({
      projectId: String(projectId),
    })
    handleCloseSidebar();
    handleSearchPaginatedAssets({
      first: pageSize,
      filter,
      projectId: String(projectId),
      types: ['CSS'],
    });
  }

  const handleOnPageSizeChange = (changedPageSize: number) => {
    setPageSize(Number(changedPageSize));
  };


  const handleUpsertCssFile = (id?: string) => {
    setSelectedCssFileId(id ? id : undefined);
    setIsSideBarOpen(true);
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    handleSearchPaginatedAssets({
      first: pageSize,
      filter,
      projectId: String(projectId),
      types: ['CSS'],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId, filter]);

  useEffect(() => {
    if (!allCssFiles) {
      return;
    }

    setCssFilesTableData(
      getCssFilesTableData({
        cssFiles: allCssFiles,
        onSettingsButtonClick: (id: string) => {
          handleUpsertCssFile(id);
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCssFiles]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  return (
    <TablePageTemplate<ICssFilesTableData>
      activePage="library"
      activeSubPage="custom-css"
      additionalBreadcrumbItems={[{
        key: '1',
        content: 'Library',
      }, {
        key: '2',
        content: 'Custom css',
      }]}
      columns={CSS_FILE_TABLE_COLUMNS}
      data={cssFilesTableData}
      defaultActiveTabId="id_general_tab"
      description={`Custom css for ${String(projectName)}`}
      handleAddNewClick={() => handleUpsertCssFile(undefined)}
      handleOnPageSizeChange={handleOnPageSizeChange}
      handleOnPaginate={handlePaginate}
      handleSearch={handleFilterSearch}
      informationBoxMessage={'Use the custom css to personalise the style of your content pages'}
      isLoading={isGetCssFilesLoadingLoading || isCompileCssFilesLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      isSideBarOpen={isSideBarOpen}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      searchPlaceholder="Search"
      size={project?.logo?.presignedUrl ? 'small': 'medium'}
      tableType="custom css"
      tabs={[
        {
          icon: GeneralTabIcon,
          id: 'id_general_tab',
          label: 'General',
          view: () => <CustomCssGeneralTab projectId={String(projectId)} selectedCssFileId={selectedCssFileId} onCancel={handleCloseSidebar} onUpdate={handleUpdate}/>,
        },
      ]}
      title={`${String(projectName)} Custom css`}
      toggleIsSideBarOpen={handleCloseSidebar}
      totalItems={totalCssFiles}
      hideSideBarButtons
    />
  );
};

export { LibraryCustomCssPage };
