
import { useCapuchinMutation } from 'hooks';

import { UPDATE_CONDITION_GROUP_MUTATION } from './useUpdateConditionGroup.graphql';
import {
  IUpdateConditionGroupMutationGraphQLResponse,
  IUpdateConditionGroupMutationVariables,
  IHandleUpdateConditionGroupParams,
  IUseUpdateConditionGroupOptions,
  IUseUpdateConditionGroup,
} from './useUpdateConditionGroup.interfaces';

const useUpdateConditionGroup = (options?: IUseUpdateConditionGroupOptions): IUseUpdateConditionGroup => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateConditionGroupOptions);

  const [executeUpdateConditionGroup, { loading: isLoading }] = useCapuchinMutation<
    IUpdateConditionGroupMutationGraphQLResponse,
    IUpdateConditionGroupMutationVariables
  >({
    mutation: mutation ?? UPDATE_CONDITION_GROUP_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          conditionGroups: { update: conditionGroupConnection },
        } = data;

        onCompleted(conditionGroupConnection);
      },
      onError,
    },
    token,
  });

  const handleUpdateConditionGroup = ({ conditionGroupId, operator }: IHandleUpdateConditionGroupParams) => {
    void executeUpdateConditionGroup({
      variables: {
        conditionGroupId,
        operator,
      },
    });
  };

  return {
    handleUpdateConditionGroup,
    isLoading,
  };
};

export { useUpdateConditionGroup };
