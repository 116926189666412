import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { COMPILE_CSS_FILES } from './useCompileCssFiles.graphql';
import {
  ICompileCssFilesMutationGraphQLResponse,
  ICompileCssFilesMutationVariables,
  IHandleCompileCssFilesParams,
  IUseCompileCssFilesOptions,
  IUseCompileCssFiles,
} from './useCompileCssFiles.interfaces';

const useCompileCssFiles = (options?: IUseCompileCssFilesOptions): IUseCompileCssFiles => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCompileCssFilesOptions);

  const [executeCompileCssFiles, { loading: isLoading }] = useEkardoMutation<
  ICompileCssFilesMutationGraphQLResponse,
  ICompileCssFilesMutationVariables
  >({
    mutation: mutation ?? COMPILE_CSS_FILES,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          compile: isCompleted,
        } = data;

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleCompileCssFiles = ({ projectId }: IHandleCompileCssFilesParams) => {
    void executeCompileCssFiles({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleCompileCssFiles,
    isLoading,
  };
};

export { useCompileCssFiles };
