
import { IInteractionsReportTableData, IInteractionsReportTableDataParams } from "./InteractionsReportPage.interfaces";


const getInteractionsReportTableData = ({ items }: IInteractionsReportTableDataParams): IInteractionsReportTableData[] => {
  return items.map(({
    commentCount = 0,
    communityCount = 0,
    connectionCount = 0,
    firstName = '',
    id,
    lastName = '',
    messageCount = 0,
    postCount = 0,
    relatesCount = 0,
    totalComments = 0,
    totalConnections = 0,
    totalMessages = 0,
    totalPosts = 0,
    totalRelates = 0,
   }) => {
    return {
      commentCount,
      communityCount,
      connectionCount,
      firstName,
      id,
      lastName,
      messageCount,
      postCount,
      relatesCount,
      totalComments,
      totalConnections,
      totalMessages,
      totalPosts,
      totalRelates,
    };
  });
};

export { getInteractionsReportTableData };
