import { gql } from '@apollo/client';

const SEARCH_CONDITIONS_QUERY = gql`
  query SearchConditions($after: String = null, $filter: String!, $first: Int = null, $projectId: String!, $scenarioId: Int = null) {
    scenarioQueries {
      searchConditions(after: $after, filter: $filter, first: $first, projectId: $projectId, scenarioId: $scenarioId) {
        edges {
          cursor
          node {
            createdAt
            delay
            description
            id
            title
            updatedAt
            ... on ConditionDayGraphType {
              day
            }
            ... on ConditionHourGraphType {
              value
            }
            ... on ConditionSessionGraphType {
              comparator
            }
            ... on ConditionStepStartedGraphType {
              comparator
              stepId
            }
            ... on ConditionStepCompletedGraphType {
              comparator
              stepId
            }
            ... on ConditionUserActivatedGraphType {
              comparator
            }
            ... on ConditionUserLoginGraphType {
              comparator
            }
            ... on ConditionStepProgressionGraphType {
              comparator
              stepId
              percentage
              valueComparator
            }
              ... on ConditionManyUserGroupGraphType {
              groupIds
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  }
`;

export { SEARCH_CONDITIONS_QUERY };
