
import { useCapuchinLazyQuery } from 'hooks';

import { SEARCH_CONDITIONS_QUERY } from './useSearchScenarioConditions.graphql';
import {
  IGetPaginatedScenarioConditionsQueryGraphQLResponse,
  IGetPaginatedScenarioConditionsQueryVariables,
  IHandleGetPaginatedScenarioConditionsParams,
  IUseGetPaginatedScenarioConditions,
  IUseGetPaginatedScenarioConditionsOptions,
} from './useSearchScenarioConditions.interfaces';

const useGetPaginatedScenarioConditions = (options?: IUseGetPaginatedScenarioConditionsOptions): IUseGetPaginatedScenarioConditions => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPaginatedScenarioConditionsOptions);

  const [executeGetPaginatedScenarioConditions, { loading: isLoading }] = useCapuchinLazyQuery<
  IGetPaginatedScenarioConditionsQueryGraphQLResponse,
  IGetPaginatedScenarioConditionsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          scenarioQueries: { searchConditions: conditionConnection },
        } = data;

        onCompleted({
          conditionConnection,
        });
      },
      onError,
    },
    query: query ?? SEARCH_CONDITIONS_QUERY,
    token,
  });

  const handleGetPaginatedScenarioConditions = ({ after, filter, first, projectId, scenarioId }: IHandleGetPaginatedScenarioConditionsParams) => {
    void executeGetPaginatedScenarioConditions({
      variables: {
        after,
        filter,
        first,
        projectId,
        scenarioId,
      },
    });
  };

  return {
    handleGetPaginatedScenarioConditions,
    isLoading,
  };
};

export { useGetPaginatedScenarioConditions };
