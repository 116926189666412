import { useContext, useState, useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { useCookie } from '@netfront/common-library';
import { useProtectedRoute } from '@netfront/gelada-identity-library';
import last from 'lodash.last';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { EventSidebarView } from 'components/SidebarViews/EventSidebarView';

import { EVENTS_PAGE_SIZE, EVENTS_TABLE_COLUMNS } from './EventsPage.constants';
import { getCommunitiesTableData } from './EventsPage.helpers';
import { IEventsTableData } from './EventsPage.interfaces';

import { TablePageTemplate } from '../../..';
import { CachingEntitiesContext, DashboardContext } from '../../../../context';
import { useGetPaginatedProjectEvents, useToast } from '../../../../hooks';
import { IDBCommunity } from '../../../../interfaces';

const EventsPage = () => {
  const { getAccessTokenCookie } = useCookie();
  const { isAuthenticated } = useProtectedRoute();
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError } = useToast();

  const { project } = useContext(CachingEntitiesContext);
  const { dashboardLink } = useContext(DashboardContext);

  const [allEvents, setAllEvents] = useState<IDBCommunity[]>([]);
  const [eventsTableData, setEventsTableData] = useState<IEventsTableData[]>([]);
  const [filter, setFilter] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(EVENTS_PAGE_SIZE);
  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [selectedEventId, setSelectedEventId] = useState<number>();
  const [totalEvents, setTotalEvents] = useState<number>(0);

  const token = getAccessTokenCookie();

  const {
    handleFetchMorePaginatedProjectEvents,
    handleGetPaginatedProjectEvents,
    isLoading: isGetPaginatedProjectEventsLoading = false,
  } = useGetPaginatedProjectEvents({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ eventConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const events = edges.map(({ node }) => node);

      setAllEvents(events as unknown as IDBCommunity[]);
      setIsLoadMoreDisabled(events.length >= totalCount || totalCount <= pageSize);
      setTotalEvents(totalCount);
    },
    projectId: String(projectId),
    token,
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleAddNewEventClick = () => {
    setSelectedEventId(undefined);
    setIsSideBarOpen(true);
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };


  const handleFilterSearch = (value: string) => {
    void handleGetPaginatedProjectEvents({
      first: pageSize,
      filter: value,
      projectId: String(projectId),
    });

    setFilter(value);
  };

  const handleSideBarClose = () => {
    setSelectedEventId(undefined);
    setIsSideBarOpen(false);
  };

  const handleUpdate = () => {
    handleSideBarClose();
    void handleGetPaginatedProjectEvents({
      filter,
      first: pageSize,
      projectId: String(projectId),
    });
  };

  useEffect(() => {
    if (!(isAuthenticated && projectId)) {
      return;
    }

    void handleGetPaginatedProjectEvents({
      filter,
      first: pageSize,
      projectId: String(projectId),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, projectId, pageSize]);

  useEffect(() => {
    setEventsTableData(
      getCommunitiesTableData({
        events: allEvents,
        onSettingsButtonClick: (id) => {
          setSelectedEventId(id);
          setIsSideBarOpen(true);
        },
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEvents]);

  useEffect(() => {
    if (!project) {
      return;
    }

    const { name } = project;

    setProjectName(name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  if (projectName)
    return (
      <>
        <TablePageTemplate<IEventsTableData>
          activePage="social"
          activeSubPage="events"
          additionalBreadcrumbItems={[
            {
              key: '1',
              content: <Link href={`${String(dashboardLink)}/social`}><span>Social</span></Link>,
            },
            {
              key: '2',
              content: 'Events',
            },
          ]}
          columns={EVENTS_TABLE_COLUMNS}
          data={eventsTableData}
          defaultActiveTabId="id_general_tab"
          description={`Events for ${projectName} project`}
          handleAddNewClick={handleAddNewEventClick}
          handleOnPageSizeChange={handlePageSizeChange}
          handleOnPaginate={async () => {
            await handleFetchMorePaginatedProjectEvents({
              after: lastCursor,
              first: pageSize,
              filter,
              projectId: String(projectId),
            });
          }}
          handleSearch={handleFilterSearch}
          informationBoxMessage={`Manage ${projectName} events`}
          isLoading={isGetPaginatedProjectEventsLoading}
          isPaginationDisabled={isLoadMoreDisabled}
          isSideBarOpen={isSideBarOpen}
          pageSize={pageSize}
          pageTitle={projectName}
          tableType="events"
          title={`${projectName} events dashboard`}
          totalItems={totalEvents}
        />
        <EventSidebarView
          handleSideBarClose={handleSideBarClose}
          isSideBarOpen={isSideBarOpen}
          projectId={projectId}
          selectedEventId={selectedEventId}
          onUpdate={handleUpdate}
        />
      </>
    );

  return <></>;
};

export { EventsPage };
