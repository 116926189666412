import React from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { FormFieldProps, Input, Spacing } from '@netfront/ui-library';
import { Control, Controller } from 'react-hook-form';

import { ScheduleTabMonthlyProps } from './ScheduleTabMonthly.interfaces';


const ScheduleTabMonthly = ({ control }: ScheduleTabMonthlyProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <>
      <Spacing>
        <Controller
          control={control as Control<FormFieldProps>}
          name="monthlyDay"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              iconAfter={{
                isTextOnly: true,
                helperText: 'Day'
              }}
              id="id_scenario_month_day"
              labelText="Recur every"
              maxNumber={30}
              minNumber={1}
              type="number"
              isLabelSideBySide
              isRequired
              {...field}
              
            />
          )}
        />
      </Spacing>
      
      <Spacing>
        <Controller
          control={control as Control<FormFieldProps>}
          name="monthlyOccurEvery"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              iconAfter={{
                isTextOnly: true,
                helperText: 'Months'
              }}
              id="id_scenario_month_occurrence"
              labelText="Of every"
              maxNumber={12}
              minNumber={1}
              type="number"
              isLabelSideBySide
              isRequired
              {...field}
              
            />
          )}
        />
      </Spacing>
    </>
  );
};

export { ScheduleTabMonthly };
