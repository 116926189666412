import { ApolloQueryResult } from '@apollo/client';


import { GET_INVITATION_REMINDERS } from './useGetInvitationReminders.graphql';
import {
  IGetPaginatedInvitationRemindersQueryGraphQLResponse,
  IGetPaginatedInvitationRemindersQueryVariables,
  IHandleFetchMorePaginatedInvitationRemindersParams,
  IHandleGetPaginatedInvitationRemindersParams,
  IUseGetPaginatedInvitationReminders,
  IUseGetPaginatedInvitationRemindersOptions,
} from './useGetInvitationReminders.interfaces';
import { useCapuchinLazyQuery } from 'hooks';

const useGetInvitationReminders = (options?: IUseGetPaginatedInvitationRemindersOptions): IUseGetPaginatedInvitationReminders => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPaginatedInvitationRemindersOptions);

  const [executeGetPaginatedInvitationReminders, { fetchMore, loading: isLoading }] = useCapuchinLazyQuery<
  IGetPaginatedInvitationRemindersQueryGraphQLResponse,
  IGetPaginatedInvitationRemindersQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          invitationReminders: { list: invitationRemindersConnection },
        } = data;

        onCompleted({
          invitationRemindersConnection,
        });
      },
      onError,
    },
    query: query ?? GET_INVITATION_REMINDERS,
    token,
  });

  const handleFetchMorePaginatedInvitationReminders = ({
    after,
    filter,
    first,
    projectId,
  }: IHandleFetchMorePaginatedInvitationRemindersParams): Promise<ApolloQueryResult<IGetPaginatedInvitationRemindersQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          invitationReminders: { list: newlyInvitationRemindersConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyInvitationRemindersConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          invitationReminders: { list: previouslyFetchedInvitationRemindersConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedInvitationRemindersConnection;

        return {
          ...previousQueryResult,
          invitationReminders: {
            ...previouslyFetchedInvitationRemindersConnection,
            list: {
              ...newlyInvitationRemindersConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        filter,
        first,
        projectId,
      },
    });
  };

  const handleGetPaginatedInvitationReminders = ({
    after,
    filter,
    first,
    projectId,
  }: IHandleGetPaginatedInvitationRemindersParams) => {
    void executeGetPaginatedInvitationReminders({
      variables: {
        after,
        filter,
        first,
        projectId,
      },
    });
  };

  return {
    handleFetchMorePaginatedInvitationReminders,
    handleGetPaginatedInvitationReminders,
    isLoading,
  };
};

export { useGetInvitationReminders };
