import addYears from 'date-fns/addYears';
import format from 'date-fns/format';
import { orderBy } from 'lodash';

import { IGetUserScenariosTableDataParams, IUserScenariosTableData } from './UserScenariosPage.interfaces';

const getScenariosTableData = ({ scenarios, onSettingsButtonClick }: IGetUserScenariosTableDataParams): IUserScenariosTableData[] => {
  const updatedScenarios = scenarios.map((scenario) => {
    const { createdDate, endDate = null } = scenario;

    return {
      ...scenario,
      endDate: endDate ? new Date(endDate) : addYears(new Date(createdDate), 1),
    };
  });

  return (orderBy(updatedScenarios, ['endDate'], ['desc'])).map(
    ({ endDate, description, id, isTestPhase, status, title }): IUserScenariosTableData => {
      return {
        description,
        endDate: format(endDate, 'dd/MM/yyyy'),
        id,
        isTestPhase,
        settingsButtonData: {
          id,
          onClick: onSettingsButtonClick,
        },
        status,
        title,
      };
    } ,
  );
};

export { getScenariosTableData };


