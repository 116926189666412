import React from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { CheckboxGroup, FormFieldProps, Input, Spacing } from '@netfront/ui-library';
import { Control, Controller } from 'react-hook-form';

import { ScheduleTabWeeklyProps } from './ScheduleTabWeekly.interfaces';

const ScheduleTabWeekly = ({ control }: ScheduleTabWeeklyProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <>
      <Spacing size="x-large">
        <Controller
          control={control as Control<FormFieldProps>}
          name="weeklyRecurrenceDays"
          render={({ field }) => (
            <CheckboxGroup
              items={[
                { labelText: 'Monday', id: 'MONDAY', value: 'MONDAY' },
                { labelText: 'Tuesday', id: 'TUESDAY', value: 'TUESDAY' },
                { labelText: 'Wednesday', id: 'WEDNESDAY', value: 'WEDNESDAY' },
                { labelText: 'Thursday', id: 'THURSDAY', value: 'THURSDAY' },
                { labelText: 'Friday', id: 'FRIDAY', value: 'FRIDAY' },
                { labelText: 'Saturday', id: 'SATURDAY', value: 'SATURDAY' },
                { labelText: 'Sunday', id: 'SUNDAY', value: 'SUNDAY' },
              ]}
              legendText="Allowable sending days"
              values={field.value}
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control as Control<FormFieldProps>}
          name="weeklyOccurEvery"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              iconAfter={{
                isTextOnly: true,
                helperText: 'Weeks'
              }}
              id="id_scenario_weekly_occurrence"
              labelText="Every"
              minNumber={1}
              tooltipText="How regularly will this scenario run in weeks"
              type="number"
              isLabelSideBySide
              isRequired
              {...field}
              
            />
          )}
        />
      </Spacing>
    </>
  );
};

export { ScheduleTabWeekly };
