export const convertToFile = (content: string, type: string, fileName = 'foo') => {
  const typeNameMap: {[key: string]: string} = {
    js: 'text/javascript',
    css: 'text/css',
    html: 'text/html',
  };

  const newFile = new File([content], `${fileName}.${type}`, {
    type: typeNameMap[type],
  });
  return newFile;
};
