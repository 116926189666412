import { useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { IContentPage } from '@netfront/ekardo-content-library';
import { ControlledForm, Dialog, DialogButtons, FormFieldProps, IOption, Select, Spacing, useControlledForm } from '@netfront/ui-library';
import { ContentBuilderContext } from 'context';
import { IEntityType } from 'interfaces';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';

import { ENTITY_NAME_MAP } from './ContentBuilderMoveEntityDialog.constants';
import { formatMove, getMoveOptions } from './ContentBuilderMoveEntityDialog.helpers';
import { ContentBuilderMoveEntityDialogProps } from './ContentBuilderMoveEntityDialog.types';



const ContentBuilderMoveEntityDialog = ({ isOpen = false }: ContentBuilderMoveEntityDialogProps) => {
  
  const { moveDialogDetails, closeMoveDialog, handleMoveEntity } = useContext(ContentBuilderContext);
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  
  const [moveOptions, setMoveOptions] = useState<IOption[]>([]);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();

  const { type, contentPage, entityId } = moveDialogDetails ?? {};

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        target: yup.string().required().label('Move target'),
      }),
    ),
  });
  

  const handleResetState = () => {
    reset();
    closeMoveDialog();
  };

  const handleSave = (item: FormFieldProps) => {
    const { target } = item;
    handleMoveEntity(formatMove(String(target), Number(entityId), type as IEntityType, contentPage as IContentPage ));
  };

  useEffect(() => {
    if (!(isOpen && type && contentPage && entityId)) return;
    setDefaultValues({
      target: '',
    })
    setMoveOptions(getMoveOptions(entityId, type, contentPage));
  }, [type, contentPage, entityId, isOpen]);


  return (
    <>
      <Dialog
        additionalClassNames="c-content-builder-bulk-action-dialog"
        isOpen={isOpen}
        title={`Move ${String(ENTITY_NAME_MAP[type ?? ''])}`}
        onClose={handleResetState}
      >
        <ControlledForm
            callBack={(item: FormFieldProps) => {
              handleSave(item);
            }}
            handleSubmit={handleSubmit}
            isStopPropagation
          >
        <Spacing size="large">
          <Controller
            control={control as Control<FormFieldProps>}
            name="target"
            render={({ field, fieldState }) => (
              <Select
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_move_target"
                labelText="Move to"
                options={moveOptions}
                {...field}
                isRequired
              />
            )}
          />
        </Spacing>
        <DialogButtons 
              submitButtonText="Move"
              onCancel={handleResetState}
              onSaveButtonType="submit"
            />

        </ControlledForm>
      </Dialog>
    </>
  );
};

export { ContentBuilderMoveEntityDialog };
