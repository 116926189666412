import { createContext } from 'react';

import { IContentBuilderContext } from './ContentBuilderContext.interfaces';


export const ContentBuilderContext = createContext<IContentBuilderContext>({
  // Base
  sectionGroups: [],
  baseUrl: '',
  contentType: undefined,
  contentGroupUrl: undefined,
  contentGroupId: undefined,
  isFormBuilder: false,
  isFormsMode: false,
  isLoading: true,
  navigationItems: [],
  pageId: undefined,
  pageOptions: [],
  pageUrl: undefined,
  projectId: undefined,
  contentPage: undefined,
  exitEditMode: () => undefined,
  contentPages: [],
  
  // Empty state
  isEmptyPage: false,
  firstSectionGroupId: undefined,

  
  // Actions
  actionsSidebarDetails: {
    contentEventId: undefined,
    hasContentEvent: false,
    id: undefined,
    type: 'targetSnippet',
  },
  closeActionsSidebar: () => undefined,
  isActionsSidebarOpen: false,
  openActionsSidebar: () => undefined,

  // Bulk actions
  bulkActionDetails: undefined,
  closeBulkActionDialog: () => undefined,
  openBulkActionDialog: () => undefined,
  handleBulkAction: () => undefined,

  // Container
  closeContainerSidebar: () => undefined,
  handleUpdateContainer: () => undefined,
  isContainerSidebarOpen: false,
  containerSidebarDetails: undefined,

  // Page
  openPageSidebar: () => undefined,
  closePageSidebar: () => undefined,
  handleUpdatePage: () => undefined,
  handleCreatePage: () => undefined,
  handleDeletePage: () => undefined,
  isPageSidebarOpen: false,
  pageSidebarDetails: undefined,

  // Section group
  closeSectionGroupSidebar: () => undefined,
  handleUpdateSectionGroup: () => undefined,
  isSectionGroupSidebarOpen: false,
  sectionGroupSidebarDetails: undefined,

  // Snippet
  closeSnippetSidebar: () => undefined,
  isSnippetSidebarOpen: false,
  snippetSidebarDetails: undefined,
  updateSnippetSidebarDetails: () => undefined,

  // Section
  closeSectionSidebar: () => undefined,
  handleUpdateSection: () => undefined,
  isSectionSidebarOpen: false,
  sectionSidebarDetails: undefined,

  // Shared
  addItemToEntity: () => undefined,
  closeDeleteEntityDialog: () => undefined,
  handleCreateEntity: () => undefined,
  handleDeleteEntity: () => undefined,
  handleUpdateSnippet: () => undefined,
  openCreateEntitySidebar: () => undefined,
  openDeleteEntityDialog: () => undefined,
  openUpdateEntitySidebar: () => undefined,

  // Forms mode
  handleEnterFormsMode: () => undefined,
  handleLeaveFormsMode: () => undefined,

  // DnD
  onDrop: () => undefined,

  // css
  customStyleSheet: undefined,
  
  // Manual move
  closeMoveDialog: () => undefined,
  handleMoveEntity: () => undefined,
  isMoveDialogOpen: false,
  moveDialogDetails: undefined,
  openMoveDialog: () => undefined,

});

// eslint-disable-next-line import/no-default-export
export default ContentBuilderContext;
