import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, ExcelIcon } from '@netfront/ui-library';
import axios, { AxiosResponse } from 'axios';
import { ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { addMonths } from 'date-fns';
import { useGetPostsReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { POSTS_COLUMNS } from './PostsReportPage.constants';
import { getPostsTableData } from './PostsReportPage.helpers';
import { PostsCsvRowData, IPostsItem, IPostsTableData } from './PostsReportPage.interfaces';



const PostsReportPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [postsItems, setPostsItems] = useState<IPostsItem[]>();
  const [allPostsItems, setAllPostsItems] = useState<IPostsItem[]>([]);
  const [postsTableData, setPostsTableData] = useState<IPostsTableData[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>([addMonths(new Date(), -1), new Date()]);
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isGenerate, setIsGenerate] = useState<boolean>(false);


  const {
    handleGetPostsReport,
    isLoading: isGetPostsLoading = false,
  } = useGetPostsReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;

      if (isGenerate) {
        setIsGenerate(false);
        setCsvUrl(presignedUrl);
        axios
          .get(presignedUrl)
          .then((res: AxiosResponse) => {

            const { data } = Papa.parse<PostsCsvRowData>(String(res.data), {
              header: true,
              skipEmptyLines: true,
            });

            const formattedItems = data.map((row: PostsCsvRowData) => ({
              id: Number(row['Author id']),
              postId: String(row['Post id']),
              groupName: String(row['Group name']),
              userType: String(row['User type']),
              post: String(row['Post']),
              likes: String(row['Likes']),
              feeling: String(row['Feeling']),
              postDate: String(row['Post date']),
              postDateUtc: String(row['Post date (utc)']),
            }));
            
            setTotalCount(data.length);
            setAllPostsItems(formattedItems);
            const paginatedItems = formattedItems.slice(0, pageSize);
            setPostsItems(paginatedItems);
            setLastItemIndex(pageSize);

          })
          .catch((error) => {
            handleToastCustomError({
              message: 'whoops! something went wrong',
            });
            // eslint-disable-next-line no-console
            console.log({ error });
          });
      } else {
        window.open(presignedUrl, '_blank');
      }
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId: String(projectId),
  });

  const generateReport = () => {
    setIsGenerate(true);
    void handleGetPostsReport({
      dateFrom: dateRange[0] ? dateRange[0] : undefined,
      dateTo: dateRange[1] ? dateRange[1] : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId,
      fileType: 'CSV'
    });
  };

  const handleDownload = (type: "CSV" | "EXCEL") => {
    setIsGenerate(false);
    void handleGetPostsReport({
      dateFrom: dateRange[0] ? dateRange[0] : undefined,
      dateTo: dateRange[1] ? dateRange[1] : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId,
      fileType: type
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setPostsItems(allPostsItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setPostsItems(allPostsItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!postsItems) {
      return;
    }

    setPostsTableData(
      getPostsTableData({
        items: postsItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postsItems]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetPostsLoading;

  return (
    <ReportTable<IPostsTableData>
      activePath={['reporting', 'reporting-social', 'export-posts']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'Posts',
      }]}
      additionalDownloadOptions={
        csvUrl ? (
        <Button
          additionalClassNames="c-report-download-button"
          icon={ExcelIcon}
          size="xs"
          text="Download as excel"
          isIconOnly
          onClick={() => handleDownload('EXCEL')}
        />
      ): undefined}
      columns={POSTS_COLUMNS}
      csvUrl={csvUrl}
      data={postsTableData}
      dateRange={dateRange}
      description={`Posts reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports posts based on the selected filters'}
      isLoading={isLoading}
      isLoadMoreDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      totalCount={totalCount}
      onDateSelect={(dates: Date[]) => setDateRange(dates)}
      onGenerate={generateReport}
    />
  );
};

export { PostsReportPage };
