import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { removeItemFromLocalStorage, saveItemWithExpiryToLocalStorage } from '@netfront/common-library';
import {
  useGetSettings,
  ISetting,
  useAuthentication,
  IGeladaProject,
  getFormattedProjectSettings,
  IProjectSetting,
} from '@netfront/gelada-identity-library';
import { Spinner } from '@netfront/ui-library';
import { useToast } from 'hooks';
import { useRouter } from 'next/router';

import ProjectSettingsContext from './ProjectSettingsContext';
import { ProjectSettingsContextProps } from './ProjectSettingsContext.interfaces';

export function ProjectSettingsProvider({ children }: Readonly<ProjectSettingsContextProps>) {

  const { isAuthenticated } = useAuthentication();
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError } = useToast();

  const [projectId, setProjectId] = useState<string>('');

  
  const [project, setProject] = useState<IGeladaProject>();
  const [projectSettings, setProjectSettings] = useState<IProjectSetting>();
  const [projectSettingsExpiry, setProjectSettingsExpiry] = useState<number>();

  const { handleGetSettings, isLoading: isGetSettingsLoading = false } = useGetSettings({
    fetchPolicy: 'no-cache',
    onCompleted: ({ settings: returnedSettings }) => {
      saveItemWithExpiryToLocalStorage('projectSettings', JSON.stringify(returnedSettings), {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 1,
        },
      });

      const formattedSettings = getFormattedProjectSettings(returnedSettings as ISetting[]);

      setProjectSettings(formattedSettings as IProjectSetting);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const refreshProjectSettings = () => {
    handleGetSettings({
      projectId: String(projectId),
    });
  };


  useEffect(() => {
    if (!projectId) {
      return;
    }

    if (projectId !== project?.id) {
      removeItemFromLocalStorage('projectSettings');

      handleGetSettings({
        projectId: String(projectId),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);


  useEffect(() => {
    if (!projectId) return;
    const projectJSON = JSON.parse(String(window.localStorage.getItem('project')));

    if (!projectJSON) {
      return;
    }

    setProject(JSON.parse(String(projectJSON.value)) as IGeladaProject);
  }, [projectId]);



  useEffect(() => {
    if (!projectId) return;
    const projectSettingsJSON = JSON.parse(String(window.localStorage.getItem('projectSettings')));

    if (!projectSettingsJSON) {
      return;
    }
    const formattedSettings = getFormattedProjectSettings(JSON.parse(String(projectSettingsJSON.value)) as ISetting[])

    setProjectSettings(formattedSettings as IProjectSetting);
    setProjectSettingsExpiry(Number(projectSettingsJSON.expiry));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);


  useEffect(() => {
    if (!projectSettingsExpiry) {
      return;
    }

    if (new Date().getTime() < projectSettingsExpiry) {
      return;
    }

    removeItemFromLocalStorage('projectSettings');
  }, [projectSettingsExpiry]);



  useEffect(() => {
    if (!isAuthenticated) return;
    setProjectId(queryProjectId as string);
  }, [queryProjectId, isAuthenticated]);


  const isLoading = isGetSettingsLoading;

  return (
    <ProjectSettingsContext.Provider
      value={{
        isLoading,
        refreshProjectSettings,
        projectSettings
      }}
    >
      {isLoading ? <Spinner isLoading={true} /> : children}
    </ProjectSettingsContext.Provider>
  );
}
