import { gql } from '@apollo/client';

const UPDATE_SCHEDULE_DETAILS_MUTATION = gql`
  mutation UpdateScheduleDetails(
    $endDate: DateTime = null
    $maxRecurrence: Int!
    $scenarioId: Int!
    $startDate: DateTime = null
  ) {
    scenarioMutations {
      updateDetails(endDate: $endDate, id: $scenarioId, maxRecurrence: $maxRecurrence, startDate: $startDate) {
        carbonCopies
        createdDate
        delayBetweenNotification
        delayBetweenNotificationInDay
        delayBetweenNotificationInMonth
        delayBetweenNotificationInYear
        delayBetweenNotificationInWeek
        description
        endDate
        id
        isTestPhase
        maxRecurrence
        schedule {
          ... on ScheduleDailyGraphType {
            occurEvery
          }
          ... on ScheduleWeeklyGraphType {
            occurEvery
            recurrenceDays
          }
          ... on ScheduleMonthlyGraphType {
            day
            occurEvery
          }
          ... on ScheduleYearlyGraphType {
            day
            month
            occurEvery
          }
        }
        startDate
        status
        subject
        title
        updatedDate
        overrideReceiver
      }
    }
  }
`;

export { UPDATE_SCHEDULE_DETAILS_MUTATION };
