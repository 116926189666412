import { gql } from '@apollo/client';

const COMPILE_CSS_FILES = gql`
  mutation compileCssFiles($projectId: String!) {
    css {
      compile(projectId: $projectId)
    }
  }
`;

export { COMPILE_CSS_FILES };
