import React from 'react';

import { Spacing } from '@netfront/ui-library';
import { ConditionDelayInput } from 'components';
import { Controller } from 'react-hook-form';

import { ConditionFormUserRegisteredTypeViewProps } from './ConditionFormUserRegisteredTypeView.interfaces';

import { scenarioConditionTabConstants } from '../../../ScenarioConditionTab.constants';

const ConditionFormUserRegisteredTypeView = ({ control }: ConditionFormUserRegisteredTypeViewProps) => {

  const { conditionDelayMinNumber } = scenarioConditionTabConstants;

  return (
    <Spacing size="large">
      <Controller
        control={control}
        name="delay"
        render={({ field }) => (
          <ConditionDelayInput
            id="user_registered_type"
            initialValue={field.value}
            labelText="Condition delay"
            minNumber={conditionDelayMinNumber}
            name={field.name}
            tooltipText="The schedule will execute using the configuration below"
            onChange={({ value }) => field.onChange(value)}
          />
        )}
      />
    </Spacing>
      
  );
};

export { ConditionFormUserRegisteredTypeView };
