import { SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { ITestUsersTableData } from './TestModePage.interfaces';


const TEST_USERS_TABLE_COLUMNS: ReadonlyArray<Column<ITestUsersTableData>> = [
  {
    accessor: 'firstName',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>First name</div>,
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Last name</div>,
  },
  {
    accessor: 'email',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Email</div>,
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick } }) => <SettingsButton supportiveText="Update test user" onClick={() => onClick(id)} />,
  },
];

export { TEST_USERS_TABLE_COLUMNS };
