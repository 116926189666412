import { useCapuchinMutation } from 'hooks';

import { DELETE_CONDITION_GROUP_MUTATION } from './useDeleteConditionGroup.graphql';
import {
  IDeleteConditionGroupMutationGraphQLResponse,
  IDeleteConditionGroupMutationVariables,
  IHandleDeleteConditionGroupParams,
  IUseDeleteConditionGroupOptions,
  IUseDeleteConditionGroup,
} from './useDeleteConditionGroup.interfaces';

const useDeleteConditionGroup = (options?: IUseDeleteConditionGroupOptions): IUseDeleteConditionGroup => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteConditionGroupOptions);

  const [executeDeleteConditionGroup, { loading: isLoading }] = useCapuchinMutation<
    IDeleteConditionGroupMutationGraphQLResponse,
    IDeleteConditionGroupMutationVariables
  >({
    mutation: mutation ?? DELETE_CONDITION_GROUP_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          conditionGroups: { delete: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteConditionGroup = ({ conditionGroupId }: IHandleDeleteConditionGroupParams) => {
    void executeDeleteConditionGroup({
      variables: {
        conditionGroupId,
      },
    });
  };

  return {
    handleDeleteConditionGroup,
    isLoading,
  };
};

export { useDeleteConditionGroup };
