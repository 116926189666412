import { gql } from '@apollo/client';

const GET_ALL_SLIDERS = gql`
  query getAllSliders($projectId: String!) {
    slider {
      searchSliders(projectId: $projectId) {
        id
        title
      }
    }
  }
`;
export { GET_ALL_SLIDERS };
