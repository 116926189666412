import { useCapuchinMutation } from 'hooks';

import { UPDATE_SCHEDULE_MUTATION } from './useUpdateSchedule.graphql';
import {
  IUpdateScheduleMutationGraphQLResponse,
  IUpdateScheduleMutationVariables,
  IHandleUpdateScheduleParams,
  IUseUpdateScheduleOptions,
  IUseUpdateSchedule,
} from './useUpdateSchedule.interfaces';

const useUpdateSchedule = (options?: IUseUpdateScheduleOptions): IUseUpdateSchedule => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateScheduleOptions);

  const [executeUpdateSchedule, { loading: isLoading }] = useCapuchinMutation<
    IUpdateScheduleMutationGraphQLResponse,
    IUpdateScheduleMutationVariables
  >({
    mutation: mutation ?? UPDATE_SCHEDULE_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          schedule: { updateSchedule: scheduleConnection },
        } = data;

        onCompleted(scheduleConnection);
      },
      onError,
    },
    token,
  });

  const handleUpdateSchedule = ({ daily, weekly, monthly, scenarioId, yearly }: IHandleUpdateScheduleParams) => {
    void executeUpdateSchedule({
      variables: {
        daily,
        weekly,
        monthly,
        scenarioId,
        yearly,
      },
    });
  };

  return {
    handleUpdateSchedule,
    isLoading,
  };
};

export { useUpdateSchedule };
