import React, { useState, useEffect } from 'react';

import {
  Dialog,
  EyeShowIcon,
  InformationBox,
  ISearchList,
  Label,
  SelectWithSearch,
  Spacing,
  Spinner,
} from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { useGetTemplates, useGetTemplate, useToast } from 'hooks';
import { IDBTemplate } from 'interfaces';


import {
  IInvitationReminderSidebarCurrentTemplates,
  InvitationReminderTemplatesTabProps,
} from './InvitationRemindersTemplatesTab.interfaces';


const InvitationRemindersTemplatesTab = ({ projectId, initialTemplateId, setValue }: InvitationReminderTemplatesTabProps) => {
  const { handleToastError } = useToast();

  const [isTemplatePreviewDialogOpen, setIsTemplatePreviewDialogOpen] = useState<boolean>(false);
  const [scenarioTemplateContent, setScenarioTemplateContent] = useState<IDBTemplate['content']>();
  
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [searchSuggestions, setSearchSuggestions] = useState<ISearchList[]>([]);

  const [currentTemplate, setCurrentTemplate] = useState<IInvitationReminderSidebarCurrentTemplates | undefined>();

  const { handleGetTemplates, isLoading: isGetTemplatesLoading = false } = useGetTemplates(
    {
      onCompleted: ({ templates: returnedTemplates }) => {
        const suggestions = returnedTemplates.map((template) => {
          const { id, name } = template;

          return {
            id,
            label: name,
          };
        });

        setSearchSuggestions(suggestions);
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    },
  );


  const { handleGetTemplate: executeGetTemplate, isLoading: isGetTemplateLoading = false } = useGetTemplate({
    onCompleted: ({ templateConnection: template }) => {
      const { content } = template;

      setIsTemplatePreviewDialogOpen(true);
      setScenarioTemplateContent(content);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handleGetTemplate = (template: IInvitationReminderSidebarCurrentTemplates) => {
    executeGetTemplate({
      templateId: template.id,
    });
  };



  const handleSearchItemClick = (selectedId: string | number ) => {
    setValue('templateId', Number(selectedId));
    const template = searchSuggestions.find(({ id}) => id === selectedId);
    if (template) {
      setCurrentTemplate({
        id: Number(template.id),
        title: template.label,
      })
    }
  };

  useEffect(() => {
    if (!projectId) return;

    void handleGetTemplates({
      projectId,
    });
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (!initialTemplateId) return;
    if (searchSuggestions.length === 0) return;

    const template = searchSuggestions.find(({ id }) => id === initialTemplateId);

    if (template) {
      setCurrentTemplate({
        id: Number(template.id),
        title: template.label,
      })
    }

  }, [initialTemplateId, searchSuggestions]);


  const isLoading = isGetTemplatesLoading || isGetTemplateLoading;

  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />

      <Spacing size="2x-large">
        <SelectWithSearch
          buttonText="Copy existing template"
          countText="templates"
          id="id_scenario_templates"
          isDisplaySearchContent={isSearchContentVisible}
          isLabelHidden={false}
          labelText="Search"
          searchList={searchSuggestions}
          tooltipText="Search for existing templates within the project templates library"
          isAvatarVisible
          isLabelSideBySide
          onDisplaySearchContent={() => setSearchIsContentVisible(!isSearchContentVisible)}
          onSearchItemClick={handleSearchItemClick}
        />
      </Spacing>

      <Spacing size="2x-large">

        <section className="c-scenario-sidebar-templates-view__list-section">
          <Label forId="templates-list" labelText="Current template" />

          {currentTemplate ? (
            <div>
              <ul className="c-scenario-sidebar-templates-view__templates-list" id="templates-list">
                <li
                  className="c-scenario-sidebar-templates-view__templates-item c-scenario-sidebar-templates-view__templates-item--one"
                >
                  <div>
                    <button
                      className="c-scenario-sidebar-templates-view__preview-button"
                      title="Preview template"
                      type="button"
                      onClick={() => handleGetTemplate(currentTemplate)}
                    >
                      <EyeShowIcon />
                    </button>
                    <span className="c-scenario-sidebar-templates-view__templates__name">{currentTemplate.title}</span>

                    <Dialog
                      isOpen={isTemplatePreviewDialogOpen}
                      title={`Preview template: ${String(currentTemplate.title)}`}
                      onCancel={() => setIsTemplatePreviewDialogOpen(false)}
                      onClose={() => setIsTemplatePreviewDialogOpen(false)}
                    >
                      {scenarioTemplateContent ? (
                        <div dangerouslySetInnerHTML={{ __html: scenarioTemplateContent }} />
                      ) : (
                        'No content available for the template'
                      )}
                    </Dialog>
                  </div>
                </li>
              </ul>
            </div>
          ) : (
            <InformationBox message="No templates attached for the scenario" />
          )}
        </section>
      </Spacing>
    </SidebarContainer>
  );
};

export { InvitationRemindersTemplatesTab };
