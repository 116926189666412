import { IContentPage, IContentSection, IContentSectionContainer, ISectionGroup } from "@netfront/ekardo-content-library";
import { IOption } from "@netfront/ui-library";
import { getEntityByTypeAndId, getParentByIdAndType, IMoveEntity } from "context";
import { IEntityType } from "interfaces";

const childItemKeyMap: { [key in Exclude<IEntityType, 'page'>]: keyof IContentPage | keyof IContentSection | keyof IContentSectionContainer | keyof ISectionGroup } = {
  sectionGroup: 'sectionGroups',
  section: 'contentSections',
  container: 'sectionContainers',
  targetSnippet: 'snippets',
};

const getParentChildEntityDetails = (entityId: number, type: IEntityType, parent: IContentPage | IContentSection | IContentSectionContainer | ISectionGroup | undefined) => {
  if (!parent) {
    return {
      parentChildEntities: [],
      isFirstChild: false,
      isLastChild: false,
    };
  }
  const key = childItemKeyMap[type as Exclude<IEntityType, 'page'>];
  const parentChildEntities = parent[key as keyof typeof parent] as Array<{ id: number; sort: number }>;
  const sortedEntities = [...parentChildEntities].sort((a, b) => a.sort - b.sort);
  const isFirstChild = sortedEntities[0]?.id === entityId;
  const isLastChild = sortedEntities[sortedEntities.length - 1]?.id === entityId;

  return {
    parentChildEntities: sortedEntities,
    isFirstChild,
    isLastChild,
  };
}

const getMoveIntoOptions = ({ contentPage, type }: { contentPage: IContentPage, type: IEntityType }): IOption[] => {
  const { sectionGroups } = contentPage;
  if (type === 'sectionGroup') return [];

  if (type === 'section') {
    return sectionGroups.map(({ id, title }, index) => ({
      name: `Move to ${title ?? `Section group ${index + 1}`}`,
      value: `sectionGroup-${id}`,
      id: `sectionGroup-${id}`,
    }));
  }

  if (type === 'container') {
    return contentPage.sectionGroups.flatMap((sectionGroup) =>
      sectionGroup.contentSections.map((section, sectionIndex) => ({
        name: `Move to ${section.title ?? `Section ${sectionIndex + 1}`}`,
        value: `section-${section.id}`,
        id: `section-${section.id}`,
      }))
    );
  }

  if (type === 'targetSnippet') {
    return contentPage.sectionGroups.flatMap((sectionGroup) =>
      sectionGroup.contentSections.flatMap((section) =>
        section.sectionContainers.map((container, containerIndex) => ({
          name: `Move to ${container.title ?? `Container ${containerIndex + 1}`}`,
          value: `container-${container.id}`,
          id: `container-${container.id}`,
        }))
      )
    );
  }

  return [];
  
}

const getMoveOptions = (entityId: number, type: IEntityType, contentPage: IContentPage): IOption[] => {



  const parent = getParentByIdAndType({ id: entityId, type, contentPage });
  const entity = getEntityByTypeAndId({ contentPage, id: entityId, type });

  if (!parent || !entity) return [];

  if (!(type in childItemKeyMap)) return [];

  const { isFirstChild, isLastChild } = getParentChildEntityDetails(entityId, type, parent);

  const defaultOptions = [
    {
      name: 'Move up',
      value: 'move-up',
      id: 'move-up',
      isDisabled: isFirstChild,
    },
    {
      name: 'Move down',
      value: 'move-down',
      id: 'move-down',
      isDisabled: isLastChild,
    },
    {
      name: 'Move to top',
      value: 'move-to-top',
      id: 'move-to-top',
      isDisabled: isFirstChild,
    },
    {
      name: 'Move to bottom',
      value: 'move-to-bottom',
      id: 'move-to-bottom',
      isDisabled: isLastChild,
    },
  ];

  return [
    ...defaultOptions,
    ...getMoveIntoOptions({ contentPage, type }),
  ]

};

const formatMove = (value: string, entityId: number, type: IEntityType, contentPage: IContentPage): IMoveEntity => {

  const targetParentMap = {
    'page': 'root',
    'sectionGroup': 'root',
    'section': 'sectionGroup',
    'container': 'section',
    'targetSnippet': 'container',
  };

  let target: IMoveEntity['target'] = {} as IMoveEntity['target'];
  let updatedIndex = 0;

  if (value.includes('move')) {
    const entity = getEntityByTypeAndId({ contentPage, id: entityId, type });
    const parent = getParentByIdAndType({ id: entityId, type, contentPage });
    const { parentChildEntities = [] } = getParentChildEntityDetails(entityId, type, parent);
    
    target = {
      id: Number(parent?.id),
      type: targetParentMap[type],
    };

    const { sort = 0 } = entity ?? {};

    if (value === 'move-to-top') {
      updatedIndex = 0;
    }

    if (value === 'move-to-bottom') {
      updatedIndex = parentChildEntities.length - 1;
    }

    if (value === 'move-up') {
      updatedIndex = Number(sort - 1);
    }

    if (value === 'move-down') {

      updatedIndex = Number(sort + 1);
    }

  } else {
    const [entityType, id] = value.split('-');
    
    target = {
      id: Number(id),
      type: entityType,
    };
  }
  


  

  return {
    currentItem: {
      id: entityId,
      type,
    },
    target,
    updatedIndex,
  }
};



export { getMoveOptions, formatMove };