
import { useCapuchinMutation } from 'hooks';

import { UPDATE_CONDITION_MUTATION } from './useUpdateScenarioCondition.graphql';
import {
  IUpdateScenarioConditionMutationGraphQLResponse,
  IUpdateScenarioConditionMutationVariables,
  IHandleUpdateScenarioConditionParams,
  IUseUpdateScenarioConditionOptions,
  IUseUpdateScenarioCondition,
} from './useUpdateScenarioCondition.interfaces';

const useUpdateScenarioCondition = (options?: IUseUpdateScenarioConditionOptions): IUseUpdateScenarioCondition => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateScenarioConditionOptions);

  const [executeUpdateScenarioCondition, { loading: isLoading }] = useCapuchinMutation<
  IUpdateScenarioConditionMutationGraphQLResponse,
  IUpdateScenarioConditionMutationVariables
  >({
    mutation: mutation ?? UPDATE_CONDITION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          conditions: { update: condition },
        } = data;

        onCompleted(condition);
      },
      onError,
    },
    token,
  });

  const handleUpdateScenarioCondition = ({
    conditionId,
    day,
    hour,
    stepStarted,
    stepCompleted,
    stepProgression,
    userActivated,
    userLogin,
    userRegistered,
    userLastLogin,
    userSession,
    manyUserGroup,
  }: IHandleUpdateScenarioConditionParams) => {
    void executeUpdateScenarioCondition({
      variables: {
        conditionId,
        day,
        hour,
        stepStarted,
        stepCompleted,
        stepProgression,
        userActivated,
        userLogin,
        userRegistered,
        userLastLogin,
        userSession,
        manyUserGroup,
      },
    });
  };

  return {
    handleUpdateScenarioCondition,
    isLoading,
  };
};

export { useUpdateScenarioCondition };
