import { useEffect, useRef, useState } from 'react';


import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ControlledForm, Dialog, FormFieldProps, Input, SidebarButtons,  Spacing,  Spinner, Textarea, useControlledForm } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import {  
  useToast, 
  useGetAsset,
  useDeleteAsset,
  useCreateAsset,
  useUpdateAsset, 
} from 'hooks';
import { Control, Controller } from 'react-hook-form';
import { convertToFile, handleGetFileText, pushImageToAws } from 'utils';
import * as yup from 'yup';


import { getCssFileDefaultValues } from './CustomCssGeneralTab.helpers';
import { CustomCssGeneralTabProps } from './CustomCssGeneralTab.interfaces';


const CustomCssGeneralTab = ({
  selectedCssFileId,
  onCancel,
  onUpdate,
  projectId,
}: CustomCssGeneralTabProps) => {

  const { handleToastError, handleToastSuccess } = useToast();
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const cssFileRef = useRef<{value: File | undefined}>({ value: undefined });

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const [isAssetUploadToAwsLoading, setIsAssetUploadToAwsLoading] = useState<boolean>(false);

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        content: yup.string().label('Html').required(),
      }),
    ),
  });


  const handleError = (error: ApolloError) => {
    handleToastError({
      error,
    });
  };

  const { handleGetAsset, isLoading: isGetAssetLoading = false } = useGetAsset({
    onCompletedAsync: async ({ asset }) => {
      const { presignedUrl } = asset
      const cssText = await handleGetFileText(presignedUrl);
      setDefaultValues(getCssFileDefaultValues({ asset, cssText }));
    },
    onError: handleError,
  });

  const { handleDeleteAsset, isLoading: isDeleteAssetLoading = false } = useDeleteAsset({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);
      reset();

      handleToastSuccess({ message: `css file deleted successfully`});

      onUpdate();
    },
    onError: handleError,
  });

  const { handleCreateAsset, isLoading: isCreateAssetLoading = false } = useCreateAsset({
    onCompletedAsync: async (data) => {

      const { signedUrl } = data;

      setIsAssetUploadToAwsLoading(true);

      await pushImageToAws(signedUrl, cssFileRef.current.value, () => {
          handleToastSuccess({ message: `Css file created successfully`});
          onUpdate();
          reset();
          setIsAssetUploadToAwsLoading(false);
      });
    },
    onError: handleError,
  });

  const { handleUpdateAsset, isLoading: isUpdateAssetLoading = false } = useUpdateAsset({
    onCompletedAsync: async (data) => {
      onUpdate();
      reset();
      const { signedUrl } = data;

      await pushImageToAws(String(signedUrl), cssFileRef.current.value, () => {
        handleToastSuccess({ message: `Css file updated successfully`});
        onUpdate();
        reset();
        setIsAssetUploadToAwsLoading(false);
    });

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleOnCancel = () => {
    reset();
    onCancel();
  };

  const handleSave = (item: FormFieldProps) => {
    const { content, title = '', description } = item;

    const contentFile = convertToFile(String(content), 'css', String(title));

    cssFileRef.current.value = contentFile;

    const { type, name, size } = contentFile;

    if (!selectedCssFileId) {
      handleCreateAsset({
        projectId,
        fileName: name,
        alt: 'css file',
        contentType: type,
        description,
        type: 'CSS',
        tagList: [],
        fileSizeInBytes: size,
        title,
      });
    } else {
      handleUpdateAsset({
        assetId: String(selectedCssFileId),
        alt: 'css file',
        contentType: type,
        description,
        fileName: name,
        fileSizeInBytes: size,
        title,
        tagIds: []
      });
    }
  };

  useEffect(() => {
    if (selectedCssFileId) {
      handleGetAsset({assetId: selectedCssFileId});
    } else {
      setDefaultValues(getCssFileDefaultValues({}));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCssFileId]);

  const isLoading = isDeleteAssetLoading || isCreateAssetLoading || isUpdateAssetLoading || isGetAssetLoading || isAssetUploadToAwsLoading;

  return (
    <>
      <Spinner isLoading={isLoading} spinnerIconSize={'small'} />
      <Dialog
        isOpen={isDeleteDialogOpen}
        title="Delete css file?"
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!selectedCssFileId) return;
          handleDeleteAsset({
            assetId: selectedCssFileId,
          });
        }}
      />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
      >
        <SidebarContainer>
          <Spacing>
            <Controller
              control={control as Control<FormFieldProps>}
              name="title"
              render={({ field, fieldState }) => (
                <Input
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_css_file_title"
                  labelText="Title"
                  type="text"
                  isLabelSideBySide
                  isRequired
                  {...field}
                />
              )}
            />
          </Spacing>
          <Spacing>
            <Controller
              control={control as Control<FormFieldProps>}
              name="description"
              render={({ field }) => (
                <Textarea
                  id="id_css_file_description"
                  labelText="Description"
                  isLabelSideBySide
                  {...field}
                />
              )}
            />
          </Spacing>
          <Spacing>
            <Controller
              control={control as Control<FormFieldProps>}
              name="content"
              render={({ field, fieldState }) => (
                <Textarea
                  additionalClassNames="c-css-content__textarea"
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id="id_css_file_content"
                  labelText="CSS"
                  isLabelSideBySide
                  isRequired
                  {...field}
                />
              )}
            />
          </Spacing>
          <SidebarButtons
            onCancel={handleOnCancel}
            onDelete={selectedCssFileId ? () => setIsDeleteDialogOpen(true): undefined}
            onSaveButtonType="submit"
          />
        </SidebarContainer>
        
      </ControlledForm>
    </>
  );
};

export { CustomCssGeneralTab };
