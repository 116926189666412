import React from 'react';

import { Spacing } from '@netfront/ui-library';
import { ConditionDelayInput } from 'components';
import { Controller } from 'react-hook-form';

import { ConditionFormUserLastLoginTypeViewProps } from './ConditionFormUserLastLoginTypeView.interfaces';

import { scenarioConditionTabConstants } from '../../../ScenarioConditionTab.constants';

const ConditionFormUserLastLoginTypeView = ({ control }: ConditionFormUserLastLoginTypeViewProps) => {
  const { conditionDelayMinNumber } = scenarioConditionTabConstants;

  return (
    <Spacing size="large">
      <Controller
        control={control}
        name="delay"
        render={({ field }) => (
          <ConditionDelayInput
            id="user_last_login_type"
            initialValue={field.value}
            labelText="Condition delay"
            minNumber={conditionDelayMinNumber}
            name={field.name}
            tooltipText="The schedule will execute using the configuration below"
            onChange={({ value }) => field.onChange(value)}
          />
        )}
      />
    </Spacing>
  );
};

export { ConditionFormUserLastLoginTypeView };
