import React, { useState, useEffect } from 'react';

import {
  Alert,
  ISearchList,
  Preloader,
  SelectWithSearch,
  Spacing,
  ToggleSwitch,
  WarningIcon,
} from '@netfront/ui-library';
import { useGetTemplates, useToast } from 'hooks';
import { Controller } from 'react-hook-form';

import { SidebarContainer } from 'components/SidebarContainer';

import { ScenarioTemplatesOverview } from './Components';
import { scenarioTemplatesTabConstants } from './ScenarioTemplatesTab.constants';
import {
  handleSetTemplatePercentage,
  handleUpdateTemplatesList,
} from './ScenarioTemplatesTab.helpers';
import {
  IScenarioSearchTemplate,
  IScenarioTemplate,
  ScenarioTemplatesTabProps,
} from './ScenarioTemplatesTab.interfaces';

const ScenarioTemplatesTab = ({ 
  control, 
  getValues, 
  projectId, 
  setValue,
  watch,
  initialTemplates = [],
}: ScenarioTemplatesTabProps) => {
  const { handleToastError } = useToast();

  const {
    abToggleAlertVisibilityInSeconds,
  } = scenarioTemplatesTabConstants;

  const [isAbToggleAlertVisible, setIsAbToggleAlertVisible] = useState<boolean>(false);


  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [searchSuggestions, setSearchSuggestions] = useState<ISearchList[]>([]);

  const { handleGetTemplates, isLoading: isGetTemplatesLoading } = useGetTemplates(
    {
      onCompleted: ({ templates: returnedTemplates }) => {
        const suggestions = returnedTemplates.map((template) => {
          const { id, name } = template;

          return {
            id,
            label: name,
          };
        });

        setSearchSuggestions(suggestions);
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    },
  );

  
  const handleUpdateCount = (templateId: number, type: 'increase' | 'decrease' | 'event' = 'increase', value: number) => {
    const { templates: currentTemplates = [] } = getValues();

    const updatedTemplates = handleSetTemplatePercentage(currentTemplates as IScenarioTemplate[], Number(templateId), value, type);

    setValue('templates', updatedTemplates);
  };


  const handleSearchItemClick = (selectedId: string | number) => {
    const { templates: currentTemplates = [] } = getValues(); 

    const selectedTemplateToAdd = searchSuggestions.find(({ id }) => id === selectedId);

    const hasExistingTemplate = (currentTemplates as IScenarioSearchTemplate[]).some(({ id }) => id === selectedId);

    if (selectedTemplateToAdd && !hasExistingTemplate) {
      const updatedTemplates: IScenarioSearchTemplate[] = [
        ...(currentTemplates as IScenarioSearchTemplate[]), 
        {
          id: selectedTemplateToAdd.id,
          title: selectedTemplateToAdd.label,
        } as IScenarioSearchTemplate
      ];

      const updatedTemplatesList = handleUpdateTemplatesList(updatedTemplates);
  
      setValue('templates', updatedTemplatesList);
      setValue('isAbTesting', updatedTemplatesList.length > 1);
    }
  };

  const handleRemoveTemplate = (id: number) => {
    const { templates: currentTemplates = [] } = getValues();

    const updatedTemplates = (currentTemplates as IScenarioTemplate[]).filter(({ id: templateId }) => templateId !== id);

    const updatedTemplatesList = handleUpdateTemplatesList(updatedTemplates);

    setValue('templates', updatedTemplatesList);
    setValue('isAbTesting', updatedTemplatesList.length > 1);
  };

  useEffect(() => {
    if (!projectId) return;

    void handleGetTemplates({
      projectId,
    });
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);


  useEffect(() => {
    if (!isAbToggleAlertVisible) {
      return;
    }

    setTimeout(() => {
      setIsAbToggleAlertVisible(false);
    }, abToggleAlertVisibilityInSeconds * 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAbToggleAlertVisible]);


  const isLoading = isGetTemplatesLoading;

  return (
    <SidebarContainer>
      <Spacing size="2x-large">
        <Controller
          control={control}
          name="isAbTesting"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
              id="id_scenario_ab_testing"
              isChecked={field.value}
              labelText="A/B"
              name={field.name}
              tooltipText="Send variations of templates within this scenario"
              isInline
              isLabelSideBySide
              onChange={({ target: { checked: isChecked }}) => {

                const { templates: currentTemplates = []} = getValues();
                if (currentTemplates.length > 1 && !isChecked) {
                  setIsAbToggleAlertVisible(true);
                  field.onChange(true);
                } else {
                  field.onChange(isChecked);
                }
                
              }}
            />
          )}
        />
      </Spacing>

      <Spacing size="2x-large">
        <SelectWithSearch
          buttonText="Copy existing template"
          countText="templates"
          id="id_scenario_templates"
          isDisplaySearchContent={isSearchContentVisible}
          isLabelHidden={false}
          labelText="Search"
          searchList={searchSuggestions}
          tooltipText="Search for existing templates within the project templates library"
          isAvatarVisible
          isLabelSideBySide
          onDisplaySearchContent={() => setSearchIsContentVisible(!isSearchContentVisible)}
          onSearchItemClick={handleSearchItemClick}
        />
      </Spacing>

      

      <Spacing size="2x-large">
        {isLoading ? (
          <Preloader isLoading={isLoading} />
        ) : (
          <ScenarioTemplatesOverview 
            templates={initialTemplates} 
            watch={watch} 
            onRemoveTemplate={handleRemoveTemplate}
            onUpdateCount={handleUpdateCount}
          />
        )}
      </Spacing>
      {isAbToggleAlertVisible && (
        <Alert
          icon={WarningIcon}
          message="Please remove multiple templates before switching A/B testing off"
          timeAlertIsVisibleInSeconds={abToggleAlertVisibilityInSeconds}
        />
      )}
    </SidebarContainer>
  );
};

export { ScenarioTemplatesTab };
