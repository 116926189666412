import React, { useEffect, useState } from 'react';

import { useGetPaginatedGroups } from '@netfront/gelada-identity-library';
import { InputFieldWrapper, ISearchList, MultiSelect, Spacing, Spinner } from '@netfront/ui-library';
import { useToast } from 'hooks';
import { Controller } from 'react-hook-form';

import { ConditionFormManyUserGroupTypeViewProps } from './ConditionFormManyUserGroupTypeView.interfaces';


const ConditionFormManyUserGroupTypeView = ({ control, projectId, setValue }: ConditionFormManyUserGroupTypeViewProps) => {
  const [searchSuggestions, setSearchSuggestions] = useState<ISearchList[]>([]);
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);

  const { handleToastError } = useToast();
  const { handleGetPaginatedGroups, isLoading = false } = useGetPaginatedGroups({
    onCompleted: ({ groupConnection: { edges } }) => {

      const groups = edges.map(({ node }) => node);

      setSearchSuggestions(groups.map(
        ({ id, name }) => ({
          id,
          label: name,
        }),
      ))

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleSearchItemClick = (selectedValues: string[]) => {
    setValue('groupIds', selectedValues);
  };


  useEffect(() => {
    if (!projectId) return;

    void handleGetPaginatedGroups({
      projectId: String(projectId),
      status: 'ACTIVE'
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <>
      <Spinner isLoading={isLoading} />
      <Spacing size="large">
        <Controller
          control={control}
          name="groupIds"
          render={({ field }) => (
            <InputFieldWrapper 
              id="id_condition_group_select" 
              label="Groups" 
              labelType="span"
              isLabelSideBySide
            >
              <MultiSelect
                buttonText="Select groups"
                countText="groups"
                defaultValues={field.value}
                isDisplaySearchContent={isSearchContentVisible}
                searchList={searchSuggestions}
                onDisplaySearchContent={() => setSearchIsContentVisible(!isSearchContentVisible)}
                onSearchItemClick={handleSearchItemClick}
              />
            </InputFieldWrapper>
          )}
        />
      </Spacing>
    </>
  );
};

export { ConditionFormManyUserGroupTypeView };
