import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useCookie, useGeladaAccessTokenDecoder } from '@netfront/common-library';

import { PERMISSIONS, PERMISSIONS_MAPPING } from './useGetUserPermissions.constants';
import { IPermission, PermissionType } from './useGetUserPermissions.interfaces';

export const useGetUserPermissions = (projectId: string ): {
  hasCollaboratePermission: boolean;
  hasEditPermission: boolean;
  hasLimitedPermission: boolean;
  hasManageUsersPermission: boolean;
  hasNonePermission: boolean;
  hasReadPermission: boolean;
} => {


  const { getDecodedJwt, getJwtClaim } = useGeladaAccessTokenDecoder();
  const { getAccessTokenCookie } = useCookie();

  const [hasCollaboratePermission, setHasCollaboratePermission] = useState<boolean>(false);
  const [hasEditPermission, setHasEditPermission] = useState<boolean>(false);
  const [hasLimitedPermission, setHasLimitedPermission] = useState<boolean>(false);
  const [hasManageUsersPermission, setHasManageUsersPermission] = useState<boolean>(false);
  const [hasNonePermission, setHasNonePermission] = useState<boolean>(false);
  const [hasReadPermission, setHasReadPermission] = useState<boolean>(false);

  const permissionMap: { [key: string]: Dispatch<SetStateAction<boolean>>} = {
    LIMITED: setHasLimitedPermission,
    NONE: setHasNonePermission,
    MANAGE_USERS: setHasManageUsersPermission,
    EDIT: setHasEditPermission,
    READ: setHasReadPermission,
    COLLABORATE: setHasCollaboratePermission,
  };

  const getDecodedPermissions = (): IPermission[] | undefined => {
    const token = getAccessTokenCookie();
    if (!token) return undefined;

    const decoded = getDecodedJwt(token);
    const permissions = getJwtClaim(decoded, 'permissions');
    if (!permissions) return undefined;

    return JSON.parse(String(permissions)) as IPermission[];
  };

  const loopThroughPermissions = (
    id: string,
    permissions: IPermission[],
    permission?: number,
  ): PermissionType | undefined => {
    for (let i = 0; i != permissions.length; i++) {
      const right = lookThroughEntityOrSubEntity(id, permissions[i], permission);
      if (right !== undefined) return right;
    }
    return undefined;
  };

  const lookThroughEntityOrSubEntity = (
    targetId: string,
    entity: IPermission,
    inheritedPermission?: number,
  ): PermissionType | undefined => {
    const { id, p: permission, _c: subEntities } = entity;
    if (targetId === id) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return inheritedPermission ? PERMISSIONS[inheritedPermission] : PERMISSIONS[permission];
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (subEntities) return loopThroughPermissions(targetId, subEntities, inheritedPermission ?? permission);

    return undefined;
  };

  useEffect(() => {
    const permissions = getDecodedPermissions();
    if (!permissions) return;

    for (let i = 0; i != permissions.length; i++) {
      const right = lookThroughEntityOrSubEntity(projectId, permissions[i]);

      Object.entries(permissionMap).forEach(([key, setStateFunction]) => {
        const formattedKey = key as PermissionType;
        if (right !== undefined) {
          setStateFunction(right === formattedKey || (PERMISSIONS_MAPPING[right] as string[]).includes(formattedKey));
        }
      });
    }

   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return {
    hasCollaboratePermission,
    hasEditPermission,
    hasLimitedPermission,
    hasManageUsersPermission,
    hasNonePermission,
    hasReadPermission,
  };
}
