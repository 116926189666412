import { useCapuchinLazyQuery } from 'hooks';

import { GET_SCENARIO_QUERY } from './useGetScenario.graphql';
import {
  IGetScenarioQueryGraphQLResponse,
  IGetScenarioQueryVariables,
  IHandleGetScenarioParams,
  IUseGetScenario,
  IUseGetScenarioOptions,
} from './useGetScenario.interfaces';

const useGetScenario = (options?: IUseGetScenarioOptions): IUseGetScenario => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetScenarioOptions);

  const [executeGetScenario, { loading: isLoading }] = useCapuchinLazyQuery<IGetScenarioQueryGraphQLResponse, IGetScenarioQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          scenarioQueries: { get: scenario },
        } = data;

        onCompleted({
          scenario,
        });
      },
      onError,
    },
    query: query ?? GET_SCENARIO_QUERY,
    token,
  });

  const handleGetScenario = ({ scenarioId }: IHandleGetScenarioParams) => {
    void executeGetScenario({
      variables: {
        scenarioId,
      },
    });
  };

  return {
    handleGetScenario,
    isLoading,
  };
};

export { useGetScenario };
