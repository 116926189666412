import { useContext, useEffect, useState } from 'react';

import { InvitationRemindersSidebarView, TablePageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetInvitationReminders, useToast } from 'hooks';
import {  IDBInvitationReminder } from 'interfaces';
import last from 'lodash.last';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';

import { INVITATION_SCENARIOS_TABLE_COLUMNS } from './InvitationsScenariosPage.constants';
import { getInvitationRemindersTableData } from './InvitationsScenariosPage.helpers';
import { IInvitationRemindersTableData } from './InvitationsScenariosPage.interfaces';


const InvitationsScenariosPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError } = useToast();
  const defaultPageSize = 10;

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [allReminders, setAllReminders] = useState<IDBInvitationReminder[]>();
  const [filter, setFilter] = useState<string>();
  const [selectedReminderId, setSelectedReminderId] = useState<number>();
  const [remindersTableData, setRemindersTableData] = useState<IInvitationRemindersTableData[]>([]);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [totalReminders, setTotalReminders] = useState<number>(0);

  const {
    handleFetchMorePaginatedInvitationReminders,
    handleGetPaginatedInvitationReminders,
    isLoading: isGetPaginatedRemindersLoading = false,
  } = useGetInvitationReminders({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ invitationRemindersConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const invitationReminder = edges.map(({ node }) => node);

      setAllReminders(invitationReminder);
      setIsLoadMoreDisabled(invitationReminder.length >= totalCount || totalCount <= pageSize);
      setTotalReminders(totalCount);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleFilterSearch = (value: string) => {
    handleGetPaginatedInvitationReminders({
      first: pageSize,
      projectId: String(projectId),
      filter: value,
    });

    setFilter(value);
  };

  const handleCloseSidebar = () => {
    setIsSideBarOpen(false);
    setSelectedReminderId(undefined);
  };

  const handleUpdate = () => {
    handleCloseSidebar();

    handleGetPaginatedInvitationReminders({
      first: pageSize,
      projectId: String(projectId),
      filter,
    });
  };

  const handleOnPageSizeChange = (changedPageSize: number) => {
    setPageSize(Number(changedPageSize));
  };

  const handleSelectScenario = (id?: number) => {
    setIsSideBarOpen(true);
    setSelectedReminderId(id);
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    handleGetPaginatedInvitationReminders({
      first: pageSize,
      projectId: String(projectId),
      filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId]);

  useEffect(() => {
    if (!allReminders) {
      return;
    }

    setRemindersTableData(
      getInvitationRemindersTableData({
        invitationReminders: allReminders,
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedReminderId(id);
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allReminders]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  return (
    <>
      <TablePageTemplate<IInvitationRemindersTableData>
        activePath={['notifications', 'scheduler', 'scenarios', 'scenario-invitations']}
        additionalBreadcrumbItems={[{
          key: '1',
          content: 'Notifications',
        }, {
          key: '2',
          content: 'Scheduler',
        }, {
          key: '3',
          content: 'Scenarios',
        }, {
          key: '4',
          content: 'Invitation reminders',
        }]}
        columns={INVITATION_SCENARIOS_TABLE_COLUMNS}
        data={remindersTableData}
        defaultActiveTabId="id_general_tab"
        description={`Invitation reminders for ${String(projectName)}`}
        handleAddNewClick={() => handleSelectScenario(undefined)}
        handleOnPageSizeChange={handleOnPageSizeChange}
        handleOnPaginate={async () => {
          await handleFetchMorePaginatedInvitationReminders({
            after: lastCursor,
            first: pageSize,
            projectId: String(projectId),
            filter,
          });
        }}
        handleSearch={handleFilterSearch}
        informationBoxMessage={`Manage ${String(projectName)} schedule notfications information: ${totalReminders} ${pluralize('invitation reminder', totalReminders)}`}
        isLoading={isGetPaginatedRemindersLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        logoUrl={project?.logo?.presignedUrl}
        pageSize={pageSize}
        pageTitle={String(projectName)}
        searchPlaceholder="Search"
        size={project?.logo?.presignedUrl ? 'small': 'medium'}
        tableType="invitation reminders"
        title={`${String(projectName)} Invitation reminder scenarios`}
        totalItems={totalReminders}
      />
      {
        projectId && (
          <InvitationRemindersSidebarView
            handleSideBarClose={handleCloseSidebar}
            isSideBarOpen={isSideBarOpen}
            projectId={String(projectId)}
            selectedReminderId={selectedReminderId}
            onUpdate={handleUpdate}
          />
        )
      }
    </>

    
  );
};

export { InvitationsScenariosPage };
