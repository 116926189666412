import { gql } from '@apollo/client';
import { AppFragment, AudioFragment, ButtonFragment, CodeFragment, DocumentFragment, ContentSnippetSliderFragment, EmbedFragment, FormFragment, ImageFragment, MapFragment, QuestionFragment, QuoteFragment, RegionsFragment, SliderFragment, SpacerFragment, SummaryFragment, TextFragment, VideoFragment } from 'fragments';

const UPDATE_CONTENT_SNIPPET = gql`
  mutation updateSnippet(
    $baseSnippet: UpdateContentSnippetInputType!,
    $buttonSnippet: ContentSnippetButtonInputType,
    $textSnippet: ContentSnippetTextInputType,
    $audioSnippet: ContentSnippetAudioInputType
    $videoSnippet: ContentSnippetVideoInputType
    $codeSnippet: ContentSnippetCodeInputType
    $documentSnippet: ContentSnippetDocumentInputType
    $formSnippet: ContentSnippetFormInputType
    $imageSnippet: ContentSnippetImageInputType
    $summarySnippet: ContentSnippetSummaryInputType
    $embedSnippet: ContentSnippetEmbedInputType
    $questionSnippet: ContentSnippetQuestionInputType
    $quoteSnippet: ContentSnippetQuoteInputType,
    $mapSnippet: ContentSnippetMapInputType,
    $shouldCreateEvent: Boolean!,
    $createContentEvent: CreateContentEventInput,
    $shouldUpdateEvent: Boolean!,
    $updateContentEvent: UpdateContentEventInput,
    $shouldDetachCustomTheme: Boolean!,
    $shouldAttachCustomTheme: Boolean!,
    $snippetId: Int!,
    $styleId: Int!,
    $sliderSnippet: ContentSnippetSliderInputType,
  ) {

    contentEvent {
      createEvent(contentEvent: $createContentEvent)
        @include(if: $shouldCreateEvent) {
        id
      }
      updateEvent(contentEvent: $updateContentEvent)
        @include(if: $shouldUpdateEvent) {
        id
      }
    }

    cssStyle {
      detach(snippetId: $snippetId)
        @include(if: $shouldDetachCustomTheme)

      attachStyleToContentSnippet(contentSnippetId: $snippetId, styleId: $styleId)
        @include(if: $shouldAttachCustomTheme)
    }

    contentSnippet {
      updateContentSnippet(
        baseSnippet: $baseSnippet,
        contentSnippetText: $textSnippet,
        contentSnippetAudio: $audioSnippet,
        contentSnippetButton: $buttonSnippet,
        contentSnippetVideo: $videoSnippet,
        contentSnippetSummary: $summarySnippet,
        contentSnippetCode: $codeSnippet,
        contentSnippetDocument: $documentSnippet,
        contentSnippetForm: $formSnippet,
        contentSnippetImage: $imageSnippet,
        contentSnippetQuestion: $questionSnippet,
        contentSnippetEmbed: $embedSnippet,
        contentSnippetQuote: $quoteSnippet,
        contentSnippetMap: $mapSnippet,
        contentSnippetSlider: $sliderSnippet,
      ) {
        id
        sort
        visible
        cssStyle {
          id
          name
        }
        ... on ContentSnippetAvatarType {
          avatar {
            configuration {
              key
              value
            }
            imageUrl
          }
        }
        borderColor
        backgroundColor
        type: __typename
        title
        __typename
        ...app
        ...audio
        ...button
        ...code
        ...document
        ...embed
        ...form
        ...image
        ...map
        ...question
        ...quote
        ...regions
        ...slider
        ...spacer
        ...summary
        ...text
        ...video

        ... on ContentSnippetSliderType {
          sliderId
        }
      }
    }
  }

  ${AppFragment}
  ${AudioFragment}
  ${ButtonFragment}
  ${CodeFragment}
  ${ContentSnippetSliderFragment}
  ${DocumentFragment}
  ${EmbedFragment}
  ${FormFragment}
  ${ImageFragment}
  ${MapFragment}
  ${QuestionFragment}
  ${QuoteFragment}
  ${RegionsFragment}
  ${SliderFragment}
  ${SpacerFragment}
  ${SummaryFragment}
  ${TextFragment}
  ${VideoFragment}
`;

export { UPDATE_CONTENT_SNIPPET };
