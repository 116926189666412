import { FormFieldProps } from "@netfront/ui-library";
import { IActionCondition } from "components";

const getConditionDefaultValues = (condition?: IActionCondition): FormFieldProps => {
  const {
    contentPageId,
    contentSnippetQuestionId,
    title,
    type,
    isTemplate,
    tempId,
    questionResponseCheckboxId,
    questionResponseRadioId,
    text,
    minValue,
    maxValue,
    dateRangeType,
    minDate,
    maxDate,
    minTimeSpan,
    maxTimeSpan,
    selectedDate,
    selection,
    id,
    userFlowStepId,
    isApplyToAllSteps,
  } = condition ?? {};

  return {
    contentPageId: contentPageId ?? null,
    contentSnippetQuestionId: contentSnippetQuestionId ?? null,
    title: title ?? '',
    type: type ?? '',
    isTemplate: isTemplate ?? false,
    tempId: tempId ?? '',
    questionResponseCheckboxId: questionResponseCheckboxId ? [String(questionResponseCheckboxId)] : [],
    questionResponseRadioId,
    text,
    minValue,
    maxValue,
    dateRangeType: dateRangeType ?? '',
    minDate,
    maxDate,
    minTimeSpan: minTimeSpan ?? 0,
    maxTimeSpan: maxTimeSpan ?? 0,
    selectedDate,
    selection,
    questionFormId: undefined,
    userFlowStepId: userFlowStepId ?? undefined,
    isApplyToAllSteps: isApplyToAllSteps ?? false,
    id
  };
};

export { getConditionDefaultValues };
