import React, { useState, useEffect } from 'react';

import {
  Button,
  Preloader,
  SidebarDialog,
  Tooltip,
  TooltipIcon,
} from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { useAddConditionGroup, useDeleteConditionGroup, useGetConditionGroups, useSortConditionGroup, useToast, useUpdateConditionGroup } from 'hooks';
import { DBLogicOperatorType, IDBCondition, IDBConditionGroup } from 'interfaces';

import { ConditionGroup } from './Components';
import { ConditionFormView, ISelectedCondition } from './ConditionFormView';
import { ScenarioConditionTabProps } from './ScenarioConditionTab.interfaces';


const ScenarioConditionTab = ({ projectId, scenarioId }: ScenarioConditionTabProps) => {
  const { handleToastError, handleToastSuccess } = useToast();


  const [currentConditionGroups, setCurrentConditionGroups] = useState<IDBConditionGroup[]>();
  const [isUpsertMode, setIsUpsertMode] = useState<boolean>(false);
  const [selectedCondition, setSelectedCondition] = useState<ISelectedCondition>();
  const [selectedConditionGroupId, setSelectedConditionGroupId] = useState<IDBConditionGroup['id']>();

  const { handleGetConditionGroups, isLoading: isGetConditionGroupsLoading = false } = useGetConditionGroups({
    onCompleted: ({ conditionGroupConnection: conditionGroups }) => {
      setCurrentConditionGroups(conditionGroups);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleAddConditionGroup, isLoading: isAddConditionGroupLoading = false } = useAddConditionGroup({
    onCompleted: () => {
      handleGetConditionGroups({
        scenarioId,
      });

      handleToastSuccess({
        message: 'Condition group created successfully',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { handleSortConditionGroup } = useSortConditionGroup({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Condition moved successfully',
      });

      handleGetConditionGroups({
        scenarioId,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteConditionGroup, isLoading: isDeleteConditionGroupLoading = false } = useDeleteConditionGroup({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Condition group deleted successfully',
      });

      handleGetConditionGroups({
        scenarioId,
      });

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateConditionGroup } = useUpdateConditionGroup({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Condition group updated successfully',
      });
      handleGetConditionGroups({
        scenarioId,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handleClickDeleteConditionGroup = (conditionGroupId: IDBConditionGroup['id']) => {
    handleDeleteConditionGroup({
      conditionGroupId,
    });
  };

  const handleUpsertCondition = (conditionGroupId: number, condition?: IDBCondition ) => {
    setIsUpsertMode(true);
    setSelectedConditionGroupId(conditionGroupId);
    setSelectedCondition(condition as ISelectedCondition);
  };

  const cancelUpsertCondition = () => {
    setIsUpsertMode(false);
    setSelectedConditionGroupId(undefined);
    setSelectedCondition(undefined);
  };

  const handleUpdate = () => {
    cancelUpsertCondition();
    handleGetConditionGroups({
      scenarioId,
    });
  };

  const handleClickToggleButton = (operator: string, currentConditionGroupId: number) => {

    handleUpdateConditionGroup({
      conditionGroupId: currentConditionGroupId,
      operator: operator as DBLogicOperatorType,
    });
  };


  useEffect(() => {
    handleGetConditionGroups({
      scenarioId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading =
    isGetConditionGroupsLoading || isAddConditionGroupLoading || isDeleteConditionGroupLoading; /*|| isCopyConditionLoading*/

  return (
    <SidebarContainer>

      
      <>
        {isLoading ? (
          <Preloader isLoading={isLoading} />
        ) : (
          
          currentConditionGroups &&
          Boolean(currentConditionGroups.length) &&
          currentConditionGroups.map((currentConditionGroup, index) => {
            const { id: conditionGroupId, operator } = currentConditionGroup;

            return (
              <ConditionGroup
                key={`${conditionGroupId}-${operator}`}
                conditionGroup={currentConditionGroup}
                conditionGroupsLength={currentConditionGroups.length}
                handleSort={(sort) => {
                  handleSortConditionGroup({
                    conditionGroupId,
                    sort,
                  })
                }}
                handleToggle={handleClickToggleButton}
                handleUpsert={handleUpsertCondition}
                index={index}
                onDelete={handleClickDeleteConditionGroup}
              />
            );
          })
        )}

        {isLoading ? (
          <Preloader isLoading={isLoading} />
        ) : (
          <section className="c-scenario-sidebar-condition-view__add-group-section">
            <Tooltip
              additionalClassNames="c-scenario-sidebar-condition-view__tooltip"
              icon={TooltipIcon}
              placement="left"
              text="Add new condition group"
            />

            <Button
              size="xs"
              text="Add new group"
              type="button"
              variant="primary-inverse"
              onClick={() => {
                void handleAddConditionGroup({
                  scenarioId,
                  operator: 'AND',
                });
              }}
            />
          </section>
        )}
      </>
      <SidebarDialog
        isOpen={isUpsertMode}
        onClose={cancelUpsertCondition}
      >
        <ConditionFormView
          projectId={projectId}
          scenarioId={scenarioId}
          selectedCondition={selectedCondition}
          selectedConditionGroupId={selectedConditionGroupId}
          onCancel={cancelUpsertCondition}
          onUpdate={handleUpdate}
        />
      </SidebarDialog>
      
        
    </SidebarContainer>
  );
};

export { ScenarioConditionTab };
