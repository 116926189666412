/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FormFieldProps } from "@netfront/ui-library";
import { IDBInvitationReminder } from "interfaces";

const getInvitationReminderDefaultValues = ({ invitationReminder }: {invitationReminder?: IDBInvitationReminder}): FormFieldProps => {

  const {
    title,
    description,
    end,
    start,
    delay,
    templateId,
    groupId,
  } = invitationReminder ?? {};

  return {
    title: title || '',
    description: description || '',
    end: end ? new Date(end): undefined,
    start: start ? new Date(start): undefined,
    delay: delay || '',
    templateId,
    groupId,
  };
};

export { getInvitationReminderDefaultValues };