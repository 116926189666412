import { gql } from '@apollo/client';

const GET_AVAILABLE_PROJECT_INSIGHTS_QUERY = gql`
  query getInsights($projectId: String!){
    insights{
      configuration(projectId:$projectId){
        availableInsights
      }
    }
  }
`;

export { GET_AVAILABLE_PROJECT_INSIGHTS_QUERY };
