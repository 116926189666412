import { gql } from '@apollo/client';

const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateEvent($inputType: UpdateEventCommunityInputType!) {
    community {
      updateEvent(inputType: $inputType) {
        bannerImage {
          assetId
          fileName
          fileSizeInBytes
          s3Key
          presignedUrl
          contentType
        }
        categoryId
        description
        id
        privacy
        profileImage {
          assetId
          contentType
          fileName
          fileSizeInBytes
          presignedUrl
          s3Key
        }
        status
        title
        start
        end
      }
    }
  }
`;

export { UPDATE_EVENT_MUTATION };
