export const readTextFile = async (file: string) => {
  try {
    const response = await fetch(file);
    if (!response.ok) {
      throw new Error('Failed to fetch file');
    }
    return await response.text();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return '';
  }
};