
import { UPDATE_EVENT_MUTATION } from './useUpdateEvent.graphql';
import {
  IUpdateEventMutationGraphQLResponse,
  IUpdateEventMutationVariables,
  IHandleUpdateEventParams,
  IUseUpdateEventOptions,
  IUseUpdateEvent,
} from './useUpdateEvent.interfaces';

import { useBonoboMutation } from '../../useBonoboMutation';

const useUpdateEvent = (options?: IUseUpdateEventOptions): IUseUpdateEvent => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseUpdateEventOptions);

  const [executeUpdateEvent, { loading: isLoading }] = useBonoboMutation<
    IUpdateEventMutationGraphQLResponse,
    IUpdateEventMutationVariables
  >({
    mutation: mutation ?? UPDATE_EVENT_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          community: { updateEvent: eventConnection },
        } = data;

        onCompleted({ eventConnection });
      },
      onError,
    },
    token,
  });

  const handleUpdateEvent = async ({
    inputType: { categoryId, description, status, title, privacy, id, start, end },
  }: IHandleUpdateEventParams) => {
    await executeUpdateEvent({
      variables: {
        inputType: {
          categoryId,
          description,
          privacy,
          status,
          title,
          id,
          start,
          end,
        },
      },
    });
  };

  return {
    handleUpdateEvent,
    isLoading,
  };
};

export { useUpdateEvent };
