const HOUR_TO_SECONDS = 3600;

const DAYS_TO_SECONDS = 86400;

export const convertSecondsToDaysAndHours = (seconds: number): { days: number, hours: number} => {
  const days = Math.floor(seconds/ DAYS_TO_SECONDS);

  const remainingSeconds = seconds % DAYS_TO_SECONDS;

  const hours = Math.floor(remainingSeconds / HOUR_TO_SECONDS);

  return {
    days,
    hours,
  };
};