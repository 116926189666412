import React from 'react';

import { Select, Spacing } from '@netfront/ui-library';
import { ConditionDelayInput, SidebarContainer } from 'components';
import { Controller } from 'react-hook-form';

import { invitationReminderConditionTabConstants } from './InvitationRemindersConditionTab.constants';
import { InvitationRemindersConditionTabProps } from './InvitationRemindersConditionTab.interfaces';



const InvitationRemindersConditionTab = ({ control, groupOptions = [] }: InvitationRemindersConditionTabProps) => {
  const { conditionDelayMinNumber } = invitationReminderConditionTabConstants;

  return (
    <SidebarContainer>
      <Spacing size="large">
        <Controller
          control={control}
          name="delay"
          render={({ field }) => (
            <ConditionDelayInput
              id="invitation_reminder_delay"
              initialValue={field.value}
              labelText="Condition delay"
              minNumber={conditionDelayMinNumber}
              name={field.name}
              tooltipText="The schedule will execute using the configuration below"
              onChange={({ value }) => field.onChange(value)}
            />
          )}
        />
      </Spacing>
      <Spacing size="large">
        <Controller
          control={control}
          name="groupId"
          render={({ field }) => (
            <Select
              id="id_group_select"
              labelText="Group"
              options={groupOptions}
              {...field}
            />
          )}
        />
      </Spacing>
    </SidebarContainer>
  );
};

export { InvitationRemindersConditionTab };
