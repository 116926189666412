import { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledForm, Dialog, FormFieldProps, GeneralTabIcon, SideBarTabSet, Spinner, StyleTabIcon, TabSetImperativeHandleRef, useControlledForm } from '@netfront/ui-library';
import { CommunitySidebarGeneralTab, CommunitySidebarThemeTab } from 'components';
import { useDeleteCommunity as useDeleteEvent, useGetCommunity as useGetEvent, useToast, useUpsertEvent } from 'hooks';
import { Control, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';


import { getDateTime, getEventDefaultValues } from './EventSidebarView.helpers';
import { EventSidebarViewProps } from './EventSidebarView.interfaces';


const EventSidebarView = ({
  handleSideBarClose,
  isSideBarOpen,
  onUpdate,
  projectId,
  selectedEventId,
}: EventSidebarViewProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const uploadedImageRef = useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  const uploadedBannerRef = useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalBannerRef = useRef<{value: boolean }>({ value: false });

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);


  const { control, handleSubmit, reset, setValue} = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().required().label('Title'),
        description: yup.string().required().label('Description'),
        status: yup.string().required().label('Description'),
        privacy: yup.string().required().label('Description'),
      }),
    ),
  });

  const { handleGetCommunity: handleGetEvent, isLoading: isGetEventLoading = false } = useGetEvent({
    onCompleted: ({ community }) => {
      setDefaultValues(getEventDefaultValues({ community }))
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId,
  });


  const { handleDeleteCommunity: handleDeleteEvent, isLoading: isDeleteEventLoading = false } = useDeleteEvent({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);
      reset();

      handleToastSuccess({ message: `Event successfully deleted`});

      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId,
  });


  const { handleUpsertEvent, isLoading: isUpsertEventLoading = false} = useUpsertEvent({
    onUpdate: () => {
      reset();
      onUpdate();
    },
    projectId, 
  })

  const handleUploadImage = (uploadedFile?: File) => {
    uploadedImageRef.current.value = uploadedFile;
  };

  const handleRemoveUploadedImage = () => {
    hasDeletedOriginalImageRef.current.value = true;
    uploadedImageRef.current.value = undefined;
  }

  const handleUploadBanner = (uploadedFile?: File) => {
    uploadedBannerRef.current.value = uploadedFile;
  };

  const handleRemoveUploadedBanner = () => {
    hasDeletedOriginalBannerRef.current.value = true;
    uploadedBannerRef.current.value = undefined;
  }

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const handleSaveCommunity = (item: FormFieldProps) => {
    const { description, title, status, privacy, startDate, endDate, startTime, endTime } = item;

    handleUpsertEvent({
      communityId: selectedEventId,
      description,
      privacy,
      status,
      title,
      start: getDateTime(new Date(String(startDate)), String(startTime)),
      end: getDateTime(new Date(String(endDate)), String(endTime)),
      uploadedBanner: uploadedBannerRef.current.value,
      uploadedImage: uploadedImageRef.current.value,
      deletedBannerId: hasDeletedOriginalBannerRef.current.value && defaultValues.bannerId ? defaultValues.bannerId: undefined,
      deletedImageId: hasDeletedOriginalImageRef.current.value && defaultValues.imageId ? defaultValues.imageId: undefined,
    });
  };

  useEffect(() => {
    if (selectedEventId) {
      handleGetEvent({
        communityId: selectedEventId,
      });
    }
    setDefaultValues(getEventDefaultValues({}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEventId, isSideBarOpen]);

  const isLoading =  isDeleteEventLoading || isUpsertEventLoading || isGetEventLoading;

  return (
    <>
      <Spinner isLoading={isLoading} spinnerIconSize={'small'} />
      <Dialog
        isOpen={isDeleteDialogOpen}
        title={`Delete ${String(defaultValues.title ?? '')}?`}
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!selectedEventId) {
            return;
          }

          handleDeleteEvent({
            communityId: selectedEventId,
          });
        }}
      />

      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSaveCommunity(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >

        <SideBarTabSet
          defaultActiveTabId="id_general_tab"
          handleOpenCloseSideBar={handleSideBarClose}
          hasViewPadding={false}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_general_tab',
              label: 'General',
              view: () => (
                <CommunitySidebarGeneralTab
                  control={control as Control<FormFieldProps>}
                  selectedId={selectedEventId}
                  setValue={setValue}
                  isEvent
                />
              ),
            },
            {
              icon: StyleTabIcon,
              id: 'id_style_tab',
              label: 'Theme',
              view: () => (
                <CommunitySidebarThemeTab 
                  control={control as Control<FormFieldProps>}
                  onRemoveBanner={handleRemoveUploadedBanner}
                  onRemoveImage={handleRemoveUploadedImage}
                  onUploadBanner={handleUploadBanner}
                  onUploadImage={handleUploadImage}
                />
              ),
            },
          ]}
          onDelete={selectedEventId ? () => setIsDeleteDialogOpen(true) : undefined}
          onSaveButtonType="submit"

        />
      </ControlledForm>
    </>
  );
};

export { EventSidebarView };
