import { gql } from "@apollo/client";

import { SECTION_GROUPS_FRAGMENT } from "./sectionGroup";
import { SECTION_GROUPS_TAB_FRAGMENT } from "./sectionGroupTab";

export const CONTENT_PAGE_CHILDREN_FRAGMENT = gql`
  fragment ContentPageChildrenFragment on ContentPageType {
    id
    title
    url
    parentId
    asset {
      assetId
      presignedUrl
      contentType
      fileName
      fileSizeInBytes
      isAnimatedImage
      s3Key
      type
    }
    bubbleTexts {
      angle
      character {
        id
        image {
          presignedUrl
          contentType
          s3Key
        }
        name
        projectId
      }
      characterId
      id
      isTailVisible
      hasShadow
      shape
      tailType
      sort
      text
      w
      x
      y
    }
    audio {
      assetId
      presignedUrl
      contentType
      fileName
      fileSizeInBytes
      presignedUrl
      s3Key
      type
    }
    minimumExposureTime
    sort
    sectionGroups {
      ...SectionGroupsFragment
      ...SectionGroupsTabFragment
    }
  }

  ${SECTION_GROUPS_FRAGMENT}
  ${SECTION_GROUPS_TAB_FRAGMENT}
`;
