

import { useCapuchinLazyQuery } from 'hooks';

import { GET_INVITATION_REMINDER } from './useGetInvitationReminder.graphql';
import {
  IGetInvitationReminderQueryGraphQLResponse,
  IGetInvitationReminderQueryVariables,
  IHandleGetInvitationReminderParams,
  IUseGetInvitationReminder,
  IUseGetInvitationReminderOptions,
} from './useGetInvitationReminder.interfaces';

const useGetInvitationReminder = (options?: IUseGetInvitationReminderOptions): IUseGetInvitationReminder => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetInvitationReminderOptions);

  const [executeGetInvitationReminder, { loading: isLoading }] = useCapuchinLazyQuery<IGetInvitationReminderQueryGraphQLResponse, IGetInvitationReminderQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          invitationReminders: { get: invitationReminder },
        } = data;

        onCompleted({
          invitationReminder,
        });
      },
      onError,
    },
    query: query ?? GET_INVITATION_REMINDER,
    token,
  });

  const handleGetInvitationReminder = ({ id }: IHandleGetInvitationReminderParams) => {
    void executeGetInvitationReminder({
      variables: {
        id,
      },
    });
  };

  return {
    handleGetInvitationReminder,
    isLoading,
  };
};

export { useGetInvitationReminder };
