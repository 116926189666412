
import { useCapuchinMutation } from 'hooks';

import { UPDATE_SCENARIO_MUTATION } from './useUpdateScenario.graphql';
import {
  IUpdateScenarioMutationGraphQLResponse,
  IUpdateScenarioMutationVariables,
  IHandleUpdateScenarioParams,
  IUseUpdateScenarioOptions,
  IUseUpdateScenario,
} from './useUpdateScenario.interfaces';

const useUpdateScenario = (options?: IUseUpdateScenarioOptions): IUseUpdateScenario => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateScenarioOptions);

  const [executeUpdateScenario, { loading: isLoading }] = useCapuchinMutation<
  IUpdateScenarioMutationGraphQLResponse,
  IUpdateScenarioMutationVariables
  >({
    mutation: mutation ?? UPDATE_SCENARIO_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          scenarioMutations: { updateScenario: scenario },
        } = data;

        onCompleted({
          scenario,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateScenario = ({ description, id, isTestPhase, status, title, overrideReceiver }: IHandleUpdateScenarioParams) => {
    void executeUpdateScenario({
      variables: {
        description,
        id,
        isTestPhase,
        status,
        title,
        overrideReceiver,
      },
    });
  };

  return {
    handleUpdateScenario,
    isLoading,
  };
};

export { useUpdateScenario };
