
import { useCapuchinMutation } from 'hooks';

import { DELETE_INVITATION_REMINDER } from './useDeleteInvitationReminder.graphql';
import {
  IDeleteInvitationReminderMutationGraphQLResponse,
  IDeleteInvitationReminderMutationVariables,
  IHandleDeleteInvitationReminderParams,
  IUseDeleteInvitationReminderOptions,
  IUseDeleteInvitationReminder,
} from './useDeleteInvitationReminder.interfaces';

const useDeleteInvitationReminder = (options?: IUseDeleteInvitationReminderOptions): IUseDeleteInvitationReminder => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteInvitationReminderOptions);

  const [executeDeleteInvitationReminder, { loading: isLoading }] = useCapuchinMutation<
  IDeleteInvitationReminderMutationGraphQLResponse,
  IDeleteInvitationReminderMutationVariables
  >({
    mutation: mutation ?? DELETE_INVITATION_REMINDER,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          invitationReminders: { remove: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteInvitationReminder = ({ id }: IHandleDeleteInvitationReminderParams) => {
    void executeDeleteInvitationReminder({
      variables: {
        id,
      },
    });
  };

  return {
    handleDeleteInvitationReminder,
    isLoading,
  };
};

export { useDeleteInvitationReminder };
