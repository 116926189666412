import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IUser, useDomain, useGetGeladaProjectsForConnectedUser, useUser } from '@netfront/gelada-identity-library';
import { Spinner } from '@netfront/ui-library';
import { useToast } from 'hooks';
import { useRouter } from 'next/router';
import { useGetUserPermissions } from 'utils';

import { CachingEntitiesContext } from 'context/CachingEntitiesContext';

import UserContext from './UserContext';
import { UserContextProps } from './UserContext.interfaces';

export function UserProvider({ children }: Readonly<UserContextProps>) {
  const {
    events,
    query: { projectId: queryProjectId },
    push,
    pathname,
  } = useRouter();
  const { isDomainReady } = useDomain();
  const { handleToastError } = useToast();
  const { getUser } = useUser();

  const [projectId, setProjectId] = useState<string>('');
  const [hasCheckCompleted, setHasCheckCompleted] = useState<boolean>(false);
  const [isMemberOfProject, setIsMemberOfProject] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [profileImageUrl, setProfileImageUrl] = useState<string>('');
  const [loggedUser, setLoggedUser] = useState<IUser>();
  const {
    hasCollaboratePermission,
    hasEditPermission,
    hasLimitedPermission,
    hasManageUsersPermission,
    hasNonePermission,
    hasReadPermission,
  } = useGetUserPermissions(projectId);
  
  const { organisation }= useContext(CachingEntitiesContext);

  const handleError = (error: ApolloError) => {
    handleToastError({
      error,
    });
  };

  const { handleGetGeladaProjectsForConnectedUser, isLoading: isGetGeladaProjectsLoading = false } = useGetGeladaProjectsForConnectedUser({
    onCompleted: ({ geladaProjects = [] }) => {
      setHasCheckCompleted(true);

      setIsMemberOfProject(geladaProjects.some(({ id }) => id === projectId))
    },
    onError: handleError,
  });

  const checkUser = () => {
    if (!hasCheckCompleted) return;
    if (isMemberOfProject) return;
    if (pathname === '/404') return;
    
    push(`/404`).catch((error) =>
      handleToastError({
        error,
      }),
    );
  };


  useEffect(() => {
    if (!organisation) return;
    if (hasCheckCompleted) return;

    void handleGetGeladaProjectsForConnectedUser({
      organisationId: organisation.id,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation, hasCheckCompleted]);
  

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    checkUser();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCheckCompleted, isMemberOfProject]);

  useEffect(() => {

    events.on('routeChangeStart', checkUser);
  
    return () => {
      events.off('routeChangeStart', checkUser);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }
    setLoggedUser(getUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!loggedUser) return;

    const { firstName = '', lastName = '', email: loggedUserEmail, pictures } = loggedUser;
    const { originalPicture, profilePicture } = pictures ?? {};

    const image = !profilePicture?.presignedUrl ? originalPicture?.presignedUrl ?? '' : profilePicture.presignedUrl;

    setFullName(`${String(firstName)} ${String(lastName)}`);
    setEmail(loggedUserEmail);
    setProfileImageUrl(String(decodeURIComponent(image)));

  }, [loggedUser]);

  const isLoading = isGetGeladaProjectsLoading;

  return (
    <UserContext.Provider
      value={{
        isLoading,
        profileImageUrl,
        fullName,
        email,
        user: loggedUser,
        hasCollaboratePermission,
        hasEditPermission,
        hasLimitedPermission,
        hasManageUsersPermission,
        hasNonePermission,
        hasReadPermission,
      }}
    >
      {isLoading ? <Spinner isLoading={true} /> : children}
    </UserContext.Provider>
  );
}
