import { IDBAppComponentFile } from "interfaces";
import { handleGetFileText } from "utils";


const handleGetAppFileText = async (file?: IDBAppComponentFile): Promise<string> => {
  if (!file) return '';

  
  const {
    location: { presignedUrl },
  } = file;

  return await handleGetFileText(presignedUrl);
};

export { handleGetAppFileText }; 