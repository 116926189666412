import React, { useState } from 'react';

import { Button, ButtonIconOnly, Dialog, InformationBox, Label, NavigationButton, PlusIcon, SettingsButton, SortDownFullIcon, SortDownHalfIcon, SortUpFullIcon, SortUpHalfIcon, Spacing } from '@netfront/ui-library';
import { scenarioConditionTabConstants } from 'components';

import { ToggleButtons } from '../ToggleButtons';

import { ConditionGroupProps } from './ConditionGroup.interfaces';

const ConditionGroup = ({ 
  conditionGroup, 
  index, 
  conditionGroupsLength,
  handleUpsert,
  handleToggle,
  handleSort,
  onDelete,
}: ConditionGroupProps) => {
  const { conditionGroupOperatorTypesOptions } = scenarioConditionTabConstants;

  const [isDeleteDialogOpen,  setIsDeleteDialogOpen] = useState<boolean>(false);

  const { id: conditionGroupId, conditions, operator, sort } = conditionGroup;

  const conditionsList = conditions.map((currentCondition) => {
    const { id, title } = currentCondition;

    return (
      <li key={`${id}-${title}`} className="c-scenario-sidebar-condition-view__condition-item">
        <span className="c-scenario-sidebar-condition-view__condition__name">{title}</span>

        <SettingsButton
          supportiveText="Open condition"
          onClick={() => handleUpsert(conditionGroupId, currentCondition)}
        />
      </li>
    );
  });

  return (
    <div id={`${conditionGroupId}-${operator}`}>
      <Spacing size="2x-large">
        <section className="c-sidebar-section c-sidebar-section--aligned">
          <Spacing size="small">
            <div className="c-sidebar-section__toggle-buttons">
              {index !== 0 ? (
                <ToggleButtons
                  key={`${String(conditionGroupId)}-${String(operator)}`}
                  items={conditionGroupOperatorTypesOptions}
                  name={`condition-group-operator-${String(conditionGroupId)}`}
                  selectedValue={operator}
                  onChange={({ target: { value }}) => {
                    handleToggle(value, conditionGroupId);
                  }}
                />
              ) : (
                <Label forId={`condition-groups-list`} labelText="Condition groups" />
              )}

              {conditionGroupsLength > 1 && (
                <div className="c-scenario-sidebar-condition-view__condition-item-sort">
                  {index !== 0 && index !== conditionGroupsLength - 1 && (
                    <>
                      <NavigationButton
                        direction="previous"
                        icon={SortUpHalfIcon}
                        onClick={() => handleSort(sort - 1)}
                      />
                      <NavigationButton
                        direction="next"
                        icon={SortDownHalfIcon}
                        onClick={() => handleSort(sort + 1)}
                      />
                    </>
                  )}

                  {index === 0 ? (
                    <NavigationButton
                      direction="previous"
                      icon={SortDownFullIcon}
                      onClick={() => handleSort(sort + 1)}
                    />
                  ) : null}

                  {index === conditionGroupsLength - 1 ? (
                    <NavigationButton
                      direction="next"
                      icon={SortUpFullIcon}
                      onClick={() => handleSort(sort - 1)}
                    />
                  ) : null}
                </div>
              )}

              <Dialog
                isOpen={isDeleteDialogOpen}
                title={`Delete condition group with all the conditions?`}
                isNarrow
                onCancel={() => setIsDeleteDialogOpen(false)}
                onClose={() => setIsDeleteDialogOpen(false)}
                onConfirm={() => {
                  onDelete(conditionGroupId);
                  setIsDeleteDialogOpen(false);
                }}
              />
            </div>
          </Spacing>

          <Spacing>
            {conditionsList.length ? (
              <ul>
                {conditionsList}
              </ul>
            ) : (
              <InformationBox message="No conditions created for the condition group" />
            )}
          </Spacing>

          <section className="c-scenario-sidebar-condition-view__add-section">
            <Button text="Delete" type="button" variant="danger--tertiary" onClick={() => setIsDeleteDialogOpen(true)} />

            <ButtonIconOnly icon={PlusIcon} text="Add condition" onClick={() => handleUpsert(conditionGroupId)} />
          </section>
        </section>
      </Spacing>
    </div>
  );
}

export { ConditionGroup };
