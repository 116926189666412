import { useContext, useEffect, useState } from 'react';

import { useBackofficeSiteUrls, useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';
import { Dropdown, EnterIcon, IDropdownNavigationItem, LogoAvatar, LogoutIcon, PlansIcon, UserNavigation, UserNavigationItemProps } from '@netfront/ui-library';
import { CachingEntitiesContext, UserContext } from 'context';

const UserAccountMenu = () => {

  const { project } = useContext(CachingEntitiesContext);
  const { user, fullName, profileImageUrl, email } = useContext(UserContext);
  const { isDomainReady } = useDomain();
  const { getLogoutUrl, getProfileUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const { getBaseUrl } = useBackofficeSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_BACK_OFFICE_LOCAL_PORT,
  });

  const [dashboardUrl, setDashboardUrl] = useState<string>('');
  const [footerConfig, setFooterConfig] = useState<IDropdownNavigationItem>();
  const [navigationList, setNavigationList] = useState<UserNavigationItemProps[]>([]);
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [logoutUrl, setLogoutUrl] = useState<string>();
  const [profileUrl, setProfileUrl] = useState<string>();

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setDashboardUrl(getBaseUrl());

    setLogoutUrl(getLogoutUrl());
    setProfileUrl(getProfileUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!(dashboardUrl && user && project)) {
      return;
    }


    const { id: projectId, organisation, isCustomBuild = false } = project;


    const ekardoBaseUrl = `/dashboard/${String(organisation.key)}/${String(projectId)}`;

    const dropdownNavigationItems: UserNavigationItemProps[] = [
      {
        header: {
          title: String(fullName),
          name: String(email),
          icon: EnterIcon,
          href: String(profileUrl),
          linkText: 'View profile'
        },
        options: [
          {
            icon: PlansIcon,
            href: `${ekardoBaseUrl}/library/apps`,
            label: 'My apps',
            isHidden: isCustomBuild,
          }, 
        ],
      },
    ];

    setNavigationList(dropdownNavigationItems);

    setFooterConfig({
      label: 'Logout',
      href: logoutUrl,
      icon: LogoutIcon,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardUrl, user, project]);

  return (
    <div className="c-user-account-menu">
      {user && (
        <Dropdown
          dropdownId="profile-dropdown"
          isDisplayContent={isSearchContentVisible}
          trigger={<LogoAvatar avatarImageSrc={String(profileImageUrl)} size="small" title={String(fullName)} />}
          onDisplayContent={setSearchIsContentVisible}
        >
          <UserNavigation
            dropdownNavigationItems={navigationList}
            footerConfig={footerConfig}
          />
        </Dropdown>
      )}
    </div>
  );
};

export { UserAccountMenu };
