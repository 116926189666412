export const compareArrays = <T extends {id: number }>(array1: T[], array2: T[]): boolean => {
  // If lengths are different, arrays are different
  if (array1.length !== array2.length) return true;

  // Sort and stringify arrays for comparison
  const sortedArray1 = [...array1].sort((a, b) => a.id - b.id);
  const sortedArray2 = [...array2].sort((a, b) => a.id - b.id);

  // Compare each object by converting to JSON string
  return !sortedArray1.every((item, index) =>
    JSON.stringify(item) === JSON.stringify(sortedArray2[index])
  );
};