import { gql } from '@apollo/client';

const GET_SCENARIO_QUERY = gql`
  query getScenario($scenarioId: Int!) {
    scenarioQueries {
      get(id: $scenarioId) {
        carbonCopies
        createdDate
        delayBetweenNotification
        delayBetweenNotificationInDay
        delayBetweenNotificationInMonth
        delayBetweenNotificationInYear
        delayBetweenNotificationInWeek
        description
        endDate
        id
        isTestPhase
        maxRecurrence
        schedule {
          ... on ScheduleDailyGraphType {
            occurEvery
          }
          ... on ScheduleWeeklyGraphType {
            occurEvery
            recurrenceDays
          }
          ... on ScheduleMonthlyGraphType {
            day
            occurEvery
          }
          ... on ScheduleYearlyGraphType {
            day
            month
            occurEvery
          }
          __typename
        }
        startDate
        status
        subject
        title
        updatedDate
        overrideReceiver
      }
    }
  }
`;

export { GET_SCENARIO_QUERY };
