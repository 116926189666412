import { ICssFilesTableData, ICssFilesTableDataParams } from "./LibraryCustomCssPage.interfaces";


const getCssFilesTableData = ({ cssFiles, onSettingsButtonClick }: ICssFilesTableDataParams): ICssFilesTableData[] => {
  return cssFiles.map(({ description = '', assetId, title = '' }) => {
    return {
      title,
      id: assetId,
      description,
      settingsButtonData: {
        id: assetId,
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getCssFilesTableData };
