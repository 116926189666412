import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { ToggleSwitch } from '@netfront/ui-library';
import { TablePageTemplate, ScenarioSidebarView } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetPaginatedScenarios, useToast } from 'hooks';
import { DBScenarioStatusType, IDBScenario } from 'interfaces';
import last from 'lodash.last';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';

import { SCENARIOS_TABLE_COLUMNS } from './UserScenariosPage.constants';
import { getScenariosTableData } from './UserScenariosPage.helpers';
import { IUserScenariosTableData } from './UserScenariosPage.interfaces';


const UserScenariosPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError } = useToast();
  const defaultPageSize = 10;

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [allScenarios, setAllScenarios] = useState<IDBScenario[]>();
  const [filter, setFilter] = useState<string>();
  const [selectedScenarioId, setSelectedScenarioId] = useState<number>();
  const [scenarioTableData, setScenarioTableData] = useState<IUserScenariosTableData[]>([]);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<DBScenarioStatusType>('ACTIVE');
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [totalScenarios, setTotalScenarios] = useState(0);

  const {
    handleFetchMorePaginatedScenarios,
    handleGetPaginatedScenarios,
    isLoading: isGetScenariosLoading,
  } = useGetPaginatedScenarios({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ scenarioConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const scenarios = edges.map(({ node }) => node);
      setAllScenarios(scenarios);
      setIsLoadMoreDisabled(scenarios.length >= totalCount || totalCount <= pageSize);
      setTotalScenarios(totalCount);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleFilterSearch = (value: string) => {
    void handleGetPaginatedScenarios({
      first: pageSize,
      projectId: String(projectId),
      filter: value,
      shouldIncludeConditions: false,
      status: statusFilter,
    });

    setFilter(value);
  };

  const handleCloseSidebar = () => {
    setIsSideBarOpen(false);
    setSelectedScenarioId(undefined);
  };

  const handleUpdate = ({ isCreate = false, scenario  }: { isCreate?: boolean; scenario?: IDBScenario;  }) => {

    const { status, id } = scenario ?? {}; 

    if (!isCreate) {
      handleCloseSidebar();
    } else {
      setSelectedScenarioId(id);
    }

    if (Boolean(status) && status !== statusFilter) {
      setStatusFilter(status as IDBScenario['status']);
    } else {
      void handleGetPaginatedScenarios({
        first: pageSize,
        projectId: String(projectId),
        filter,
        shouldIncludeConditions: false,
        status: statusFilter,
      });
    }
  };

  const handleOnPageSizeChange = (changedPageSize: number) => {
    setPageSize(Number(changedPageSize));
  };

  const handleSelectScenario = (id?: number) => {
    setIsSideBarOpen(true);
    setSelectedScenarioId(id);
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    void handleGetPaginatedScenarios({
      first: pageSize,
      projectId: String(projectId),
      filter,
      shouldIncludeConditions: false,
      status: statusFilter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId, statusFilter]);

  useEffect(() => {
    if (!allScenarios) {
      return;
    }

    setScenarioTableData(
      getScenariosTableData({
        scenarios: allScenarios,
        onSettingsButtonClick: (id) => {
          handleSelectScenario(id)
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allScenarios]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  return (
    <>
      <TablePageTemplate<IUserScenariosTableData>
        activePath={['notifications', 'scheduler', 'scenarios', 'scenario-users']}
        additionalBreadcrumbItems={[{
          key: '1',
          content: 'Notifications',
        }, {
          key: '2',
          content: 'Scheduler',
        }, {
          key: '3',
          content: 'Scenarios',
        }, {
          key: '4',
          content: 'Users',
        }]}
        childrenEnd={
          <ToggleSwitch
            id="id_show_inactive"
            isChecked={statusFilter === 'INACTIVE'}
            labelText="Show inactive"
            isInline
            onChange={({ target: { checked: isChecked }}) => setStatusFilter(isChecked ? 'INACTIVE' : 'ACTIVE')}
          />
        }
        columns={SCENARIOS_TABLE_COLUMNS}
        data={scenarioTableData}
        defaultActiveTabId="id_general_tab"
        description={`User scenarios for ${String(projectName)}`}
        handleAddNewClick={() => handleSelectScenario(undefined)}
        handleOnPageSizeChange={handleOnPageSizeChange}
        handleOnPaginate={async () => {
          await handleFetchMorePaginatedScenarios({
            after: lastCursor,
            first: pageSize,
            projectId: String(projectId),
            filter: filter,
            shouldIncludeConditions: false,
            status: statusFilter,
          });
        }}
        handleSearch={handleFilterSearch}
        informationBoxMessage={`Manage ${String(projectName)} schedule notfications information: ${totalScenarios} ${pluralize('scenario', totalScenarios)}`}
        isLoading={isGetScenariosLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        logoUrl={project?.logo?.presignedUrl}
        pageSize={pageSize}
        pageTitle={String(projectName)}
        searchPlaceholder="Search"
        size={project?.logo?.presignedUrl ? 'small': 'medium'}
        tableType="scenarios"
        title={`${String(projectName)} User scenarios`}
        totalItems={totalScenarios}
      />
      <ScenarioSidebarView
        handleSideBarClose={handleCloseSidebar} 
        isSideBarOpen={isSideBarOpen} 
        projectId={projectId}
        selectedScenarioId={selectedScenarioId}
        onUpdate={handleUpdate}
      />
    </>

    
  );
};

export { UserScenariosPage };
