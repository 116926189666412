import React, { useState, useRef, useEffect, useContext } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { IDBAsset } from '@netfront/common-library';
import { ControlledForm, FilmIcon, FormFieldProps, GeneralTabIcon, IOption, SidebarButtons, SingleCheckbox, Spinner, StyleTabIcon, TabSet, TabSetImperativeHandleRef, UsageIcon, useControlledForm } from '@netfront/ui-library';
import { AnimationTab, EventTab, StyleTab, UpsertSliderSnippetGeneralTab, animationTabValidation, eventTabValidation } from 'components';
import { CachingEntitiesContext } from 'context';
import { useToast, useUpsertAssetWrapper, useUpsertSnippet, useGetAllSliders } from 'hooks';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getSliderSnippetCommonVariables, getSliderSnippetDefaultValues, setQuickAddSliderValues } from './UpsertSliderSnippetSidebarView.helpers';
import { UpsertSliderSnippetSidebarViewProps } from './UpsertSliderSnippetSidebarView.interfaces';


const UpsertSliderSnippetSidebarView = ({ containerId, onClose, onCreate, onDelete, onUpdate, snippet, sort, isLoading: isSnippetLoading = false, handleUpdateSortValue }: UpsertSliderSnippetSidebarViewProps) => {

  const { project } = useContext(CachingEntitiesContext);
  const { handleToastError } = useToast();

  const createdEventAssetIdRef = useRef<{ value: string| null}>({ value: null });

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});
  const [sliderOptions, setSliderOptions] = useState<IOption[]>([]);
  const droppedFileEventAssetRef =  useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const { control, handleSubmit, reset, getValues } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        sliderId: yup.number().required(),
        ...eventTabValidation,
        ...animationTabValidation,
      }),
    ),
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleUpsertAsset: handleUpsertEventAsset, isLoading: isUpsertEventAssetLoading = false } = useUpsertAssetWrapper();


  const { handleUpsertSnippet, isLoading: isUpsertSnippetLoading = false } = useUpsertSnippet({
    onCreate: () => {
      const { isCreateNew = false } = getValues();

      if (isCreateNew) {
        droppedFileEventAssetRef.current.value = undefined;
        createdEventAssetIdRef.current.value = null;
        hasDeletedOriginalImageRef.current.value = false;
        handleUpdateSortValue(sort + 1);
        setDefaultValues(setQuickAddSliderValues(getValues()));
      } else {
        reset();
      }
      onCreate(isCreateNew as boolean);
    },
    onUpdate: (returnedSnippet) => {
      reset();
      onUpdate(returnedSnippet);
    }
  });


  const { handleGetAllSliders, isLoading: isGetAllSlidersLoading = false } = useGetAllSliders({
    onCompleted: ({ sliders }) => {

      setSliderOptions(sliders.map(({ id, title}) => ({
        id,
        value: Number(id),
        name: title,
      })));
    },
    onError: handleGetError,
  });

  const handleDropFile = (uploadedFile?: File) => {
    droppedFileEventAssetRef.current.value = uploadedFile;
  };

  const handleRemoveAsset = () => {
    hasDeletedOriginalImageRef.current.value = true;
  };

  const handleSave = (item: FormFieldProps) => {

    handleUpsertEventAsset({
      assetType: 'image',
      isCreate: true,
      projectId: String(project?.id),
      uploadedFile: droppedFileEventAssetRef.current.value,
      deletedFileId: hasDeletedOriginalImageRef.current.value && defaultValues.assetId ? defaultValues.assetId : undefined,
      callBack: (asset?: IDBAsset) => {

        const commonVariables = getSliderSnippetCommonVariables({
          item,
          eventAssetId: asset ? String(asset.assetId): undefined,
        });

        handleUpsertSnippet({
          containerId,
          snippetId: snippet? Number(snippet.id): undefined,
          sort,
          variables: commonVariables,
        });
      }
    });
  };

  useEffect(() => {

    setDefaultValues(getSliderSnippetDefaultValues({ snippet }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippet]);

  useEffect(() => {
    if (!project) return;
    
    void handleGetAllSliders({
      projectId: project.id,
    });
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const isLoading =
    isUpsertSnippetLoading ||
    isGetAllSlidersLoading ||
    isUpsertEventAssetLoading ||
    isSnippetLoading;
  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >
      <TabSet
        ref={tabsetRef}
        defaultActiveTabId="id_general_tab"
        tabs={[
          {
            icon: GeneralTabIcon,
            id: 'id_general_tab',
            label: 'General',
            view: () => isLoading ? <></> : (
              <UpsertSliderSnippetGeneralTab
                control={control as Control<FormFieldProps>}
                sliderOptions={sliderOptions}
              />
            ),
          },
          {
            icon: UsageIcon,
            id: 'id_event_tab',
            label: 'Event',
            view: () => isLoading ? <></> : (
              <EventTab
                control={control as Control<FormFieldProps>}
                initialEvent={defaultValues.event}
                onDeleteAsset={handleRemoveAsset}
                onDrop={handleDropFile}
              />
            ),
          },
          {
            icon: FilmIcon,
            id: 'id_animation_tab',
            label: 'Animation',
            view: () => isLoading ? <></> : (
              <AnimationTab
                animationType={defaultValues.animation ?? ''}
                control={control as Control<FormFieldProps>}
              />
            ),
          },
          {
            icon: StyleTabIcon,
            id: 'id_style_tab',
            label: 'Style',
            view: () => isLoading ? <></> : (
              <StyleTab
                control={control as Control<FormFieldProps>}
                entityType="targetSnippet"
              />
            ),
          },
        ]}
      />
      <SidebarButtons
        additionalButton={!snippet ? (
          <Controller
            control={control as Control<FormFieldProps>}
            name="isCreateNew"
            render={({ field }) => (
              <SingleCheckbox
                hasPadding={false}
                id="id_is_create_new"
                isChecked={field.value}
                labelText="Create another snippet"
                name={field.name}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        ) : undefined
        }
        onCancel={onClose}
        onDelete={onDelete}
        onSaveButtonType="submit"
      />
      </ControlledForm>
    </>
  );
};


export { UpsertSliderSnippetSidebarView };
