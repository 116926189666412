import { gql } from '@apollo/client';


export const SET_CHECKBOX_CORRECT_RESPONSE_MUTATION = gql`
  mutation setCheckboxCorrectResponseMutation(
    $questionResponseId: Int!
    $contentGroupId: Int!
    $contentSnippetId: Int!
  ) {
    contentGroup {
      setCorrectResponse(
        checkbox: {
          questionResponseCheckboxId: $questionResponseId
          contentGroupId: $contentGroupId
          contentSnippetId: $contentSnippetId
        }
      )
    }
  }
`;

export const SET_RADIO_CORRECT_RESPONSE_MUTATION = gql`
  mutation setRadioCorrectResponseMutation(
    $questionResponseId: Int!
    $contentGroupId: Int!
    $contentSnippetId: Int!
  ) {
    contentGroup {
      setCorrectResponse(
        radio: {
          questionResponseRadioId: $questionResponseId
          contentGroupId: $contentGroupId
          contentSnippetId: $contentSnippetId
        }
      )
    }
  }
`;

export const SET_DROPDOWN_LIST_CORRECT_RESPONSE_MUTATION = gql`
  mutation setDropDownListCorrectResponseMutation(
    $questionResponseId: Int!
    $contentGroupId: Int!
    $contentSnippetId: Int!
  ) {
    contentGroup {
      setCorrectResponse(
        dropdownlist: {
          questionResponseDropDownListId: $questionResponseId
          contentGroupId: $contentGroupId
          contentSnippetId: $contentSnippetId
        }
      )
    }
  }
`;

