import React from 'react';

import { RadioButtonGroup, Spacing } from '@netfront/ui-library';
import { Controller } from 'react-hook-form';

import { ConditionFormDayTypeViewProps } from './ConditionFormDayTypeView.interfaces';

import { scenarioConditionTabConstants } from '../../../ScenarioConditionTab.constants';

const ConditionFormDayTypeView = ({ control }: ConditionFormDayTypeViewProps) => {

  const { conditionDayOptions } = scenarioConditionTabConstants;

  return (
    <Spacing size="x-large">
      <Controller
        control={control}
        name="day"
        render={({ field }) => (
          <RadioButtonGroup
            items={conditionDayOptions}
            selectedValue={String(field.value)}
            title="Condition day"
            tooltipText="Choose day of the week"
            isLabelSideBySide
            {...field}
          />
        )}
      />
    </Spacing>
  );
};

export { ConditionFormDayTypeView };
