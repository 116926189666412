import { createContext } from 'react';

import { IUserContext } from './UserContext.interfaces';


export const UserContext = createContext<IUserContext>({
  email: undefined,
  fullName: undefined,
  hasCollaboratePermission: false,
  hasEditPermission: false,
  hasLimitedPermission: false,
  hasManageUsersPermission: false,
  hasNonePermission: false,
  hasReadPermission: false,
  isLoading: true,
  profileImageUrl: undefined,
  user: undefined,
  
});

// eslint-disable-next-line import/no-default-export
export default UserContext;
