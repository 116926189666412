import { useCapuchinMutation } from 'hooks';

import { DELETE_TEST_USER_MUTATION } from './useDeleteTestUser.graphql';
import {
  IDeleteTestUserMutationGraphQLResponse,
  IDeleteTestUserMutationVariables,
  IHandleDeleteTestUserParams,
  IUseDeleteTestUserOptions,
  IUseDeleteTestUser,
} from './useDeleteTestUser.interfaces';

const useDeleteTestUser = (options?: IUseDeleteTestUserOptions): IUseDeleteTestUser => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteTestUserOptions);

  const [executeDeleteTestUser, { loading: isLoading }] = useCapuchinMutation<
    IDeleteTestUserMutationGraphQLResponse,
    IDeleteTestUserMutationVariables
  >({
    mutation: mutation ?? DELETE_TEST_USER_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          projectTestUserMutations: { removeUser: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteTestUser = ({ projectId, userId }: IHandleDeleteTestUserParams) => {
    void executeDeleteTestUser({
      variables: {
        projectId,
        userId,
      },
    });
  };

  return {
    handleDeleteTestUser,
    isLoading,
  };
};

export { useDeleteTestUser };
