import { useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { IQuestionCondition } from '@netfront/ekardo-content-library';
import { IActionCondition } from 'components';
import { IHandleCreateConditionParams, IHandleUpdateConditionParams, useCreateCondition, useToast, useUpdateCondition } from 'hooks';



const useUpsertConditions = ({ onUpdate }: { onUpdate: () => void }) => {
  const chainedRequestsCompletedCountRef = useRef({ value: 0 });
  const chainedRequestsCountRef = useRef({ value: 0 });
  const { handleToastError } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleUpsertConditionOnCompleted = () => {
    chainedRequestsCompletedCountRef.current.value += 1;

    if (chainedRequestsCompletedCountRef.current.value === chainedRequestsCountRef.current.value) {
      onUpdate();
      chainedRequestsCompletedCountRef.current.value = 0;
      chainedRequestsCountRef.current.value = 0;
    }
  };

  const { handleUpdateCondition: handleUpdateCheckboxCondition, isLoading: isUpdateCheckboxConditionLoading = false } = useUpdateCondition({
    conditionType: 'checkbox',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleCreateCondition: handleCreateCheckboxCondition, isLoading: isCreateCheckboxConditionLoading = false } = useCreateCondition({
    conditionType: 'checkbox',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleUpdateCondition: handleUpdateRadioCondition, isLoading: isUpdateRadioConditionLoading = false } = useUpdateCondition({
    conditionType: 'radio',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleCreateCondition: handleCreateRadioCondition, isLoading: isCreateRadioConditionLoading = false } = useCreateCondition({
    conditionType: 'radio',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleUpdateCondition: handleUpdateTextCondition, isLoading: isUpdateTextConditionLoading = false } = useUpdateCondition({
    conditionType: 'text',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleCreateCondition: handleCreateTextCondition, isLoading: isCreateTextConditionLoading = false } = useCreateCondition({
    conditionType: 'text',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleUpdateCondition: handleUpdateSliderCondition, isLoading: isUpdateSliderConditionLoading = false } = useUpdateCondition({
    conditionType: 'slider',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleCreateCondition: handleCreateSliderCondition, isLoading: isCreateSliderConditionLoading = false } = useCreateCondition({
    conditionType: 'slider',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleUpdateCondition: handleUpdateDateCondition, isLoading: isUpdateDateConditionLoading = false } = useUpdateCondition({
    conditionType: 'calendar',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const { handleCreateCondition: handleCreateDateCondition, isLoading: isCreateDateConditionLoading = false } = useCreateCondition({
    conditionType: 'calendar',
    onCompleted: handleUpsertConditionOnCompleted,
    onError: handleGetError,
  });

  const conditionTypeFunctionMap: { [key: string]: { create: (variables: IHandleCreateConditionParams) => void; update: (params: IHandleUpdateConditionParams) => void }; } = {
    checkbox: {
      update: handleUpdateCheckboxCondition,
      create: handleCreateCheckboxCondition,
    },
    radio: {
      update: handleUpdateRadioCondition,
      create: handleCreateRadioCondition,
    },
    text: {
      update: handleUpdateTextCondition,
      create: handleCreateTextCondition,
    },
    slider: {
      update: handleUpdateSliderCondition,
      create: handleCreateSliderCondition,
    },
    calendar: {
      update: handleUpdateDateCondition,
      create: handleCreateDateCondition,
    },
  };

  const getConditionVariables = (condition: IActionCondition, actionId: number): IQuestionCondition => {
    const { type } = condition;

    const sharedVariables = {
      contentPageId: condition.contentPageId,
      contentSnippetQuestionId: condition.contentSnippetQuestionId,
      isTemplate: condition.isTemplate,
      questionConditionId: condition.id,
      questionActionId: actionId,
      title: condition.title,
      userFlowStepId: condition.userFlowStepId
    };

    const conditionVariableMap: { [key: string]: Partial<IQuestionCondition> } = {
      checkbox: {
        questionResponseCheckboxId: condition.questionResponseCheckboxId,
        selection: condition.selection,
      },
      radio: {
        questionResponseRadioId: condition.questionResponseRadioId,
        selection: condition.selection,
      },
      text: {
        text: condition.text,
      },
      slider: {
        minValue: condition.minValue,
        maxValue: condition.maxValue,
      },
      calendar: {
        dateRangeType: condition.dateRangeType,
        minDate: condition.minDate,
        maxDate: condition.maxDate,
        minTimeSpan: condition.minTimeSpan,
        maxTimeSpan: condition.maxTimeSpan,
        selectedDate: condition.selectedDate,
      },
    };

    return {
      ...sharedVariables,
      ...conditionVariableMap[type],
    } as IQuestionCondition;
  };

  const getConditionSettings = (condition: IActionCondition, actionId: number): {
    functions: {
      create: (variables: IHandleCreateConditionParams) => void;
      update: (variables: IHandleUpdateConditionParams) => void;
    };
    variables: IQuestionCondition;
  } => {
    const { type } = condition;
    return {
      functions: conditionTypeFunctionMap[type],
      variables: getConditionVariables(condition, actionId),
    };
  };

  const upsertCondition = (condition: IActionCondition, actionId: number) => {
    const { id } = condition;

    const {
      variables,
      functions: { update, create },
    } = getConditionSettings(condition, actionId);

    if (id) {
      update(variables);
    } else {
      create(variables);
    }
  };

  const handleUpsertConditions = ({ conditions, actionId }: { actionId: number; conditions: IActionCondition[] }) => {
    chainedRequestsCountRef.current.value = conditions.length;

    conditions.forEach((condition) => upsertCondition(condition, actionId));
  };

  return {
    isLoading:
      isUpdateCheckboxConditionLoading ||
      isCreateCheckboxConditionLoading ||
      isUpdateRadioConditionLoading ||
      isCreateRadioConditionLoading ||
      isUpdateTextConditionLoading ||
      isCreateTextConditionLoading ||
      isUpdateSliderConditionLoading ||
      isCreateSliderConditionLoading ||
      isUpdateDateConditionLoading ||
      isCreateDateConditionLoading,
    handleUpsertConditions,
  };
};
export { useUpsertConditions };
