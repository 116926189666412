import React from 'react';

import { Input, MinusCircleIcon, PlusCircleIcon } from '@netfront/ui-library';
import cx from 'classnames';

import { InputCounterProps } from './InputCounter.interfaces';

const InputCounter = ({
  additionalClassNames,
  id,
  maxNumber,
  minNumber,
  value,
  name,
  handleUpdate
}: InputCounterProps) => {
  return (
    <div className={cx('c-input-counter', additionalClassNames)}>
      <div className="c-input-counter__counter">
        <button className="c-input-counter__button" title="minus" type="button" onClick={() => handleUpdate(id, 'decrease', 1)}>
          <MinusCircleIcon />
        </button>
        <Input
          additionalClassNames="c-input-counter__input"
          hasPadding={false}
          id={`id_${String(id)}_input_counter`}
          labelText=""
          maxNumber={maxNumber}
          minNumber={minNumber}
          name={name}
          type="number"
          value={value}
          onChange={({ target: { value: updatedValue} }) => handleUpdate(id, 'event', Number(updatedValue))}
        />
        <button className="c-input-counter__button" title="plus" type="button" onClick={() => handleUpdate(id, 'increase', 1)}>
          <PlusCircleIcon />
        </button>
      </div>
    </div>
  );
};

export { InputCounter };
