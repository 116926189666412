import { format } from 'date-fns';

import { IEventsTableDataParams, IEventsTableData } from './EventsPage.interfaces';

const getCommunitiesTableData = ({ events, onSettingsButtonClick }: IEventsTableDataParams): IEventsTableData[] => {
  return events.map(({ id, description, title, status, privacy, start = '', end = '' }): IEventsTableData => {
    return {
      id,
      start: start ? format(new Date(start), 'dd/MM/yyyy hh:mm'): '-',
      end: end ? format(new Date(end), 'dd/MM/yyyy hh:mm'): '-',
      title,
      privacy,
      description,
      status,
      settingsButtonData: {
        id,
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getCommunitiesTableData };
