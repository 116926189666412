import { ChangeEvent, useState, useEffect, useContext } from 'react';

import { ApolloError } from '@apollo/client';
import { DBAssetTypeType, DBAssetSortType, IDBAsset } from '@netfront/common-library';
import {
  AssetType,
  CardListPageItems,
  FilterBar,
  ICheckboxItem,
  Select,
  Spinner,
  ToggleSwitch,
} from '@netfront/ui-library';
import { CardListTemplatePage , AssetListSidebarView, AssetBulkActionDialog } from 'components';
import { CachingEntitiesContext } from 'context';
import capitalize from 'lodash/capitalize';
import last from 'lodash.last';
import Link from 'next/link';
import { useRouter } from 'next/router';



import { assetListConstants } from './AssetList.constants';
import { AssetListProps } from './AssetList.interfaces';


import { useAddToFavoriteAssets, useRemoveFromFavoriteAssets, useSearchPaginatedAssets, useSearchPaginatedTags, useToast } from '../../../../../hooks';

const AssetList = ({ assetType }: AssetListProps) => {
  const { project } = useContext(CachingEntitiesContext);

  const { query: { projectId: queryProjectId } } = useRouter();

  const {
    defaultPageSize,
    assetSortSelectOptions,
  } = assetListConstants;


  const { handleToastError, handleToastSuccess } = useToast();
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);

  const [projectId, setProjectId] = useState<string>('');
  const [organisationKey, setOrganisationKey] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');

  const [allAssets, setAllAssets] = useState<IDBAsset[]>([]);
  const [filterBarItems, setFilterBarItems] = useState<ICheckboxItem[]>([]);
  const [filter, setFilter] = useState<string>();
  const [filterBarSelectedValues, setFilterBarSelectedValues] = useState<string[]>([]);
  const [assetSort, setAssetSort] = useState<DBAssetSortType>('NEWEST');
  const [isFavoriteAssets, setIsFavoriteAssets] = useState<boolean>(false);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [selectedAsset, setSelectedAsset] = useState<IDBAsset>();
  const [totalAssets, setTotalAssets] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPreLoaderLoading, setIsPreloaderLoading] = useState<boolean>(true);

    // bulk actions
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [bulkActionType, setBulkActionType] = useState<string>('add');
    const [isBulkActionDialogOpen, setIsBulkActionDialogOpen] = useState<boolean>(false);
  

  const {
    handleFetchMorePaginatedAssets,
    handleSearchPaginatedAssets: executeSearchPaginatedAssets,
    isLoading: isGetPaginatedAssetsLoading = false,
  } = useSearchPaginatedAssets({
    fetchPolicy: 'no-cache',
    onCompleted: ({ assetConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const assets = edges.map(({ node }) => node);
      setAllAssets(assets);
      setIsLoadMoreDisabled(assets.length >= totalCount || totalCount <= pageSize);
      setTotalAssets(totalCount);
      if (isLoading) setIsLoading(false);
      if (isPreLoaderLoading) setIsPreloaderLoading(false);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleSearchPaginatedTags: executeSearchPaginatedTags } = useSearchPaginatedTags(
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ edges }) => {
        const tags = edges.flatMap(({ node }) => (node ? node : []));
  
        const formattedTags = tags.map(({ id, name }) => {
          return {
            labelText: `${name}`,
            id: String(id),
            value: String(id),
          };
        });
    
        setFilterBarItems(formattedTags);
      },
      onError: (error: ApolloError) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    },
  );

  const { handleAddToFavoriteAssets: executeAddToFavoriteAssets } = useAddToFavoriteAssets({
    onCompleted: () => {
      handleToastSuccess({
        message: `${capitalize(assetType)} added to favorites successfully`,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleRemoveFromFavoriteAssets: executeRemoveFromFavoriteAssets } = useRemoveFromFavoriteAssets({
    onCompleted: () => {
      handleToastSuccess({
        message: `${capitalize(assetType)} removed from favorites successfully`,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleAddNewAssetClick = () => {
    setSelectedAsset(undefined);
    setIsSideBarOpen(true);
  };

  const handleAddToFavoriteAssets = (id: string) => {
    executeAddToFavoriteAssets({
      assetId: id,
    });

    const assetIndex = allAssets.findIndex(({ assetId }) => assetId === id);
    const asset = allAssets.find(({ assetId }) => assetId === id);

    const newAsset = {
      ...asset,
      isFavorite: true,
    };

    allAssets.splice(assetIndex, 1, newAsset as IDBAsset);
  };

  const handleRemoveFromFavoriteAssets = (id: string) => {
    executeRemoveFromFavoriteAssets({
      assetId: id,
    });

    const assetIndex = allAssets.findIndex(({ assetId }) => assetId === id);
    const asset = allAssets.find(({ assetId }) => assetId === id);

    const newAsset = {
      ...asset,
      isFavorite: false,
    };

    allAssets.splice(assetIndex, 1, newAsset as IDBAsset);
  };

  const handleChangeAssetSort = (event: ChangeEvent<HTMLSelectElement>) => {

    const {
      target: { value },
    } = event;

    setAssetSort(value as DBAssetSortType);
  };


  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };

  const handleChangeTagFilters = (values: string[]) => {
    setFilterBarSelectedValues(values);
  };


  const handleClearFilterBar = () => {
    setFilterBarSelectedValues([]);

    executeSearchPaginatedAssets({
      first: pageSize,
      filter,
      projectId: String(projectId),
      tags: [],
      types: [`${assetType.toUpperCase() as DBAssetTypeType}`],
      favorite: isFavoriteAssets,
      sort: assetSort,
    });
  };

  const handleCreateAsset = () => {
    executeSearchPaginatedAssets({
      first: pageSize,
      filter,
      projectId: String(projectId),
      tags: filterBarSelectedValues.map((item) => Number(item)),
      types: [`${assetType.toUpperCase() as DBAssetTypeType}`],
      favorite: isFavoriteAssets,
      sort: assetSort,
    });

    executeSearchPaginatedTags({
      projectId,
    });

    handleSideBarClose();

  };

  const handleClickFilterBar = () => {
    setIsLoading(true);
    executeSearchPaginatedAssets({
      filter,
      first: pageSize,
      projectId: String(projectId),
      tags: filterBarSelectedValues.map((item) => Number(item)),
      types: [`${assetType.toUpperCase() as DBAssetTypeType}`],
      favorite: isFavoriteAssets,
      sort: assetSort,
    });
  };


  const handleDeletedAsset = () => {
    executeSearchPaginatedAssets({
      first: pageSize,
      filter,
      projectId: String(projectId),
      tags: filterBarSelectedValues.map((item) => Number(item)),
      types: [`${assetType.toUpperCase() as DBAssetTypeType}`],
      favorite: isFavoriteAssets,
      sort: assetSort,
    });

    executeSearchPaginatedTags({
      projectId,
    });
 
    handleSideBarClose();

  };

  const handleGetAssetComponentCommonProps = (asset: IDBAsset) => {
    return {
      id: asset.assetId,
      imageSize: 'SMALL',
      isFavorite: asset.isFavorite,
      isSelected: asset.isFavorite,
      supportiveText: asset.alt ?? '',
      settingsButtonSupportiveText: `Update ${assetType}`,
      settingsButtonOnClick: () => handleOpenSidebar(asset),
      title: asset.title ?? asset.fileName,
      isFavoriteVisible: true,
      onSelectClick: () =>
        !asset.isFavorite ? handleAddToFavoriteAssets(String(asset.assetId)) : handleRemoveFromFavoriteAssets(String(asset.assetId)),
    };
  };



  const handleSearchFilter = (val: string) => {
    executeSearchPaginatedAssets({
      filter: val,
      first: pageSize,
      projectId: String(projectId),
      tags: filterBarSelectedValues.map((item) => Number(item)),
      types: [`${assetType.toUpperCase() as DBAssetTypeType}`],
      favorite: isFavoriteAssets,
      sort: assetSort,
    });

    setFilter(val);
  };

  const handleSideBarClose = () => {
    setSelectedAsset(undefined);
    setIsSideBarOpen(false);
  };

  const handleOpenSidebar = (asset: IDBAsset) => {
    setIsSideBarOpen(true);
    setSelectedAsset(asset);
  };

  const handleToggleFavoriteAssets = () => {
    setIsFavoriteAssets(!isFavoriteAssets);
  };


  const handleUpdatedAsset = () => {
    executeSearchPaginatedAssets({
      first: pageSize,
      filter,
      projectId: String(projectId),
      tags: filterBarSelectedValues.map((item) => Number(item)),
      types: [`${assetType.toUpperCase() as DBAssetTypeType}`],
      favorite: isFavoriteAssets,
      sort: assetSort,
    });

    executeSearchPaginatedTags({
      projectId,
    });

    handleSideBarClose();

  };

  const handlePaginate = async () => {
    await handleFetchMorePaginatedAssets({
      after: lastCursor,
      first: pageSize,
      projectId: String(projectId),
      types: [`${assetType.toUpperCase() as DBAssetTypeType}`],
      sort: assetSort,
    });
  };


  const getAssetProps = (selectedAssetType: AssetType, asset: IDBAsset ) => {
    if (selectedAssetType === 'video') return {
      videoSrc: String(asset.presignedUrl),
      videoThumbnailSrc: "null",
    }

    if (selectedAssetType === 'audio') return {
      audioSrc: String(asset.presignedUrl)
    }

    if (selectedAssetType === 'document') return {
      contentType: asset.contentType,
    }

    return {
      image: {
        altText: String(asset.alt),
        src: String(asset.presignedUrl),
      }
    };
  };

   // bulk actions
   const handleOpenBulkActionDialog = (type: string) => {
    setBulkActionType(type);
    setIsBulkActionDialogOpen(true);
  };

  const handleCloseBulkActionDialog = () => {
    setBulkActionType('');
    setIsBulkActionDialogOpen(false);
  };

  const handleBulkActionSave = () => {
    handleCloseBulkActionDialog();
    executeSearchPaginatedAssets({
      first: pageSize,
      filter,
      projectId: String(projectId),
      tags: filterBarSelectedValues.map((item) => Number(item)),
      types: [`${assetType.toUpperCase() as DBAssetTypeType}`],
      favorite: isFavoriteAssets,
      sort: assetSort,
    });
  };

  const handleSelectedRows = (selectedIds: string[]) => {
    setSelectedRows(selectedIds);
  }


  useEffect(() => {
    if (!projectId) return;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!assetSort) return;
    setIsPreloaderLoading(true);
    executeSearchPaginatedAssets({
      first: pageSize,
      filter,
      projectId: String(projectId),
      tags: filterBarSelectedValues.map((item) => Number(item)),
      types: [`${assetType.toUpperCase() as DBAssetTypeType}`],
      favorite: isFavoriteAssets,
      sort: assetSort,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetSort, isFavoriteAssets, pageSize, projectId, assetType]);

  useEffect(() => {
    if (!project) return;

    setOrganisationKey(project.organisation.key);
    setProjectName(project.name);

    executeSearchPaginatedTags({
      projectId: project.id,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  return (
    <>
      <Spinner isLoading={isPreLoaderLoading || isGetPaginatedAssetsLoading}/>
      <CardListTemplatePage
        activePage="library"
        activeSubPage={assetType}
        additionalClassNames="c-card-list-page-template"
        additionalToolbarItems={<>
          <FilterBar
            clearText="Clear tag selection"
            isLoading={isLoading}
            items={filterBarItems}
            selectedValues={filterBarSelectedValues}
            onChange={handleChangeTagFilters}
            onClear={handleClearFilterBar}
            onClick={handleClickFilterBar}
          />
        </>}
        addNewOnClick={handleAddNewAssetClick}
        breadcrumbItems={[
          {
            key: '0',
            content: <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}`}><span>Dashboard</span></Link>,
          },
          {
            key: '1',
            content: <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}/library`}><span>Library</span></Link>,
          },
          {
            key: '2',
            content: `${capitalize(assetType)} library`,
          },
        ]}
        bulkActions={[
          { id: 0, label: 'Update tags', action: () => handleOpenBulkActionDialog('updateTags') },
          { id: 1, label: 'Delete', action: () => handleOpenBulkActionDialog('delete') },
          { id: 2, label: 'Toggle public', action: () => handleOpenBulkActionDialog('toggleIsPublic') },
        ]}
        cardListName="assets"
        childrenMiddle={
          <>
            <Select
              additionalClassNames="c-asset-sort-select"
              hasPadding={false}
              id={`${assetType}-sort`}
              isDisabled={false}
              labelText="Sort"
              name={`${assetType}Sort`}
              options={assetSortSelectOptions}
              value={assetSort}
              isLabelHidden
              onChange={handleChangeAssetSort}
            />
            <>
              <ToggleSwitch
                additionalClassNames={`c-asset-list-toggle`}
                id={`favorite-${assetType}s-toggle`}
                isChecked={isFavoriteAssets}
                labelText="Show favorites"
                isInline
                onChange={handleToggleFavoriteAssets}
              />
            </>
          </>
        }
        defaultView={allAssets.length >= 10 ? 'list': 'grid'}
        description={`Project dashboard for ${projectName} project`}
        handleOnPageSizeChange={handleChangePageSize}
        handleOnPaginate={handlePaginate}
        hasViewSwitch={assetType === 'image' || assetType === 'audio'}
        informationBoxMessage={<>Manage <strong>{projectName}</strong> project</>}
        isLoading={isLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        lists={[
          {
            isCentered: false,
            items: allAssets.map((asset) => {
              const { id, title } = asset;
              return {
                type: assetType,
                searchKey: title,
                itemProps: {
                  key: String(id),
                  ...getAssetProps(assetType, asset),
                  ...handleGetAssetComponentCommonProps(asset),
                },
              } as CardListPageItems;
            })
          }
        ]}
        listType={assetType}
        logoUrl={project?.logo?.presignedUrl}
        pageSize={pageSize}
        pageTitle={`${projectName ? projectName + ' dashboard' : 'Dashboard'}`}
        projectName={projectName}
        searchPlaceholder="Search name"
        totalItems={totalAssets}
        onSearch={handleSearchFilter}
        onSelectRows={handleSelectedRows}
      />

      <AssetListSidebarView
        asset={selectedAsset}
        assetType={assetType}
        handleSideBarClose={handleSideBarClose}
        isSideBarOpen={isSideBarOpen}
        organisationKey={organisationKey}
        projectId={String(projectId)}
        onCreate={handleCreateAsset}
        onDelete={handleDeletedAsset}
        onUpdate={handleUpdatedAsset}
      />
      {projectId && (
        <AssetBulkActionDialog
          bulkActionType={bulkActionType}
          handleCloseDialog={handleCloseBulkActionDialog}
          isOpen={isBulkActionDialogOpen}
          projectId={projectId}
          selectedAssets={selectedRows}
          onSave={handleBulkActionSave}
        />
      )}
    </>
  );
};

export { AssetList };
