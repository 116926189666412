import React, { useState, useEffect, useContext } from 'react';

import { IOption, PageContainer, Select } from '@netfront/ui-library';
import { getFlattenedReportOptions } from 'components';
import { CachingEntitiesContext, DashboardContext, ProjectSettingsContext } from 'context';
import { useToast } from 'hooks';
import { useRouter } from 'next/router';

import { ReportSelectorProps } from './ReportSelector.interfaces';


export const ReportSelector = ({ activePath }: ReportSelectorProps) => {
  const { push } = useRouter();
  const { handleToastError } = useToast();
  const { dashboardLink } = useContext(DashboardContext);
  const { project } = useContext(CachingEntitiesContext);
  const { projectSettings } = useContext(ProjectSettingsContext);
  const [baseUrl, setBaseUrl] = useState<string>('');
  const [currentOption, setCurrentOption] = useState<string>();
  const [options, setOptions] = useState<IOption[]>([]);

  const handleSelectReport = ({ target: { value }}: { target: { value: string }}) => {
    if (!(value && baseUrl)) return;
    push(`${baseUrl}/${value}`).catch((error) =>
      handleToastError({
        error,
      }),
    );
  };

  useEffect(() => {
    if (!project) {
      return;
    }

    if (!projectSettings) {
      return;
    }

    const { id, services } = project;

    setOptions(getFlattenedReportOptions({ services, projectId: id, projectSettings }));

    const [, reportType, report] = activePath;

    setCurrentOption(`${reportType}/${report}`);

  }, [activePath, project, projectSettings]);

  useEffect(() => {
    if (!dashboardLink) return;
    setBaseUrl(`${dashboardLink}/reporting`);
  }, [dashboardLink]);




  return (
    <div className="c-report-container">
      <PageContainer>
        <Select
          additionalClassNames="c-report-selector"
          hasPadding={false}
          id="id_report_selector"
          labelText="Switch reports"
          name="report_selector"
          options={options}
          value={currentOption}
          onChange={handleSelectReport}
        />
      </PageContainer>
    </div>
  );

};
