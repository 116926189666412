import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { GeneralTabIcon } from '@netfront/ui-library';
import { TestModeGeneralTab, TablePageTemplate } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetPaginatedTestUsers, useToast } from 'hooks';
import { IDBTestUser } from 'interfaces';
import last from 'lodash.last';
import { useRouter } from 'next/router';

import { TEST_USERS_TABLE_COLUMNS } from './TestModePage.constants';
import { getTestUsersTableData } from './TestModePage.helpers';
import { ITestUsersTableData } from './TestModePage.interfaces';


const TestModePage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError } = useToast();
  const defaultPageSize = 10;

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [allTestUsers, setAllTestUsers] = useState<IDBTestUser[]>();
  const [filter, setFilter] = useState<string>();
  const [selectedTestUser, setSelectedTestUser] = useState<IDBTestUser>();
  const [testUserTableData, setTestUserTableData] = useState<ITestUsersTableData[]>([]);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [totalSimulators, setTotalSimulators] = useState(0);

  const {
    handleFetchMorePaginatedTestUsers,
    handleGetPaginatedTestUsers,
    isLoading: isGetTestUsersLoading,
  } = useGetPaginatedTestUsers({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ testUsersConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const testUsers = edges.map(({ node }) => node);
      setAllTestUsers(testUsers);
      setIsLoadMoreDisabled(testUsers.length >= totalCount || totalCount <= pageSize);
      setTotalSimulators(totalCount);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleFilterSearch = (value: string) => {
    void handleGetPaginatedTestUsers({
      first: pageSize,
      projectId: String(projectId),
      filter: value,
    });

    setFilter(value);
  };

  const handleCloseSidebar = () => {
    setIsSideBarOpen(false);
    setSelectedTestUser(undefined);
  };

  const handleUpdate = () => {
    handleCloseSidebar();
    void handleGetPaginatedTestUsers({
      first: pageSize,
      projectId: String(projectId),
      filter,
    });
  };

  const handleOnPageSizeChange = (changedPageSize: number) => {
    setPageSize(Number(changedPageSize));
  };

  const handleSelectTestUser = (id?: number) => {
    setIsSideBarOpen(true);
    setSelectedTestUser(allTestUsers?.find(({ id: testUserId }) => id === testUserId));
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    void handleGetPaginatedTestUsers({
      first: pageSize,
      projectId: String(projectId),
      filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId]);

  useEffect(() => {
    if (!allTestUsers) {
      return;
    }

    setTestUserTableData(
      getTestUsersTableData({
        testUsers: allTestUsers,
        onSettingsButtonClick: (id) => {
          handleSelectTestUser(id)
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTestUsers]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  return (
    <TablePageTemplate<ITestUsersTableData>
      activePath={['notifications', 'scheduler', 'test-mode']}
      additionalBreadcrumbItems={[{
        key: '1',
        content: 'Notifications',
      }, {
        key: '2',
        content: 'Scheduler',
      }, {
        key: '3',
        content: 'Test mode',
      }]}
      columns={TEST_USERS_TABLE_COLUMNS}
      data={testUserTableData}
      defaultActiveTabId="id_general_tab"
      description={`Scenario test mode for ${String(projectName)}`}
      handleAddNewClick={handleSelectTestUser}
      handleOnPageSizeChange={handleOnPageSizeChange}
      handleOnPaginate={async () => {
        await handleFetchMorePaginatedTestUsers({
          after: lastCursor,
          first: pageSize,
          projectId: String(projectId),
          filter: filter,
        });
      }}
      handleSearch={handleFilterSearch}
      informationBoxMessage="If any running scenario has been set to Test mode, only users configured in the list below will receive the notification"
      isLoading={isGetTestUsersLoading}
      isPaginationDisabled={isLoadMoreDisabled}
      isSideBarOpen={isSideBarOpen}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      searchPlaceholder="Search"
      size={project?.logo?.presignedUrl ? 'small': 'medium'}
      tableType="test users"
      tabs={[
        {
          icon: GeneralTabIcon,
          id: 'id_general_tab',
          label: 'General',
          view: () => <TestModeGeneralTab isSidebarOpen={isSideBarOpen} projectId={projectId} selectedTestUser={selectedTestUser} onClose={handleCloseSidebar} onUpdate={handleUpdate} />,
        },
      ]}
      title={`${String(projectName)} Scenario test mode`}
      toggleIsSideBarOpen={handleCloseSidebar}
      totalItems={totalSimulators}
      hideSideBarButtons
    />
  );
};

export { TestModePage };
