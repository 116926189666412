import React, { ChangeEvent, useEffect, useState } from 'react';

import { FlexContainer, Input, InputFieldWrapper } from '@netfront/ui-library';
import { convertDaysAndHoursToSeconds, convertSecondsToDaysAndHours } from 'utils';

import { ConditionDelayInputProps } from './ConditionDelayInput.interfaces';

const ConditionDelayInput = ({
  id,
  initialValue = 0,
  labelText,
  maxNumber,
  minNumber,
  name,
  onChange,
  tooltipText = '',
}: ConditionDelayInputProps) => {
  const [hours, setHours] = useState<number>(0);
  const [days, setDays] = useState<number>(0);

  const handleDaysUpdate = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { target: { value } } = event;
    setDays(Number(value));

    onChange({name, value: convertDaysAndHoursToSeconds(Number(value), hours)});

  };

  const handleHoursUpdate = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { target: { value } } = event;
    setHours(Number(value));

    onChange({name, value: convertDaysAndHoursToSeconds(days, Number(value))});
  };

  useEffect(() => {
    const { days: initialDays, hours: initialHours} = convertSecondsToDaysAndHours(initialValue);

    setDays(initialDays);
    setHours(initialHours);
  }, [initialValue]);

  return (
    <InputFieldWrapper 
      id={`id_${id}_days_delay`}
      label={labelText} 
      labelType="span"
      tooltipText={tooltipText}
      isLabelSideBySide
    >
      <FlexContainer alignItems="center">
        <Input
          iconAfter={{
            isTextOnly: true,
            helperText: 'Days',
          }}
          id={`id_${id}_days_delay`}
          labelText="Days"
          maxNumber={maxNumber}
          minNumber={minNumber}
          name={`hours_${id}_delay`}
          type="number"
          value={days}
          isLabelHidden
          onChange={handleDaysUpdate}
        />

        <Input
          iconAfter={{
            isTextOnly: true,
            helperText: 'Hours',
          }}
          id={`id_${id}_hours_delay`}
          labelText="Hours"
          maxNumber={maxNumber}
          minNumber={minNumber}
          name={`hours_${id}_delay`}
          type="number"
          value={hours}
          isLabelHidden
          onChange={handleHoursUpdate}
        />

      </FlexContainer>
    </InputFieldWrapper>

  );
};

export { ConditionDelayInput };
