import { useState, useEffect, useContext } from 'react';

import { ApolloError } from '@apollo/client';
import { useDomain } from '@netfront/gelada-identity-library';
import {
  CardListPageItems,
  Spinner,
} from '@netfront/ui-library';
import { CardListTemplatePage , CharacterSidebarView } from 'components';
import { CachingEntitiesContext } from 'context';
import { ICharacter } from 'interfaces';
import last from 'lodash.last';
import Link from 'next/link';
import { useRouter } from 'next/router';



import { useSearchPaginatedCharacters, useToast } from '../../../hooks';

const CharacterPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { isDomainReady } = useDomain();
  const { handleToastError } = useToast();


  const [projectId, setProjectId] = useState<string>('');
  const [organisationKey, setOrganisationKey] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');

  const [allCharacters, setAllCharacters] = useState<ICharacter[]>([]);
  const [filter, setFilter] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalCharacters, setTotalCharacters] = useState<number>(0);
  const [selectedCharacter, setSelectedCharacter] = useState<ICharacter>();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);


  const {
    handleSearchPaginatedCharacters,
    handleFetchMorePaginatedCharacters,
    isLoading: isSearchPaginatedCharactersLoading = false
  } = useSearchPaginatedCharacters({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ characterConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);


      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const characters = edges.map(({ node }) => node);
      setAllCharacters(characters);
      setIsLoadMoreDisabled(characters.length >= totalCount || totalCount <= pageSize);
      setTotalCharacters(totalCount);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };

  const handlePaginate = async () => {
    await handleFetchMorePaginatedCharacters({
      after: lastCursor,
      first: pageSize,
      projectId: String(projectId),
      filter,
    });
  };

  const handleSearchFilter = (val: string) => {
    handleSearchPaginatedCharacters({
      filter: val,
      first: pageSize,
      projectId: String(projectId),
    });

    setFilter(val);
  };

  const handleUpdate = () => {
    handleCloseSidebar();
    handleSearchPaginatedCharacters({
      filter,
      first: pageSize,
      projectId: String(projectId),
    });
  }

  const handleSelectItem = (character?: ICharacter) => {
    setSelectedCharacter(character);
    setIsSidebarOpen(true);
  };

  const handleAddNewCharacter = () => {
    setSelectedCharacter(undefined);
    setIsSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setSelectedCharacter(undefined);
    setIsSidebarOpen(false);
  }

  useEffect(() => {
    if (!projectId) return;
    handleSearchPaginatedCharacters({
      first: pageSize,
      filter,
      projectId: String(projectId),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, pageSize]);

  useEffect(() => {
    if (!(project && isDomainReady)) return;

    setOrganisationKey(project.organisation.key);
    setProjectName(project.name);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, isDomainReady]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isSearchPaginatedCharactersLoading;

  return (
    <>
      <Spinner isLoading={isLoading}/>
      <CardListTemplatePage
        activePage="library"
        activeSubPage="character"
        additionalClassNames="c-card-list-page-template"
        addNewOnClick={handleAddNewCharacter}
        breadcrumbItems={[
          {
            key: '0',
            content: <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}`}><span>Dashboard</span></Link>,
          },
          {
            key: '1',
            content: <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}/library`}><span>Library</span></Link>,
          },
          {
            key: '2',
            content: 'Character',
          },
        ]}
        defaultView={allCharacters.length >= 10 ? 'list': 'grid'}
        description={`Project dashboard for ${projectName} project`}
        handleOnPageSizeChange={handleChangePageSize}
        handleOnPaginate={handlePaginate}
        informationBoxMessage={<>Manage <strong>{projectName}</strong> project</>}
        isLoading={isLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        lists={[
          {
            isCentered: false,
            items: allCharacters.map((item) => {
              const { id, name, image } = item;
              const { presignedUrl = '', alt = '' } = image ?? {};
              return {
                type: 'image',
                searchKey: name,
                itemProps: {
                  key: String(id),
                  id,
                  image: {
                    altText: alt,
                    src: presignedUrl,
                  },
                  imageSize: 'SMALL',
                  supportiveText: alt,
                  settingsButtonSupportiveText: `Update character`,
                  settingsButtonOnClick: () => handleSelectItem(item),
                  title: name,
                  isFavoriteVisible: false,
                },
              } as CardListPageItems;
            })
          }
        ]}
        listType="character"
        logoUrl={project?.logo?.presignedUrl}
        pageSize={pageSize}
        pageTitle={`${projectName ? projectName + ' dashboard' : 'Dashboard'}`}
        projectName={projectName}
        totalItems={totalCharacters}
        onSearch={handleSearchFilter}
      />
      <CharacterSidebarView
        handleSideBarClose={handleCloseSidebar}
        isSideBarOpen={isSidebarOpen}
        projectId={String(projectId)}
        selectedCharacter={selectedCharacter}
        onUpdate={handleUpdate}
      />
    </>
  );
};

export { CharacterPage };
