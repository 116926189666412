import React, { useEffect, useState } from 'react';

import { Spacing, Table, ButtonIconOnly, FlexContainer, InputFieldWrapper, BinIcon, CaretDownIcon, PlusIcon, Pill } from '@netfront/ui-library';
import { generateRandomId } from 'utils';

import { ConditionsOverviewProps } from '../ConditionTemplateSelector/ConditionsOverview.interfaces';
import { IActionCondition } from '../UpsertAction/UpsertAction.interfaces';


const ConditionsOverview = ({ onEditClick, onDeleteClick, conditionItems }: ConditionsOverviewProps) => {
  const [items, setItems] = useState<IActionCondition[]>([]);

  useEffect(() => {
    setItems(conditionItems ?? []);
  }, [conditionItems]);
  return (
    <>
      {items.length > 0 && (
        <Spacing>
          <Table
            additionalClassNames="c-condition-table"
            columns={[
              {
                accessor: 'title',
                Cell: ({ value }: { value: string }) => <span>{value === 'AND' ? <Pill value="AND" variant="green"/>: value }</span>,
                Header: () => <div>Conditions</div>,
                width: '95%',
              },
              {
                accessor: 'settingsButtonData',
                width: '5%',
                Cell: ({
                  value: {
                    handleEdit,
                    handleDelete,
                    tempId,
                    isHidden = false,
                  } }: {
                  value : {
                    handleDelete: (tempId: string) => void;
                    handleEdit: (tempId: string) => void;
                    isHidden?: boolean;
                    tempId: string
                  }
                }) => (
                  <>
                  {!isHidden && (
                    <FlexContainer gap="2x-small" justifyContent="end">
                      <ButtonIconOnly
                        additionalClassNames=""
                        icon={BinIcon}
                        isIconBorderVisible={false}
                        text="Delete condition"
                        onClick={() => handleDelete(tempId)}
                        />
                      <ButtonIconOnly
                        additionalClassNames="h-rotate-right"
                        icon={CaretDownIcon}
                        isIconBorderVisible={false}
                        text="Edit condition"
                        onClick={() => handleEdit(tempId)}
                        />
                    </FlexContainer>
                  )}
                  </>
                ),
              },
            ]}
            data={items.flatMap((item, index) => {
              const row = {
                id: item.tempId,
                title: item.title,
                settingsButtonData: {
                  tempId: item.tempId,
                  handleEdit: onEditClick,
                  handleDelete: onDeleteClick,
                },
              };

              const id = generateRandomId();
          
              const orRow = {
                title: 'AND',
                id,
                settingsButtonData: {
                  tempId: item.tempId,
                  handleEdit: onEditClick,
                  handleDelete: onDeleteClick,
                  isHidden: true
                },
              };
          
              return index < items.length - 1 ? [row, orRow] : [row];
            })}
          />
        </Spacing>
      )}

      <Spacing>
        <InputFieldWrapper
          id="id_add_condition"
          label="Add condition"
          labelType="span"
          type="custom"
          isLabelSideBySide
        >
          <FlexContainer justifyContent="flex-end" isFullWidth>
            <ButtonIconOnly icon={PlusIcon} text="Add condition" onClick={() => onEditClick(undefined)} />
          </FlexContainer>
        </InputFieldWrapper>
      </Spacing>

    </>
  );
};

export { ConditionsOverview };
