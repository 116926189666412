import React from 'react';

import { Select, Spacing } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { IMarkableQuestion } from 'interfaces';
import { getQuestionText } from 'utils';

import { FormsCorrectAnswersTabProps } from './FormsCorrectAnswersTab.interfaces';



const FormsCorrectAnswersTab = ({
  markableQuestions = [],
  getValues,
  setValue,
}: FormsCorrectAnswersTabProps) => {


  const handleUpdateCorrectResponse = ({ id, value }: { id: number; value: number; }) => {
    const { markableQuestions: currentMarkableQuestions } = getValues();

    const updatedQuestions = (currentMarkableQuestions as IMarkableQuestion[]).map((question) => {
      const {id: questionId } = question;
      if (id === questionId) {
        return {
          ...question,
          correctResponseId: value,
        }
      }

      return question;
    });

    setValue('markableQuestions', updatedQuestions);
  };


  return (
    <SidebarContainer hasPaddingEnds>
      {markableQuestions.map((question) => {
        const {
          id,
          question: questionText = '', 
          correctResponseId, 
          configuration
        } = question;

        const { responseSet } = configuration ?? {};

        const { availableResponses = [] } = responseSet ?? {};

        const options = availableResponses.map(({ id: responseId, label, value }) => ({
          id: responseId,
          value: id,
          name: `${String(label)} (value: ${String(value)})`
        }));
        
        return (
          <Spacing key={id}>
            <Select
              id={`id_correct_response_${id}`}
              labelText={getQuestionText(String(questionText), 'text/html') ?? `Question-${id}`}
              name={`correct_response_${id}`}
              options={options}
              value={correctResponseId}
              onChange={({ target: { value }}) => handleUpdateCorrectResponse({ value: Number(value), id })}
            />
        </Spacing>
      );


    })}
    </SidebarContainer>
  );
};

export { FormsCorrectAnswersTab };
