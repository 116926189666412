/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */

// Function to match balanced braces
const matchKeyframes = (styleSheet: string) => {
  const keyframesRegex = /@keyframes\s+[a-zA-Z0-9_-]+\s*\{/g;
  let match;
  const results = [];
  while ((match = keyframesRegex.exec(styleSheet)) !== null) {
    const startIndex = match.index;
    let openBraces = 1;
    let endIndex = keyframesRegex.lastIndex;
    
    while (openBraces > 0 && endIndex < styleSheet.length) {
      const char = styleSheet[endIndex++];
      if (char === '{') openBraces++;
      if (char === '}') openBraces--;
    }
    results.push(styleSheet.slice(startIndex, endIndex));
  }
  return results.join('\n');
};

const scopeStylesToPreview = (css: string) => {
  // Extract declarations from :root, html, body, and * using regular expressions
  const rootRegex = /:root\s*{([^}]*)}/;
  const htmlRegex = /html\s*{([^}]*)}/;
  const bodyRegex = /body\s*{([^}]*)}/;

  // Extract the content for each section
  const rootContent = (rootRegex.exec(css) || [])[1] || '';
  const htmlContent = (htmlRegex.exec(css) || [])[1] || '';
  const bodyContent = (bodyRegex.exec(css) || [])[1] || '';

  const keyframesContent = matchKeyframes(css);  

  // Collate all extracted styles into the scoped class
  const scopedStyles = `
    ${keyframesContent}
    .scope-content-preview {

      ${rootContent}

      ${htmlContent}

      ${bodyContent}

      ${css}

    }
  `;

  const cleanedCSS = scopedStyles.replace(rootRegex, '');
  return cleanedCSS.trim();
};

export { scopeStylesToPreview };
