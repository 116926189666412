import React from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ToggleSwitch, Spacing, Input, Select } from '@netfront/ui-library';
import { ContentBuilderTabWrapper } from 'components';
import { Controller } from 'react-hook-form';

import { UpsertSliderSnippetGeneralTabProps } from './UpsertSliderSnippetGeneralTab.interfaces';


const UpsertSliderSnippetGeneralTab = ({ control, sliderOptions = [] }: UpsertSliderSnippetGeneralTabProps) => {

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <ContentBuilderTabWrapper>
      <Spacing>
        <Controller
          control={control}
          name="title"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_slider_snippet_title"
              labelText="Title"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />

      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="sliderId"
          render={({ field, fieldState }) => (
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_slider_template"
              labelText="Slider"
              options={sliderOptions}
              isLabelSideBySide
              {...field}
              isRequired
            />
          )}
        />
      </Spacing>
      
      <Spacing>
        <Controller
          control={control}
          name="isSensitive"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_sensitive_content"
              isChecked={field.value}
              labelText="Sensitive"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isVisible"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              id="id_is_visible"
              isChecked={field.value}
              labelText="Is visible"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
    </ContentBuilderTabWrapper>
  );
};

export { UpsertSliderSnippetGeneralTab };
