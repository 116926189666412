import { gql } from '@apollo/client';

const ADD_CONDITION_MUTATION = gql`
  mutation AddCondition(
    $stepCompleted: AddConditionStepCompletedInputGraphType
    $stepStarted: AddConditionStepStartedInputGraphType
    $userActivated: AddConditionUserActivatedInputGraphType
    $userLogin: AddConditionUserLoginInputGraphType
    $userRegistered: AddConditionUserRegisteredInputGraphType
    $userLastLogin: AddConditionUserLastLoginInputGraphType
    $day: AddConditionDayInputGraphType
    $hour: AddConditionHourInputGraphType
    $userSession: AddConditionSessionInputGraphType
    $stepProgression: AddConditionStepProgressionInputGraphType
    $manyUserGroup: AddConditionManyUserGroupInputGraphType
  ) {
    conditions {
      add(
        stepCompleted: $stepCompleted
        stepStarted: $stepStarted
        userActivated: $userActivated
        userLogin: $userLogin
        userRegistered: $userRegistered
        userLastLogin: $userLastLogin
        day: $day
        hour: $hour
        userSession: $userSession
        stepProgression: $stepProgression
        manyUserGroup: $manyUserGroup
      ) {
        createdAt
        delay
        description
        id
        title
        updatedAt
        ... on ConditionDayGraphType {
          day
        }
        ... on ConditionHourGraphType {
          value
        }
        ... on ConditionSessionGraphType {
          comparator
        }
        ... on ConditionStepStartedGraphType {
          comparator
          stepId
        }
        ... on ConditionStepCompletedGraphType {
          comparator
          stepId
        }
        ... on ConditionUserActivatedGraphType {
          comparator
        }
        ... on ConditionUserLoginGraphType {
          comparator
        }
        ... on ConditionStepProgressionGraphType {
          comparator
          stepId
          percentage
          valueComparator
        }
        ... on ConditionManyUserGroupGraphType {
          groupIds
        }
      }
    }
  }
`;

export { ADD_CONDITION_MUTATION };
