import { ApolloQueryResult } from '@apollo/client';
import { useBonoboLazyQuery } from 'hooks';

import { GET_PROJECT_EVENTS_QUERY } from './useGetEventsByProject.graphql';
import {
  IGetPaginatedProjectEventsQueryGraphQLResponse,
  IGetPaginatedProjectEventsQueryVariables,
  IHandleFetchMorePaginatedProjectEventsParams,
  IHandleGetPaginatedProjectEventsParams,
  IUseGetPaginatedProjectEvents,
  IUseGetPaginatedProjectEventsOptions,
} from './useGetEventsByProject.interfaces';

const useGetPaginatedProjectEvents = (options?: IUseGetPaginatedProjectEventsOptions): IUseGetPaginatedProjectEvents => {
  const {
    fetchPolicy,
    onCompleted,
    onError,
    query,
    token,
    projectId: bonoboProjectId,
  } = options ?? ({} as IUseGetPaginatedProjectEventsOptions);

  const [executeGetPaginatedProjectEvents, { fetchMore, loading: isLoading }] = useBonoboLazyQuery<
  IGetPaginatedProjectEventsQueryGraphQLResponse,
  IGetPaginatedProjectEventsQueryVariables
  >({
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          community: { byProject: eventConnection },
        } = data;

        onCompleted({
          eventConnection,
        });
      },
      onError,
    },
    query: query ?? GET_PROJECT_EVENTS_QUERY,
    token,
  });

  const handleFetchMorePaginatedProjectEvents = ({
    after,
    first,
    filter,
    projectId,
  }: IHandleFetchMorePaginatedProjectEventsParams): Promise<
  ApolloQueryResult<IGetPaginatedProjectEventsQueryGraphQLResponse>
  > => {
    return fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          community: { byProject: newlyFetchedEventConnection },
        } = fetchMoreResult;

        const { edges: newlyFetchedEdges } = newlyFetchedEventConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          community: { byProject: previouslyFetchedEventConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedEventConnection;

        return {
          ...previousQueryResult,
          community: {
            ...previouslyFetchedEventConnection,
            byProject: {
              ...newlyFetchedEventConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        filter,
        projectId,
      },
    });
  };

  const handleGetPaginatedProjectEvents = ({ 
    after, 
    first, 
    filter, 
    projectId, 
  }: IHandleGetPaginatedProjectEventsParams) => {
    void executeGetPaginatedProjectEvents({
      variables: {
        after,
        first,
        filter,
        projectId,
      },
    });
  };

  return {
    handleFetchMorePaginatedProjectEvents,
    handleGetPaginatedProjectEvents,
    isLoading,
  };
};

export { useGetPaginatedProjectEvents };
