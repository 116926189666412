import { ReactNode, useContext, useEffect, useState } from 'react';

import { CardListPageItems, CardListPageTemplate as NetfrontCardListPageTemplate } from '@netfront/ui-library';
import Link from 'next/link';
import { useAppHeaderHeight } from 'utils';

import { DashboardContext, IDashboardItem, IDashboardItemsList, formatDashboardItems } from 'context/DashboardContext';

import { DashboardListTemplatePageProps } from './DashboardListTemplatePage.types';

import { PageLayout } from '../../PageLayout';

const DashboardListTemplatePage = ({
  additionalClassNames = '',
  breadcrumbItems,
  description,
  informationBoxMessage,
  isLoading = false,
  pageId = 'project',
  logoUrl,
  pageTitle,
  projectName,
  children,
  type = 'nav',
  activePage,
  activeSubPage,
  activePath,
  listType
}: DashboardListTemplatePageProps) => {

  const { dashboardItems, getDashboardItemsById, dashboardLink, isLoading: isDashboardLoading = false } = useContext(DashboardContext);
  const [cardItems, setCardItems] = useState<IDashboardItemsList[] | IDashboardItem[]>([]);
  const headerHeight = useAppHeaderHeight();

  useEffect(() => {
    setCardItems(getDashboardItemsById(pageId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardItems]);
  return (
    <PageLayout
      additionalClassNames={`c-project-dashboard__page-layout ${additionalClassNames}`}
      meta={{ description: description }}
      title={pageTitle}
      hasPrivateLayout
    >
      {projectName && (
        <>
          {children ? children : <NetfrontCardListPageTemplate
            activePage={activePage}
            activePath={activePath}
            activeSubPage={activeSubPage}
            avatarTitle={projectName}
            breadcrumbItems={breadcrumbItems}
            dashboardLink={dashboardLink}
            emptyStateImageUrl="/images/empty-state.webp"
            hasLeftSidebar={true}
            hasToolbar={false}
            informationBoxMessage={informationBoxMessage}
            isLoading={isLoading || isDashboardLoading}
            lists={[
              {
                isCentered: false,
                items: cardItems.map((cardItem) => {
                  const { id, description: cardDescription, href, image, title } = cardItem;
                  return {
                    type: type,
                    searchKey: title,
                    itemProps: {
                      key: String(id),
                      title: title,
                      logoUrl: image,
                      linkUrl: href,
                      wrapperComponent: ({ children: wrapperChildren }: { children: ReactNode }) => <Link className="c-cover-link" href={href}>{wrapperChildren} </Link>,
                      supportiveText: cardDescription,
                      hasFooter: type !== 'nav',
                    },
                  } as CardListPageItems;
                }),
              },
            ]}
            listType={listType}
            logoUrl={logoUrl}
            navigationItems={formatDashboardItems(dashboardItems)}
            offsetPositioning={headerHeight}
            pageTitle={pageTitle}
            size={logoUrl ? 'small': 'medium'}
          />}

        </>
      )}
    </PageLayout>
  );
};

export { DashboardListTemplatePage };
