import React from 'react';

import { Input, Spacing } from '@netfront/ui-library';
import { Controller } from 'react-hook-form';

import { ConditionFormHourTypeViewProps } from './ConditionFormHourTypeView.interfaces';

import { conditionFormViewConstants } from '../../ConditionFormView.constants';

const ConditionFormHourTypeView = ({ control }: ConditionFormHourTypeViewProps) => {

  const { hourToSeconds, minutesToSeconds } = conditionFormViewConstants;

  const handleCalculateSecondsToHourAndMinutes = (seconds: number) => {
    const hours = Math.floor(seconds / hourToSeconds);
    const minutes = Math.floor((seconds - hours * hourToSeconds) / minutesToSeconds);

    const hourValue = hours < 10 ? `0${hours}` : hours;
    const minuteValue = minutes < 10 ? `0${minutes}` : minutes;

    return `${hourValue}:${minuteValue}`;
  };

  const convertHoursAndMinutesToSeconds = (value: string) => {
    const times = value.split(':');
    const hours = Number(times[0]);
    const minutes = Number(times[1]);

    const seconds = hours * hourToSeconds + minutes * minutesToSeconds;
    return seconds;
  };


  return (
    <Spacing size="large">
      <Controller
        control={control}
        name="value"
        render={({ field }) => (
          <Input
            iconAfter={{
              isTextOnly: true,
              helperText: 'Hours'
            }}
            id="id_hour"
            labelText="Condition hour"
            tooltipText="Condition hour"
            type="time"
            isLabelSideBySide
            {...field}
            value={handleCalculateSecondsToHourAndMinutes(Number(field.value))}
            onChange={({ target: { value}}) => {
              field.onChange(convertHoursAndMinutesToSeconds(value))
            }}

          />
        )}
      />
    </Spacing>
  );
};

export { ConditionFormHourTypeView };
