import React from 'react';

import { SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { IEventsTableData } from './EventsPage.interfaces';

const EVENTS_PAGE_SIZE = 10;

const EVENTS_TABLE_COLUMNS: ReadonlyArray<Column<IEventsTableData>> = [
  {
    accessor: 'title',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Community name</div>,
    width: '20%',
  },
  {
    accessor: 'description',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Description</div>,
    width: '40%'
  },
  {
    accessor: 'start',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Start</div>,
    width: '10%'
  },
  {
    accessor: 'end',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>End</div>,
    width: '10%'
  },
  {
    accessor: 'privacy',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Privacy</div>,
    width: '8%'
  },
  {
    accessor: 'status',
    Cell: ({ value }) => <span>{value}</span>,
    Header: () => <div>Status</div>,
    width: '10%'
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick } }) => <SettingsButton supportiveText="Update community" onClick={() => onClick(id)} />,
    width: '2%',
  },
];

export { EVENTS_TABLE_COLUMNS, EVENTS_PAGE_SIZE };
