import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import axios, { AxiosResponse } from 'axios';
import { ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { addMonths } from 'date-fns';
import { useGetLogAccessReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { LOG_ACCESS_COLUMNS } from './LogAccessPage.constants';
import { getLogAccessTableData } from './LogAccessPage.helpers';
import { LogAccessCsvRowData, ILogAccessItem, ILogAccessTableData } from './LogAccessPage.interfaces';



const LogAccessPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [logAccessItems, setLogAccessItems] = useState<ILogAccessItem[]>();
  const [allLogAccessItems, setAllLogAccessItems] = useState<ILogAccessItem[]>([]);
  const [LogAccessTableData, setLogAccessTableData] = useState<ILogAccessTableData[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>([addMonths(new Date(), -1), new Date()]);
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);


  const {
    handleGetLogAccessReport,
    isLoading: isGetLogAccessLoading = false,
  } = useGetLogAccessReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;
      setCsvUrl(presignedUrl);
      axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {
          const { data } = Papa.parse<LogAccessCsvRowData>(String(res.data), {
            header: true,
            skipEmptyLines: true,
          });

          const formattedItems = data.map((row: LogAccessCsvRowData) => ({
            id: Number(row['Id']),
            firstName: String(row['First name']),
            lastName: String(row['Last name']),
            date: String(row['Date']),
          }));
          
          setTotalCount(data.length);
          setAllLogAccessItems(formattedItems);
          const paginatedItems = formattedItems.slice(0, pageSize);
          setLogAccessItems(paginatedItems);
          setLastItemIndex(pageSize);
        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId: String(projectId),
  });

  const generateReport = () => {
    if (!dateRange[0] || !dateRange[1]) return
    void handleGetLogAccessReport({
      dateFrom: dateRange[0],
      dateTo: dateRange[1],
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setLogAccessItems(allLogAccessItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setLogAccessItems(allLogAccessItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!logAccessItems) {
      return;
    }

    setLogAccessTableData(
      getLogAccessTableData({
        items: logAccessItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logAccessItems]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetLogAccessLoading;

  return (
    <ReportTable<ILogAccessTableData>
      activePath={['reporting', 'reporting-user-entry', 'export-triplee-log-access']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'Log access',
      }]}
      columns={LOG_ACCESS_COLUMNS}
      csvUrl={csvUrl}
      data={LogAccessTableData}
      dateRange={dateRange}
      description={`Log access reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports user log access based on the selected filters'}
      isGenerateButtonDisabled={!dateRange[0] || !dateRange[1]}
      isLoading={isLoading}
      isLoadMoreDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      totalCount={totalCount}
      onDateSelect={(dates: Date[]) => setDateRange(dates)}
      onGenerate={generateReport}
    />
  );
};

export { LogAccessPage };
