import { useState, useEffect, useContext } from 'react';

import { AssetType } from '@netfront/ui-library';
import { ProjectSettingsContext } from 'context';
import { useToast } from 'hooks';
import { useRouter } from 'next/router';

import { AssetList } from './AssetList';


const AssetLibraryPage = () => {

  const { query, push } = useRouter();
  const { type } = query;
  const { handleToastError } = useToast();
  const { projectSettings } = useContext(ProjectSettingsContext);

  const [assetType, setAssetType] = useState<string>('');

  useEffect(() => {
    if (!(assetType && projectSettings)) return;

    if (
      (assetType === 'document' && !(projectSettings['HAS_DOCUMENT_LIBRARY'] as boolean))
      || (assetType === 'video' && !(projectSettings['HAS_VIDEO_LIBRARY'] as boolean))
      || (assetType === 'audio' && !(projectSettings['HAS_AUDIO_LIBRARY'] as boolean))
      || (assetType === 'image' && !(projectSettings['HAS_IMAGE_LIBRARY'] as boolean))
     ) {
      push('/404').catch((error) =>
        handleToastError({
          error,
        }),
      );
    }
    

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSettings, assetType]);

  

  useEffect(() => {
    if (!type) {
      return;
    }

    setAssetType(String(type).substring(0, type.length - 1));
  }, [type]);

  return (
    assetType ? <AssetList assetType={assetType as AssetType} /> : <></>

  );
};

export { AssetLibraryPage };
