import format from 'date-fns/format';
import { orderBy } from 'lodash';

import { IGetInvitationRemindersTableDataParams, IInvitationRemindersTableData } from './InvitationsScenariosPage.interfaces';

const getInvitationRemindersTableData = ({ invitationReminders, onSettingsButtonClick }: IGetInvitationRemindersTableDataParams): IInvitationRemindersTableData[] => {
  return orderBy(invitationReminders, ['title'], ['desc']).map(
    ({ end, description, id, title, start, delay }): IInvitationRemindersTableData => {
      return {
        description,
        end: end ? format(new Date(end), 'dd/MM/yyyy'): '',
        start: start ? format(new Date(start), 'dd/MM/yyyy'): '',
        id,
        delay,
        settingsButtonData: {
          id,
          onClick: onSettingsButtonClick,
        },
        title,
      };
    },
  );
};

export { getInvitationRemindersTableData };

