import React from 'react';

import { RadioButtonGroup } from '@netfront/ui-library';
import { Controller } from 'react-hook-form';

import { ConditionRadioQuestionProps } from './ConditionRadioQuestion.interfaces';

const ConditionRadioQuestion = ({ control, questionResponses }: ConditionRadioQuestionProps) => (
  <Controller
    control={control}
    name="questionResponseRadioId"
    render={({ field }) => (
      <RadioButtonGroup
        items={questionResponses}
        selectedValue={field.value}
        title="Question response"
        tooltipPosition="start"
        tooltipText="This action will execute if the checkbox selection is as selected below"
        isLabelSideBySide
        {...field}
      />
    )}
  />
);


export { ConditionRadioQuestion };
