import { readTextFile } from "./readTextFile";

export const handleGetFileText = async (presignedUrl?: string): Promise<string> => {
  if (!presignedUrl) return '';
  
  try {
    const fileText = await readTextFile(String(presignedUrl));
    return fileText;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return '';
  }
};