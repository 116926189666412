
import { ApolloError } from '@apollo/client';
import { useCreateInvitationReminder, useToast, useUpdateInvitationReminder } from 'hooks';
import { IDBInvitationReminder } from 'interfaces';



const useUpsertInvitationReminder = ({ onCompleted }: { onCompleted: ({ invitationReminder } : { invitationReminder: IDBInvitationReminder; } ) => void }) => {

  const { handleToastError } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };


  const { handleUpdateInvitationReminder, isLoading: isUpdateInvitationReminderLoading = false } = useUpdateInvitationReminder({
    onCompleted,
    onError: handleGetError,
  });

  const { handleCreateInvitationReminder, isLoading: isCreateInvitationReminderLoading = false } = useCreateInvitationReminder({
    onCompleted,
    onError: handleGetError,
  });

  const handleUpsertInvitationReminder = ({
    invitationReminderId,
    projectId,
    description,
    title,
    start,
    end,
    delay,
    templateId,
    groupId,
  }: {
    delay?: IDBInvitationReminder['delay'];
    description: IDBInvitationReminder['description'];
    end?: Date;
    groupId?: IDBInvitationReminder['groupId'];
    invitationReminderId?: IDBInvitationReminder['delay'];
    projectId: string;
    start?: Date;
    templateId: IDBInvitationReminder['templateId'];
    title: IDBInvitationReminder['title'];

  }) => {
    if (!invitationReminderId) {
      handleCreateInvitationReminder({
        projectId,
        description,
        title,
        start,
        end,
        delay,
        templateId,
        groupId,
      });
    } else {

      handleUpdateInvitationReminder({
        id: invitationReminderId,
        description,
        title,
        start,
        end,
        delay,
        templateId,
        groupId,
      });
    }
  };

  return {
    isLoading: isCreateInvitationReminderLoading || isUpdateInvitationReminderLoading,
    handleUpsertInvitationReminder,
  };
};
export { useUpsertInvitationReminder };
