
import { useCapuchinLazyQuery } from 'hooks';

import { GET_CURRENT_TEMPLATES_QUERY } from './useGetCurrentTemplates.graphql';
import {
  IGetCurrentTemplatesQueryGraphQLResponse,
  IGetCurrentTemplatesQueryVariables,
  IHandleGetCurrentTemplatesParams,
  IUseGetCurrentTemplates,
  IUseGetCurrentTemplatesOptions,
} from './useGetCurrentTemplates.interfaces';

const useGetCurrentTemplates = (options?: IUseGetCurrentTemplatesOptions): IUseGetCurrentTemplates => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetCurrentTemplatesOptions);

  const [executeGetCurrentTemplates, { loading: isLoading }] = useCapuchinLazyQuery<
  IGetCurrentTemplatesQueryGraphQLResponse,
  IGetCurrentTemplatesQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          scenarioQueries: { getTemplates: templateConnection },
        } = data;

        onCompleted({
          templateConnection,
        });
      },
      onError,
    },
    query: query ?? GET_CURRENT_TEMPLATES_QUERY,
    token,
  });

  const handleGetCurrentTemplates = ({ scenarioId }: IHandleGetCurrentTemplatesParams) => {
    void executeGetCurrentTemplates({
      variables: {
        scenarioId,
      },
    });
  };

  return {
    handleGetCurrentTemplates,
    isLoading,
  };
};

export { useGetCurrentTemplates };
