import { useEkardoMutation } from '@netfront/ekardo-content-library';

import {
  UNSET_CHECKBOX_CORRECT_RESPONSE_MUTATION,
  UNSET_RADIO_CORRECT_RESPONSE_MUTATION,
  UNSET_DROPDOWN_LIST_CORRECT_RESPONSE_MUTATION,
} from './useUnsetCorrectResponse.graphql';
import { IUnsetCorrectResponseMutationGraphQLResponse, IUnsetCorrectResponseMutationVariables, IHandleUnsetCorrectResponseParams, IUseUnsetCorrectResponse, IUseUnsetCorrectResponseOptions } from './useUnsetCorrectResponse.interfaces';

const useUnsetCorrectResponse = (options?: IUseUnsetCorrectResponseOptions): IUseUnsetCorrectResponse => {
  const { mutation, onCompleted, onError, token, questionType } = options ?? ({} as IUseUnsetCorrectResponseOptions);

  const setCorrectResponseMutationQueryMap = {
    Checkbox: UNSET_CHECKBOX_CORRECT_RESPONSE_MUTATION,
    Radio: UNSET_RADIO_CORRECT_RESPONSE_MUTATION,
    DropDownList: UNSET_DROPDOWN_LIST_CORRECT_RESPONSE_MUTATION,
  };

  const [executeUnsetCorrectResponse, { loading: isLoading }] = useEkardoMutation<IUnsetCorrectResponseMutationGraphQLResponse, IUnsetCorrectResponseMutationVariables>({
    mutation: mutation ?? setCorrectResponseMutationQueryMap[questionType],
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentGroup: { unsetCorrectResponse: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleUnsetCorrectResponse = ({ 
    contentGroupId,
    contentSnippetId,
    questionResponseId,
  }: IHandleUnsetCorrectResponseParams) => {
    void executeUnsetCorrectResponse({
      variables: {
        contentGroupId,
        contentSnippetId,
        questionResponseId,
      },
    });
  };

  return {
    handleUnsetCorrectResponse,
    isLoading,
  };
};

export { useUnsetCorrectResponse };
