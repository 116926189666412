import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, ExcelIcon } from '@netfront/ui-library';
import axios, { AxiosResponse } from 'axios';
import { ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { addMonths } from 'date-fns';
import { useGetUserSessionsReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { USER_SESSION_COLUMNS } from './SessionsReportPage.constants';
import { getUserSessionsTableData } from './SessionsReportPage.helpers';
import { UserSessionsCsvRowData, IUserSessionsItem, IUserSessionsTableData } from './SessionsReportPage.interfaces';



const SessionsReportPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [userSessionsItems, setUserSessionsItems] = useState<IUserSessionsItem[]>();
  const [allUserSessionsItems, setAllUserSessionsItems] = useState<IUserSessionsItem[]>([]);
  const [userSessionsTableData, setUserSessionsTableData] = useState<IUserSessionsTableData[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>([addMonths(new Date(), -1), new Date()]);
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isGenerate, setIsGenerate] = useState<boolean>(false);

  const {
    handleGetUserSessionsReport,
    isLoading: isGetUserSessionsLoading = false,
  } = useGetUserSessionsReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;

      if (isGenerate) {
        setIsGenerate(false);      
        setCsvUrl(presignedUrl);
        axios
          .get(presignedUrl)
          .then((res: AxiosResponse) => {

            const { data } = Papa.parse<UserSessionsCsvRowData>(String(res.data), {
              header: true,
              skipEmptyLines: true,
            });

            const formattedItems = data.map((row: UserSessionsCsvRowData) => ({
              id: Number(row['User id']),
              startSessionUtc: String(row['Start session (utc)']),
              startSession: String(row['Start session']),
              endSessionUtc: String(row['End session (utc)']),
              endSession: String(row['End session']),
            }));
            
            setTotalCount(data.length);
            setAllUserSessionsItems(formattedItems);
            const paginatedItems = formattedItems.slice(0, pageSize);
            setUserSessionsItems(paginatedItems);
            setLastItemIndex(pageSize);

          })
          .catch((error) => {
            handleToastCustomError({
              message: 'whoops! something went wrong',
            });
            // eslint-disable-next-line no-console
            console.log({ error });
          });
      } else {
        window.open(presignedUrl, '_blank');
      }
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const generateReport = () => {
    setIsGenerate(true);
    void handleGetUserSessionsReport({
      dateFrom: dateRange[0] ? dateRange[0] : undefined,
      dateTo: dateRange[1] ? dateRange[1] : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      fileType: 'CSV',
      projectId,
    });
  };

  const handleDownload = (type: "CSV" | "EXCEL") => {
    setIsGenerate(false);
    void handleGetUserSessionsReport({
      dateFrom: dateRange[0] ? dateRange[0] : undefined,
      dateTo: dateRange[1] ? dateRange[1] : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      fileType: type,
      projectId,
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setUserSessionsItems(allUserSessionsItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setUserSessionsItems(allUserSessionsItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!userSessionsItems) {
      return;
    }

    setUserSessionsTableData(
      getUserSessionsTableData({
        items: userSessionsItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSessionsItems]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetUserSessionsLoading;

  return (
    <ReportTable<IUserSessionsTableData>
      activePath={['reporting', 'reporting-users', 'export-sessions']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'User sessions',
      }]}
      additionalDownloadOptions={
        csvUrl ? (
        <Button
          additionalClassNames="c-report-download-button"
          icon={ExcelIcon}
          size="xs"
          text="Download as excel"
          isIconOnly
          onClick={() => handleDownload('EXCEL')}
        />
      ): undefined}
      columns={USER_SESSION_COLUMNS}
      csvUrl={csvUrl}
      data={userSessionsTableData}
      dateRange={dateRange}
      description={`Session reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports user sessions based on the selected filters'}
      isLoading={isLoading}
      isLoadMoreDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      totalCount={totalCount}
      onDateSelect={(dates: Date[]) => setDateRange(dates)}
      onGenerate={generateReport}
    />
  );
};

export { SessionsReportPage };
