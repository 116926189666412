import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, ExcelIcon } from '@netfront/ui-library';
import axios, { AxiosResponse } from 'axios';
import { ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetGroupsContactsReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { GROUPS_CONTACTS_COLUMNS } from './GroupsContactsPage.constants';
import { getGroupsContactsTableData } from './GroupsContactsPage.helpers';
import { GroupsContactsCsvRowData, IGroupsContactsItem, IGroupsContactsTableData } from './GroupsContactsPage.interfaces';

const GroupsContactsPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { handleToastError, handleToastCustomError } = useToast();
  const { query: { projectId: queryProjectId } } = useRouter();

  const [projectName, setProjectName] = useState<string>();
  const [groupsContactsItems, setGroupsContactsItems] = useState<IGroupsContactsItem[]>();
  const [allGroupsContactsItems, setAllGroupsContactsItems] = useState<IGroupsContactsItem[]>([]);
  const [groupsContactsTableData, setGroupsContactsTableData] = useState<IGroupsContactsTableData[]>([]);
  const [csvUrl, setCsvUrl] = useState<string>();
  const [projectId, setProjectId] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isGenerate, setIsGenerate] = useState<boolean>(false);

  const {
    handleGetGroupsContactsReport,
    isLoading: isGetGroupsContactsLoading = false,
  } = useGetGroupsContactsReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;

      if (isGenerate) {
        setIsGenerate(false);
        setCsvUrl(presignedUrl);
        axios
          .get(presignedUrl)
          .then((res: AxiosResponse) => {

            const { data } = Papa.parse<GroupsContactsCsvRowData>(String(res.data), {
              header: true,
              skipEmptyLines: true,
            });

            const formattedItems = data.map((row: GroupsContactsCsvRowData) => ({
              id: Number(row['User id']),
              displayName: String(row['Display name']),
              groupName: String(row['Group name']),
              groupAddressLine1: String(row['Group address line 1']),
              groupAddressLine2: String(row['Group address line 2']),
              groupAddressCity: String(row['Group address city']),
              groupAddressState: String(row['Group address state']),
              groupAddressCountry: String(row['Group address country']),
              detailUrl: String(row['Detail url']),
            }));
            
            setTotalCount(data.length);
            setAllGroupsContactsItems(formattedItems);
            const paginatedItems = formattedItems.slice(0, pageSize);
            setGroupsContactsItems(paginatedItems);
            setLastItemIndex(pageSize);

          })
          .catch((error) => {
            handleToastCustomError({
              message: 'whoops! something went wrong',
            });
            // eslint-disable-next-line no-console
            console.log({ error });
          });
      } else {
        window.open(presignedUrl, '_blank');
      }
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const generateReport = () => {
    if (!projectId) {
      handleToastCustomError({
        message: 'whoops! something went wrong',
      });
      return;
    }

    setIsGenerate(true);

    void handleGetGroupsContactsReport({
      fileType: 'CSV',
      projectId,
      ianaTimeZone: 'Australia/Sydney',
    });
  };

  const handleDownload = (type: "CSV" | "EXCEL") => {
    if (!projectId) {
      handleToastCustomError({
        message: 'whoops! something went wrong',
      });
      return;
    }

    setIsGenerate(false);

    void handleGetGroupsContactsReport({
      fileType: type,
      projectId,
      ianaTimeZone: 'Australia/Sydney',
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setGroupsContactsItems(allGroupsContactsItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setGroupsContactsItems(allGroupsContactsItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!groupsContactsItems) {
      return;
    }

    setGroupsContactsTableData(
      getGroupsContactsTableData({
        items: groupsContactsItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsContactsItems]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    if (!queryProjectId) return;
    setProjectId(queryProjectId as string);
  }, [queryProjectId])

  const isLoading = isGetGroupsContactsLoading;

  return (
    <ReportTable<IGroupsContactsTableData>
      activePath={['reporting', 'reporting-users', 'export-groups-contacts']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'Groups',
      }]}
      additionalDownloadOptions={
        csvUrl ? (
        <Button
          additionalClassNames="c-report-download-button"
          icon={ExcelIcon}
          size="xs"
          text="Download as excel"
          isIconOnly
          onClick={() => handleDownload('EXCEL')}
        />
      ): undefined}
      columns={GROUPS_CONTACTS_COLUMNS}
      csvUrl={csvUrl}
      data={groupsContactsTableData}
      description={`Group contacts reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports groups contacts'}
      isLoading={isLoading}
      isLoadMoreDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      totalCount={totalCount}
      onGenerate={generateReport}
    />
  );
};

export { GroupsContactsPage };
