import { createContext } from 'react';

import { IGeladaProject, IGeladaOrganisation } from '@netfront/gelada-identity-library';

export interface ICachingEntitiesContext {
  isLoading?: boolean;
  organisation: IGeladaOrganisation | undefined;
  project: IGeladaProject | undefined;
}

export const CachingEntitiesContext = createContext<ICachingEntitiesContext>({
  organisation: undefined,
  project: undefined,
  isLoading: true,
});

// eslint-disable-next-line import/no-default-export
export default CachingEntitiesContext;
