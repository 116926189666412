import { IDBAsset } from "@netfront/common-library";
import { IContentSnippet } from "@netfront/ekardo-content-library";
import { FormFieldProps } from "@netfront/ui-library";
import { getContentAnimationDetails, getContentEventDetails, getFormattedContentAnimation, getFormattedContentEvent, getQuickAddEventValues, getUpsertContentEventStatus, getContentStyleDetails, getFormattedContentStyles } from "components";

const getSliderSnippetDefaultValues = ({ snippet } : { snippet?: IContentSnippet;}): FormFieldProps => {
  const {
    title,
    contentEvent,
    visible: isVisible = true,
    sliderId,
    // isSensitive = false,
  } = snippet ?? {};

  const eventDetails = getContentEventDetails(contentEvent);
  const animationDetails = getContentAnimationDetails(contentEvent);
  const styleDetails = getContentStyleDetails({ entity: snippet });

  return {
    title: title ?? 'Slider snippet',
    sliderId,
    isVisible,
    isSensitive: false,
    isCreateNew: false,
    ...eventDetails,
    ...animationDetails,
    ...styleDetails,
  };
};

const getSliderSnippetCommonVariables = ({ eventAssetId, item }: {eventAssetId?: IDBAsset['assetId']; item: FormFieldProps; }) => {

  const {
    title = 'Slider snippet',
    isVisible,
    sliderId,
    // isSensitive,
  } = item;

  const { shouldCreateEvent, shouldUpdateEvent} = getUpsertContentEventStatus(item);
  const { baseVariables, customTheme } = getFormattedContentStyles({ item });

  const sharedVariables = {
    baseSnippet: {
      entityTitle: title,
      visible: isVisible,
      // isSensitive,
      ...baseVariables,
    },
    sliderSnippet: {
      sliderId,
    },
    contentEvent: {
      ...getFormattedContentEvent({ item, assetId: eventAssetId }),
      ...getFormattedContentAnimation(item),
    },
    shouldCreateEvent,
    shouldUpdateEvent,
    type: 'slider',
    customTheme,
  };

  return sharedVariables;

};

const setQuickAddSliderValues = (values: FormFieldProps): FormFieldProps => {
  const { sliderId } = values;
  return {
    ...getQuickAddEventValues(values),
    title: 'Slider snippet',
    sliderId,
  };
};

export { getSliderSnippetDefaultValues, getSliderSnippetCommonVariables, setQuickAddSliderValues };
