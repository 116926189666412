import { useContext, useState, useEffect, ReactNode } from 'react';

import { ApolloError } from '@apollo/client';
import { CardListPageItems, CustomRating, FlexContainer } from '@netfront/ui-library';
import { useGetPendingAppApprovals, useToast } from 'hooks';
import { IDBPendingApp } from 'interfaces';
import Link from 'next/link';

import { CachingEntitiesContext, DashboardContext } from '../../../../../context';
import { CardListTemplatePage } from '../../../../Shared';

const AppApprovalPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { dashboardLink } = useContext(DashboardContext);
  const { handleToastError } = useToast();

  const [projectName, setProjectName] = useState<string>(project?.name ?? '');
  const [projectId, setProjectId] = useState<string>(project?.id ?? '');
  const [organisationKey, setOrganisationKey] = useState<number>();
  const [allApps, setAllApps] = useState<IDBPendingApp[]>([]);
  const [apps, setApps] = useState<IDBPendingApp[]>([]);


  const {
    handleGetPendingAppApprovals,
    isLoading: isSearchPaginatedAppsLoading = false
  } = useGetPendingAppApprovals({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ apps: returnedApps }) => {

      setAllApps(returnedApps);
      setApps(returnedApps)
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handleSearchFilter = (value: string) => {
    if (value) {
      setApps(allApps.filter(({ app: { title }}) => title.includes(value)));
    } else {
      setApps(allApps);
    }
  };


  useEffect(() => {
    if (!project) {
      return;
    }

    const { name, organisationId, id } = project;

    setProjectId(id);
    setProjectName(name);
    setOrganisationKey(organisationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name, project?.organisationId, project?.id]);

  useEffect(() => {
    handleGetPendingAppApprovals();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const isLoading = isSearchPaginatedAppsLoading;

  return (
    <CardListTemplatePage
      activePath={['library', 'app', 'pending-app-approvals']}
      additionalClassNames="c-card-list-page-template"
      breadcrumbItems={[
        {
          key: '0',
          content: <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}`}>Dashboard</Link>,
        },
        {
          key: '1',
          content: <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}/library`}>Library</Link>,
        },
        {
          key: '2',
          content: <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}/library/apps`}>Apps</Link>,
        },
        {
          key: '3',
          content: 'Pending approvals'
        },
      ]}
      defaultView={apps.length >= 10 ? 'list': 'grid'}
      
      description={'Pending App approvals dashboard'}
      informationBoxMessage={<>View <strong>pending marketplace</strong> apps</>}
      isLoading={isLoading}
      lists={[
        {
          isCentered: false,
          items: apps.map((item) => {
            const { app, id, version } = item;
            const { title = '', averageRateValue } = app ;
            return {
              type: 'nav',
              searchKey: title,
              itemProps: {
                key: String(id),
                title,
                linkUrl: `${String(dashboardLink)}/library/apps/${id}/details`,
                wrapperComponent: ({ children }: { children: ReactNode }) => <Link className="c-cover-link" href={`${String(dashboardLink)}/library/apps/${id}/details`}>{children} </Link>,
                settingsButtonSupportiveText: 'Edit content group',
                additionalFooterComponent: (
                  <FlexContainer justifyContent="space-between" isFullWidth>
                    <span>Version: {version}</span>
                    <CustomRating name={`custom-rating-${id}`} precisionType="half" rateValue={averageRateValue ?? 0} isReadOnly/>
                  </FlexContainer>
                )
              },
            } as CardListPageItems;
          })
        }
      ]}
      listType="pending app"
      logoUrl={project?.logo?.presignedUrl}
      pageTitle={`${projectName ? projectName + ' dashboard' : 'Dashboard'}`}
      projectName={projectName}
      onSearch={handleSearchFilter}
    />

  );
};

export { AppApprovalPage };
