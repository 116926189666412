import { gql } from '@apollo/client';

const UPDATE_SCENARIO_MUTATION = gql`
  mutation UpdateScenario(
    $description: String
    $id: Int!
    $isTestPhase: Boolean!
    $status: EScenarioStatus!
    $title: String!
    $overrideReceiver: String
  ) {
    scenarioMutations {
      updateScenario(description: $description, id: $id, isTestPhase: $isTestPhase, status: $status, title: $title, overrideReceiver: $overrideReceiver) {
        carbonCopies
        createdDate
        delayBetweenNotification
        delayBetweenNotificationInDay
        delayBetweenNotificationInMonth
        delayBetweenNotificationInYear
        delayBetweenNotificationInWeek
        description
        endDate
        id
        isTestPhase
        maxRecurrence
        schedule {
          ... on ScheduleDailyGraphType {
            occurEvery
          }
          ... on ScheduleWeeklyGraphType {
            occurEvery
            recurrenceDays
          }
          ... on ScheduleMonthlyGraphType {
            day
            occurEvery
          }
          ... on ScheduleYearlyGraphType {
            day
            month
            occurEvery
          }
        }
        startDate
        status
        subject
        title
        updatedDate
        overrideReceiver
      }
    }
  }
`;

export { UPDATE_SCENARIO_MUTATION };
