import { useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { 
  useCreateEvent, 
  useCreateCommunityAsset as useCreateEventImage, 
  useCreateCommunityAsset as useCreateEventBanner, 
  useDeleteCommunityAsset as useDeleteCommunityImage, 
  useDeleteCommunityAsset as useDeleteCommunityBanner, 
  useToast, 
  useUpdateEvent
} from 'hooks';
import { IDBCommunity } from 'interfaces';
import { pushImageToAws } from 'utils';


const useUpsertEvent = ({ onUpdate, projectId }: { onUpdate: (returnedTopic: IDBCommunity) => void; projectId: string }) => {
  const uploadedImageRef = useRef<{value : File | undefined}>({value: undefined});
  const hasUploadedImage = useRef<{value : boolean}>({value: false});
  
  const uploadedBannerRef = useRef<{value : File | undefined}>({value: undefined});
  const hasUploadedBanner = useRef<{value : boolean}>({value: false});
  const returnedEventRef = useRef<{value : IDBCommunity | undefined}>({value: undefined});
  const eventIdRef = useRef<{value : number | undefined}>({value: undefined});

  const [isBannerAssetUploadToAwsLoading, setIsBannerAssetUploadToAwsLoading] = useState<boolean>(false);
  const [isImageAssetUploadToAwsLoading, setIsImageAssetUploadToAwsLoading] = useState<boolean>(false);

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleCleanUpAndReturn = () => {
    handleToastSuccess({
      message: `Event successfully ${eventIdRef.current.value ? 'updated': 'created'}`,
    });

    onUpdate(returnedEventRef.current.value ?? {} as IDBCommunity);
    uploadedImageRef.current.value = undefined;
    hasUploadedImage.current.value = false;
    uploadedBannerRef.current.value = undefined;
    hasUploadedBanner.current.value = false;
    returnedEventRef.current.value = undefined;
    eventIdRef.current.value = undefined;
  };

  const waitForApiReturn = () => {
    if (
        // both banner and image have been uploaded and returned from api
        (uploadedImageRef.current.value && hasUploadedImage.current.value && uploadedBannerRef.current.value && hasUploadedBanner.current.value)
      ||
        // no image uploaded and banner asset has been uploaded & returned from the api
        (!uploadedImageRef.current.value && uploadedBannerRef.current.value && hasUploadedBanner.current.value)
      || 
        // no banner uploaded and image asset has been uploaded & returned from the api
        (!uploadedBannerRef.current.value && uploadedImageRef.current.value && hasUploadedImage.current.value)
    ) {
      handleCleanUpAndReturn();
    } else {
      return;
    }
  };


  const { handleCreateCommunityAsset: handleCreateEventImageAsset, isLoading: isCreateEventImageAssetLoading = false } =
    useCreateEventImage({
      onCompletedAsync: async (data) => {
        const { signedUrl } = data;
        setIsImageAssetUploadToAwsLoading(true);
        await pushImageToAws(signedUrl, uploadedImageRef.current.value, () => {
          hasUploadedImage.current.value = true;
          setIsImageAssetUploadToAwsLoading(false);

          if (!uploadedBannerRef.current.value) {
            handleCleanUpAndReturn();
          } else {

            waitForApiReturn();
          }
          
        });
      },
      onError: handleGetError,
      projectId: String(projectId),
    });

  const { handleCreateCommunityAsset: handleCreateEventBannerAsset, isLoading: isCreateEventBannerAssetLoading = false } =
    useCreateEventBanner({
      onCompletedAsync: async (data) => {
        const { signedUrl } = data;
        setIsBannerAssetUploadToAwsLoading(true);
        await pushImageToAws(signedUrl, uploadedBannerRef.current.value, () => {
          hasUploadedBanner.current.value = true;
          setIsBannerAssetUploadToAwsLoading(false);

          if (!uploadedImageRef.current.value) {
            handleCleanUpAndReturn();
          } else {

            waitForApiReturn();
          }
        });
      },
      onError: handleGetError,
      projectId: String(projectId),
    });


  const handleUploadAssets = (communityId: number) => {
    if (uploadedBannerRef.current.value) {
      const { type, name, size } = uploadedBannerRef.current.value;
      handleCreateEventBannerAsset({
        asset: {
          communityId,
          contentType: type,
          fileName: name,
          fileSizeInBytes: size,
          type: 'IMAGE',
          usage: 'BANNER',
          alt: 'Banner image',
        },
      });
    }

    if (uploadedImageRef.current.value) {
      const { type, name, size } = uploadedImageRef.current.value;
      handleCreateEventImageAsset({
        asset: {
          communityId,
          contentType: type,
          fileName: name,
          fileSizeInBytes: size,
          type: 'IMAGE',
          usage: 'PROFILE',
        alt: 'Profile image',
        },
      })
    }

  };
  

  const { handleDeleteCommunityAsset: handleDeleteEventImage } = useDeleteCommunityImage({
    projectId,
  });

  const { handleDeleteCommunityAsset: handleDeleteEventBanner } = useDeleteCommunityBanner({
    projectId,
  });

  const { handleCreateEvent, isLoading: isCreateEventLoading = false } = useCreateEvent({
    onCompleted: ({ eventConnection }) => {
      returnedEventRef.current.value = eventConnection;

      if (uploadedBannerRef.current.value || uploadedImageRef.current.value) {
        handleUploadAssets(Number(eventConnection.id));
      } else {
        handleCleanUpAndReturn();
      }
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId,
  });


  const { handleUpdateEvent, isLoading: isUpdateEventLoading = false } = useUpdateEvent({
    onCompleted: ({ eventConnection }) => {
      returnedEventRef.current.value = eventConnection;
      if (uploadedBannerRef.current.value || uploadedImageRef.current.value) {
        handleUploadAssets(Number(eventConnection.id));
      } else {
        handleCleanUpAndReturn();
      }
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId,
  });


  const handleUpsertEvent = ({ 
    communityId,
    deletedBannerId,
    deletedImageId,
    description,
    end,
    privacy,
    start,
    status,
    title,
    uploadedBanner,
    uploadedImage,

  }: {
    communityId?: number;
    deletedBannerId?: string;
    deletedImageId?: string;
    description: string;
    end: IDBCommunity['start'],
    privacy: string;
    start: IDBCommunity['start'],
    status: string;
    title: string;
    uploadedBanner?: File;
    uploadedImage?: File;
   }) => {
    uploadedImageRef.current.value = uploadedImage;
    uploadedBannerRef.current.value = uploadedBanner;
    eventIdRef.current.value = communityId;

    if (deletedBannerId) {
      handleDeleteEventBanner({
        assetId: deletedBannerId,
      });
    }

    if (deletedImageId) {
      handleDeleteEventImage({
        assetId: deletedImageId,
      });
    }

    if (!communityId) {
      void handleCreateEvent({
        inputType: {
          description,
          privacy: privacy as IDBCommunity['privacy'],
          status: status as IDBCommunity['status'],
          title,
          start,
          end,
        }
      });
    } else {
      void handleUpdateEvent({
        inputType: {
          description,
          id: communityId,
          privacy: privacy as IDBCommunity['privacy'],
          status: status as IDBCommunity['status'],
          title,
          start,
          end,
        }
      });
    }
  };

  return {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    isLoading: isCreateEventLoading || isUpdateEventLoading || isCreateEventImageAssetLoading || isCreateEventBannerAssetLoading || isBannerAssetUploadToAwsLoading || isImageAssetUploadToAwsLoading,
    handleUpsertEvent,
  };
};
export { useUpsertEvent };