
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Input, Select, SingleDatePicker, Spacing, Textarea } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { Controller } from 'react-hook-form';

import { CommunitySidebarGeneralTabProps } from './CommunitySidebarGeneralTab.interfaces';

const CommunitySidebarGeneralTab = ({ control, selectedId, isEvent = false, setValue }: CommunitySidebarGeneralTabProps) => {

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <SidebarContainer>

      <Controller
        control={control}
        name="title"
        render={({ field, fieldState }) => (
          <Spacing>
            <Input 
              errorMessage={getFormFieldErrorMessage(fieldState)} 
              id="id_title"
              labelText="Title" 
              tooltipText="Community title" 
              type="text" 
              isLabelSideBySide 
              isRequired
              {...field} 
            />
          </Spacing>
        )}
      />

      {selectedId && (
        <Controller
          control={control}
          name="key"
          render={({ field }) => (
            <Spacing>
              <Input 
                id="id_key" 
                labelText="Key" 
                tooltipText="Community key" 
                type="text" 
                isDisabled 
                isLabelSideBySide 
                isReadOnly 
                {...field} 
              />
            </Spacing>
          )}
        />
      )}

      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => (
          <Spacing>
            <Textarea
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_description"
              labelText="Description"
              maxLength={500}
              tooltipText="Community description"
              isLabelSideBySide
              isRequired
              {...field}
            />
          </Spacing>
        )}
      />

      <Controller
        control={control}
        name="privacy"
        render={({ field, fieldState }) => (
          <Spacing>
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_privacy"
              labelText="Privacy"
              options={[
                {
                  name: 'Public',
                  value: 'PUBLIC',
                  id: 1,
                },
                {
                  name: 'Private',
                  value: 'PRIVATE',
                  id: 2,
                },
              ]}
              isLabelSideBySide
              isRequired
              {...field}
            />
          </Spacing>
        )}
      />
     
      <Controller
        control={control}
        name="status"
        render={({ field, fieldState }) => (
          <Spacing>
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_status"
              labelText="Status"
              options={[
                {
                  name: 'Pending',
                  value: 'PENDING',
                  id: 1,
                },
                {
                  name: 'Published',
                  value: 'PUBLISHED',
                  id: 2,
                },
                {
                  name: 'Unpublished',
                  value: 'UNPUBLISHED',
                  id: 3,
                },
              ]}
              tooltipText="Only published communities will be available to post in"
              isLabelSideBySide
              isRequired
              {...field}
            />
          </Spacing>
        )}
      />

      {isEvent && (
        <>
          <Controller
            control={control}
            name="startDate"
            render={({ field, fieldState }) => (
              <Spacing>
                <SingleDatePicker 
                  dateInputProps={{
                    labelText: 'Start date',
                    errorMessage: getFormFieldErrorMessage(fieldState),
                    id: 'id_start_date',
                    name: field.name,
                    type: 'text',
                    isLabelSideBySide: true,
                    isRequired: true,
                  }}
                  {...field}
                  selectedDates={field.value ? [new Date(String(field.value))] : undefined} 
                  onSelected={(dates) => setValue?.('startDate', dates[0])} 
                />
              </Spacing>
            )}
          />
        
          <Controller
            control={control}
            name="startTime"
            render={({ field }) => (
              <Spacing>
                <Input 
                  id="id_start_time" 
                  labelText="Start time" 
                  type="time" 
                  isLabelSideBySide 
                  {...field} 
                />
              </Spacing>
            )}
          />
          <Controller
            control={control}
            name="endDate"
            render={({ field, fieldState }) => (
              <Spacing>
                <SingleDatePicker 
                  dateInputProps={{
                    labelText: 'End date',
                    errorMessage: getFormFieldErrorMessage(fieldState),
                    id: 'id_end_date',
                    name: field.name,
                    type: 'text',
                    isLabelSideBySide: true,
                    isRequired: true,
                  }}
                  {...field}
                  selectedDates={field.value ? [new Date(String(field.value))] : undefined} 
                  onSelected={(dates) => setValue?.('endDate', dates[0])} 
                />
              </Spacing>
            )}
          />
        
          <Controller
            control={control}
            name="endTime"
            render={({ field }) => (
              <Spacing>
                <Input 
                  id="id_end_time" 
                  labelText="End time" 
                  type="time" 
                  isLabelSideBySide 
                  {...field} 
                />
              </Spacing>
            )}
          />
        </>
      )}


    </SidebarContainer>
  );
};

export { CommunitySidebarGeneralTab };
