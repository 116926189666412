import { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useGetPaginatedGroups } from '@netfront/gelada-identity-library';
import { ControlledForm, Dialog, FormFieldProps, GeneralTabIcon, IOption, NotesTabIcon, ScheduleIcon, SideBarTabSet, Spinner, TabSetImperativeHandleRef, UnitsTabIcon, useControlledForm } from '@netfront/ui-library';
import { useDeleteInvitationReminder, useGetInvitationReminder, useToast, useUpsertInvitationReminder } from 'hooks';
import { Control, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { InvitationRemindersConditionTab, InvitationRemindersGeneralTab, InvitationRemindersScheduleTab, InvitationRemindersTemplatesTab } from 'components/Tabs';

import { getInvitationReminderDefaultValues } from './InvitationRemindersSidebarView.helpers';
import { InvitationRemindersSidebarViewProps } from './InvitationRemindersSidebarView.interfaces';


const InvitationRemindersSidebarView = ({
  handleSideBarClose,
  isSideBarOpen = false,
  onUpdate,
  projectId,
  selectedReminderId,
}: InvitationRemindersSidebarViewProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [groupOptions, setGroupOptions] = useState<IOption[]>([]);
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();


  const { control, handleSubmit, reset, setValue } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().required().label('Title'),
        description: yup.string().required().label('Description'),
      }),
    ),
  });


  const { handleGetInvitationReminder, isLoading: isGetInvitationReminderLoading = false } = useGetInvitationReminder({
    onCompleted: ({ invitationReminder }) => {

      setDefaultValues(getInvitationReminderDefaultValues({ invitationReminder }));
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetPaginatedGroups } = useGetPaginatedGroups({
    onCompleted: ({ groupConnection: { edges } }) => {

      const groups = edges.map(({ node }) => node);

      const groupsSelectOptions = groups.map(
        ({ id, name }): IOption => ({
          id,
          name,
          value: id,
        }),
      );

      setGroupOptions(groupsSelectOptions);

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });



  const { handleUpsertInvitationReminder, isLoading: isUpsertInvitationReminderLoading = false } = useUpsertInvitationReminder({
    onCompleted: () => {
      handleToastSuccess({ message: `Reminder successfully ${selectedReminderId ? 'updated': 'created'}`});
      reset();
      onUpdate();
    },
  });

  const { handleDeleteInvitationReminder, isLoading: isDeleteInvitationReminderLoading = false } = useDeleteInvitationReminder({
    onCompleted: () => {
      reset();
      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  const closeSidebar = () => {
    reset();
    handleSideBarClose();
  }

  const handleSave = (item: FormFieldProps) => {
    const { title, description, delay, end, start, templateId, groupId} = item;

    handleUpsertInvitationReminder({
      invitationReminderId: selectedReminderId,
      projectId,
      title,
      description,
      delay,
      end,
      start,
      templateId,
      groupId: groupId === 0 ? undefined : groupId,
    })
  };

  useEffect(() => {
    if (!projectId) return;

    void handleGetPaginatedGroups({
      projectId: String(projectId),
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (selectedReminderId) {
      void handleGetInvitationReminder({
        id: selectedReminderId
      });
    } else {
      setDefaultValues(getInvitationReminderDefaultValues({}))
    }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReminderId]);

  const isLoading = isUpsertInvitationReminderLoading || isDeleteInvitationReminderLoading || isGetInvitationReminderLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >

        <SideBarTabSet
          defaultActiveTabId="id_general_tab"
          handleOpenCloseSideBar={closeSidebar}
          hasViewPadding={false}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_general_tab',
              label: 'General',
              view: () => (
                <InvitationRemindersGeneralTab control={control as Control<FormFieldProps>} />
              ),
            },
            {
              icon: UnitsTabIcon,
              id: 'id_condition_tab',
              label: 'Condition',
              view: () => (
                <InvitationRemindersConditionTab
                  control={control as Control<FormFieldProps>}
                  groupOptions={groupOptions}
                />
              ),
            },
            {
              icon: ScheduleIcon,
              id: 'id_schedule_tab',
              label: 'Schedule',
              view: () => (
                <InvitationRemindersScheduleTab
                  control={control as Control<FormFieldProps>}
                  setValue={setValue}
                />
              ),
            },
            {
              icon: NotesTabIcon,
              id: 'id_templates_tab',
              label: 'Templates',
              view: () => (
                <InvitationRemindersTemplatesTab initialTemplateId={defaultValues?.templateId} projectId={projectId} setValue={setValue} />
              ),
            },
          ]}
          onDelete={selectedReminderId ? () => setIsDeleteDialogOpen(true): undefined}
          onSaveButtonType="submit"
        />
      </ControlledForm>

      {selectedReminderId ? (
        <Dialog
          isOpen={isDeleteDialogOpen}
          title={`Delete reminder?`}
          isNarrow
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={() => handleDeleteInvitationReminder({
            id: selectedReminderId
          })}
        />
      ) : <></>}
    </>
  );
};

export { InvitationRemindersSidebarView };
