import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, ExcelIcon, IOption, Select } from '@netfront/ui-library';
import axios, { AxiosResponse } from 'axios';
import { getReportDropdownOptions, ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { addMonths } from 'date-fns';
import { useGetProfileInformationReport, useGetGroupCodes, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { PROFILE_INFORMATION_COLUMNS } from './ProfileInformationPage.constants';
import { getProfileInformationTableData } from './ProfileInformationPage.helpers';
import { ProfileInformationCsvRowData, IProfileInformationItem, IProfileInformationTableData } from './ProfileInformationPage.interfaces';

import { DROPDOWN } from '../../Reporting.constants';


const ProfileInformationPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [profileInformationItems, setProfileInformationItems] = useState<IProfileInformationItem[]>();
  const [allProfileInformationItems, setAllProfileInformationItems] = useState<IProfileInformationItem[]>([]);
  const [profileInformationTableData, setProfileInformationTableData] = useState<IProfileInformationTableData[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>([addMonths(new Date(), -1), new Date()]);
  const [groupId, setGroupId] = useState<number>();
  const [groupOptions, setGroupOptions] = useState<IOption[]>([]);
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isGenerate, setIsGenerate] = useState<boolean>(false);


  const { handleGetGroupCodes, isLoading: isGetGroupCodesLoading = false } = useGetGroupCodes({
    onCompleted: ({ groups }) => {
        setGroupOptions(
          getReportDropdownOptions(
            groups,
            [{ key: 'identifier' }, { key: 'name'}],
            'id',
          )
        );
    }
  });
  const {
    handleGetProfileInformationReport,
    isLoading: isGetModulePagesLoading = false,
  } = useGetProfileInformationReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;

      if (isGenerate) {
        setIsGenerate(false);
        setCsvUrl(presignedUrl);
        axios
          .get(presignedUrl)
          .then((res: AxiosResponse) => {

            const { data } = Papa.parse<ProfileInformationCsvRowData>(String(res.data), {
              header: true,
            skipEmptyLines: true,
            });

            const formattedItems = data.map((row: ProfileInformationCsvRowData) => ({
              id: Number(row['User Id']),
              registrationDate: String(row['Registration date']),
              registrationDateUtc: String(row['Registration date (utc)']),
              email: String(row['Email']),
              status: String(row['Status']),
              role: row['Role'] ? String(row['Role']): '-',
              firstName: row['First name'] ? String(row['First name']): '-',
              lastName: row['Last name'] ? String(row['Last name']): '-',
              groupName: String(row['Group name']),
            }));
            
            setTotalCount(data.length);
            setAllProfileInformationItems(formattedItems);
            const paginatedItems = formattedItems.slice(0, pageSize);
            setProfileInformationItems(paginatedItems);
            setLastItemIndex(pageSize);

          })
          .catch((error) => {
            handleToastCustomError({
              message: 'whoops! something went wrong',
            });
            // eslint-disable-next-line no-console
            console.log({ error });
          });
      } else {
        window.open(presignedUrl, '_blank');
      }
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const generateReport = () => {

    setIsGenerate(true);
    void handleGetProfileInformationReport({
      dateFrom: dateRange[0] ? dateRange[0]: undefined,
      dateTo: dateRange[1] ? dateRange[1]: undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      groupId,
      fileType: 'CSV',
      projectId,
    });
  };

  const handleDownload = (type: "CSV" | "EXCEL") => {

    setIsGenerate(false);
    void handleGetProfileInformationReport({
      dateFrom: dateRange[0] ? dateRange[0]: undefined,
      dateTo: dateRange[1] ? dateRange[1]: undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      groupId,
      fileType: type,
      projectId,
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setProfileInformationItems(allProfileInformationItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setProfileInformationItems(allProfileInformationItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!profileInformationItems) {
      return;
    }

    setProfileInformationTableData(
      getProfileInformationTableData({
        items: profileInformationItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileInformationItems]);

  useEffect(() => {
    if (!projectId) return;
    void handleGetGroupCodes({ projectId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetGroupCodesLoading || isGetModulePagesLoading;

  return (
    <ReportTable<IProfileInformationTableData>
      activePath={['reporting', 'reporting-users', 'export-users']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'Profile information',
      }]}
      additionalDownloadOptions={
        csvUrl ? (
        <Button
          additionalClassNames="c-report-download-button"
          icon={ExcelIcon}
          size="xs"
          text="Download as excel"
          isIconOnly
          onClick={() => handleDownload('EXCEL')}
        />
      ): undefined}
      additionalFilters={(
        <Select
          additionalClassNames="c-asset-sort-select"
          hasPadding={false}
          id="id_userflow_select"
          labelDefaultText={DROPDOWN.DEFAULT_OPTION_LABEL.GROUP_CODE}
          labelText="Userflow"
          name="userflow_select"
          options={groupOptions}
          value={String(groupId)}
          isLabelHidden
          onChange={({ target: { value }}) => setGroupId(Number(value))}
        />
      )}
      columns={PROFILE_INFORMATION_COLUMNS}
      csvUrl={csvUrl}
      data={profileInformationTableData}
      dateRange={dateRange}
      description={`Profile information reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports user profile information based on the selected filters'}
      isLoading={isLoading}
      isLoadMoreDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      totalCount={totalCount}
      onDateSelect={(dates: Date[]) => setDateRange(dates)}
      onGenerate={generateReport}
    />
  );
};

export { ProfileInformationPage };
