import { SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { ICssFilesTableData } from './LibraryCustomCssPage.interfaces';


const CSS_FILE_TABLE_COLUMNS: ReadonlyArray<Column<ICssFilesTableData>> = [

  {
    accessor: 'title',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Title</div>,
    width: '25%',
  },
  {
    accessor: 'description',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Description</div>,
    width: '70%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick } }) => <SettingsButton supportiveText="Update group" onClick={() => onClick(id)} />,
    Header: () => <span className="h-hide-visually">Settings</span>,
    width: '5%',
  },
];

export { CSS_FILE_TABLE_COLUMNS };
