import { useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { IContentSnippet } from '@netfront/ekardo-content-library';
import { getUpsertContentEventVariables } from 'components';
import { IHandleCreateSnippetParams, IHandleUpdateSnippetParams, useAttachSnippetCssStyle, useCreateContentEvent, useCreateSnippet, useToast, useUpdateSnippet } from 'hooks';
import capitalize from 'lodash.capitalize';

import { ISnippetConfig } from './useUpsertSnippet.interfaces';


const useUpsertSnippet = ({ onCreate, onUpdate }: { onCreate: () => void; onUpdate: (returnedContainer: IContentSnippet) => void }) => {
  const snippetVariables = useRef<{value :ISnippetConfig | undefined}>({value: undefined});

  const { handleToastError, handleToastSuccess } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const getSnippetVariables = ({ containerId, snippetId, sort, type }: { containerId?: number; snippetId?: number; sort?: number; type: 'create' | 'update'; }): IHandleCreateSnippetParams | IHandleUpdateSnippetParams => {

    const {
      audioSnippet,
      baseSnippet,
      buttonSnippet,
      codeSnippet,
      contentEvent = {},
      documentSnippet,
      embedSnippet,
      formSnippet,
      imageSnippet,
      mapSnippet,
      quoteSnippet,
      shouldCreateEvent = false,
      shouldUpdateEvent = false,
      customTheme = {},
      summarySnippet,
      textSnippet,
      videoSnippet,
      sliderSnippet,
    } = snippetVariables.current.value ?? {};

    const { contentEntity, contentEventId } = contentEvent;
    const { shouldAttachCustomTheme, shouldDetachCustomTheme, styleId } = customTheme;

    const variableMap = {
      create: {
        audioSnippet,
        baseSnippet: {
          ...baseSnippet,
          sort: Number(sort),
          containerId: Number(containerId),
        },
        buttonSnippet,
        codeSnippet,
        documentSnippet,
        embedSnippet,
        formSnippet,
        imageSnippet,
        mapSnippet,
        quoteSnippet,
        sliderSnippet,
        summarySnippet,
        textSnippet,
        videoSnippet,
      } as IHandleCreateSnippetParams,
      update: {
        audioSnippet,
        buttonSnippet,
        baseSnippet: {
          ...baseSnippet,
          id: Number(snippetId),
        },
        codeSnippet,
        createContentEvent: shouldCreateEvent ? {
          entityId: Number(snippetId),
          contentEntity,
          ...getUpsertContentEventVariables(contentEvent, 'create'),
        }: undefined,
        documentSnippet,
        embedSnippet,
        formSnippet,
        imageSnippet,
        mapSnippet,
        quoteSnippet,
        shouldCreateEvent,
        shouldUpdateEvent,
        shouldAttachCustomTheme,
        shouldDetachCustomTheme,
        styleId: styleId ?? 0,
        sliderSnippet,
        snippetId: Number(snippetId),
        summarySnippet,
        textSnippet,
        updateContentEvent: shouldUpdateEvent ? {
          contentEventId: Number(contentEventId),
          ...getUpsertContentEventVariables(contentEvent, 'update'),
        }: undefined ,
        videoSnippet,
      } as IHandleUpdateSnippetParams
    };

    return variableMap[type];
  };

  const { handleCreateContentEvent, isLoading: isCreateContentEventLoading = false } = useCreateContentEvent({
    onCompleted: () => {
      const { type = '' } = snippetVariables.current.value ?? {};
      handleToastSuccess({
        message: `${capitalize(String(type))} Snippet successfully created`,
      });

      onCreate();

    },
    onError: handleGetError,
  });
  
  const { handleAttachSnippetCssStyle, isLoading: isAttachSnippetCssStyleLoading = false } = useAttachSnippetCssStyle({
    onError: handleGetError,
  });

  const { handleCreateSnippet, isLoading: isCreateSnippetLoading = false } = useCreateSnippet({
    onCompleted: ({ snippet : { id }}) => {

      const { shouldCreateEvent = false, contentEvent = {}, customTheme = {}, type = '' } = snippetVariables.current.value ?? {};

      const { shouldAttachCustomTheme = false, styleId } = customTheme;

      handleToastSuccess({
        message: `${capitalize(String(type))} Snippet successfully created`,
      });

      if (!shouldCreateEvent && !shouldAttachCustomTheme) {
        onCreate();
      } else {
        if (shouldAttachCustomTheme) {
          handleAttachSnippetCssStyle({
            contentSnippetId: id,
            styleId,
          });
        }

        if (shouldCreateEvent) {
          const { contentEntity } = contentEvent;
          handleCreateContentEvent({
            contentEvent: {
              entityId: id,
              contentEntity,
              ...getUpsertContentEventVariables(contentEvent, 'create'),
            }
          });
        }
      }

    },
    onError: handleGetError,
  });

  const { handleUpdateSnippet, isLoading: isUpdateSnippetLoading = false } = useUpdateSnippet({
    onCompleted: ({ snippet: returnedSnippet }) => {
      const { type = '' } = snippetVariables.current.value ?? {};
      handleToastSuccess({
        message: `${capitalize(String(type))} Snippet successfully updated`,
      });

      onUpdate(returnedSnippet);
    },
    onError: handleGetError,
  });

  const handleUpsertSnippet = ({ containerId, snippetId, sort, variables }: {containerId: number; snippetId?: number; sort?: number; variables: ISnippetConfig; }) => {
    snippetVariables.current.value = variables;

    if (!snippetId) {

      const createSnippetVariables = getSnippetVariables({
        containerId: Number(containerId),
        sort,
        type: 'create',
      }) as IHandleCreateSnippetParams;

      void handleCreateSnippet({
        ...createSnippetVariables
      });

    } else {
      const updateSnippetVariables = getSnippetVariables({
        snippetId: Number(snippetId),
        type: 'update',
      }) as IHandleUpdateSnippetParams;

      void handleUpdateSnippet({
        ...updateSnippetVariables,
      });
    }
  };

  return {
    isLoading: isCreateSnippetLoading || isUpdateSnippetLoading || isCreateContentEventLoading || isAttachSnippetCssStyleLoading,
    handleUpsertSnippet,
  };
};
export { useUpsertSnippet };
