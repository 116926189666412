import React, { useEffect, useRef, useState } from 'react';

import { Spacing, InformationBox, Table, ButtonIconOnly, FlexContainer, BinIcon, EyeShowIcon, Label, Dialog, Spinner } from '@netfront/ui-library';
import { IScenarioTemplate } from 'components';
import { useToast } from 'hooks';
import { compareArrays } from 'utils';

import { InputCounter } from '../InputCounter';
import { useGetTemplate } from 'hooks/scheduler';

import { ScenarioTemplatesOverviewProps } from './ScenarioTemplatesOverview.interfaces';




const ScenarioTemplatesOverview = ({ onRemoveTemplate, templates = [], watch, onUpdateCount }: ScenarioTemplatesOverviewProps) => {
  const { handleToastError } = useToast();

  const [items, setItems] = useState<IScenarioTemplate[]>([]);
  const [isTemplatePreviewDialogOpen, setIsTemplatePreviewDialogOpen] = useState<boolean>(false);
  const [previewTemplate, setPreviewTemplate] = useState<IScenarioTemplate>();
  const [previewTemplateContent, setPreviewTemplateContent] = useState<string>('');
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState<boolean>(false);
  const [templateToRemove, setTemplateToRemove] = useState<IScenarioTemplate>();
  const [totalPercentage, setTotalPercentage] = useState<number>(0)
  const itemsRef = useRef<IScenarioTemplate[]>(items);


  const { handleGetTemplate, isLoading: isGetTemplateLoading = false } = useGetTemplate({
    onCompleted: ({ templateConnection: template }) => {
      const { content } = template;


      setPreviewTemplateContent(content);

      setIsTemplatePreviewDialogOpen(true);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handleViewTemplate = (id: number) => {
    handleGetTemplate({
      templateId: id,
    });
    setPreviewTemplate(items.find(({ id: templateId}) => templateId === id));
  };

  const cancelViewTemplate = () => {
    setPreviewTemplate(undefined);
    setIsTemplatePreviewDialogOpen(false);
  };

  const handleRemoveTemplate = (id: number) => {
    setTemplateToRemove(items.find(({ id: templateId}) => templateId === id));
    setIsRemoveDialogOpen(true);
  };

  const cancelRemoveTemplate = () => {
    setTemplateToRemove(undefined);
    setIsRemoveDialogOpen(false);
  };

  useEffect(() => {
    // We need to do this in order to stop watch from re-rendering everything from within the parent container on every change
    const subscription = watch((value) => {
      const { templates: updatedTemplates = [] } = value;
    
      if (compareArrays<IScenarioTemplate>(itemsRef.current, updatedTemplates as IScenarioTemplate[])) {
        setTotalPercentage(
          (updatedTemplates as IScenarioTemplate[]).reduce((accumulator, { percentage }) => {
            return accumulator + percentage;
          }, 0)
        );
        setItems(updatedTemplates as IScenarioTemplate[]);
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    setItems(templates);
  }, [templates]);

  useEffect(() => {
    itemsRef.current = items;
  }, [items]);

  
  return (
    <>
      <Spinner isLoading={isGetTemplateLoading} />
      <Label forId="" labelText="Current templates" labelType="span"/>
      {items.length > 0 ? (
        <Spacing>
          <Table
            additionalClassNames="c-communities-table"
            columns={[
              {
                accessor: 'title',
                Cell: ({ value }: { value: string }) => <span>{value}</span>,
                Header: () => <div className="h-hide-visually">Title</div>,
                width: '60%',
              },
              {
                accessor: 'percentage',
                Cell: ({ 
                  value: {
                    id,
                    value,
                  } 
                }: { 
                  value: {
                    id: number;
                    value: number;
                  }
                }) => <span><InputCounter handleUpdate={onUpdateCount} id={id} name={`new_input_counter_${id}`} value={value}/></span>,
                Header: () => <div className="h-hide-visually">Percentage</div>,
                width: '35%',
              },
              {
                accessor: 'settingsButtonData',
                width: '5%',
                Header: () => <div className="h-hide-visually">Settings</div>,
                Cell: ({
                  value: {
                    handleRemoveClick,
                    handleViewClick,
                    id,
                  }
                }: {
                  value: {
                    handleRemoveClick: (id: number) => void
                    handleViewClick: (id: number) => void;
                    id: number;
                  }
                }) => (
                  <FlexContainer gap="2x-small" justifyContent="end">
                    <ButtonIconOnly
                      additionalClassNames=""
                      icon={BinIcon}
                      isIconBorderVisible={false}
                      text="Remove template"
                      onClick={() => handleRemoveClick(id)}
                    />
                    <ButtonIconOnly
                      icon={EyeShowIcon}
                      isIconBorderVisible={false}
                      text="View template"
                      onClick={() => handleViewClick(id)}
                    />
                  </FlexContainer>
                ),
              },
            ]}
            data={items.map((item) => ({
              id: item.id,
              title: item.title,
              percentage: {
                value: item.percentage,
                id: item.id,
              },
              settingsButtonData: {
                id: item.id,
                handleViewClick: handleViewTemplate,
                handleRemoveClick: handleRemoveTemplate,
              },
            }))}
          />
          <div className="c-scenario-template-totals">
            <span>{items.length} titles</span>
            <span><strong>Total</strong> {totalPercentage}%</span>
          </div>
        </Spacing>
      ): (
        <InformationBox message="No templates attached for the scenario" />
      )}

      <Dialog
        isOpen={isRemoveDialogOpen}
        title={`Remove template: ${String(templateToRemove?.title)}?`}
        isNarrow
        onCancel={cancelRemoveTemplate}
        onClose={cancelRemoveTemplate}
        onConfirm={() => {
          onRemoveTemplate(Number(templateToRemove?.id))
          cancelRemoveTemplate();
        }}
      />
      <Dialog
        isOpen={isTemplatePreviewDialogOpen}
        title={`Preview template: ${String(previewTemplate?.title)}`}
        onCancel={cancelViewTemplate}
        onClose={cancelViewTemplate}
      >
        {previewTemplateContent ? (
          <div dangerouslySetInnerHTML={{ __html: previewTemplateContent }} />
        ) : (
          'No content available for the template'
        )}
      </Dialog>
    </>
  );
};


export { ScenarioTemplatesOverview };
