import { useContext, useState, useEffect, ReactNode } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, CardListPageItems, CustomRating, FlexContainer, GeneralTabIcon, Select, SideBarTabSet } from '@netfront/ui-library';
import { AppGeneralTab, StatusPill } from 'components';
import { useSearchPaginatedApps, useToast } from 'hooks';
import { IApp } from 'interfaces';
import last from 'lodash.last';
import Link from 'next/link';

import { CachingEntitiesContext, DashboardContext } from '../../../../../context';
import { CardListTemplatePage } from '../../../../Shared';

const AppPage = ({ activePage, initialProjectId }: { activePage: string; initialProjectId?: string }) => {
  const { project } = useContext(CachingEntitiesContext);
  const { dashboardLink } = useContext(DashboardContext);
  const { handleToastError } = useToast();

  const [projectName, setProjectName] = useState<string>(project?.name ?? '');
  const [projectId, setProjectId] = useState<string>(project?.id ?? '');
  const [organisationKey, setOrganisationKey] = useState<number>();
  const [allApps, setAllApps] = useState<IApp[]>([]);
  const [filter, setFilter] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalApps, setTotalApps] = useState<number>(0);
  const [sorting, setSorting] = useState<'POPULAR' | 'LATEST'| undefined>();
  const [status, setStatus] = useState<'PUBLISHED' | 'UNPUBLISHED'>('PUBLISHED');
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [selectedApp, setSelectedApp] = useState<IApp>();


  const {
    handleSearchPaginatedApps,
    handleFetchMorePaginatedApps,
    isLoading: isSearchPaginatedAppsLoading = false
  } = useSearchPaginatedApps({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ appConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);


      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const apps = edges.map(({ node }) => node);
      setAllApps(apps);
      setIsLoadMoreDisabled(apps.length >= totalCount || totalCount <= pageSize);
      setTotalApps(totalCount);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };

  const handlePaginate = async () => {
    await handleFetchMorePaginatedApps({
      after: lastCursor,
      first: pageSize,
      projectId: initialProjectId,
      title: filter,
      sorting: sorting ? sorting : undefined,
      status: [status],
    });
  };

  const handleSearchFilter = (val: string) => {
    handleSearchPaginatedApps({
      title: val,
      first: pageSize,
      projectId: initialProjectId,
      sorting: sorting ? sorting : undefined,
      status: [status],
    });

    setFilter(val);
  };

  const handleUpsertApp = (selectedItem?: IApp) => {
    setSelectedApp(selectedItem);
    setIsSideBarOpen(true);
  };

  const handleCloseSidebar = () => {
    setSelectedApp(undefined);
    setIsSideBarOpen(false);
  }

  const onUpdate = () => {
    handleCloseSidebar();
    handleSearchPaginatedApps({
      first: pageSize,
      projectId: initialProjectId,
      status: [status],
    });
  };


  useEffect(() => {
    if (!project) {
      return;
    }

    const { name, organisationId, id } = project;

    setProjectId(id);
    setProjectName(name);
    setOrganisationKey(organisationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name, project?.organisationId, project?.id]);

  useEffect(() => {
    handleSearchPaginatedApps({
      first: pageSize,
      title: filter,
      projectId: initialProjectId,
      sorting: sorting ? sorting : undefined,
      status: [status],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, sorting, status]);

  useEffect(() => {
    setSorting(undefined);
    setFilter('');

  }, [activePage]);

  const isLoading = isSearchPaginatedAppsLoading;

  return (
    <>
      <CardListTemplatePage
        activePath={['library', 'app', activePage]}
        additionalClassNames="c-card-list-page-template"
        addNewOnClick={activePage === 'marketplace' ? () => handleUpsertApp(undefined): undefined}
        breadcrumbItems={[
          {
            key: '0',
            content: <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}`}>Dashboard</Link>,
          },
          {
            key: '1',
            content: <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}/library`}>Library</Link>,
          },
          {
            key: '2',
            content: <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}/library/apps`}>Apps</Link>,
          },
          {
            key: '3',
            content: activePage === 'project-apps' ? 'Project apps' : 'Marketplace',
          },
        ]}
        childrenMiddle={
          <>
            {
              activePage === 'marketplace' ? (
                <>
                  <Select
                    hasPadding={false}
                    id="id_app_status_filter"
                    labelText="Status"
                    name="status"
                    options={[
                      {
                        id: 'PUBLISHED',
                        name: 'Published',
                        value: 'PUBLISHED'
                      },
                      {
                        id: 'UNPUBLISHED',
                        name: 'Unpublished',
                        value: 'UNPUBLISHED'
                      },
                      
                    ]}
                    value={status}
                    isLabelHidden
                    onChange={({ target: { value}}) => setStatus(value as 'PUBLISHED' | 'UNPUBLISHED'  )}
                  />
                  <Select
                    hasPadding={false}
                    id="id_app_sort_filter"
                    labelDefaultText="All"
                    labelText="Sorting"
                    name="sorting"
                    options={[
                      {
                        id: 'POPULAR',
                        name: 'Popular',
                        value: 'POPULAR'
                      },
                      {
                        id: 'LATEST',
                        name: 'Latest',
                        value: 'LATEST'
                      },
                      
                    ]}
                    value={sorting}
                    isLabelHidden
                    onChange={({ target: { value}}) => setSorting(value as 'POPULAR' | 'LATEST' | undefined )}
                  />
                </>
              ): (
                <FlexContainer justifyContent="flex-end" isFullWidth>
                  <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}/library/apps/marketplace`} passHref>
                    <Button
                      size="xs"
                      text="See more in marketplace"
                      onClick={() => { return; }}
                      />
                  </Link>
                </FlexContainer>
              )

            }
          </>
        }
        defaultView={allApps.length >= 10 ? 'list': 'grid'}
        description={activePage === 'project-apps' ? `Apps dashboard for ${projectName} project`: 'Marketplace dashboard'}
        handleOnPageSizeChange={handleChangePageSize}
        handleOnPaginate={handlePaginate}
        informationBoxMessage={<>Manage {activePage === 'project-apps' ? <><strong>{projectName}</strong> apps</> : <><strong>marketplace</strong> apps</>}</>}
        isLoading={isLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        lists={[
          {
            isCentered: false,
            items: allApps.map((item) => {
              const { id, title, averageRateValue, logo, isPublished = false } = item;
              const { presignedUrl = '' } = logo ?? {};
              return {
                type: 'nav',
                searchKey: title,
                itemProps: {
                  key: String(id),
                  title,
                  logoUrl: presignedUrl,
                  linkUrl: `${String(dashboardLink)}/library/apps/${id}/details`,
                  wrapperComponent: ({ children }: { children: ReactNode }) => <Link className="c-cover-link" href={`${String(dashboardLink)}/library/apps/${id}/details`}>{children} </Link>,
                  settingsButtonSupportiveText: 'Edit content group',
                  // settingsButtonOnClick: () => handleSelectItem(item),
                  additionalFooterComponent: (
                    <FlexContainer justifyContent="space-between" isFullWidth> 
                      <CustomRating name={`custom-rating-${id}`} precisionType="half" rateValue={averageRateValue ?? 0} isReadOnly/>
                      <StatusPill isHighlighted={isPublished} status={isPublished ? 'Published': 'Unpublished'}/>
                    </FlexContainer>
                  ),

                },
              } as CardListPageItems;
            })
          }
        ]}
        listType={activePage === 'project-apps' ? 'project app': 'app'}
        logoUrl={project?.logo?.presignedUrl}
        pageSize={pageSize}
        pageTitle={`${projectName ? projectName + ' dashboard' : 'Dashboard'}`}
        projectName={projectName}
        totalItems={totalApps}
        onSearch={handleSearchFilter}
      />
      {activePage === 'marketplace' && projectId && (
        <SideBarTabSet
          defaultActiveTabId="id_general_tab"
          handleOpenCloseSideBar={handleCloseSidebar}
          hasViewPadding={false}
          hideSideBarButtons={true}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_general_tab',
              label: 'General',
              view: () =>
                <AppGeneralTab 
                  projectId={projectId}
                  selectedApp={selectedApp}
                  onCancel={handleCloseSidebar}
                  onUpdate={onUpdate}
                />
            },
          ]}

        />
      )}
      
    </>

  );
};

export { AppPage };
