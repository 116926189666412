
import { CREATE_EVENT_MUTATION } from './useCreateEvent.graphql';
import {
  ICreateEventMutationGraphQLResponse,
  ICreateEventMutationVariables,
  IHandleCreateEventParams,
  IUseCreateEventOptions,
  IUseCreateEvent,
} from './useCreateEvent.interfaces';

import { useBonoboMutation } from '../../useBonoboMutation';

const useCreateEvent = (options?: IUseCreateEventOptions): IUseCreateEvent => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseCreateEventOptions);

  const [executeCreateEvent, { loading: isLoading }] = useBonoboMutation<
    ICreateEventMutationGraphQLResponse,
    ICreateEventMutationVariables
  >({
    mutation: mutation ?? CREATE_EVENT_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          community: { createEvent: eventConnection },
        } = data;

        onCompleted({ eventConnection });
      },
      onError,
    },
    token,
  });

  const handleCreateEvent = async ({
    inputType: { 
      categoryId, 
      description, 
      title, 
      privacy, 
      status,
      start,
      end,
    },
  }: IHandleCreateEventParams) => {
    await executeCreateEvent({
      variables: {
        inputType: {
          categoryId,
          description,
          privacy,
          status,
          title,
          start,
          end,
        },
      },
    });
  };

  return {
    handleCreateEvent,
    isLoading,
  };
};

export { useCreateEvent };
