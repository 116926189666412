import React, { useState, useContext, useEffect } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { IContentSnippetQuestion } from '@netfront/ekardo-content-library';
import { Select, Spacing, Spinner, IOption } from '@netfront/ui-library';
import { CachingEntitiesContext } from 'context';
import { IQuestionSelectorOption } from 'interfaces';
import { Controller } from 'react-hook-form';


import { QuestionSelectorProps } from './QuestionSelector.interfaces';

import { useGetProjectSteps, useGetQuestions, useToast } from '../../hooks';
import { getQuestionText, generateRandomId, sortObjectArrayAlphabetically, filterDuplicatesByKey } from '../../utils';


const QuestionSelector = ({
  control,
  setValue,
  initialUserFlowStepId,

}: QuestionSelectorProps) => {
  const { project } = useContext(CachingEntitiesContext);
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { handleToastError } = useToast();
  const [questionOptions, setQuestionOptions] = useState<IQuestionSelectorOption[]>([]);
  const [questionConfigs, setQuestionConfigs] = useState<IContentSnippetQuestion[]>([]);
  const [userFlowStepOptions, setUserFlowStepOptions] = useState<IOption[]>([]);
  const [selectedUserFlowStepId, setSelectedUserFlowStepId] = useState<number | undefined>(initialUserFlowStepId ?? undefined);

  const { handleGetQuestions, isLoading: isGetQuestionsLoading = false } = useGetQuestions({
    onCompleted: ({ groupedContentSnippetQuestions }) => {
      const configs: IContentSnippetQuestion[] = [];
      const newQuestionOptions = groupedContentSnippetQuestions.flatMap(({ contentSnippetFormId, questions: contentSnippetQuestions }) =>
        contentSnippetQuestions.map((contentSnippetQuestion) => {
          const { id, question } = contentSnippetQuestion;
          const label = getQuestionText(String(question), 'text/html') ?? `Question-${id}`;
          configs.push(contentSnippetQuestion);
          return {
            id: `${generateRandomId()}-${id}`,
            initialId: id,
            name: `ID: ${id} - Question label: ${label.substring(0, 30)}`,
            value: `${contentSnippetFormId}-${id}`,
          };
        }),
      );

      setQuestionConfigs(filterDuplicatesByKey<IContentSnippetQuestion>(configs, 'id'));
      setQuestionOptions(sortObjectArrayAlphabetically<IQuestionSelectorOption>(filterDuplicatesByKey<IQuestionSelectorOption>(newQuestionOptions, 'initialId'), 'name'));
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetProjectSteps, isLoading: isGetProjectStepsLoading = false } = useGetProjectSteps({
    onCompleted: ({ userFlowSteps }) => {
      if (!userFlowSteps.length) {
        return;
      }

      setUserFlowStepOptions(
        userFlowSteps.map(({ id, stepName }) => ({
          id,
          name: stepName,
          value: id,
        })),
      );
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handleUserFlowStepChange = ({ target: { value } }: { target: { value: string}}) => {
    const userFlowStepId = Number(value);
    setValue('userFlowStepId', userFlowStepId);
    setSelectedUserFlowStepId(userFlowStepId);
  };


  useEffect(() => {
    if (!project) return;
    void handleGetProjectSteps({
      projectId: String(project.id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (!selectedUserFlowStepId) return;
    void handleGetQuestions({
      userFlowStepId: Number(selectedUserFlowStepId),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserFlowStepId]);


  const isLoading = isGetQuestionsLoading || isGetProjectStepsLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <Spacing>
        <Controller
          control={control}
          name="userFlowStepId"
          render={({ field, fieldState }) => (
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_user_flow_step"
              labelText="Select a module"
              options={userFlowStepOptions}
              isLabelSideBySide
              isRequired
              {...field}
              onChange={(event) => {
                field.onChange(event);
                setValue('questionFormId', '');
                handleUserFlowStepChange(event);
              }}
            />
          )}
        />
      </Spacing>
      <Spacing>
      <Controller
          control={control}
          name="questionFormId"
          render={({ field, fieldState }) => (
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_questions"
              labelText="Select a question"
              options={questionOptions}
              isLabelSideBySide
              isRequired
              {...field}
              onChange={(event) => {
                const { target: { value } }: { target: { value: string}} = event;
                // eslint-disable-next-line prefer-destructuring
                const questionId = value.split('-')[1];
                const config = questionConfigs.find((option) => option.id === Number(questionId));

                const {
                  id,
                  configuration,
                } = config ?? {};

                const { __typename: questionConfigurationTypeName } = configuration ?? {};

                const typeName = questionConfigurationTypeName === 'SingleText' ? 'text' : String(questionConfigurationTypeName).toLowerCase();

                setValue('type', typeName);
                setValue('contentSnippetQuestionId', Number(id));

              }}
            />
          )}
        />
      </Spacing>
    </>
  );
};

export { QuestionSelector };
