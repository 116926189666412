import { SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { ISimulatorsTableData } from './SimulatorPage.interfaces';


const SIMULATORS_TABLE_COLUMNS: ReadonlyArray<Column<ISimulatorsTableData>> = [
  {
    accessor: 'firstName',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>First name</div>,
    width: '20%',
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Last name</div>,
    width: '20%',
  },
  {
    accessor: 'email',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Email</div>,
    width: '20%',
  },
  {
    accessor: 'medium',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Medium</div>,
    width: '15%',
  },
  {
    accessor: 'template',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Template</div>,
    width: '20%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { template, onClick } }) => <SettingsButton supportiveText="Preview template" onClick={() => onClick(template)} />,
    Header: () => <span className="h-hide-visually">Preview template</span>,
    width: '5%',
  },
];

export { SIMULATORS_TABLE_COLUMNS };
