import { useContext, useEffect, useState } from 'react';

import { ChartsGridContainer, DateRangePicker } from '@netfront/ui-library';
import { BasicPageTemplate , UserFlowCompletion, UserFlowStepCompletions, UserRegistrationChart , ActiveSchoolsChart , GroupCountBySchoolType , NoTrialUserRegistrationChart , TrialUserRegistrationChart , UserActiveChart , UserCountByStateChart } from 'components';
import { CachingEntitiesContext } from 'context';
import { AvailableInsights } from 'interfaces';
import Link from 'next/link';
import { useRouter } from 'next/router';


import { IGetProjectInsightsOnCompletedResponse, useGetProjectInsights } from 'hooks/useGetProjectInsights';

const InsightsPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query } = useRouter();
  const { projectId: queryProjectId, organisationId: queryOrganisationId } = query;

  const [projectId, setProjectId] = useState<string>('');
  const [organisationId, setOrganisationId] = useState<string>('');
  const [dateRange, setDateRange] = useState<Date[]>();
  const [availableInsights, setAvailableInsights] = useState<AvailableInsights[]>([]);
  const [dashboardUrl, setDashboardUrl] = useState<string>('');

  const onGetProjectInsightsCompleted = (data: IGetProjectInsightsOnCompletedResponse) => {
    const { availableInsights: returnedInsights } = data;
    setAvailableInsights(returnedInsights);
  };

  const { handleGetProjectInsights } = useGetProjectInsights({
    onCompleted: onGetProjectInsightsCompleted,
  });

  useEffect(() => {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    setDateRange([oneMonthAgo, new Date()]);
  }, []);

  useEffect(() => {
    if (!project) return;

    void handleGetProjectInsights({
      projectId: String(project.id),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    setOrganisationId(queryOrganisationId as string);
  }, [queryOrganisationId]);

  useEffect(() => {
    if (!(projectId && organisationId)) return;
    setDashboardUrl(`/dashboard/${String(organisationId)}/${String(projectId)}`);
  }, [organisationId, projectId]);

  return (
    <BasicPageTemplate
      activePage="insights"
      breadcrumbItems={[
        {
          key: '0',
          content: <Link href={dashboardUrl}><span>Dashboard</span></Link>,
        },
        {
          key: '1',
          content: 'Insight',
        },
      ]}
      description={`Project insights ${String(project?.name)}`}
      informationBoxMessage={`${project ? project.name : ''} insights`}
      logoUrl={project?.logo?.presignedUrl}
      pageTitle={`${project ? project.name : ''} insights`}
      projectName={project?.name}
    >
      <DateRangePicker
        dateInputProps={{
          labelText: 'Select date range',
          id: 'id_date_range',
          name: 'new_date_range',
          type: 'text',
          isLabelHidden: true,
        }}
        selectedDates={dateRange}
        onSelected={(dates: Date[]) => {
          setDateRange(dates);
        }}
      />
      <ChartsGridContainer>
        {dateRange && dateRange[0] && dateRange[1] && (
          <>
            {projectId && availableInsights.includes('USER_REGISTRATION') && (
              <UserRegistrationChart end={dateRange[1]} projectId={String(projectId)} start={dateRange[0]} />
            )}
            {projectId && availableInsights.includes('USER_ACTIVE') && (
              <UserActiveChart end={dateRange[1]} projectId={String(projectId)} start={dateRange[0]} />
            )}
            {projectId && availableInsights.includes('STUDENT_TEACHER_REGISTRATION_NO_TRIAL') && (
              <NoTrialUserRegistrationChart end={dateRange[1]} projectId={String(projectId)} start={dateRange[0]} />
            )}
            {projectId && availableInsights.includes('TEACHER_REGISTRATION_TRIAL') && (
              <TrialUserRegistrationChart end={dateRange[1]} projectId={String(projectId)} start={dateRange[0]} />
            )}
            {projectId && availableInsights.includes('ACTIVE_GROUP') && (
              <ActiveSchoolsChart end={dateRange[1]} projectId={String(projectId)} start={dateRange[0]} />
            )}
            {projectId && availableInsights.includes('USER_FLOW_STEP_COMPLETION') && (
              <UserFlowStepCompletions end={dateRange[1]} projectId={String(projectId)} start={dateRange[0]} />
            )}
            {projectId && availableInsights.includes('USER_FLOW_COMPLETION') && (
              <UserFlowCompletion end={dateRange[1]} projectId={String(projectId)} start={dateRange[0]} />
            )}
          </>
        )}

        {projectId && availableInsights.includes('USER_BY_STATE') && <UserCountByStateChart projectId={String(projectId)} />}
        {Boolean(projectId) && availableInsights.includes('GROUP_BY_TYPE') && <GroupCountBySchoolType projectId={String(projectId)} />}
      </ChartsGridContainer>
    </BasicPageTemplate>
  );
};

export { InsightsPage };
