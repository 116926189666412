import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, ExcelIcon } from '@netfront/ui-library';
import axios, { AxiosResponse } from 'axios';
import { ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { addMonths } from 'date-fns';
import { useGetCommentLogsReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { COMMENT_LOGS_COLUMNS } from './CommentLogsReportPage.constants';
import { getCommentLogsTableData } from './CommentLogsReportPage.helpers';
import { CommentLogsCsvRowData, ICommentLogsItem, ICommentLogsTableData } from './CommentLogsReportPage.interfaces';



const CommentLogsReportPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [commentLogsItems, setCommentLogsItems] = useState<ICommentLogsItem[]>();
  const [commentLogsTableData, setCommentLogsTableData] = useState<ICommentLogsTableData[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>([addMonths(new Date(), -1), new Date()]);
  const [csvUrl, setCsvUrl] = useState<string>();
  const [isGenerate, setIsGenerate] = useState<boolean>(false);


  const {
    handleGetCommentLogsReport,
    isLoading: isGetCommentLogsLoading = false,
  } = useGetCommentLogsReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;

      if (isGenerate) {
        setIsGenerate(false);
        setCsvUrl(presignedUrl);
        axios
          .get(presignedUrl)
          .then((res: AxiosResponse) => {

            const { data } = Papa.parse<CommentLogsCsvRowData>(String(res.data), {
              header: true,
              skipEmptyLines: true,
            });

            setCommentLogsItems(data.map((row: CommentLogsCsvRowData) => ({
              id: Number(row['Comment id']),
              authorId: row['Author id'] ? String(row['Author id']): '-',
              groupName: row['Group name'] ? String(row['Group name']) : '-',
              comment: row['Comment'] ? String(row['Comment']) : '-',
              commentDate: row['Comment date'] ? String(row['Comment date']) : '-',
              commentDateUtc: row['Comment date (utc)'] ? String(row['Comment date (utc)']) : '-',
              userType: row['User type'] ? String(row['User type']): '-',
              action: row['Action'] ? String(row['Action']): '-',
            })));
          })
          .catch((error) => {
            handleToastCustomError({
              message: 'whoops! something went wrong',
            });
            // eslint-disable-next-line no-console
            console.log({ error });
          });

      } else {
        window.open(presignedUrl, '_blank');
      }
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId: String(projectId),
  });

  useEffect(() => {
    if (!commentLogsItems) {
      return;
    }

    setCommentLogsTableData(
      getCommentLogsTableData({
        items: commentLogsItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentLogsItems]);

  const generateReport = () => {
    setIsGenerate(true);
    void handleGetCommentLogsReport({
      dateFrom: dateRange[0] ? dateRange[0] : undefined,
      dateTo: dateRange[1] ? dateRange[1] : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId,
      fileType: 'CSV'
    });
  };

  const handleDownload = (type: "CSV" | "EXCEL") => {
    setIsGenerate(false);

    void handleGetCommentLogsReport({
      dateFrom: dateRange[0] ? dateRange[0] : undefined,
      dateTo: dateRange[1] ? dateRange[1] : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId,
      fileType: type
    });
  };

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetCommentLogsLoading;

  return (
    <ReportTable<ICommentLogsTableData>
      activePath={['reporting', 'reporting-social', 'export-comment-logs']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'Comment logs',
      }]}
      additionalDownloadOptions={
        csvUrl ? (
        <Button
          additionalClassNames="c-report-download-button"
          icon={ExcelIcon}
          size="xs"
          text="Download as excel"
          isIconOnly
          onClick={() => handleDownload('EXCEL')}
        />
      ): undefined}
      columns={COMMENT_LOGS_COLUMNS}
      csvUrl={csvUrl}
      data={commentLogsTableData}
      dateRange={dateRange}
      
      description={`Comment log reports for ${String(projectName)}`}
      informationBoxMessage={'This page exports comment logs based on the selected filters'}
      isLoading={isLoading}
      logoUrl={project?.logo?.presignedUrl}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      onDateSelect={(dates: Date[]) => setDateRange(dates)}
      onGenerate={generateReport}
    />
  );
};

export { CommentLogsReportPage };
