import React, { useEffect, useState } from 'react';

import {
  Input,
  SidebarButtons,
  Spacing,
  Spinner,
} from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { useGetTemplate, useToast } from 'hooks';
import { IDBTemplate } from 'interfaces';

import { SimulatorGeneralTabProps } from './SimulatorGeneralTab.interfaces';



const SimulatorGeneralTab = ({
  selectedTemplateId,
  onClose,
}: SimulatorGeneralTabProps) => {
  const { handleToastError } = useToast();

  const [template, setTemplate] = useState<IDBTemplate>();

  

  const { handleGetTemplate, isLoading: isGetTemplateLoading = false } = useGetTemplate({
    onCompleted: ({ templateConnection }) => {

      setTemplate(templateConnection);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!selectedTemplateId) return;

    handleGetTemplate({
      templateId: selectedTemplateId,
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateId]);

  const isLoading = isGetTemplateLoading;

  const { name = '', content = '' } = template ?? {};


  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />
        

        { template && (
          <>
            <Spacing>
              <Input
                id="name"
                labelText="Name"
                name="name"
                type="text"
                value={name}
                isDisabled
                isLabelSideBySide
                onChange={() => { return }}
              />
            </Spacing>
            <Spacing>
              {content ? (
                <div dangerouslySetInnerHTML={{ __html: content }} />
              ): <p>No content available for the template</p>}
            </Spacing>
          </>
        )}

        <SidebarButtons
          buttonSize="xs"
          onCancel={onClose}
        />
    </SidebarContainer>
  );
};

export { SimulatorGeneralTab };
