import React, { useEffect, useState } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import {
  FieldsetAccordionGroup,
  FormFieldProps,
  Input,
  Select,
  SingleDatePicker,
  Spacing,
  ToggleSwitch,
} from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { Control, Controller } from 'react-hook-form';

import { ScheduleTabDaily, ScheduleTabMonthly, ScheduleTabWeekly, ScheduleTabYearly } from './Components';
import { ScenarioScheduleTabProps } from './ScenarioScheduleTab.interfaces';



const ScenarioScheduleTab = ({ control, initialIsMultiOccurrence = false, initialOccurrenceType = '', setValue }: ScenarioScheduleTabProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  
  const [isMultiOccurrence, setIsMultiOccurrence] = useState<boolean>(false);
  const [occurrenceType, setOccurrenceType] = useState<string>('');


  useEffect(() => {
    setIsMultiOccurrence(initialIsMultiOccurrence);

  }, [initialIsMultiOccurrence]);

  useEffect(() => {
    setOccurrenceType(initialOccurrenceType);

  }, [initialOccurrenceType]);


  return (
    <>
      <SidebarContainer>
        <Spacing>
          <Controller
            control={control}
            name="startDate"
            render={({ field, fieldState }) => (
              <SingleDatePicker 
                dateInputProps={{
                  labelText: 'Start date',
                  errorMessage: getFormFieldErrorMessage(fieldState),
                  id: 'id_scenario_start_date',
                  name: field.name,
                  type: 'text',
                  isLabelSideBySide: true,
                }}
                {...field}
                selectedDates={field.value ? [new Date(String(field.value))] : undefined} 
                onSelected={(dates) => {
                  field.onChange(dates[0]);
                  setValue('isDisplayingCreatedDate', false);
                }} 
              />
            )}
          />
        </Spacing>
        
        <Spacing>
          <Controller
            control={control}
            name="endDate"
            render={({ field, fieldState }) => (
              <SingleDatePicker 
                dateInputProps={{
                  labelText: 'End date',
                  errorMessage: getFormFieldErrorMessage(fieldState),
                  id: 'id_scenario_end_date',
                  name: field.name,
                  type: 'text',
                  isLabelSideBySide: true,
                  isRequired: true,
                }}
                {...field}
                selectedDates={field.value ? [new Date(String(field.value))] : undefined} 
                onSelected={(dates) => field.onChange(dates[0])} 
              />
            )}
          />
        </Spacing>
      

        <Spacing>
          <Controller
            control={control as Control<FormFieldProps>}
            name="isMultiOccurrence"
            render={({ field }) => (
              <ToggleSwitch
                additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
                id="id_is_multi_occurrence"
                isChecked={field.value}
                labelText="Multiple occurrence"
                name={field.name}
                tooltipPosition="start"
                isInline
                isLabelSideBySide
                onChange={(event)  => {
                  const { target: { checked: isChecked }} = event;
                  setIsMultiOccurrence(isChecked);
                  field.onChange(event);
                }}
              />
            )}
          />
        </Spacing>
      </SidebarContainer>

      { isMultiOccurrence && (
        <FieldsetAccordionGroup
          data={[
            {
              id: 'id_occurrence_accordion',
              label: 'Occurrence',
              isOpenOnLoad: true,
              children: (
                <>
                  <Spacing size="2x-large">
                    <Controller
                      control={control as Control<FormFieldProps>}
                      name="maxRecurrence"
                      render={({ field, fieldState }) => (
                        <Input
                          errorMessage={getFormFieldErrorMessage(fieldState)}
                          iconAfter={{
                            isTextOnly: true,
                            helperText: 'Occurrences'
                          }}
                          id="id_scenario_max_recurrence"
                          labelText="End after"
                          tooltipText="How many times will this scenario run"
                          type="number"
                          isLabelSideBySide
                          isRequired
                          {...field}
                          
                        />
                      )}
                    />
                  </Spacing>
                  
                  <Spacing size="2x-large">
                    <Controller
                      control={control as Control<FormFieldProps>}
                      name="multiOccurrenceType"
                      render={({ field }) => (
                        <Select
                          id="id_multi_occurence_type"
                          labelText="Occurrence type"
                          options={[
                            {
                              name: 'Daily',
                              value: 'daily',
                              id: 'daily',
                            },
                            {
                              name: 'Weekly',
                              value: 'weekly',
                              id: 'weekly',
                            },
                            {
                              name: 'Monthly',
                              value: 'monthly',
                              id: 'monthly',
                            },
                            {
                              name: 'Yearly',
                              value: 'yearly',
                              id: 'yearly',
                            },
                          ]}
                          {...field}
                          isLabelSideBySide
                          isRequired
                          onChange={(event) => {
                            const { target: { value }} = event;
                            setOccurrenceType(value);
                            field.onChange(event);
                          }}
                        />
                      )}
                    />
                  </Spacing>

                  {occurrenceType === 'daily' && (
                    <ScheduleTabDaily control={control} />
                  )}
                  
                  {occurrenceType === 'weekly' && (
                    <ScheduleTabWeekly control={control} />
                  )}
                  
                  {occurrenceType === 'monthly' && (
                    <ScheduleTabMonthly control={control} />
                  )}
                  
                  {occurrenceType === 'yearly' && (
                    <ScheduleTabYearly control={control} />
                  )}

                </>
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export { ScenarioScheduleTab };
