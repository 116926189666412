import React from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Spacing, SingleDatePicker } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { Controller } from 'react-hook-form';

import { InvitationRemindersScheduleTabProps } from './InvitationRemindersScheduleTab.interfaces';


const InvitationRemindersScheduleTab = ({ setValue, control }: InvitationRemindersScheduleTabProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <SidebarContainer>
      <Spacing size="large">
        <Controller
          control={control}
          name="start"
          render={({ field, fieldState }) => (
            <SingleDatePicker 
              dateInputProps={{
                labelText: 'Start date',
                errorMessage: getFormFieldErrorMessage(fieldState),
                id: 'id_start_date',
                name: field.name,
                type: 'text',
                isLabelSideBySide: true,
              }}
              {...field}
              selectedDates={field.value ? [new Date(String(field.value))] : undefined} 
              onSelected={(dates) => setValue('start', dates[0])} 
            />
          )}
        />
      </Spacing>
      <Spacing size="large">
        <Controller
          control={control}
          name="end"
          render={({ field, fieldState }) => (
            <SingleDatePicker 
              dateInputProps={{
                labelText: 'End date',
                errorMessage: getFormFieldErrorMessage(fieldState),
                id: 'id_end_date',
                name: field.name,
                type: 'text',
                isLabelSideBySide: true,
              }}
              {...field}
              selectedDates={field.value ? [new Date(String(field.value))] : undefined} 
              onSelected={(dates) => setValue('end', dates[0])} 
            />
          )}
        />
      </Spacing>
    </SidebarContainer>
  );
};

export { InvitationRemindersScheduleTab };
