import { gql } from '@apollo/client';

const GET_SCENARIOS_QUERY = gql`
  query GetScenarios(
    $after: String = null
    $filter: String = null
    $first: Int = null
    $projectId: String!
    $shouldIncludeConditions: Boolean!
    $status: EScenarioStatus!
  ) {
    scenarioQueries {
      getScenarios(after: $after, first: $first, filter: $filter, projectId: $projectId, status: $status) {
        edges {
          cursor
          node {
            carbonCopies
            conditions @include(if: $shouldIncludeConditions) {
              __typename
            }
            createdDate
            delayBetweenNotification
            delayBetweenNotificationInDay
            delayBetweenNotificationInMonth
            delayBetweenNotificationInYear
            delayBetweenNotificationInWeek
            description
            endDate
            id
            isTestPhase
            maxRecurrence
            schedule {
              ... on ScheduleDailyGraphType {
                occurEvery
              }
              ... on ScheduleWeeklyGraphType {
                occurEvery
                recurrenceDays
              }
              ... on ScheduleMonthlyGraphType {
                day
                occurEvery
              }
              ... on ScheduleYearlyGraphType {
                day
                month
                occurEvery
              }
            }
            startDate
            status
            subject
            title
            updatedDate
            overrideReceiver
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  }
`;

export { GET_SCENARIOS_QUERY };
