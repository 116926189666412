import React from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { FormFieldProps, Input, Spacing } from '@netfront/ui-library';
import { Control, Controller } from 'react-hook-form';

import { ScheduleTabDailyProps } from './ScheduleTabDaily.interfaces';


const ScheduleTabDaily = ({ control }: ScheduleTabDailyProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <Spacing>
      <Controller
        control={control as Control<FormFieldProps>}
        name="dailyOccurEvery"
        render={({ field, fieldState }) => (
          <Input
            errorMessage={getFormFieldErrorMessage(fieldState)}
            iconAfter={{
              isTextOnly: true,
              helperText: 'Days'
            }}
            id="id_scenario_daily_occurrence"
            labelText="Every"
            minNumber={1}
            tooltipText="How regularly will this scenario run in days"
            type="number"
            isLabelSideBySide
            isRequired
            {...field}
            
          />
        )}
      />
    </Spacing>
  );
};

export { ScheduleTabDaily };
