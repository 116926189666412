import { gql } from '@apollo/client';

const SORT_CONDITION_GROUP_MUTATION = gql`
  mutation SortConditionGroup($conditionGroupId: Int!, $sort: Int!) {
    conditionGroups {
      sort(conditionGroupId: $conditionGroupId, sort: $sort) {
        id
        sort
        operator
      }
    }
  }
`;

export { SORT_CONDITION_GROUP_MUTATION };
