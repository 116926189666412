import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, ExcelIcon } from '@netfront/ui-library';
import axios, { AxiosResponse } from 'axios';
import { ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { addMonths } from 'date-fns';
import { useGetReportedPostsReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { REPORTED_POSTS_COLUMNS } from './ReportedPostsReportPage.constants';
import { getReportedPostsTableData } from './ReportedPostsReportPage.helpers';
import { ReportedPostsCsvRowData, IReportedPostsItem, IReportedPostsTableData } from './ReportedPostsReportPage.interfaces';



const ReportedPostsReportPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [reportedPostsItems, setReportedPostsItems] = useState<IReportedPostsItem[]>();
  const [allReportedPostsItems, setAllReportedPostsItems] = useState<IReportedPostsItem[]>([]);
  const [reportedPostsTableData, setReportedPostsTableData] = useState<IReportedPostsTableData[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>([addMonths(new Date(), -1), new Date()]);
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isGenerate, setIsGenerate] = useState<boolean>(false);


  const {
    handleGetReportedPostsReport,
    isLoading: isGetReportedPostsLoading = false,
  } = useGetReportedPostsReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;

      if (isGenerate) {
        setIsGenerate(false);
        setCsvUrl(presignedUrl);
        axios
          .get(presignedUrl)
          .then((res: AxiosResponse) => {

            const { data } = Papa.parse<ReportedPostsCsvRowData>(String(res.data), {
              header: true,
              skipEmptyLines: true,
            });

            setTotalCount(data.length);
            const formattedItems = data.map((row: ReportedPostsCsvRowData) => ({
              id: Number(row['Author id']),
              postId: String(row['Post id']),
              groupName: String(row['Group name']),
              userType: String(row['User type']),
              firstName: String(row['First name']),
              lastName: String(row['Last name']),
              email: String(row['Email']),
              post: String(row['Post']),
              postDate: String(row['Post date']),
              postDateUtc: String(row['Post date (utc)']),
              likes: String(row['Likes']),
              feeling: String(row['Feeling']),
            }));

            setAllReportedPostsItems(formattedItems);
            const paginatedItems = formattedItems.slice(0, pageSize);
            setReportedPostsItems(paginatedItems);


            setLastItemIndex(pageSize);
          })
          .catch((error) => {
            handleToastCustomError({
              message: 'whoops! something went wrong',
            });
            // eslint-disable-next-line no-console
            console.log({ error });
          });
      } else {
        window.open(presignedUrl, '_blank');
      }
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    projectId: String(projectId),
  });



  const generateReport = () => {
    setIsGenerate(true);
    void handleGetReportedPostsReport({
      dateFrom: dateRange[0] ? dateRange[0] : undefined,
      dateTo: dateRange[1] ? dateRange[1] : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId,
      fileType: 'CSV'
    });
  };

  const handleDownload = (type: "CSV" | "EXCEL") => {
    setIsGenerate(false);
    void handleGetReportedPostsReport({
      dateFrom: dateRange[0] ? dateRange[0] : undefined,
      dateTo: dateRange[1] ? dateRange[1] : undefined,
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId,
      fileType: type
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setReportedPostsItems(allReportedPostsItems.slice(0, selectedPageSize));
  };


  const handlePaginate = () => {
    const updateIndex = lastItemIndex + pageSize;
    setLastItemIndex(updateIndex);
    setIsLoadMoreDisabled(totalCount <= updateIndex);
    setReportedPostsItems(allReportedPostsItems.slice(0, updateIndex));
  };


  useEffect(() => {
    if (!reportedPostsItems) {
      return;
    }

    setReportedPostsTableData(
      getReportedPostsTableData({
        items: reportedPostsItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportedPostsItems]);


  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetReportedPostsLoading;

  return (
    <ReportTable<IReportedPostsTableData>
      activePath={['reporting', 'reporting-social', 'export-reported-posts']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'Posts',
      }]}
      additionalDownloadOptions={
        csvUrl ? (
        <Button
          additionalClassNames="c-report-download-button"
          icon={ExcelIcon}
          size="xs"
          text="Download as excel"
          isIconOnly
          onClick={() => handleDownload('EXCEL')}
        />
      ): undefined}
      columns={REPORTED_POSTS_COLUMNS}
      csvUrl={csvUrl}
      data={reportedPostsTableData}
      dateRange={dateRange}
      description={`Reported posts reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={void handlePaginate}
      informationBoxMessage={'This page exports reported posts based on the selected filters'}
      isLoading={isLoading}
      isLoadMoreDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      totalCount={totalCount}
      onDateSelect={(dates: Date[]) => setDateRange(dates)}
      onGenerate={generateReport}
    />
  );
};

export { ReportedPostsReportPage };
