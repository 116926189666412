import { useContext, useState, useEffect } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { CachingEntitiesContext } from '../../../../context';
import { DashboardListTemplatePage } from '../../../Shared';

const SocialDashboardPage = () => {
  const { query } = useRouter();
  const { projectId: queryProjectId, organisationId: queryOrganisationId } = query;

  const { project } = useContext(CachingEntitiesContext);

  const [projectName, setProjectName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projectId, setProjectId] = useState<string>('');
  const [organisationId, setOrganisationId] = useState<string>('');
  const [dashboardUrl, setDashboardUrl] = useState<string>('');

  useEffect(() => {
    if (!project) {
      return;
    }

    const { name } = project;

    setProjectName(name);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.name, project?.features]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    setOrganisationId(queryOrganisationId as string);
  }, [queryOrganisationId]);

  useEffect(() => {
    if (!(projectId && organisationId)) return;

    setDashboardUrl(`/dashboard/${String(organisationId)}/${String(projectId)}`);
  }, [organisationId, projectId]);

  return (
    <DashboardListTemplatePage
      activePage="social"
      additionalClassNames="c-social-dashboard__page-layout"
      breadcrumbItems={[
        {
          key: '0',
          content: <Link href={dashboardUrl}><span>Dashboard</span></Link>,
        },
        {
          key: '1',
          content: 'Social',
        },
      ]}
      description={`Social dashboard for ${projectName || 'the project'}`}
      informationBoxMessage={`Manage and add to your social settings`}
      isLoading={isLoading}
      logoUrl={project?.logo?.presignedUrl}
      pageId="social"
      pageTitle={`${projectName ? projectName + ' library' : 'Library'} dashboard`}
      projectName={projectName}
    />
  );
};

export { SocialDashboardPage };
