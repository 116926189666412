import React, { useState, useEffect, ReactNode } from 'react';

import { ApolloError } from '@apollo/client';
import { IDBUserFlowStep, IUseGetUserFlowStepsOnCompletedResponse, useGetUserFlowSteps } from '@netfront/ekardo-content-library';
import {
  Dialog,
  Input,
  NavigationButton,
  Preloader,
  Select,
  Spacing,
  Spinner,
  Textarea,
  ToggleSwitch,
  Tooltip,
  SidebarButtons,
  InformationBox,
  CaretIcon,
  TooltipIcon,
  InfoIcon,
  useControlledForm,
  FormFieldProps,
  ControlledForm,
  FlexContainer,
  ISearchList,
  SelectWithSearch,
} from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { useCopyScenarioCondition, useDeleteScenarioCondition, useGetPaginatedScenarioConditions, useToast, useUpsertScenarioCondition } from 'hooks';
import { DBConditionType } from 'interfaces';
import { Control, Controller } from 'react-hook-form';

import { scenarioConditionTabConstants } from '../ScenarioConditionTab.constants';

import {
  ConditionFormDayTypeView,
  ConditionFormHourTypeView,
  ConditionFormStepCompletedTypeView,
  ConditionFormStepStartedTypeView,
  ConditionFormUserActivatedTypeView,
  ConditionFormUserLoginTypeView,
  ConditionFormUserRegisteredTypeView,
  ConditionFormUserLastLoginTypeView,
  ConditionFormUserSessionTypeView,
  ConditionFormStepProgressionTypeView,
  ConditionFormManyUserGroupTypeView,
} from './ConditionFormTypeViews';
import { getConditionTypeSelectOptions, getScenarioConditionDefaultValues, getScenarioConditionVariables } from './ConditionFormView.helpers';
import { ConditionFormViewProps } from './ConditionFormView.interfaces';

const ConditionFormView = ({
  projectId,
  scenarioId,
  selectedCondition,
  selectedConditionGroupId,
  onUpdate,
  onCancel,
}: ConditionFormViewProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const {
    conditionTypeMap,
    conditionSelectTypes,
    conditionTypeMessageMap,
    descriptionCharacterMaxLength,
    titleCharacterMaxLength,
  } = scenarioConditionTabConstants;

  const [conditionType, setConditionType] = useState<DBConditionType>('' as DBConditionType);
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [searchSuggestions, setSearchSuggestions] = useState<ISearchList[]>([]);
  const [allUserFlowSteps, setAllUserFlowSteps] = useState<IDBUserFlowStep[]>();
  const [isCopyScenarioConditionVisible, setIsCopyScenarioConditionVisible] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({});

  const { control, handleSubmit, setValue, reset} = useControlledForm({ defaultValues });

  const { handleUpsertScenarioCondition, isLoading: isUpsertScenarioConditionLoading = false } = useUpsertScenarioCondition({
    onCompleted: () => {
      reset();
      handleToastSuccess({
        message: `Condition successfully ${selectedCondition ? 'updated': 'created' }`,
      });
      onUpdate();
    },
  });

  const { handleGetUserFlowSteps, isLoading: isGetUserFlowStepsLoading } = useGetUserFlowSteps({
    onCompleted: ({ userFlowSteps }: IUseGetUserFlowStepsOnCompletedResponse) => {
      setAllUserFlowSteps(userFlowSteps);    
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleCopyScenarioCondition, isLoading: isCopyScenarioConditionLoading = false } = useCopyScenarioCondition({
    onCompleted: () => {
      reset();
      onUpdate();

      handleToastSuccess({
        message: 'Condition copied successfully',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteScenarioCondition: executeDeleteScenarioCondition, isLoading: isDeleteScenarioConditionLoading = false } = useDeleteScenarioCondition({
    onCompleted: () => {
      reset();
      handleToastSuccess({
        message: 'Condition deleted successfully',
      });

      onUpdate();
      setIsDeleteDialogOpen(false);

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetPaginatedScenarioConditions, isLoading: isGetPaginatedScenarioConditionsLoading = false } = useGetPaginatedScenarioConditions({
    onCompleted: ({ conditionConnection: { edges } }) => {
      const conditions = edges.map(({ node }) => node);

      const suggestions = conditions.map((condition) => {
        const { id, title } = condition;

        return {
          id,
          label: title,
        };
      });

      setSearchSuggestions(suggestions.sort((a, b) => a.label.localeCompare(b.label)));
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleAttachCondition = (selectedId: string | number) => {

    handleCopyScenarioCondition({
      conditionGroupId: Number(selectedConditionGroupId),
      conditionId: Number(selectedId),
    });
  };


  const handleSave = (item: FormFieldProps) => {
    const variables = getScenarioConditionVariables({ conditionGroupId: Number(selectedConditionGroupId), item});

    handleUpsertScenarioCondition({
      conditionId: selectedCondition?.id,
      ...variables,
    });
  };


  const conditionTypeViewMap: {[key: string]: ReactNode} = {
    day: <ConditionFormDayTypeView control={control as Control<FormFieldProps>} />,
    hour: <ConditionFormHourTypeView control={control as Control<FormFieldProps>} />,
    stepStarted:
      allUserFlowSteps && allUserFlowSteps.length > 0 ? (
        <ConditionFormStepStartedTypeView
          control={control as Control<FormFieldProps>}
          initialComparatorValue={defaultValues.comparator}
          setValue={setValue}
          userFlowSteps={allUserFlowSteps}
        />
      ) : (
        <Preloader isLoading={isGetUserFlowStepsLoading ?? false} />
      ),
    stepCompleted:
      allUserFlowSteps && allUserFlowSteps.length > 0 ? (
        <ConditionFormStepCompletedTypeView
          control={control as Control<FormFieldProps>}
          initialComparatorValue={defaultValues.comparator}
          setValue={setValue}
          userFlowSteps={allUserFlowSteps}
        />
      ) : (
        <Preloader isLoading={isGetUserFlowStepsLoading ?? false} />
      ),
    stepProgression:
      allUserFlowSteps && allUserFlowSteps.length > 0 ? (
        <ConditionFormStepProgressionTypeView
          control={control as Control<FormFieldProps>}
          initialComparatorValue={defaultValues.comparator}
          setValue={setValue}
          userFlowSteps={allUserFlowSteps}
        />
      ) : (
        <Preloader isLoading={isGetUserFlowStepsLoading ?? false} />
      ),
    userActivated: (
      <ConditionFormUserActivatedTypeView 
        control={control as Control<FormFieldProps>} 
        initialComparatorValue={defaultValues.comparator}
        setValue={setValue}
        />
    ),
    userLastLogin: (
      <ConditionFormUserLastLoginTypeView control={control as Control<FormFieldProps>} />
    ),
    userLogin: (
      <ConditionFormUserLoginTypeView 
        control={control as Control<FormFieldProps>} 
        initialComparatorValue={defaultValues.comparator}
        setValue={setValue} 
      />
    ),
    manyUserGroup: (
      <ConditionFormManyUserGroupTypeView
        control={control as Control<FormFieldProps>}
        projectId={projectId}
        setValue={setValue}
      />
    ),
    userRegistered: (
      <ConditionFormUserRegisteredTypeView control={control as Control<FormFieldProps>} />
    ),
    userSession: (
      <ConditionFormUserSessionTypeView 
        control={control as Control<FormFieldProps>} 
        initialComparatorValue={defaultValues.comparator}
        setValue={setValue} 
      />
    ),
  };


  useEffect(() => {
    if (!projectId ) return;

    void handleGetUserFlowSteps({
      projectId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    setDefaultValues(getScenarioConditionDefaultValues({ condition: selectedCondition }));

    setConditionType(conditionTypeMap[String(selectedCondition?.__typename ?? '')] as DBConditionType);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCondition]);

  useEffect(() => {
    if (!isCopyScenarioConditionVisible) return;

    handleGetPaginatedScenarioConditions({
      projectId,
      scenarioId,
      filter: '',
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCopyScenarioConditionVisible])


  const isLoading = isUpsertScenarioConditionLoading || isDeleteScenarioConditionLoading || isCopyScenarioConditionLoading || isGetPaginatedScenarioConditionsLoading;

  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />
      <Spacing size="2x-large">
        <FlexContainer alignItems="center" justifyContent="space-between">
          <NavigationButton
            additionalClassNames="c-action__back-to-action"
            direction="previous"
            icon={CaretIcon}
            rotationCssSuffix="90"
            text="Condition"
            onClick={onCancel}
          />
          <Tooltip
            additionalClassNames="c-scenario-sidebar__tooltip"
            bubbleTheme="dark"
            icon={TooltipIcon}
            placement="left"
            text="Configure the scenario trigger condition"
          />
        </FlexContainer>
      </Spacing>

      {!selectedCondition && (
        <Spacing size="4x-large">
          <ToggleSwitch
            additionalClassNames='c-sidebar-toggle'
            id="copy-condition"
            isChecked={isCopyScenarioConditionVisible}
            labelText="Copy existing condition"
            isInline
            isLabelSideBySide
            onChange={() => setIsCopyScenarioConditionVisible(!isCopyScenarioConditionVisible)}
          />
        </Spacing>
      )}

      {isCopyScenarioConditionVisible ? (
        <Spacing size="2x-large">
          <SelectWithSearch
            buttonText="Copy existing condition"
            countText="conditions"
            id="id_existing_conditions"
            isDisplaySearchContent={isSearchContentVisible}
            isLabelHidden={false}
            labelText="Search"
            searchList={searchSuggestions}
            tooltipText="Search and copy existing condition"
            isAvatarVisible
            isLabelSideBySide
            onDisplaySearchContent={() => setSearchIsContentVisible(!isSearchContentVisible)}
            onSearchItemClick={handleAttachCondition}
          />
        </Spacing>
      ) : (
        <ControlledForm
          callBack={(item: FormFieldProps) => {
            handleSave(item);
          }}
          handleSubmit={handleSubmit}
          isStopPropagation
        >
          <Spacing size="large">
            <Controller
              control={control as Control<FormFieldProps>}
              name="title"
              render={({ field }) => (
                <Input
                  id="title"
                  labelText="Title"
                  maxLength={titleCharacterMaxLength}
                  type="text"
                  isLabelSideBySide
                  isRequired
                  {...field}
                />
              )}
            />
            <Controller
              control={control as Control<FormFieldProps>}
              name="description"
              render={({ field }) => (
                <Textarea
                  id="description"
                  labelText="Description"
                  maxLength={descriptionCharacterMaxLength}
                  isLabelSideBySide
                  {...field}
                />
              )}
            />
          </Spacing>

          <Spacing size="x-large">
            <Controller
              control={control as Control<FormFieldProps>}
              name="conditionType"
              render={({ field }) => (
                <Select
                  id="condition-type"
                  isDisabled={Boolean(selectedCondition)}
                  labelText="Trigger"
                  options={getConditionTypeSelectOptions(conditionSelectTypes)}
                  tooltipText="Define a trigger condition"
                  isLabelSideBySide
                  isRequired
                  {...field}
                  onChange={(event) => {
                    const { target: { value }} = event;
                    field.onChange(event);
                    setConditionType(value as DBConditionType);
                  }}
                />
              )}
            />
            {Boolean(conditionType) && (
              <InformationBox
                headingTagComponent="span"
                icon={InfoIcon}
                message={conditionTypeMessageMap[conditionType]}
              />
            )}
          </Spacing>

          {conditionTypeViewMap[conditionType]}

          
          {selectedCondition ? (
            <Dialog
              isOpen={isDeleteDialogOpen}
              title={`Delete condition?`}
              isNarrow
              onCancel={() => setIsDeleteDialogOpen(false)}
              onClose={() => setIsDeleteDialogOpen(false)}
              onConfirm={() => {
            
                executeDeleteScenarioCondition({
                  conditionId: selectedCondition.id,
                });
              }}
            />
          ) : null}

          <SidebarButtons
            onCancel={onCancel}
            onDelete={selectedCondition ? () => setIsDeleteDialogOpen(true) : undefined}
            onSaveButtonType="submit"
          />
        </ControlledForm>
        
      )}
    </SidebarContainer>
  );
};

export { ConditionFormView };
