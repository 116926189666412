import { gql } from '@apollo/client';

export const FRAGMENT_RESPONSE_SET = gql`
  fragment ResponseSetFragment on QuestionResponseSetType {
    id
    title
    availableResponses {
      id
      asset {
        assetId
        presignedUrl
          s3Key
        contentType
      }
      label
      questionResponseSetId
      value
    }
  }
`;


const GET_FORM_DETAILS = gql`
  query getContentGroupFormDetailReq($contentGroupId: Int!) {
    contentGroup {
      getContentGroup(contentGroupId: $contentGroupId) {
        id
        projectId
        sort
        title
        url
        description
        status
        formType
        contentPages {
          id
          contentSections {
            id
            sectionContainers {
              id
              snippets {
                id
                ... on ContentSnippetQuestionType {
                  question
                  configuration {
                    id
                    ... on Checkbox {
                      responseSet {
                        ...ResponseSetFragment
                      }
                    }
                    ... on Radio {
                      responseSet {
                        ...ResponseSetFragment
                      }
                    }
                    ... on DropDownList {
                      responseSet {
                        ...ResponseSetFragment
                      }
                    }
                  }
                }
              }
            }
          }
        }
        correctResponses {
          contentGroupId
          contentSnippetId
          ... on CorrectResponseCheckboxType {
            correctResponses {
              questionResponseCheckboxId
            }
          }
          ... on CorrectResponseRadioType {
            questionResponseRadioId
          }
          ... on CorrectResponseDropDownListType {
            questionResponseDropDownListId
          }
        }

      }
    }
  }
  ${FRAGMENT_RESPONSE_SET}
`;


export { GET_FORM_DETAILS };


// contentPages {
//   id
//   contentSections {
//     id
//     sectionContainers {
//       id
//       snippets {
//         id
//         ... on ContentSnippetQuestionType {
//           question
//           configuration {
//             id
//             ... on Checkbox {
//               responseSet {
//                 ...ResponseSetFragment
//               }
//             }
//             ... on Radio {
//               responseSet {
//                 ...ResponseSetFragment
//               }
//             }
//             ... on DropDownList {
//               responseSet {
//                 ...ResponseSetFragment
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
// correctResponses {
//   contentGroupId
//   contentSnippetId
//   ... on CorrectResponseCheckboxType {
//     correctResponses {
//       questionResponseCheckboxId
//     }
//   }
//   ... on CorrectResponseRadioType {
//     questionResponseRadioId
//   }
//   ... on CorrectResponseDropDownListType {
//     questionResponseDropDownListId
//   }
// }
