import { useContext, useEffect, useState } from 'react';

import { BinIcon, Button, CustomRating, Dialog, EmptyState, FlexContainer, PlusIcon, Skeleton, Spacing, Spinner, Toolbar } from '@netfront/ui-library';
import { AppComponentPreview, AppComponentSidebarView, AppTemplatePage, IAppComponentFileDetails, UpsertComponentCodeSidebarView } from 'components';
import { AppDetailsContext, DashboardContext } from 'context';
import { useDeleteAppComponent, useGetAppComponentDetails, useToast } from 'hooks';
import { IDBAppComponent } from 'interfaces';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { handleGetAppFileText } from './AppComponentPage.helpers';


const AppComponentPage = () => {

  const { query, push } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();
  const { projectId: queryProjectId, versionId: queryVersionId, componentId: queryComponentId } = query;
  const { appPageBaseUrl, appDetails, isLoading: isAppLoading = false, onUpdateApp, getAppVersion } = useContext(AppDetailsContext);
  const { dashboardLink } = useContext(DashboardContext);
  
  const [versionId, setVersionId] = useState<number>();
  const [versionName, setVersionName] = useState<string>();
  const [componentId, setComponentId] = useState<number>();
  const [deleteComponentId, setDeleteComponentId] = useState<number>();
  const [projectId, setProjectId] = useState<string>();
  const [isUpsertComponentDetailsSidebarOpen, setUpsertComponentDetailsSidebarOpen] = useState<boolean>(false);
  const [isAddNewComponentSidebarOpen, setIsAddNewComponentSidebarOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [currentComponent, setCurrentComponent] = useState<IDBAppComponent>();
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [componentFileDetails, setComponentFileDetails] = useState<IAppComponentFileDetails>({
    cssContent: '',
    htmlContent: '',
    jsContent: '',
  });
  
  const { logo, averageRateValue = 0, identifier = '' } = appDetails ?? {};
  const { presignedUrl} = logo ?? {}; 
  const { title = '' } = currentComponent ?? {}

  const { handleGetAppComponentDetails, isLoading: isGetAppComponentDetailsLoading = false } = useGetAppComponentDetails({
    fetchPolicy: 'no-cache',
    onCompletedAsync: async ({ componentDetails }) => {
      const { appVersionId} = componentDetails;
      setCurrentComponent(componentDetails);
      const versionDetails = getAppVersion(appVersionId);
      setVersionName(versionDetails?.version);
      setIsReadOnly(versionDetails?.status !== 'DRAFT');
      
      const { files = [] } = componentDetails;
      setIsEmpty(files.length === 0);

      if (files.length === 0) return;

      const html = files.find(({ fileType}) => fileType === 'HTML');
      const css = files.find(({ fileType}) => fileType === 'CSS');
      const javascript = files.find(({ fileType}) => fileType === 'JAVASCRIPT');

      const htmlContent = await handleGetAppFileText(html);
      const jsContent = await handleGetAppFileText(javascript);
      const cssContent = await handleGetAppFileText(css);

      
      setComponentFileDetails({
        htmlContent,
        jsContent,
        cssContent
      })
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteAppComponent, isLoading: isDeleteAppComponentLoading = false } = useDeleteAppComponent({
    onCompleted: () => {
      onUpdateApp();
      setIsDeleteDialogOpen(false);
      handleToastSuccess({ message: 'Component successfully deleted'});
      if (deleteComponentId !== componentId) {
        handleGetAppComponentDetails({
          appComponentId: Number(componentId),
        });
        setDeleteComponentId(undefined);
      } else {
        setDeleteComponentId(undefined);
        push(`${appPageBaseUrl}/${String(versionId)}/components`).catch((error) =>
          handleToastError({
            error,
          }),
        );
      }
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleUpdate = (id?: number) => {
    onUpdateApp();
    if (id) {
      setIsAddNewComponentSidebarOpen(false);
      push(`${appPageBaseUrl}/${String(versionId)}/components/${id}`).catch((error) =>
        handleToastError({
          error,
        }),
      );
    } else {
      setUpsertComponentDetailsSidebarOpen(false);
      handleGetAppComponentDetails({
        appComponentId: Number(componentId),
      });
    }
    
    
    onUpdateApp();
  };


  useEffect(() => {
    if (!componentId) return;
    handleGetAppComponentDetails({
      appComponentId: componentId,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentId]);


  useEffect(() => {
    if (!queryVersionId) return;
    setVersionId(Number(queryVersionId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryVersionId]);

  useEffect(() => {
    if (!queryComponentId) return;
    setComponentId(Number(queryComponentId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryComponentId]);

  useEffect(() => {
    if (!queryProjectId) return;
    setProjectId(String(queryProjectId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryProjectId]);

  const isLoading = isDeleteAppComponentLoading || isAppLoading || isGetAppComponentDetailsLoading;

  return (
    <AppTemplatePage 
      activePageId={`versions${versionId ? `--version-${versionId}` : ''}${componentId ? `--component-${componentId}`: ''}`}
      addOnClick={() => setIsAddNewComponentSidebarOpen(true)}
      breadcrumbItems={[
        {
          key: '0',
          content: <Link href={`${String(dashboardLink)}/library/apps/marketplace`}>Market place</Link>,
        },
        {
          key: '1',
          content: 'Component preview',
        },
      ]}
      dashboardLink={`${String(dashboardLink)}/library/apps/marketplace`}
      dashboardLinkText="Marketplace"
      description={`Component preview for ${title}`}
      informationBoxMessage={
        <FlexContainer justifyContent="space-between">
          <span>Component preview for <strong>{title}</strong></span>
          <FlexContainer><strong>Overall rating</strong>: <CustomRating iconCount={1} name="overall-rating" rateValue={averageRateValue > 0 ? 1 : 0} isReadOnly/> {averageRateValue}</FlexContainer>
        </FlexContainer>
      }
      isLoading={isLoading}
      logoUrl={presignedUrl}
      pageTitle="Component preview"
      settingsDropdownOptions={[
        {
          id: 'id_add_new_component',
          icon: PlusIcon,
          label: 'New component',
          isVisibleByTypeArray: ['component'],
          onClick: !isReadOnly ? () => setIsAddNewComponentSidebarOpen(true): undefined,
        },
        {
          id: 'id_delete_item',
          icon: BinIcon,
          label: 'Delete',
          isVisibleByTypeArray: ['component'],
          isHidden: false,
          onClick: !isReadOnly ?  ({ id: entityId }) =>  {
            if (!entityId) return; 
            const [, selectedComponentId] = String(entityId).split('-');
            setIsDeleteDialogOpen(true);
            setDeleteComponentId(Number(selectedComponentId));
          } : undefined,
        },
      ]} 
      size={presignedUrl ? 'small' : 'medium'} 
      title={String(title)}
      versionId={versionId}
    >
      <div style={{width: '100%'}}>
        <Spinner isLoading={isLoading} />
        <Spacing size="2x-large">
          <Toolbar
            childrenEnd={
              <Button
                size="xs"
                text="Edit code"
                variant="primary-inverse"
                onClick={() => setUpsertComponentDetailsSidebarOpen(true)}
              />
            }
            childrenStart={<></>}
          />
        </Spacing>
        {isLoading ? (
            <Skeleton height="13.625rem" />
        ): (
          <>
            {isEmpty ? (
              <EmptyState
                buttonText="Add code"
                imageUrl="/images/content-landing.svg"
                size="large"
                text="You haven't added any code. Click add code to get started."
                title="Get started!"
                onClick={() => setUpsertComponentDetailsSidebarOpen(true)} 
              />
            ): (
              <AppComponentPreview componentFileDetails={componentFileDetails} />
            )}
          </>
        )}
        
      </div>
      {currentComponent && projectId && (
        <UpsertComponentCodeSidebarView
          componentFileDetails={componentFileDetails}
          currentComponent={currentComponent}
          isReadOnly={isReadOnly}
          isSideBarOpen={isUpsertComponentDetailsSidebarOpen}
          projectId={projectId}
          onCancel={() => setUpsertComponentDetailsSidebarOpen(false)}
          onUpdate={handleUpdate}

        />
      )}
      {identifier && versionName && (
        <AppComponentSidebarView 
          appIdentifier={identifier}
          appVersion={versionName}
          isSideBarOpen={isAddNewComponentSidebarOpen} 
          onCancel={() => setIsAddNewComponentSidebarOpen(false)} 
          onUpdate={handleUpdate} 
        />
      )}
      <Dialog
        isOpen={isDeleteDialogOpen}
        title="Delete Component?"
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!deleteComponentId) {
            return;
          }

          handleDeleteAppComponent({
            componentId: deleteComponentId,
          });
        }}
      />
    </AppTemplatePage>
  );
};

export { AppComponentPage };
