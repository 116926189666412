
import { ApolloError } from '@apollo/client';
import { ITemplatesAllocation, IUpdateScheduleDailyMutationVariables, IUpdateScheduleMonthlyMutationVariables, IUpdateScheduleWeeklyMutationVariables, IUpdateScheduleYearlyMutationVariables, useAddScenario, useDeleteSchedule, useSetTemplates, useToast, useUpdateScenario, useUpdateSchedule, useUpdateScheduleDetails } from 'hooks';
import { IDBScenario } from 'interfaces';



const useUpsertScenario = ({ onCompleted }: { onCompleted: ({ scenario } : { scenario: IDBScenario; } ) => void }) => {

  const { handleToastError } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };


  const { handleUpdateScenario, isLoading: isUpdateScenarioLoading = false } = useUpdateScenario({
    onCompleted,
    onError: handleGetError,
  });

  const { handleAddScenario, isLoading: isAddScenarioLoading = false } = useAddScenario({
    onCompleted,
    onError: handleGetError,
  });

  const { handleUpdateScheduleDetails, isLoading: isUpdateScheduleDetailsLoading = false } = useUpdateScheduleDetails({
      onError: handleGetError,
    });

  const { handleUpdateSchedule, isLoading: isUpdateScheduleLoading = false } = useUpdateSchedule({
    onError: handleGetError,
  });

  const { handleDeleteSchedule, isLoading: isDeleteScheduleLoading = false } = useDeleteSchedule({
    onError: handleGetError,
  });

  const { handleSetTemplates, isLoading: isSetTemplatesLoading } = useSetTemplates({
    onError: handleGetError,
  });

  const handleUpsertScenario = ({
    scenarioId,
    projectId,
    // general
    description,
    isTestPhase = false,
    overrideReceiver,
    isOverrideReceiver = false,
    status,
    title,

    // schedule
    startDate,
    endDate,
    isMultiOccurrence = false,
    maxRecurrence,
    daily,
    weekly,
    monthly,
    yearly,

    // templates
    templatesAllocation = [],
  }: {
    
    daily?: IUpdateScheduleDailyMutationVariables;
    description: string;
    endDate?: Date;
    isMultiOccurrence?: boolean;
    isOverrideReceiver?: boolean;
    isTestPhase?: boolean;
    maxRecurrence?: number
    monthly?: IUpdateScheduleMonthlyMutationVariables;
    overrideReceiver: string
    projectId: string;
    scenarioId?: number;
    startDate?: Date;
    status?: IDBScenario['status'];
    templatesAllocation?: ITemplatesAllocation[];
    title: string;
    weekly?: IUpdateScheduleWeeklyMutationVariables;
    yearly?: IUpdateScheduleYearlyMutationVariables;


  }) => {
    if (!scenarioId) {
      handleAddScenario({
        projectId,
        description,
        isTestPhase,
        title,
        overrideReceiver: isOverrideReceiver ? overrideReceiver : null,
      });
    } else {

      handleUpdateScheduleDetails({
        endDate,
        maxRecurrence: Number(maxRecurrence),
        scenarioId,
        startDate,
      });

      if (!isMultiOccurrence) {
        handleDeleteSchedule({
          scenarioId
        });
      } else {
        handleUpdateSchedule({
          scenarioId,
          daily,
          weekly,
          monthly,
          yearly,
        })
      }

      handleSetTemplates({
        scenarioId,
        templatesAllocation,
      })

      handleUpdateScenario({
        description,
        id: scenarioId,
        isTestPhase,
        status: status as IDBScenario['status'],
        title,
        overrideReceiver: isOverrideReceiver ? overrideReceiver : null,
      });
    }
  };

  return {
    isLoading: isAddScenarioLoading || isUpdateScenarioLoading || isUpdateScheduleDetailsLoading || isUpdateScheduleLoading || isDeleteScheduleLoading || isSetTemplatesLoading,
    handleUpsertScenario,
  };
};
export { useUpsertScenario };
