/* eslint-disable @typescript-eslint/indent */
import { useContext, useEffect, useState } from 'react';

import { useJwtDecoder, useCookie, IGeladaAccessTokenPayload } from '@netfront/common-library';
import { useIdentitySiteUrls, useDomain } from '@netfront/gelada-identity-library';
import { Dropdown, OrganisationIcon, OrganisationNavigation, OrganisationNavigationItemProps, SwitchIcon, TeamIcon, UsersIcon } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { CachingEntitiesContext, UserContext } from '../../context';

const OrganisationMenu = () => {
  const { isDomainReady } = useDomain();

  const { getDecodedJwt } = useJwtDecoder();
  const { getAccessTokenCookie } = useCookie();
  const { hasManageUsersPermission } = useContext(UserContext);

  const { query } = useRouter();
  const { organisationId: queryOrganisationId } = query;

  const { organisation, project } = useContext(CachingEntitiesContext);

  const { getBaseUrl: identityBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [organisationId, setOrganisationId] = useState<string>('');
  const [identityUrl, setIdentityUrl] = useState<string>('');
  const [isCustomBuild, setIsCustomBuild] = useState<boolean>(false);
  const [navigationList, setNavigationList] = useState<OrganisationNavigationItemProps[]>([]);
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setIdentityUrl(identityBaseUrl());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    const accessToken = getAccessTokenCookie();
    if (!accessToken) return;

    const decoded = getDecodedJwt(accessToken) as IGeladaAccessTokenPayload;
    const claim = decoded.custom_build;
    setIsCustomBuild(Boolean(String(claim).toLowerCase() === 'true'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenCookie]);

  useEffect(() => {

    if (!identityUrl) return;

    const dropdownNavigationItems: OrganisationNavigationItemProps[] = [
      {
        isHidden: !organisation || !hasManageUsersPermission,
        header: {
          title: `Current account`,
          name: organisationId ? organisation?.name  ?? '': 'Organisations',
          icon: SwitchIcon,
          href: !isCustomBuild ? `${identityUrl}/dashboard`: undefined
        },
        options: [
          {
            icon: TeamIcon,
            label: 'Team',
            isHidden: isCustomBuild || !hasManageUsersPermission,
            href: `${identityUrl}/account/${String(organisation?.key)}/team`,
          },
        ],
      },
      {
        isHidden: !project,
        header: {
          title: `Current project`,
          name: project?.name ?? '',
          icon: SwitchIcon,
          href: !isCustomBuild ? `${identityUrl}/dashboard`: undefined
        },
        options: [
          {
            icon: UsersIcon,
            label: 'Users',
            isHidden: !project,
            href: `${identityUrl}/project/${String(project?.id)}/user`
          },
        ],
      },
    ];

    setNavigationList(dropdownNavigationItems);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityUrl, isCustomBuild, organisation, organisationId, project, hasManageUsersPermission]);

  useEffect(() => {
    setOrganisationId(queryOrganisationId as string);
  }, [queryOrganisationId]);

  return (
    <div className="c-organisation-menu">
      {organisation && (
        <Dropdown
          dropdownId="organisation-dropdown"
          isDisplayContent={isSearchContentVisible}
          trigger={<OrganisationIcon className="c-icon c-app-header__item" />}
          onDisplayContent={setSearchIsContentVisible}
        >
          <div className="c-organisation-menu">
            <OrganisationNavigation
              dropdownNavigationItems={navigationList}
            />
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export { OrganisationMenu };
