import React from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { FormFieldProps, Input, Spacing, Textarea } from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { Control, Controller } from 'react-hook-form';


import { InvitationReminderGeneralTabConstants } from './InvitationRemindersGeneralTab.constants';
import { InvitationRemindersGeneralTabProps } from './InvitationRemindersGeneralTab.interfaces';

const InvitationRemindersGeneralTab = ({ control }: InvitationRemindersGeneralTabProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { descriptionCharacterMaxLength, titleCharacterMaxLength } = InvitationReminderGeneralTabConstants;

  return (
    <SidebarContainer>
       <Spacing size="large">
        <Controller
          control={control as Control<FormFieldProps>}
          name="title"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_invitation_reminder_title"
              labelText="Title"
              maxLength={titleCharacterMaxLength}
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
              
            />
          )}
        />
      </Spacing>

      <Spacing size="large">
        <Controller
          control={control as Control<FormFieldProps>}
          name="description"
          render={({ field, fieldState }) => (
            <Textarea 
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_invitation_reminder_description"
              labelText="Description"
              maxLength={descriptionCharacterMaxLength}
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>
    </SidebarContainer>
  );
};

export { InvitationRemindersGeneralTab };
