import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IContentGroup, IContentPage } from '@netfront/ekardo-content-library';
import { ContentBuilderNavigationItemProps, DroppedData, IOption } from '@netfront/ui-library';
import { DashboardContext } from 'context';
import { useDeleteContentGroup, useDuplicatePage, useGetContentGroups, useGetContentPages, useToast, useUpdatePageSort, useUpdatePageStatus } from 'hooks';
import { ContentPageType, IPageSidebarDetails } from 'interfaces';
import { useRouter } from 'next/router';
import { handleGetFileText } from 'utils';

import ContentPageContext from './ContentPageContext';
import { findParentIdByChildId, getPageById, getPageByUrl, formatContentPagesToNavigationItems, findPreviousPage } from './ContentPageContext.helpers';
import { ContentPageContextProps, IDeletePageDetails } from './ContentPageContext.interfaces';


export function ContentPageProvider({ contentType, contentGroupUrl, children, pageUrl, isForm  = false }: ContentPageContextProps) {
  const { handleToastSuccess, handleToastError, handleToastCustomError } = useToast();
  const { query, push } = useRouter();
  const { projectId: queryProjectId } = query;

  const { dashboardLink } = useContext(DashboardContext);

  // Base
  const [navigationItems, setNavigationItems] = useState<ContentBuilderNavigationItemProps[]>([]);
  const [isEmptyPage, setIsEmptyPage] = useState<boolean>(false);
  const [isCartoon, setIsCartoon] = useState<boolean>(false);
  const [hasNoContent, setHasNoContent] = useState<boolean>(false);
  const [firstPageId, setFirstPageId] = useState<number>();
  const [projectId, setProjectId] = useState<string>();
  const [contentGroupId, setContentGroupId] = useState<number>();
  const [contentPages, setContentPages] = useState<IContentPage[]>([]);
  const [contentPage, setContentPage] = useState<IContentPage>();
  const [activePageId, setActivePageId] = useState<string>('');
  const [customStyleSheet, setCustomStyleSheet] = useState<string>('');
  const [baseUrl, setBaseUrl] = useState<string>('');
  const [moduleOptions, setModuleOptions] = useState<IOption[]>([]);
  const [allModules, setAllModules] = useState<IContentGroup[]>([]);

  const [deletePageDetails, setDeletePageDetails] = useState<IDeletePageDetails>();

  // Page
  const [pageSidebarDetails, setPageSidebarDetails] = useState<IPageSidebarDetails>({} as IPageSidebarDetails);
  const [isPageSidebarOpen, setIsPageSidebarOpen] = useState<boolean>(false);
  
  // Module
  const [isModuleSidebarOpen, setIsModuleSidebarOpen] = useState<boolean>(false);
  const [selectedModule, setSelectedModule] = useState<IContentGroup>();
  const [moduleUrl, setModuleUrl] = useState<string>('');
  
  // Test mode
  const [isTestMode, SetIsTestMode] = useState<boolean>(false);


  const handleError = (error: ApolloError) => {
    handleToastError({
      error,
    });
  };

  const { handleGetContentPages, isLoading: isGetContentPagesLoading = false } = useGetContentPages({
    fetchPolicy: 'no-cache',
    onCompleted: ({ contentGroup }) => {
      const { contentPages: returnedContentPages = [] } = contentGroup;
      setContentGroupId(contentGroup.id);

      const {contentPage: currentPage, activePageId: currentActivePageId } = getPageByUrl({contentPages: returnedContentPages, url: pageUrl}) ?? {};

      const [ firstPage ] = returnedContentPages;

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const { id = '', asset, contentPageChildren = [] } = firstPage ?? {};

      const currentContentPage = currentPage ?? firstPage;

      setActivePageId(currentActivePageId ?? String(id))
      setContentPage(currentContentPage);

      setFirstPageId(id ? Number(id): undefined);
      setIsEmptyPage((!isCartoon && returnedContentPages.length === 0) || (isCartoon && !asset && contentPageChildren.length === 0));
      setContentPages(returnedContentPages);

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!currentContentPage) {
        setHasNoContent(true);
      } else {
        const { sectionGroups = [] } = currentContentPage;

        setHasNoContent(
          // not a cartoon content type
          !isCartoon 
          // only has 1 section group
          && sectionGroups.length === 1 
          // has no content sections
          && sectionGroups[0].contentSections.length === 0
        );
      }

      

      setNavigationItems(formatContentPagesToNavigationItems({ contentPages: returnedContentPages, baseUrl, isCartoon, isForm }));

    },
    onError: handleError,
  });

  const { handleDeleteContentGroup, isLoading: isDeleteContentGroupLoading = false } = useDeleteContentGroup({
    onCompleted: () => {

      handleToastSuccess({ message: 'Content group successfully deleted'});

      onUpdateModule({
        updatedModule: {
          id: Number(contentGroupId),
          url: contentGroupUrl
        } as IContentGroup
      });

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetContentGroups, isLoading: isGetContentGroupsLoading = false } = useGetContentGroups({
    fetchPolicy: 'no-cache',
    onCompleted: ({ contentGroups }) => {

      const formattedModules = contentGroups.sort((a, b) => a.sort - b.sort).map(({ title, url}) => {
        return {
          id: String(url),
          value: String(url),
          name: title,
        }
      });

      setAllModules(contentGroups.sort((a, b) => a.sort - b.sort));
      setModuleOptions(formattedModules);
    },
    onError: handleError,
  });
  
  const { handleDuplicatePage, isLoading: isDuplicatePageLoading = false } = useDuplicatePage({
    onCompleted: () => {

      handleGetContentPages({
        url: contentGroupUrl,
        projectId: String(projectId),
      });
    },
    onError: handleError,
  });

  const { handleUpdatePageStatus, isLoading: isUpdatePageStatusLoading = false } = useUpdatePageStatus({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Page successfully deleted',
      });

      const { entityId } = deletePageDetails ?? {};

      const previousPage = findPreviousPage({contentPages, id: Number(entityId) });

      const { url = '' } = previousPage ?? {};
      push(`${baseUrl}/${String(url)}`).catch((error) =>
        handleToastError({
          error,
        }),
      );
      handleGetContentPages({
        url: contentGroupUrl,
        projectId: String(projectId),
      });

      closeDeletePageDialog();
    },
    onError: handleError,
  });

  const { handleUpdatePageSort, isLoading: isUpdatePageSortLoading = false } = useUpdatePageSort({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Page successfully moved',
      });

      handleGetContentPages({
        url: contentGroupUrl,
        projectId: String(projectId),
      });
    },
    onError: handleError,
  });

  
  const duplicatePage = ({ entityId }: { entityId: number }) =>{
    handleDuplicatePage({
      contentPageId: entityId
    });
  };

  const closePageSidebar = () => {
    setIsPageSidebarOpen(false);
    setPageSidebarDetails({} as IPageSidebarDetails);
  };


  const addPageToEntity = ({ entityId, isOpenedFromEmptyState = false, type }: {entityId?: number; isOpenedFromEmptyState?: boolean; type?: ContentPageType }) => {
    setIsPageSidebarOpen(true);
    setPageSidebarDetails({
      contentGroupId,
      parentId: entityId,
      pageId: undefined,
      sort: undefined,
      cartoonType: type,
      isOpenedFromEmptyState,
    });
  };


  const openCreatePageSidebar = ({entityId, type }: {entityId: number; type: ContentPageType} ) => {
    setIsPageSidebarOpen(true);
    const parentId = type !== 'page' ? findParentIdByChildId({
      id: entityId,
      contentPages,
    }): undefined;
    
    setPageSidebarDetails({
      contentGroupId,
      parentId,
      pageId: undefined,
      sort: undefined,
      cartoonType: parentId ? type: undefined,
    });
  };

  const openUpdatePageSidebar = (entityId: number, type: ContentPageType) => {
    setIsPageSidebarOpen(true);

    const page = getPageById({contentPages, id: entityId });

    setPageSidebarDetails({
      pageId: page?.id,
      sort: page?.sort,
      contentGroupId,
      parentId: page?.parentId,
      cartoonType: type,
    } as IPageSidebarDetails);
  };

  const handleCreatePage = (returnedContentPage: IContentPage) => {
    const { url } = returnedContentPage;
    handleGetContentPages({
      url: contentGroupUrl,
      projectId: String(projectId),
    });

    push(`${baseUrl}/${url}${!isCartoon ? '/edit': ''}`).catch((error) =>
      handleToastError({
        error,
      }),
    );
    
  };

  const handleDeletePage = (id?: number) => {
    const previousPage = findPreviousPage({contentPages, id: Number(id) });

    const { url = '' } = previousPage ?? {};
    push(`${baseUrl}/${String(url)}`).catch((error) =>
      handleToastError({
        error,
      }),
    );
    handleGetContentPages({
      url: contentGroupUrl,
      projectId: String(projectId),
    });
  };

  const handleUpdatePage = (returnedContentPage: IContentPage) => {
    const { url } = returnedContentPage;
    push(`${baseUrl}/${url}`).catch((error) =>
      handleToastError({
        error,
      }),
    );
    handleGetContentPages({
      url: contentGroupUrl,
      projectId: String(projectId),
    });
  };


  const openDeletePageDialog = ({ id: entityId, type }: { id: number; type: ContentPageType}) => {
    setDeletePageDetails({
      entityId,
      type,
      isOpen: true
    });
  };

  const closeDeletePageDialog = () => {
    setDeletePageDetails(undefined);
  };

  const handleDeleteEntity = ({ id: entityId }: { id: number}) => {
    handleUpdatePageStatus({
      contentPageId: Number(entityId),
      status: 'DELETED',
    });
  };

  const enterEditMode = (id: number) => {
    const page = getPageById({ contentPages, id });

    const { url } = page ?? {};
    if (url) {
      push(`${baseUrl}/${String(url)}/edit`).catch((error) =>
        handleToastError({
          error,
        }),
      );
    }
  };


  // Module
  const handleOpenModuleSidebar = (selectedUrl?: string) => {
    const chosenModule = allModules.find(({ url }) => url === selectedUrl);
    setSelectedModule(chosenModule);
    setIsModuleSidebarOpen(true);
  };

  const handleCloseModuleSidebar = () => {
    setSelectedModule(undefined);
    setIsModuleSidebarOpen(false);
  };

  const handleSelectModule = (selectedUrl: string) => {
    if (!(selectedUrl && moduleUrl)) return;
    push(`${moduleUrl}/${selectedUrl}`).catch((error) =>
      handleToastError({
        error,
      }),
    );
  };

  const onUpdateModule = ({updatedModule, updatedUrl }:  {updatedModule?: IContentGroup; updatedUrl?: string }) => {
    handleCloseModuleSidebar();
    // updated module only gets returned on creation or delete
    if (updatedModule) {
      const isDeleted = allModules.find(({ id }) => id === updatedModule.id);
      let groupUrl = '';

      if (isDeleted) {
        const firstItem = allModules.find(({ id }) => id !== updatedModule.id);

        const { url = '' } = firstItem ?? {};
        if (firstItem) {
          groupUrl = url;
        } else {
          push(`${String(dashboardLink)}/content/${contentType.toLowerCase()}?isBetaVersion=true`).catch((error) =>
            handleToastError({
              error,
            }),
          );
        }
        
      } else {
        groupUrl = updatedModule.url;
      }

      handleSelectModule(groupUrl);

    } else {

      if (updatedUrl) {
        handleSelectModule(updatedUrl);
      } else {
        handleGetContentPages({
          url: contentGroupUrl,
          projectId: String(projectId),
        });
    
        handleGetContentGroups({
          projectId: String(projectId),
          type: [contentType],  
        });
      }

      
    }
  };

  const handleDeleteModule = () => {
    handleDeleteContentGroup({
      contentGroupId: Number(contentGroupId),
    })
  };

  const handleRefreshPage = () => {
    handleGetContentPages({
      url: contentGroupUrl,
      projectId: String(projectId),
    });

    handleGetContentGroups({
      projectId: String(projectId),
      type: [contentType],  
    });
  };


  // Test mode

  const handleTestMode = () => {
    SetIsTestMode(!isTestMode);
  };

  // Dnd

  const onDrop = (data: DroppedData) => {
    const { draggedItem, target, updatedIndex } = data;

    const { type, id } = draggedItem;
    const { type: targetType, id: targetId } = target;

    if (isCartoon && type === 'scene' && ['scene', 'slide'].includes(String(targetType))) {
      handleToastCustomError({
        message: "whoops! You can't move this scene here",
      });

      return;
    }

    handleUpdatePageSort({
      contentPageId: Number(id),
      parentId: Number(targetId) !== 0 ? Number(targetId): undefined,
      sort: updatedIndex
    });

  };


  useEffect(() => {
    if (!(contentGroupUrl && projectId)) return;

    handleGetContentPages({
      url: contentGroupUrl,
      projectId,
    });

    handleGetContentGroups({
      projectId: String(projectId),
      type: [contentType],  
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentGroupUrl, projectId]);


  useEffect(() => {
    if (!queryProjectId) return;

    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    if (!pageUrl) return;

    const {contentPage: currentPage, activePageId: currentActivePageId } = getPageByUrl({contentPages, url: pageUrl}) ?? {};

    setContentPage(currentPage);
    setActivePageId(currentActivePageId ?? '');

    setIsEmptyPage(!currentPage);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!currentPage) {
      setHasNoContent(true);
    } else {
      const { sectionGroups = [] } = currentPage;

      setHasNoContent(
        // not a cartoon content type
        !isCartoon 
        // only has 1 section group
        && sectionGroups.length === 1 
        // has no content sections
        && sectionGroups[0].contentSections.length === 0
      );
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageUrl]);

  useEffect(() => {
    if (!(dashboardLink && contentGroupUrl)) return;
    setBaseUrl(`${dashboardLink}/content/${isForm ? 'form-builder': 'builder'}/${contentType.toLowerCase()}/${contentGroupUrl}`);
  }, [contentType, contentGroupUrl, dashboardLink, isForm]);

  useEffect(() => {
    if (!(dashboardLink)) return;
    setModuleUrl(`${dashboardLink}/content/builder/${contentType.toLowerCase()}`);
  }, [contentType, dashboardLink]);

  useEffect(() => {
    setIsCartoon(contentType === 'CARTOON');
  }, [contentType]);

  useEffect(() => {
    if (!projectId) return;
    
    const getCustomCss = async () => {
      const customStyleSheetText = await handleGetFileText(`https://ekardo.s3.ap-southeast-2.amazonaws.com/${String(process.env.REACT_APP_CSS_FILE_ENVIRONMENT)}/project/${projectId}/Css/allinone.min.css`);
      setCustomStyleSheet(customStyleSheetText);
    };

    void getCustomCss()
  }, [projectId]);


  const isLoading =
    isGetContentPagesLoading ||
    isUpdatePageStatusLoading || 
    isGetContentGroupsLoading || 
    isDuplicatePageLoading ||
    isDeleteContentGroupLoading ||
    isUpdatePageSortLoading;

  return (
    <ContentPageContext.Provider
      value={{
        // Base
        activePageId,
        contentType,
        isLoading,
        contentGroupUrl,
        contentGroupId,
        navigationItems,
        pageUrl,
        projectId,
        contentPage,
        contentPages,
        baseUrl,
        isCartoon,
        moduleOptions,
        hasNoContent,
        setPageSidebarDetails,

        // Empty state
        isEmptyPage,
        firstPageId,

        // Page
        closePageSidebar,
        isPageSidebarOpen,
        handleCreatePage,
        handleDeletePage,
        handleUpdatePage,
        handleRefreshPage,
        pageSidebarDetails,
        enterEditMode,
        duplicatePage,

        addPageToEntity,
        closeDeletePageDialog,
        deletePageDetails,
        openCreatePageSidebar,
        openDeletePageDialog,
        openUpdatePageSidebar,
        handleDeleteEntity,

        // Module
        isModuleSidebarOpen,
        handleOpenModuleSidebar,
        handleCloseModuleSidebar,
        onUpdateModule,
        selectedModule,
        handleSelectModule,
        handleDeleteModule,

        // Test mode
        handleTestMode,
        isTestMode,

        // Dnd
        onDrop,

        // css
        customStyleSheet
      }}
    >
      {children}
    </ContentPageContext.Provider>
  );
}
