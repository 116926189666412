
import { useCapuchinMutation } from 'hooks';

import { COPY_CONDITION_MUTATION } from './useCopyScenarioCondition.graphql';
import {
  ICopyScenarioConditionMutationGraphQLResponse,
  ICopyScenarioConditionMutationVariables,
  IHandleCopyScenarioConditionParams,
  IUseCopyScenarioConditionOptions,
  IUseCopyScenarioCondition,
} from './useCopyScenarioCondition.interfaces';

const useCopyScenarioCondition = (options?: IUseCopyScenarioConditionOptions): IUseCopyScenarioCondition => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCopyScenarioConditionOptions);

  const [executeCopyScenarioCondition, { loading: isLoading }] = useCapuchinMutation<
    ICopyScenarioConditionMutationGraphQLResponse,
    ICopyScenarioConditionMutationVariables
  >({
    mutation: mutation ?? COPY_CONDITION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          conditions: { copy: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleCopyScenarioCondition = ({ conditionGroupId, conditionId }: IHandleCopyScenarioConditionParams) => {
    void executeCopyScenarioCondition({
      variables: {
        conditionGroupId,
        conditionId,
      },
    });
  };

  return {
    handleCopyScenarioCondition,
    isLoading,
  };
};

export { useCopyScenarioCondition };
