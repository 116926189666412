import { DBNetfrontServiceType, IProjectSetting } from "@netfront/gelada-identity-library";
import { IOption } from "@netfront/ui-library";

import { REPORT_OPTIONS } from "./Reporting.constants";
import { IReportOption } from "./Reporting.interfaces";


const getReportDropdownOptions = <T>(
  options: T[],
  textKeys: { key: keyof T, prefix?: string }[],
  valueKey: keyof T
): IOption[] => {

  const customOptions = options.map((option) => {
    const value = option[valueKey] as unknown as string | number;

    return {
      id: value,
      name: textKeys.map(({ key, prefix = '' }) => `${prefix}${String(option[key])}`).join(' - '),
      value,
    };
  }) as IOption[];

  return customOptions.length ? customOptions : [];
};


const getFormattedReportOptions = ({ services, projectId, projectSettings }: { projectId: string; projectSettings: IProjectSetting; services?: DBNetfrontServiceType[];}): IReportOption[] => {

  const options: IReportOption[] =
    REPORT_OPTIONS.sort((a, b) => a.order - b.order)
    .reduce((acc, item) => {
      const { items, service, name, route, icon, setting } = item;
      const optionItems = items.sort((a, b) => a.order - b.order).map(({ route: itemRoute, name: itemName, projectId: itemProjectId, setting: itemSetting }) => ({
        id: itemRoute,
        name: itemName,
        value: itemRoute,
        isVisible: (
          (!itemSetting || projectSettings[itemSetting] as boolean) &&
          (!service || services?.includes(service as DBNetfrontServiceType)) &&
          (itemRoute !== 'export-logins-trials' || (projectId === itemProjectId))
        )  ?? false,
      })).filter(({ isVisible }) => isVisible);

      const reportOption = {
        id: route,
        isVisible: ((!service || services?.includes(service as DBNetfrontServiceType)) && (!setting || projectSettings[setting] as boolean)) ?? false,
        name,
        value: route,
        icon,
        subItems: optionItems,
      };

      if (optionItems.length > 0) {
        acc.push(reportOption);
      }
      return acc;
    }, [] as IReportOption[]);

    return options.filter(({ isVisible }) => isVisible);

};

const hasReportOptions = ({ services, projectId, projectSettings, route }: { projectId: string; projectSettings: IProjectSetting; route: string; services?: DBNetfrontServiceType[];}) => {
  const options = getFormattedReportOptions({ services, projectId, projectSettings });
  return options.some(({ id }) => id === route);
};

const getReportTypeName = (reportType: string): string => {
  const option = REPORT_OPTIONS.find(({ route }) => reportType === route);
  return option ? option.name : '';
}

const getFilteredReportOptions = ({ reportOptions, value = '' }: { reportOptions: IReportOption[]; value?: string }): IReportOption[] => {
  if (value === '') return reportOptions;

  const formattedValue = value.toLowerCase();

  return reportOptions
    .map((parent) => {
      const { subItems = [] } = parent;
      const hasParentMatches = parent.name.toLowerCase().includes(formattedValue);
      const matchingItems = subItems.filter(({ name }) =>
        name.toLowerCase().includes(formattedValue)
      );

      // If parent matches, return the entire parent object
      if (hasParentMatches) {
        return parent;
      }

      // If parent doesn't match but has matching items, return filtered items
      if (matchingItems.length > 0) {
        return {
          ...parent,
          subItems: matchingItems, // Include only matching items
        };
      }

      // Otherwise, exclude the parent entirely
      return null;
    })
    .filter((parent) => Boolean(parent)) as IReportOption[];
};

const getFlattenedReportOptions = ({ services, projectId, projectSettings }: { projectId: string; projectSettings: IProjectSetting; services?: DBNetfrontServiceType[];}): IOption[] => {
  const initialItems = getFormattedReportOptions({ services, projectId, projectSettings });

  const options: IOption[] =
    initialItems.reduce((acc, item) => {
      const { subItems = [], id, name, value } = item;

      acc.push({
        name,
        value,
        id,
      });
      subItems.forEach(({ id: itemId, name: itemName, value: itemValue }) => {

        acc.push({
          id: `${id}/${itemId}`,
          name: `${'\u00A0\u00A0\u00A0'}${itemName}`,
          value: `${value}/${itemValue}`
        });
      });
      
      return acc;
    }, [] as IOption[]);

  return options;

};

export { getFormattedReportOptions, getReportTypeName, getFilteredReportOptions, getReportDropdownOptions, getFlattenedReportOptions, hasReportOptions };
