

import { useBonoboLazyQuery } from 'hooks';

import { EXPORT_INTERACTIONS } from './useGetInteractionsReport.graphql';
import {
  IGetInteractionsReportQueryGraphQLResponse,
  IGetInteractionsReportQueryVariables,
  IHandleGetInteractionsReportParams,
  IUseGetInteractionsReport,
  IUseGetInteractionsReportOptions,
} from './useGetInteractionsReport.interfaces';

const useGetInteractionsReport = (options?: IUseGetInteractionsReportOptions): IUseGetInteractionsReport => {
  const { fetchPolicy, onCompleted, onError, query, token, projectId: bonoboProjectId } = options ?? ({} as IUseGetInteractionsReportOptions);

  const [executeGetInteractionsReport, { loading: isLoading }] = useBonoboLazyQuery<
    IGetInteractionsReportQueryGraphQLResponse,
    IGetInteractionsReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportInteractions } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportInteractions,
        });
      },
      onError,
    },
    query: query ?? EXPORT_INTERACTIONS,
    token,
  });


  const handleGetInteractionsReport = async ({
    dateFrom,
    dateTo,
    fileType,
    ianaTimeZone,
    projectId,
  }: IHandleGetInteractionsReportParams) => {
    await executeGetInteractionsReport({
      variables: {
        dateFrom,
        dateTo,
        fileType,
        ianaTimeZone,
        projectId,
      },
    });
  };

  return {
    handleGetInteractionsReport,
    isLoading,
  };
};

export { useGetInteractionsReport };
