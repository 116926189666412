import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_ACTION } from './useUpdateAction.graphql';
import {
  IUpdateActionMutationGraphQLResponse,
  IUpdateActionMutationVariables,
  IHandleUpdateActionParams,
  IUseUpdateActionOptions,
  IUseUpdateAction,
} from './useUpdateAction.interfaces';

const useUpdateAction = (options?: IUseUpdateActionOptions): IUseUpdateAction => {
  const { mutation, onCompleted, onCompletedAsync, onError, token } = options ?? ({} as IUseUpdateActionOptions);


  const [executeUpdateAction, { loading: isLoading }] = useEkardoMutation<IUpdateActionMutationGraphQLResponse, IUpdateActionMutationVariables>({
    mutation: mutation ?? UPDATE_ACTION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {

        const {
          questionAction: { update: action },
        } = data;

        if (onCompleted) {
          void onCompleted({
            action,
          });
        }
        
        if (onCompletedAsync) {
          void onCompletedAsync({
            action,
          });
        }
      },
      onError,
    },
    token,
  });

  const handleUpdateAction = async ({
    actionId,
    conditions,
    contentPageId,
    deletedConditionIds,
    title,
    type,
  }: IHandleUpdateActionParams) => {
    await executeUpdateAction({
      variables: {
        actionId,
        conditions,
        contentPageId,
        deletedConditionIds,
        title,
        type,
      },
    });
  };

  return {
    handleUpdateAction,
    isLoading,
  };
};

export { useUpdateAction };
