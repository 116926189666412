import { useState, useEffect, useContext } from 'react';

import { DBContentGroupTypeType, useToast } from '@netfront/ekardo-content-library';
import { ProjectSettingsContext } from 'context';
import { useRouter } from 'next/router';

import { ContentGroupList } from './ContentGroupList';


const ContentGroupPage = () => {
  const { query, push } = useRouter();
  const { type } = query;

  const { handleToastError } = useToast();
  const { projectSettings } = useContext(ProjectSettingsContext);
  const [contentGroupType, setContentGroupType] = useState<DBContentGroupTypeType>();


  useEffect(() => {
    if (!(contentGroupType && projectSettings)) return;

    if (
      (contentGroupType === 'LEARNING' && !(projectSettings['HAS_CONTENT_TYPE_LEARNING'] as boolean))
      || (contentGroupType === 'RESOURCE' && !(projectSettings['HAS_CONTENT_TYPE_RESOURCE'] as boolean))
      || (contentGroupType === 'PAGE' && !(projectSettings['HAS_CONTENT_TYPE_PAGE'] as boolean))
      || (contentGroupType === 'NEWS' && !(projectSettings['HAS_CONTENT_TYPE_NEWS'] as boolean))
      || (contentGroupType === 'BLOG' && !(projectSettings['HAS_CONTENT_TYPE_BLOG'] as boolean))
      || (contentGroupType === 'QUESTIONNAIRE' && !(projectSettings['HAS_CONTENT_TYPE_QUESTIONNAIRE'] as boolean))
      || (contentGroupType === 'EVENTS' && !(projectSettings['HAS_CONTENT_TYPE_EVENTS'] as boolean))
      || (contentGroupType === 'HELP' && !(projectSettings['HAS_CONTENT_TYPE_HELP'] as boolean))
      || (contentGroupType === 'CONSENT' && !(projectSettings['HAS_CONTENT_TYPE_CONSENT'] as boolean))
      || (contentGroupType === 'CARTOON' && !(projectSettings['HAS_CONTENT_TYPE_CARTOON'] as boolean))
      || (contentGroupType === 'ACTIVITY' && !(projectSettings['HAS_CONTENT_TYPE_ACTIVITY'] as boolean))
     ) {
      push('/404').catch((error) =>
        handleToastError({
          error,
        }),
      );
    }
    

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSettings, contentGroupType]);


  useEffect(() => {
    if (!type) {
      return;
    }

    setContentGroupType(String(type).toUpperCase() as DBContentGroupTypeType);
  }, [type]);


  return (
    contentGroupType ? <ContentGroupList contentGroupType={contentGroupType} /> : <></>
  );
};

export { ContentGroupPage };
