/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { IDBAsset } from "@netfront/common-library";
import { FormFieldProps } from "@netfront/ui-library";



const getCssFileDefaultValues = ({ asset, cssText = '' }: {asset?: IDBAsset; cssText?: string}): FormFieldProps => {
  const { title = '', description = '' } = asset ?? {};

  return {
    title: title || '',
    description: description || '', 
    content: cssText
  };
};


export { getCssFileDefaultValues };