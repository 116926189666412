import { useContext, useEffect, useRef, useState } from 'react';

import { IContentPage, CMSCartoonContentPage, DBContentGroupTypeType } from '@netfront/ekardo-content-library';
import {
  BinIcon,
  Button,
  Container,
  ContentBuilderNavigationRef,
  CopyIcon,
  Dialog,
  EmptyState,
  EnterIcon,
  HollowEditIcon,
  PlusIcon,
  SettingsIcon,
  Spinner,
} from '@netfront/ui-library';
import { CartoonPageSidebarView, ContentBuilderPreview, ContentBuilderTemplatePage, ContentGroupListSidebarView, PageSidebarView } from 'components';
import { ContentPageProvider, ContentPageContext, DashboardContext } from 'context';
import { ContentPageType } from 'interfaces';
import capitalize from 'lodash.capitalize';

import { ContentPagesDashboardPageProps } from './ContentPagesDashboardPage.interfaces';


const ContentPagesDashboardPageInner = () => {

  const {
    activePageId,
    baseUrl,
    contentType,
    contentPage,
    contentPages,
    contentGroupId,
    contentGroupUrl,
    isLoading = false,
    isEmptyPage = false,
    hasNoContent = false,
    isCartoon = false,
    firstPageId,
    navigationItems,
    deletePageDetails,
    closeDeletePageDialog,
    projectId, 
    isPageSidebarOpen,
    pageSidebarDetails,
    handleDeletePage,
    handleUpdatePage,
    handleCreatePage,
    closePageSidebar,
    openUpdatePageSidebar,
    addPageToEntity,
    openDeletePageDialog,
    openCreatePageSidebar,
    handleDeleteEntity,
    enterEditMode,
    moduleOptions,
    duplicatePage,
    handleOpenModuleSidebar,
    selectedModule,
    handleCloseModuleSidebar,
    onUpdateModule,
    isModuleSidebarOpen = false,
    handleSelectModule,
    handleDeleteModule,
    isTestMode = false,
    handleTestMode,
    handleRefreshPage,
    setPageSidebarDetails,
    onDrop,
    customStyleSheet = '',
  } = useContext(ContentPageContext);
  const navigationSidebarRef = useRef<ContentBuilderNavigationRef>(null);

  const { dashboardLink } = useContext(DashboardContext);
  const [isDeleteModuleDialogOpen, setIsDeleteModuleDialogOpen] = useState<boolean>(false);
  const [dashboardUrl, setDashboardUrl] = useState<string>('');

  const { entityId, type: entityType, isOpen = false } = deletePageDetails ?? {};

  const { contentPageChildren = [] } = contentPage ?? {};

  useEffect(() => {
    if (navigationSidebarRef.current) {
      navigationSidebarRef.current.handleToggleSidebar(!isPageSidebarOpen && !isModuleSidebarOpen);
    }

  }, [isPageSidebarOpen, isModuleSidebarOpen]);

  useEffect(() => {

    if (!dashboardLink) return;

    setDashboardUrl(`${dashboardLink}/content?isBetaVersion=true`);

  }, [dashboardLink]);

  return (
    <>
      <Spinner isLoading={isLoading}/>
      <ContentBuilderTemplatePage
        ref={navigationSidebarRef}
        acceptsMap={{
          root: ['scene', 'slide', 'page'],
          page: ['page'],
          scene: ['slide'],
          slide: [],
        }}
        activePageId={activePageId}
        additionalClassNames=""
        additionalToolbarItems={!isCartoon && contentPage ? (
          <Button
            additionalClassNames="c-content-builder__edit-button"
            icon={HollowEditIcon}
            size="xs" 
            text="Edit"
            type="button"
            variant="primary-inverse"
            onClick={() => enterEditMode(Number(contentPage.id))}
          />
        ): undefined}
        contentGroupId={contentGroupId}
        contentType={contentType}
        dashboardLink={dashboardUrl}
        description="Content pages dashboard"
        handleTestMode={handleTestMode}
        hasHighlightOnHoverSiblingChild={false}
        isTestMode={isTestMode}
        navigationItems={navigationItems}
        pageDropdownOptions={[
          {
            id: 'id_edit_module',
            icon: SettingsIcon,
            label: 'Settings',
            isHidden: false,
            onClick: () => handleOpenModuleSidebar(String(contentGroupUrl)),
          },
          {
            id: 'id_add_module',
            icon: PlusIcon,
            label: 'Create new content group',
            isHidden: false,
            onClick: () => handleOpenModuleSidebar(),
          },
          {
            id: 'id_delete_module',
            icon: BinIcon,
            label: 'Delete',
            isHidden: false,
            onClick: () => setIsDeleteModuleDialogOpen(true),
          },
        ]}
        pageTitle={`${capitalize(contentType)} content pages dashboard`}
        selectedValue={String(contentGroupUrl)}
        selectOptions={moduleOptions}
        settingItems={[
          {
            id: 'id_settings_item',
            icon: SettingsIcon,
            label: 'Settings',
            isHidden: false,
            onClick: ({ id: selectedEntityId, type: selectedEntityType }) => openUpdatePageSidebar(Number(selectedEntityId), selectedEntityType as ContentPageType)
          },
          {
            id: 'id_edit_item',
            icon: HollowEditIcon,
            label: 'Edit',
            isBulkOption: false,
            isVisibleByTypeArray: ['page'],
            onClick: ({ id: selectedEntityId }) => enterEditMode(Number(selectedEntityId))
          },
          {
            id: 'id_duplicate_item',
            icon: CopyIcon,
            label: 'Duplicate',
            isHidden: false,
            onClick: ({ id: selectedEntityId }) => duplicatePage({ entityId: Number(selectedEntityId) })
          },
          {
            id: 'id_add_new_item',
            icon: EnterIcon,
            label: 'Add new scene',
            isVisibleByTypeArray: ['scene'],
            onClick: ({ id: selectedEntityId }) => addPageToEntity({entityId: Number(selectedEntityId), type: 'slide'}),
          },
          {
            id: 'id_add_sub_page',
            icon: EnterIcon,
            label: 'Add sub page',
            isVisibleByTypeArray: ['page'],
            onClick: ({ id: selectedEntityId }) => addPageToEntity({entityId: Number(selectedEntityId), type: 'page'}),
          },
          {
            id: 'id_delete_item',
            icon: BinIcon,
            label: 'Delete',
            isHidden: false,
            onClick: ({ id: selectedEntityId,  type: selectedEntityType }) => openDeletePageDialog({ id: Number(selectedEntityId), type: selectedEntityType as ContentPageType}),
          },
        ]}
        isShowPageHighlight
        onAddClick={(selectedId: string | number, type: string) => openCreatePageSidebar({entityId: Number(selectedId), type: type as ContentPageType})}
        onDrop={onDrop}
        onSelectChange={({target : { value }}: { target: { value: string }}) => handleSelectModule(value)}
      >
        <Container additionalClassNames="c-content-builder-container" alignment="left" size="2x-large">
          {isCartoon && isEmptyPage && !isTestMode && (
            <EmptyState
              buttonText={`${firstPageId ? 'Add image' : 'Start building your cartoon'}`}
              imageUrl="/images/content-landing.svg"
              size="large"
              text={`${
                firstPageId 
                ? 'Add an image to your scene by hitting add image below. alternatively add a slide by clicking the settings button next to your slide' 
                : 'To get started, click "Start building your cartoon below.'
              }`}
              title="Lets get started!"
              onClick={firstPageId ? () => openUpdatePageSidebar(firstPageId, 'scene'): () => addPageToEntity({entityId: firstPageId, isOpenedFromEmptyState: true })} 
            />
          )}
          {!isCartoon && isEmptyPage && (
            <EmptyState
              buttonText="Add page"
              imageUrl="/images/content-landing.svg"
              size="large"
              text="Get building by hitting add page below. Need some help? Check out our help guide."
              title="Get started!"
              onClick={() => addPageToEntity({entityId: firstPageId, type: 'page'})} 
            />
          )}
          
          {!isCartoon && !isEmptyPage && hasNoContent && (
            <EmptyState
              buttonText="Edit page"
              imageUrl="/images/content-landing.svg"
              size="large"
              text="It looks like there is no content for this page. Hit edit page below to start adding content."
              title="No content!"
              onClick={() => enterEditMode(Number(contentPage?.id))} 
            />
          )}
          {isCartoon && (!isEmptyPage || isTestMode) && (
            <CMSCartoonContentPage
              baseUrl={baseUrl}
              contentPage={contentPage ?? {} as IContentPage}
              contentPages={contentPages}
              editModeEmptyState={
                <EmptyState
                  buttonText="Add image"
                  imageUrl="/images/content-landing.svg"
                  size="large"
                  text="Add an image to your scene by hitting add image below. alternatively add a slide by clicking the settings button next to your slide" 
                  title="No image!"
                  onClick={() => openUpdatePageSidebar(Number(contentPage?.id), contentPageChildren.length > 0 ? 'scene': 'slide')} 
                />
              }
              hasShareHandler={true}
              isEditMode={!isTestMode}
              onCompleteCartoonButton={
                <Button
                  text="Complete" 
                  variant="primary"
                  onClick={handleTestMode}
                />
              }
          />
          )}
          {!isCartoon && contentPage && !isEmptyPage && (
            <ContentBuilderPreview contentPage={contentPage} customStyleSheet={customStyleSheet} isEditMode={false}/>
          )}

          {!isCartoon && (
            <PageSidebarView 
              closePageSidebar={closePageSidebar}
              contentType={contentType as DBContentGroupTypeType}
              handleCreatePage={handleCreatePage}
              handleDeletePage={handleDeletePage}
              handleUpdatePage={handleUpdatePage}
              isPageSidebarOpen={isPageSidebarOpen}
              pageSidebarDetails={pageSidebarDetails}
              projectId={String(projectId)}
            />
          )}
          
          {isCartoon && (
            <CartoonPageSidebarView 
              closePageSidebar={closePageSidebar}
              contentType={contentType as DBContentGroupTypeType}
              handleCreatePage={handleCreatePage}
              handleDeletePage={handleDeletePage}
              handleRefreshPage={handleRefreshPage}
              handleUpdatePage={handleUpdatePage}
              isPageSidebarOpen={isPageSidebarOpen}
              pageSidebarDetails={pageSidebarDetails}
              projectId={String(projectId)}
              setPageSidebarDetails={setPageSidebarDetails}
            />
          )}
          
          { contentType && (
            <ContentGroupListSidebarView
              contentGroupType={contentType}
              handleSideBarClose={handleCloseModuleSidebar}
              isSideBarOpen={isModuleSidebarOpen}
              projectId={String(projectId)}
              selectedContentGroup={selectedModule}
              onUpdate={onUpdateModule}
            />
          )}
          
          { entityType && entityId && (
            <Dialog 
              isOpen={isOpen}
              title={`Delete ${entityType}?`}
              isNarrow
              onCancel={closeDeletePageDialog}
              onClose={closeDeletePageDialog}
              onConfirm={() => handleDeleteEntity({ id: entityId })}
            />
          )}

          <Dialog
            isOpen={isDeleteModuleDialogOpen}
            title={`Delete content group?`}
            onCancel={() => setIsDeleteModuleDialogOpen(false)}
            onClose={() => setIsDeleteModuleDialogOpen(false)}
            onConfirm={() => {
              setIsDeleteModuleDialogOpen(false);
              handleDeleteModule();
            }}
          />
        </Container>
      </ContentBuilderTemplatePage>
    </>
  );
};


const ContentPagesDashboardPage = ({ contentType, contentGroupUrl, pageUrl, isFormBuilder }: ContentPagesDashboardPageProps) => {
  return (
    <ContentPageProvider contentGroupUrl={contentGroupUrl} contentType={contentType} isForm={isFormBuilder} pageUrl={pageUrl}>
      <ContentPagesDashboardPageInner/>
    </ContentPageProvider>
  )
}

export { ContentPagesDashboardPage };
