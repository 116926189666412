import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_ASSET_QUERY } from './useGetAsset.graphql';
import {
  IGetAssetQueryGraphQLResponse,
  IGetAssetQueryVariables,
  IHandleGetAssetParams,
  IUseGetAsset,
  IUseGetAssetOptions,
} from './useGetAsset.interfaces';

const useGetAsset = (options?: IUseGetAssetOptions): IUseGetAsset => {
  const { fetchPolicy, onCompleted, onCompletedAsync, onError, query, token } = options ?? ({} as IUseGetAssetOptions);

  const [executeGetAsset, { loading: isLoading }] = useEkardoLazyQuery<IGetAssetQueryGraphQLResponse, IGetAssetQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {

        const {
          asset: {
            getAsset: asset,
          },
        } = data;

        if (onCompleted) {
          void onCompleted({ asset });
        }

        if (onCompletedAsync) {
          void onCompletedAsync({ asset });
        }
      },
      onError,
    },
    query: query ?? GET_ASSET_QUERY,
    token,
  });

  const handleGetAsset = ({ assetId }: IHandleGetAssetParams) => {
    void executeGetAsset({
      variables: {
        assetId,
      },
    });
  };

  return {
    handleGetAsset,
    isLoading,
  };
};

export { useGetAsset };
