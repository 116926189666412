
import { ApolloError } from '@apollo/client';
import { IUpdateScenarioConditionDayMutationVariables, IUpdateScenarioConditionHourMutationVariables, IUpdateScenarioConditionStepCompletedMutationVariables, IUpdateScenarioConditionStepProgressionMutationVariables, IUpdateScenarioConditionStepStartedMutationVariables, IUpdateScenarioConditionUserActivatedMutationVariables, IUpdateScenarioConditionManyUserGroupMutationVariables, IUpdateScenarioConditionUserLastLoginMutationVariables, IUpdateScenarioConditionUserLoginMutationVariables, IUpdateScenarioConditionUserRegisteredMutationVariables, IUpdateScenarioConditionUserSessionMutationVariables, useAddScenarioCondition, useToast, useUpdateScenarioCondition } from 'hooks';
import { IDBCondition } from 'interfaces';


const useUpsertScenarioCondition = ({ onCompleted }: { onCompleted: ({ condition } : { condition: IDBCondition; } ) => void }) => {

  const { handleToastError } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };


  const { handleUpdateScenarioCondition, isLoading: isUpdateScenarioConditionLoading = false } = useUpdateScenarioCondition({
    onCompleted,
    onError: handleGetError,
  });

  const { handleAddScenarioCondition, isLoading: isAddScenarioConditionLoading = false } = useAddScenarioCondition({
    onCompleted: (condition: IDBCondition) => onCompleted({ condition }),
    onError: handleGetError,
  });

  const handleUpsertScenarioCondition = ({
    conditionId,
    day,
    hour,
    manyUserGroup,
    stepCompleted,
    stepProgression,
    stepStarted,
    userActivated,
    userLastLogin,
    userLogin,
    userRegistered,
    userSession,
  }: {
    
    conditionId?: IDBCondition['id'];
    day?: IUpdateScenarioConditionDayMutationVariables;
    hour?: IUpdateScenarioConditionHourMutationVariables;
    manyUserGroup?: IUpdateScenarioConditionManyUserGroupMutationVariables;
    stepCompleted?: IUpdateScenarioConditionStepCompletedMutationVariables;
    stepProgression?: IUpdateScenarioConditionStepProgressionMutationVariables;
    stepStarted?: IUpdateScenarioConditionStepStartedMutationVariables;
    userActivated?: IUpdateScenarioConditionUserActivatedMutationVariables;
    userLastLogin?: IUpdateScenarioConditionUserLastLoginMutationVariables;
    userLogin?: IUpdateScenarioConditionUserLoginMutationVariables;
    userRegistered?: IUpdateScenarioConditionUserRegisteredMutationVariables;
    userSession?: IUpdateScenarioConditionUserSessionMutationVariables;


  }) => {
    if (!conditionId) {
      handleAddScenarioCondition({
        day,
        hour,
        manyUserGroup,
        stepCompleted,
        stepProgression,
        stepStarted,
        userActivated,
        userLastLogin,
        userLogin,
        userRegistered,
        userSession,
      });
    } else {

      handleUpdateScenarioCondition({
        conditionId,
        day,
        hour,
        manyUserGroup,
        stepCompleted,
        stepProgression,
        stepStarted,
        userActivated,
        userLastLogin,
        userLogin,
        userRegistered,
        userSession,
      });
    }
  };

  return {
    isLoading: isAddScenarioConditionLoading || isUpdateScenarioConditionLoading,
    handleUpsertScenarioCondition,
  };
};
export { useUpsertScenarioCondition };
