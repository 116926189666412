/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { IContentGroup, IDBCorrectResponse } from "@netfront/ekardo-content-library"
import { FormFieldProps } from "@netfront/ui-library"
import { IMarkableQuestion, IUpdatedCorrectResponse } from "interfaces";


const getFormDefaultValues = (contentGroup?: IContentGroup): FormFieldProps => {
  const {
    title = '',
    description = '',
    status = 'PUBLISHED',
    id,
    formType,
    sort,
    url,
    // hasQuestionNumbers = false,
    contentPages = [],
    correctResponses = []
  } = contentGroup ?? {};
  

  const [ contentPage ] = contentPages ?? [];

  const markableQuestionTypes = ['Radio', 'Checkbox', 'DropDownList'];
  let markableQuestions: IMarkableQuestion[] = [];

  if (contentPage) {

    contentPage.contentSections?.forEach((contentSection) => {
      contentSection.sectionContainers.forEach((sectionContainer) => {
        markableQuestions = sectionContainer.snippets
          .filter(
            (snippet) =>
              // eslint-disable-next-line no-underscore-dangle
              snippet.__typename === 'ContentSnippetQuestionType' &&
              // eslint-disable-next-line no-underscore-dangle
              markableQuestionTypes.includes(String(snippet.configuration?.__typename ?? '')),
          )
          .map((question) => {
            const correctResponse = correctResponses.find(
              (savedCorrectResponse: IDBCorrectResponse) => savedCorrectResponse.contentSnippetId === question.id,
            );

            const correctResponseId =
              (correctResponse &&
                (correctResponse.questionResponseCheckboxId ||
                  correctResponse.questionResponseRadioId ||
                  correctResponse.questionResponseDropDownListId)) ||
              null;

            return {
              ...question,
              correctResponseId: correctResponseId ? Number(correctResponseId): undefined,
            };
          });
      });
    });
  }

  return {
    id,
    title,
    description,
    status,
    sort,
    url,
    formType: formType ?? 'LIST',
    isActivated: status === 'PUBLISHED',
    hasQuestionNumbers: false,
    markableQuestions,
  };
};


const getUpdatedMarkableQuestions = ({ formId, initialAnswers = [], updatedAnswers = [] }: { formId?: number; initialAnswers: IMarkableQuestion[]; updatedAnswers: IMarkableQuestion[]; }): IUpdatedCorrectResponse[] => {
  if (!formId) return [];
  const markableQuestions = updatedAnswers.reduce((acc, question) => {
    const { id: updatedId, correctResponseId, configuration } = question;
    const { __typename: type = '' } = configuration ?? {};
    const initialAnswer = initialAnswers.find(({ id }) => id === updatedId);

    if ((correctResponseId || !correctResponseId && initialAnswer?.correctResponseId ) && initialAnswer && initialAnswer.correctResponseId !== correctResponseId) {
      acc.push({
        currentResponseId: initialAnswer.correctResponseId,
        updatedResponseId: correctResponseId,
        contentSnippetId: updatedId,
        contentGroupId: formId,
        type,
      });
    }

      return acc;
    }, [] as IUpdatedCorrectResponse[]);
  

  return markableQuestions;
}

export { getFormDefaultValues, getUpdatedMarkableQuestions };
