import { useState, useEffect, useContext } from 'react';

import { ApolloError } from '@apollo/client';
import { IContentGroup } from '@netfront/ekardo-content-library';
import {
  useBackofficeSiteUrls, useDomain
} from '@netfront/gelada-identity-library';
import { Button, FlexContainer, Pill } from '@netfront/ui-library';
import { TablePageTemplate , LibraryFormsSidebarView } from 'components';
import { CachingEntitiesContext, DashboardContext, ProjectSettingsContext } from 'context';
import { useRouter } from 'next/router';

import { FORMS_TABLE_COLUMNS } from './LibraryFormsPage.constants';
import { getFormsTableData } from './LibraryFormsPage.helpers';
import { IFormsTableData } from './LibraryFormsPage.interfaces';

import { useToast, useGetContentGroups } from '../../../../hooks';

const LibraryFormsPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { dashboardLink } = useContext(DashboardContext);
  const { projectSettings } = useContext(ProjectSettingsContext);
  const { query: { projectId: queryProjectId, isBetaVersion: queryIsBetaVersion }, push, asPath } = useRouter();
  const { isDomainReady } = useDomain();
  const { handleToastError } = useToast();
  const { getDashboardUrl } = useBackofficeSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_BACK_OFFICE_LOCAL_PORT,
  });


  const [projectId, setProjectId] = useState<string>('');
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [allFormItems, setAllFormItems] = useState<IContentGroup[]>();
  const [formsTableData, setFormsTableData] = useState<IFormsTableData[]>([]);
  const [baseUrl, setBaseUrl] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [selectedFormId, setSelectedFormId] = useState<number>();
  const [filter, setFilter] = useState<string>();
  const [isBetaVersion, setIsBetaVersion] = useState<boolean>(false);

  const handleSwitch = () => {
    push(`${asPath.split('?')[0]}/${!isBetaVersion ? '?isBetaVersion=true': ''}`).catch((error) =>
      handleToastError({
        error,
      }),
    );
  };


  const {
    handleGetContentGroups,
    isLoading: isGetContentGroupsLoading = false
  } = useGetContentGroups({
    fetchPolicy: 'no-cache',
    onCompleted: ({ contentGroups }) => {
      setAllFormItems(contentGroups);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const addNewFormItem = () => {
    setSelectedFormId(undefined);
    setIsSideBarOpen(true);
  };


  const handleSelectItem = (id: number) => {
    setSelectedFormId(id);
    setIsSideBarOpen(true);
  }

  const handleCloseSidebar = () => {
    setIsSideBarOpen(false);
    setSelectedFormId(undefined);
  };

  const handleFilterSearch = (value: string) => {
    setFilter(value);
  };

  const handleUpdate = () => {
    handleCloseSidebar();
    handleGetContentGroups({
      projectId: String(projectId),
      type: ['FORM'],
      title: filter
    });
  }


  useEffect(() => {
    if (!projectId) return;
    handleGetContentGroups({
      projectId: String(projectId),
      type: ['FORM'],
      title: filter

    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, filter]);

  useEffect(() => {
    if (!allFormItems) {
      return;
    }

    setFormsTableData(
      getFormsTableData({
        formItems: allFormItems,
        onSettingsButtonClick: (id) => {
          handleSelectItem(id);
        },
        baseUrl: baseUrl,
        isBetaVersion,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFormItems]);

  useEffect(() => {
    if (!(project && isDomainReady && projectId)) return;
    setProjectName(project.name);
    setBaseUrl(
      isBetaVersion 
      ? `${String(dashboardLink)}/content/form-builder/form`
      : `${getDashboardUrl()}/${String(project.organisation.key)}/${String(projectId)}/form-builder/form`
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, isDomainReady, projectId, dashboardLink, isBetaVersion]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    setIsBetaVersion( queryIsBetaVersion === 'true' && projectSettings?.['HAS_NEW_BUILDER'] as boolean);
  }, [queryIsBetaVersion, projectSettings]);

  return (
    <>
      <TablePageTemplate<IFormsTableData>
        activePage="library"
        activeSubPage="form"
        additionalBreadcrumbItems={[{
          key: '1',
          content: 'Library',
        }, {
          key: '2',
          content: 'Forms',
        }]}
        columns={FORMS_TABLE_COLUMNS}
        data={formsTableData}
        defaultActiveTabId="id_general_tab"
        description={`Forms for ${String(projectName)}`}
        handleAddNewClick={addNewFormItem}
        handleSearch={handleFilterSearch}
        informationBoxMessage={(
          <FlexContainer alignItems="center" justifyContent="space-between">
            <FlexContainer alignItems="center" gap="small">
                {isBetaVersion && (
                  <Pill value="BETA" variant="green"/>
                )}
              <span>Manage project forms</span>
            </FlexContainer>
            {projectSettings?.['HAS_NEW_BUILDER'] as boolean && (
              <Button
                size="xs"
                text={`Switch to ${isBetaVersion ? 'Legacy': 'Beta'}`}
                variant="secondary"
                onClick={handleSwitch}
              />
            )}
          </FlexContainer>
        )}
        isLoading={isGetContentGroupsLoading}
        logoUrl={project?.logo?.presignedUrl}
        pageTitle={projectName}
        searchPlaceholder="Search"
        size={project?.logo?.presignedUrl ? 'small': 'medium'}
        tableType="forms"
        title={`${String(projectName)} forms`}
        hideSideBarButtons
      />

      <LibraryFormsSidebarView
        handleSideBarClose={handleCloseSidebar}
        isSideBarOpen={isSideBarOpen}
        projectId={String(projectId)}
        selectedFormId={selectedFormId}
        onUpdate={handleUpdate}
      />
    </>

  );
};

export { LibraryFormsPage };
