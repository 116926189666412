
import { useCapuchinMutation } from 'hooks';

import { DELETE_CONDITION_MUTATION } from './useDeleteScenarioCondition.graphql';
import {
  IDeleteScenarioConditionMutationGraphQLResponse,
  IDeleteScenarioConditionMutationVariables,
  IHandleDeleteScenarioConditionParams,
  IUseDeleteScenarioConditionOptions,
  IUseDeleteScenarioCondition,
} from './useDeleteScenarioCondition.interfaces';

const useDeleteScenarioCondition = (options?: IUseDeleteScenarioConditionOptions): IUseDeleteScenarioCondition => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteScenarioConditionOptions);

  const [executeDeleteScenarioCondition, { loading: isLoading }] = useCapuchinMutation<
    IDeleteScenarioConditionMutationGraphQLResponse,
    IDeleteScenarioConditionMutationVariables
  >({
    mutation: mutation ?? DELETE_CONDITION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          conditions: { delete: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteScenarioCondition = ({ conditionId }: IHandleDeleteScenarioConditionParams) => {
    void executeDeleteScenarioCondition({
      variables: {
        conditionId,
      },
    });
  };

  return {
    handleDeleteScenarioCondition,
    isLoading,
  };
};

export { useDeleteScenarioCondition };
